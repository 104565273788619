import DTO from "dto/dto";
import { ParkPermitType } from "type/parkpermit.type";
import V1DateTimeDTO from "../v1datetime.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebParkPermitDTO implements DTO {

  parkPermitUuid!: string;
  parkPermitValidFrom!: Date;
  parkPermitValidTo!: Date;
  parkPermitValidFromV1DateTimeDto!: V1DateTimeDTO;
  parkPermitValidToV1DateTimeDto!: V1DateTimeDTO;
  parkPermitDataPolicyAcceptedTimeStamp!: Date;
  parkPermitDataPolicyRevision!: string;
  parkPermitNotes!: string;
  parkPermitIsEnabled!: boolean;
  parkPermitParkPermitType!: ParkPermitType;
  abstractParkingAreaUuid!: string;
  abstractParkingAreaParkingAreaCode!: string;
  abstractParkingAreaAddress!: string;
  abstractParkingAreaZip!: string;
  abstractParkingAreaCity!: string;
  vehicleUuid!: string;
  vehicleVehicleId!: string;
  parkingLimitationUuid!: string;
  abstractUserClientUuid!: string;
  abstractUserClientUsername!: string;
}