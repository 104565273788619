import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { ErrorAction, ERROR_GET_PAGEABLELIST, ERROR_SEARCH_PAGEABLELIST } from "./error.action";

export type ErrorState = {
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: ErrorState = {
  status: IDLE_STATUS,
  pageableListCount: 0, 
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "createdDate",
  pageableListDirection: Direction.ASC,
  pageableListKeyword: "",
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function errorReducer(state: ErrorState = initialState, action: ErrorAction): ErrorState {
  switch(state.status) {
    case IDLE_STATUS: {
      return errorIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return errorLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return errorSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return errorFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default errorReducer;

function errorIdleReducer(state: ErrorState, action: ErrorAction): ErrorState {
  switch (action.type) {
    case ERROR_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case ERROR_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function errorLoadingReducer(state: ErrorState, action: ErrorAction): ErrorState {
  switch (action.type) {
    case ERROR_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case ERROR_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function errorSuccessReducer(state: ErrorState, action: ErrorAction): ErrorState {
  switch (action.type) {
    case ERROR_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case ERROR_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function errorFailureReducer(state: ErrorState, action: ErrorAction): ErrorState {
  switch (action.type) {
    case ERROR_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case ERROR_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}