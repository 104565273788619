import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { CustomerAction, CUSTOMER_GET, CUSTOMER_GET_PAGEABLELIST, CUSTOMER_GET_PARKINGAREA_PAGEABLELIST, CUSTOMER_GET_TRANSACTION_PAGEABLELIST, CUSTOMER_GET_USERCLIENT_PAGEABLELIST, CUSTOMER_PUT, CUSTOMER_SEARCH_PAGEABLELIST } from "./customer.action";

export type CustomerState = {
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  customerUuid: string;
  customerCompanyName: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: string;
  customerZip: string;
  customerCity: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerVatNumber: string;
  customerBankName: string;
  customerBankAccount: string;
  customerNotes: string;
  parkingAreaStatus: StateStatus;
  parkingAreaPageableListCount: number;
  parkingAreaPageableListPageNumber: number;
  parkingAreaPageableListPageSize: number;
  parkingAreaPageableListContent: Array<any[]>;
  parkingAreaPageableListSort: string;
  parkingAreaPageableListDirection: Direction;
  userClientStatus: StateStatus;
  userClientPageableListCount: number;
  userClientPageableListPageNumber: number;
  userClientPageableListPageSize: number;
  userClientPageableListContent: Array<any[]>;
  userClientPageableListSort: string;
  userClientPageableListDirection: Direction;
  transactionStatus: StateStatus;
  transactionPageableListCount: number;
  transactionPageableListPageNumber: number;
  transactionPageableListPageSize: number;
  transactionPageableListContent: Array<any[]>;
  transactionPageableListSort: string;
  transactionPageableListDirection: Direction;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: CustomerState = {
  status: IDLE_STATUS,
  pageableListCount: 0, 
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "companyName",
  pageableListDirection: Direction.ASC,
  pageableListKeyword: "",
  customerUuid: "",
  customerCompanyName: "",
  customerFirstName: "",
  customerLastName: "",
  customerAddress: "",
  customerZip: "",
  customerCity: "",
  customerEmail: "",
  customerPhoneNumber: "",
  customerVatNumber: "",
  customerBankName: "",
  customerBankAccount: "",
  customerNotes: "",
  parkingAreaStatus: IDLE_STATUS,
  parkingAreaPageableListCount: 0,
  parkingAreaPageableListPageNumber: 0,
  parkingAreaPageableListPageSize: 5,
  parkingAreaPageableListContent: Array<any[]>(),
  parkingAreaPageableListSort: "areaCode",
  parkingAreaPageableListDirection: Direction.ASC,
  userClientStatus: IDLE_STATUS,
  userClientPageableListCount: 0,
  userClientPageableListPageNumber: 0,
  userClientPageableListPageSize: 5,
  userClientPageableListContent: Array<any[]>(),
  userClientPageableListSort: "username",
  userClientPageableListDirection: Direction.ASC,
  transactionStatus: IDLE_STATUS,
  transactionPageableListCount: 0,
  transactionPageableListPageNumber: 0,
  transactionPageableListPageSize: 5,
  transactionPageableListContent: Array<any[]>(),
  transactionPageableListSort: "createdDate",
  transactionPageableListDirection: Direction.DESC,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function customerReducer(state: CustomerState = initialState, action: CustomerAction): CustomerState {
  switch(state.status) {
    case IDLE_STATUS: {
      return customerIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return customerLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return customerSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return customerFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default customerReducer;

function customerIdleReducer(state: CustomerState, action: CustomerAction): CustomerState {
  switch (action.type) {
    case CUSTOMER_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_GET_PARKINGAREA_PAGEABLELIST: {
      return {
        ...state,
        parkingAreaStatus: action.status
      }
    }
    case CUSTOMER_GET_USERCLIENT_PAGEABLELIST: {
      return {
        ...state,
        userClientStatus: action.status
      }
    }
    case CUSTOMER_GET_TRANSACTION_PAGEABLELIST: {
      return {
        ...state,
        transactionStatus: action.status
      }
    }
    case CUSTOMER_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function customerLoadingReducer(state: CustomerState, action: CustomerAction): CustomerState {
  switch (action.type) {
    case CUSTOMER_GET: {
      return {
        ...state,
        status: action.status,
        customerUuid: action.customerUuid,
        customerCompanyName: action.customerCompanyName,
        customerFirstName: action.customerFirstName,
        customerLastName: action.customerLastName,
        customerAddress: action.customerAddress,
        customerZip: action.customerZip,
        customerCity: action.customerCity,
        customerEmail: action.customerEmail,
        customerPhoneNumber: action.customerPhoneNumber,
        customerVatNumber: action.customerVatNumber,
        customerBankName: action.customerBankName,
        customerBankAccount: action.customerBankAccount,
        customerNotes: action.customerNotes,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case CUSTOMER_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        customerUuid: "",
        customerCompanyName: "",
        customerFirstName: "",
        customerLastName: "",
        customerAddress: "",
        customerZip: "",
        customerCity: "",
        customerEmail: "",
        customerPhoneNumber: "",
        customerVatNumber: "",
        customerBankName: "",
        customerBankAccount: "",
        customerNotes: "",
        parkingAreaStatus: IDLE_STATUS,
        parkingAreaPageableListCount: 0,
        parkingAreaPageableListPageNumber: 0,
        parkingAreaPageableListPageSize: 5,
        parkingAreaPageableListContent: Array<any[]>(),
        parkingAreaPageableListSort: "areaCode",
        parkingAreaPageableListDirection: Direction.ASC,
        userClientStatus: IDLE_STATUS,
        userClientPageableListCount: 0,
        userClientPageableListPageNumber: 0,
        userClientPageableListPageSize: 5,
        userClientPageableListContent: Array<any[]>(),
        userClientPageableListSort: "username",
        userClientPageableListDirection: Direction.ASC,
        transactionStatus: IDLE_STATUS,
        transactionPageableListCount: 0,
        transactionPageableListPageNumber: 0,
        transactionPageableListPageSize: 5,
        transactionPageableListContent: Array<any[]>(),
        transactionPageableListSort: "createdDate",
        transactionPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case CUSTOMER_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        customerUuid: "",
        customerCompanyName: "",
        customerFirstName: "",
        customerLastName: "",
        customerAddress: "",
        customerZip: "",
        customerCity: "",
        customerEmail: "",
        customerPhoneNumber: "",
        customerVatNumber: "",
        customerBankName: "",
        customerBankAccount: "",
        customerNotes: "",
        parkingAreaStatus: IDLE_STATUS,
        parkingAreaPageableListCount: 0,
        parkingAreaPageableListPageNumber: 0,
        parkingAreaPageableListPageSize: 5,
        parkingAreaPageableListContent: Array<any[]>(),
        parkingAreaPageableListSort: "areaCode",
        parkingAreaPageableListDirection: Direction.ASC,
        userClientStatus: IDLE_STATUS,
        userClientPageableListCount: 0,
        userClientPageableListPageNumber: 0,
        userClientPageableListPageSize: 5,
        userClientPageableListContent: Array<any[]>(),
        userClientPageableListSort: "username",
        userClientPageableListDirection: Direction.ASC,
        transactionStatus: IDLE_STATUS,
        transactionPageableListCount: 0,
        transactionPageableListPageNumber: 0,
        transactionPageableListPageSize: 5,
        transactionPageableListContent: Array<any[]>(),
        transactionPageableListSort: "createdDate",
        transactionPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case CUSTOMER_GET_PARKINGAREA_PAGEABLELIST: {
      return {
        ...state,
        parkingAreaStatus: action.status,
        parkingAreaPageableListCount: action.parkingAreaPageableListCount,
        parkingAreaPageableListPageNumber: action.parkingAreaPageableListPageNumber,
        parkingAreaPageableListPageSize: action.parkingAreaPageableListPageSize,
        parkingAreaPageableListContent: action.parkingAreaPageableListContent,
        parkingAreaPageableListSort: action.parkingAreaPageableListSort,
        parkingAreaPageableListDirection: action.parkingAreaPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case CUSTOMER_GET_USERCLIENT_PAGEABLELIST: {
      return {
        ...state,
        userClientStatus: action.status,
        userClientPageableListCount: action.userClientPageableListCount,
        userClientPageableListPageNumber: action.userClientPageableListPageNumber,
        userClientPageableListPageSize: action.userClientPageableListPageSize,
        userClientPageableListContent: action.userClientPageableListContent,
        userClientPageableListSort: action.userClientPageableListSort,
        userClientPageableListDirection: action.userClientPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case CUSTOMER_GET_TRANSACTION_PAGEABLELIST: {
      return {
        ...state,
        transactionStatus: action.status,
        transactionPageableListCount: action.transactionPageableListCount,
        transactionPageableListPageNumber: action.transactionPageableListPageNumber,
        transactionPageableListPageSize: action.transactionPageableListPageSize,
        transactionPageableListContent: action.transactionPageableListContent,
        transactionPageableListSort: action.transactionPageableListSort,
        transactionPageableListDirection: action.transactionPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case CUSTOMER_PUT: {
      return {
        ...state,
        status: action.status,
        customerUuid: action.customerUuid,
        customerCompanyName: action.customerCompanyName,
        customerFirstName: action.customerFirstName,
        customerLastName: action.customerLastName,
        customerAddress: action.customerAddress,
        customerZip: action.customerZip,
        customerCity: action.customerCity,
        customerEmail: action.customerEmail,
        customerPhoneNumber: action.customerPhoneNumber,
        customerVatNumber: action.customerVatNumber,
        customerBankName: action.customerBankName,
        customerBankAccount: action.customerBankAccount,
        customerNotes: action.customerNotes,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function customerSuccessReducer(state: CustomerState, action: CustomerAction): CustomerState {
  switch (action.type) {
    case CUSTOMER_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_GET_PARKINGAREA_PAGEABLELIST: {
      switch (state.parkingAreaStatus) {
        case IDLE_STATUS: {
          return customerIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return customerLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return customerFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            parkingAreaStatus: action.status
          }
        }
      }
    }
    case CUSTOMER_GET_USERCLIENT_PAGEABLELIST: {
      switch (state.userClientStatus) {
        case IDLE_STATUS: {
          return customerIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return customerLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return customerFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            userClientStatus: action.status
          }
        }
      }
    }
    case CUSTOMER_GET_TRANSACTION_PAGEABLELIST: {
      switch (state.transactionStatus) {
        case IDLE_STATUS: {
          return customerIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return customerLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return customerFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            transactionStatus: action.status
          }
        }
      }
    }
    case CUSTOMER_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function customerFailureReducer(state: CustomerState, action: CustomerAction): CustomerState {
  switch (action.type) {
    case CUSTOMER_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case CUSTOMER_GET_PARKINGAREA_PAGEABLELIST: {
      return {
        ...state,
        parkingAreaStatus: action.status
      }
    }
    case CUSTOMER_GET_USERCLIENT_PAGEABLELIST: {
      return {
        ...state,
        userClientStatus: action.status
      }
    }
    case CUSTOMER_GET_TRANSACTION_PAGEABLELIST: {
      return {
        ...state,
        transactionStatus: action.status
      }
    }
    case CUSTOMER_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}