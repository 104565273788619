import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebErrorListElementDTO from "dto/v1/web/v1weberrorlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebErrorService from "service/v1/web/v1weberror.service";
import { ErrorState } from "./error.reducer";

export const ERROR_GET_PAGEABLELIST = "error/get/pageablelist";
export const ERROR_SEARCH_PAGEABLELIST = "error/search/pageablelist";

export interface ErrorAction {
  type:
    typeof ERROR_GET_PAGEABLELIST |
    typeof ERROR_SEARCH_PAGEABLELIST
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type ErrorThunkAction = ThunkAction<Promise<void>, ErrorState, any, ErrorAction>;

export function errorGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ErrorThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ERROR_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebErrorService.getErrors(page).then((response: AxiosResponse) => {
        let errorPageableList = response.data as Response<V1WebErrorListElementDTO>

        let pageableListContent: any[] = [];
        errorPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractErrorUuid, value.abstractErrorErrorType])
        });

        dispatch({
          type: ERROR_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: errorPageableList.totalElements,
          pageableListPageNumber: errorPageableList.pageable.pageNumber,
          pageableListPageSize: errorPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: ERROR_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function errorSearchPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ErrorThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ERROR_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebErrorService.searchError(v1keywordDto, page).then((response: AxiosResponse) => {
        let errorPageableList = response.data as Response<V1WebErrorListElementDTO>

        let pageableListContent: any[] = [];
        errorPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractErrorUuid, value.abstractErrorErrorType])
        });

        dispatch({
          type: ERROR_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: errorPageableList.totalElements,
          pageableListPageNumber: errorPageableList.pageable.pageNumber,
          pageableListPageSize: errorPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: ERROR_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}