import React from "react";

const Logo: React.FC = () => {
  return (
    <>
      <img
        alt="Logo"
        src="/asset/logo.svg"
      />
    </>
  );
};

export default Logo;