import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { VehicleAction, VEHICLE_GET, VEHICLE_GET_PAGEABLELIST, VEHICLE_GET_PARKPERMIT_PAGEABLELIST, VEHICLE_GET_TICKET_PAGEABLELIST, VEHICLE_PUT, VEHICLE_SEARCH_PAGEABLELIST } from "./vehicle.action";

export type VehicleState = {
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  vehicleUuid: string
  vehicleVehicleId: string
  vehicleNotes: string
  vehicleIsAllowedToPark: boolean
  parkPermitStatus: StateStatus
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  ticketStatus: StateStatus
  ticketPageableListCount: number
  ticketPageableListPageNumber: number
  ticketPageableListPageSize: number
  ticketPageableListContent: Array<any[]>
  ticketPageableListSort: string
  ticketPageableListDirection: Direction
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: VehicleState = {
  status: IDLE_STATUS,
  pageableListCount: 0, 
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "vehicleId",
  pageableListDirection: Direction.ASC,
  pageableListKeyword: "",
  vehicleUuid: "",
  vehicleVehicleId: "",
  vehicleNotes: "",
  vehicleIsAllowedToPark: false,
  parkPermitStatus: IDLE_STATUS,
  parkPermitPageableListCount: 0,
  parkPermitPageableListPageNumber: 0,
  parkPermitPageableListPageSize: 5,
  parkPermitPageableListContent: Array<any[]>(),
  parkPermitPageableListSort: "createdDate",
  parkPermitPageableListDirection: Direction.DESC,
  ticketStatus: IDLE_STATUS,
  ticketPageableListCount: 0,
  ticketPageableListPageNumber: 0,
  ticketPageableListPageSize: 5,
  ticketPageableListContent: Array<any[]>(),
  ticketPageableListSort: "createdDate",
  ticketPageableListDirection: Direction.ASC,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function vehicleReducer(state: VehicleState = initialState, action: VehicleAction): VehicleState {
  switch(state.status) {
    case IDLE_STATUS: {
      return vehicleIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return vehicleLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return vehicleSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return vehicleFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default vehicleReducer;

function vehicleIdleReducer(state: VehicleState, action: VehicleAction): VehicleState {
  switch (action.type) {
    case VEHICLE_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_GET_PARKPERMIT_PAGEABLELIST: {
      return {
        ...state,
        parkPermitStatus: action.status
      }
    }
    case VEHICLE_GET_TICKET_PAGEABLELIST: {
      return {
        ...state,
        ticketStatus: action.status
      }
    }
    case VEHICLE_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function vehicleLoadingReducer(state: VehicleState, action: VehicleAction): VehicleState {
  switch (action.type) {
    case VEHICLE_GET: {
      return {
        ...state,
        status: action.status,
        vehicleUuid: action.vehicleUuid,
        vehicleVehicleId: action.vehicleVehicleId,
        vehicleNotes: action.vehicleNotes,
        vehicleIsAllowedToPark: action.vehicleIsAllowedToPark,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case VEHICLE_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        vehicleNotes: "",
        vehicleIsAllowedToPark: false,
        parkPermitStatus: IDLE_STATUS,
        parkPermitPageableListCount: 0,
        parkPermitPageableListPageNumber: 0,
        parkPermitPageableListPageSize: 5,
        parkPermitPageableListContent: Array<any[]>(),
        parkPermitPageableListSort: "createdDate",
        parkPermitPageableListDirection: Direction.DESC,
        ticketStatus: IDLE_STATUS,
        ticketPageableListCount: 0,
        ticketPageableListPageNumber: 0,
        ticketPageableListPageSize: 5,
        ticketPageableListContent: Array<any[]>(),
        ticketPageableListSort: "createdDate",
        ticketPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case VEHICLE_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        vehicleUuid: "",
        vehicleVehicleId: "",
        vehicleNotes: "",
        vehicleIsAllowedToPark: false,
        parkPermitStatus: IDLE_STATUS,
        parkPermitPageableListCount: 0,
        parkPermitPageableListPageNumber: 0,
        parkPermitPageableListPageSize: 5,
        parkPermitPageableListContent: Array<any[]>(),
        parkPermitPageableListSort: "createdDate",
        parkPermitPageableListDirection: Direction.DESC,
        ticketStatus: IDLE_STATUS,
        ticketPageableListCount: 0,
        ticketPageableListPageNumber: 0,
        ticketPageableListPageSize: 5,
        ticketPageableListContent: Array<any[]>(),
        ticketPageableListSort: "createdDate",
        ticketPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case VEHICLE_GET_PARKPERMIT_PAGEABLELIST: {
      return {
        ...state,
        parkPermitStatus: action.status,
        parkPermitPageableListCount: action.parkPermitPageableListCount,
        parkPermitPageableListPageNumber: action.parkPermitPageableListPageNumber,
        parkPermitPageableListPageSize: action.parkPermitPageableListPageSize,
        parkPermitPageableListContent: action.parkPermitPageableListContent,
        parkPermitPageableListSort: action.parkPermitPageableListSort,
        parkPermitPageableListDirection: action.parkPermitPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case VEHICLE_GET_TICKET_PAGEABLELIST: {
      return {
        ...state,
        ticketStatus: action.status,
        ticketPageableListCount: action.ticketPageableListCount,
        ticketPageableListPageNumber: action.ticketPageableListPageNumber,
        ticketPageableListPageSize: action.ticketPageableListPageSize,
        ticketPageableListContent: action.ticketPageableListContent,
        ticketPageableListSort: action.ticketPageableListSort,
        ticketPageableListDirection: action.ticketPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case VEHICLE_PUT: {
      return {
        ...state,
        status: action.status,
        vehicleUuid: action.vehicleUuid,
        vehicleNotes: action.vehicleNotes,
        vehicleIsAllowedToPark: action.vehicleIsAllowedToPark,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function vehicleSuccessReducer(state: VehicleState, action: VehicleAction): VehicleState {
  switch (action.type) {
    case VEHICLE_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_GET_PARKPERMIT_PAGEABLELIST: {
      switch(state.parkPermitStatus) {
        case IDLE_STATUS: {
          return vehicleIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return vehicleLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return vehicleFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            parkPermitStatus: action.status
          }
        }
      }
    }
    case VEHICLE_GET_TICKET_PAGEABLELIST: {
      switch(state.ticketStatus) {
        case IDLE_STATUS: {
          return vehicleIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return vehicleLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return vehicleFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            ticketStatus: action.status
          }
        }
      }
    }
    case VEHICLE_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function vehicleFailureReducer(state: VehicleState, action: VehicleAction): VehicleState {
  switch (action.type) {
    case VEHICLE_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case VEHICLE_GET_PARKPERMIT_PAGEABLELIST: {
      return {
        ...state,
        parkPermitStatus: action.status
      }
    }
    case VEHICLE_GET_TICKET_PAGEABLELIST: {
      return {
        ...state,
        ticketStatus: action.status
      }
    }
    case VEHICLE_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}