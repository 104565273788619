import { Box, TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootRedux } from "redux/store";

interface OwnProps {
  tableColSpan: number | null
}

interface DispatchProps {
}

interface StateProps {
}

type Props = StateProps & OwnProps & DispatchProps

const EmptyContainer = (props: Props) => {
  const [tableColSpan, setTableColSpan] = useState(100);

  useEffect(() => {
    setTableColSpan(props.tableColSpan === null || props.tableColSpan === undefined ? 100 : props.tableColSpan);
  }, [props.tableColSpan]); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <>
      <TableRow
        hover
        key={1}
        
      >
        <TableCell
          colSpan={tableColSpan + 1}
          height={1 * 73} 
          sx={{
            '&:hover': {
              background: "#fff",
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              p: 1
            }}
          >
            Der er ingen data at vise
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(EmptyContainer)