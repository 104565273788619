import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebCustomerDTO from "dto/v1/web/v1webcustomer.dto";

class V1WebCustomerService {
  createCustomer(v1WebCustomerDto: V1WebCustomerDTO) {
    return axiosConfig.post("v1/web/customer/", v1WebCustomerDto);
  }
  getCustomer(customerUuid: string) {
    return axiosConfig.get("v1/web/customer/" + customerUuid);
  }
  getCustomers(page: Page) {
    return axiosConfig.get("v1/web/customer?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchCustomer(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/customer/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  existsCompanyName(companyName: string) {
    return axiosConfig.get("v1/web/customer/companyname/" + companyName);
  }
  getCustomerParkingArea(customerUuid: string, page: Page) {
    return axiosConfig.get("v1/web/customer/" + customerUuid + "/parkingarea?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getCustomerUserClient(customerUuid: string, page: Page) {
    return axiosConfig.get("v1/web/customer/" + customerUuid + "/userclient?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getCustomerTransaction(customerUuid: string, page: Page) {
    return axiosConfig.get("v1/web/customer/" + customerUuid + "/transaction?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  putCustomer(v1WebCustomerDto: V1WebCustomerDTO) {
    return axiosConfig.put("v1/web/customer/", v1WebCustomerDto);
  }
}

export default new V1WebCustomerService();