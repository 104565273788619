import { UserType } from "type/user.type";
import V1WebAbstractUserClientDTO from "./v1webabstractuserclient.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebUserClientKioskDTO extends V1WebAbstractUserClientDTO {

  abstractParkingAreaUuid!: string;
  abstractParkingAreaParkingAreaCode!: string;

  constructor() {
    super();
    this.abstractUserUserType = UserType.KIOSK;
  }
}