import { Typography, Grid, Card, CardHeader, CardContent, TextField, Autocomplete, CircularProgress, FormControlLabel, Switch, Divider, Box, Button, Table, TableHead, TableRow, TableCell, TableSortLabel, TableBody, TableFooter, TablePagination, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, IconButton, Tooltip } from "@mui/material";
import CustomContainer from "component/customcontainer";
import SpinnerContainer from "component/spinnercontainer";
import UUIDHelper from "helper/uuidhelper";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store"
import { UserType } from "type/user.type";
import EmptyContainer from "component/emptycontainer";
import ErrorContainer from "component/errorcontainer";
import { areaAdd, getUserClientAreaOperatorUserClientPageableList, getUserClientAreaParkingAreaPageableList, getUserClientParkPermitPageableList, userAdd, userGet, userGetAuthorityPageableList, userGetTokenPageableList, userPut, userPutAuthorities } from "redux/user/user.action";
import { AxiosResponse } from "axios";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebCustomerListElementDTO from "dto/v1/web/v1webcustomerlistelement.dto";
import { Direction } from "dto/direction";
import v1WebCustomerService from "service/v1/web/v1webcustomer.service"
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service"
import Response from "dto/response";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import v1WebUserService from "service/v1/web/v1webuser.service";
import HeadCell from "component/headcell";
import FetchingContainer from "component/fetchingcontainer";
import { TokenType } from "type/token.type";
import V1WebWelcomeUserDTO from "dto/v1/web/v1webwelcomeuser.dto";
import { authorityGetPageableList } from "redux/authority/authority.action";
import { NavigateNext } from "@mui/icons-material";
import V1WebUserListElementDTO from "dto/v1/web/v1webuserlistelement.dto";

interface OwnProps {
}

interface DispatchProps {
  getUserClientParkPermitPageableList: (userUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  getUserClientAreaParkingAreaPageableList: (userUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  getUserClientAreaOperatorUserClientPageableList: (userUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  userGet: (userUuid: string) => void;
  userGetAuthorityPageableList: (userUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  userGetTokenPageableList: (userUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  userPut: (userUuid: string, userUsername: string, userFirstName: string, userLastName: string, userAddress: string, userZip: string, userCity: string, userEmail: string, userPhone: string, userUserType: UserType, userNotes: string, userIsEnabled: boolean, userClientHasTextMessageNotificationService: boolean, userClientHasEmailNotificationService: boolean, userClientCustomerUuid: string, userClientKioskParkingAreaUuid: string) => void;
  authorityGetPageableList: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  userPutAuthorities: (userUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction, authorityUuids: string[]) => void;
  areaAdd: (userUuid: string, areaUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  userAdd: (userUuid: string, userClientUuid: string, multiSetup: boolean, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  userStateStatus: StateStatus;
  userUuid: string;
  userUsername: string;
  userFirstName: string;
  userLastName: string;
  userAddress: string;
  userZip: string;
  userCity: string;
  userEmail: string;
  userPhone: string;
  userUserType: UserType | null;
  userNotes: string;
  userIsEnabled: boolean;
  userIsAccountNonExpired: boolean;
  userIsAccountNonLocked: boolean;
  userIsCredentialsNonExpired: boolean;
  userCredentialsDate: Date | null;
  userClientHasTextMessageNotificationService: boolean;
  userClientHasEmailNotificationService: boolean;
  userClientCustomerUuid: string;
  userClientCustomerCompanyName: string;
  userClientKioskParkingAreaUuid: string;
  userClientKioskParkingAreaParkingAreaCode: string;
  userGuardTicketCount: number;
  userGuardGuardId: number;
  userAuthorityStatus: StateStatus
  userAuthorityPageableListCount: number
  userAuthorityPageableListPageNumber: number
  userAuthorityPageableListPageSize: number
  userAuthorityPageableListContent: Array<any[]>
  userAuthorityPageableListSort: string
  userAuthorityPageableListDirection: Direction
  userTokenStatus: StateStatus
  userTokenPageableListCount: number
  userTokenPageableListPageNumber: number
  userTokenPageableListPageSize: number
  userTokenPageableListContent: Array<any[]>
  userTokenPageableListSort: string
  userTokenPageableListDirection: Direction
  authorityStateStatus: StateStatus
  authorityPageableListCount: number
  authorityPageableListPageNumber: number
  authorityPageableListPageSize: number
  authorityPageableListContent: Array<any[]>
  authorityPageableListSort: string
  authorityPageableListDirection: Direction

  parkPermitStateStatus: StateStatus
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  parkingAreaStateStatus: StateStatus
  parkingAreaPageableListCount: number
  parkingAreaPageableListPageNumber: number
  parkingAreaPageableListPageSize: number
  parkingAreaPageableListContent: Array<any[]>
  parkingAreaPageableListSort: string
  parkingAreaPageableListDirection: Direction
  userClientStateStatus: StateStatus
  userClientPageableListCount: number
  userClientPageableListPageNumber: number
  userClientPageableListPageSize: number
  userClientPageableListContent: Array<any[]>
  userClientPageableListSort: string
  userClientPageableListDirection: Direction

  userErrorCode: number
  userErrorMessage: string
  userErrorUuid: string
}

interface FormProps {
  selectedAuthorityList: string[];
}

interface FormProps2 {
  userClientParkingAreaParkingAreaCode: string;
  userClientParkingAreaUuid: string;
}

interface FormProps3 {
  userClientUserClientEmail: string;
  userClientUserClientUsername: string;
  userClientUserClientMultiSetup: boolean;
  userClientUserClientUuid: string;
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorUserView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isUserAuthorityLoaded, setUserAuthorityLoaded] = useState(false);
  const [isParkPermitLoaded, setParkPermitLoaded] = useState(false);
  const [isParkingAreaListLoaded, setParkingAreaListLoaded] = useState(false);
  const [isUserClientListLoaded, setUserClientListLoaded] = useState(false);
  const [isTokenLoaded, setTokenLoaded] = useState(false);
  const [isAuthorityLoaded, setAuthorityLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkingAreaLoading, setParkingAreaLoading] = useState(false);
  const [isCustomerLoading, setCustomerLoading] = useState(false);
  const [isUserClientLoading, setUserClientLoading] = useState(false);
  const [isUserAuthorityLoading, setUserAuthorityLoading] = useState(true);
  const [isParkPermitLoading, setParkPermitLoading] = useState(true);
  const [isParkingAreaListLoading, setParkingAreaListLoading] = useState(true);
  const [isUserClientListLoading, setUserClientListLoading] = useState(true);
  const [isTokenLoading, setTokenLoading] = useState(true);
  const [isAuthorityLoading, setAuthorityLoading] = useState(true)
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [showDialogViewAlert, setShowDialogViewAlert] = useState(false);
  const [parkingAreaOptions, setParkingAreaOptions] = useState(Array<any[]>());
  const [customerOptions, setCustomerOptions] = useState(Array<any[]>());
  const [userClientOptions, setUserClientOptions] = useState(Array<any[]>());
  const [isUserActivated, setUserActivated] = useState(false);
  const [isAuthorityAddDialogOpen, setAuthorityAddDialogOpen] = useState(false);
  const [isUserAddDialogOpen, setUserAddDialogOpen] = useState(false);
  const [isAreaAddDialogOpen, setAreaAddDialogOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    getValues: getUpdateFormValues,
    setValue: setUpdateFormValue,
    watch: watchUpdateForm
  } = useForm<StateProps>({
    mode: 'all'
  });

  const watchUserClientKioskParkingAreaParkingAreaCode = watchUpdateForm("userClientKioskParkingAreaParkingAreaCode");
  const watchUserClientCustomerCompanyName = watchUpdateForm("userClientCustomerCompanyName");

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading && formProps.userUserType !== null) {
      props.userPut(formProps.userUuid, formProps.userUsername, formProps.userFirstName, formProps.userLastName, formProps.userAddress, formProps.userZip, formProps.userCity, formProps.userEmail, formProps.userPhone, formProps.userUserType, formProps.userNotes, formProps.userIsEnabled, formProps.userClientHasTextMessageNotificationService, formProps.userClientHasEmailNotificationService, formProps.userClientCustomerUuid, formProps.userClientKioskParkingAreaUuid);
    }
  }

  const {
    handleSubmit: handleAddAuthorityFormSubmit,
    control: addAuthorityFormControl,
    reset: resetAddAuthorityForm,
    getValues: getAddAuthorityFormValues,
    setValue: setAddAuthorityFormValue
  } = useForm<FormProps>({
    mode: 'all'
  });

  const onAddAuthorityFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isAuthorityLoading && !isUserAuthorityLoading && props.userUuid !== null) {
      props.userPutAuthorities(props.userUuid, props.userAuthorityPageableListPageNumber, props.userAuthorityPageableListPageSize, props.userAuthorityPageableListSort, props.userAuthorityPageableListDirection, formProps.selectedAuthorityList);
      setAuthorityAddDialogOpen(false);
      setAuthorityLoaded(false);
    }
  }

  const {
    handleSubmit: handleAddAreaFormSubmit,
    control: addAreaFormControl,
    reset: resetAddAreaForm,
    getValues: getAddAreaFormValues,
    setValue: setAddAreaFormValue,
    watch: watchAddAreaForm
  } = useForm<FormProps2>({
    mode: 'all'
  });

  const watchUserClientParkingAreaParkingAreaCode = watchAddAreaForm("userClientParkingAreaParkingAreaCode");

  const onAddAreaFormSubmit: SubmitHandler<FormProps2> = (formProps2: FormProps2) => {
    if (!isParkingAreaListLoading && props.userUuid !== null && formProps2.userClientParkingAreaUuid !== null) {
      props.areaAdd(props.userUuid, formProps2.userClientParkingAreaUuid, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
      handleAreaAddCloseClick();
    }
  }

  const {
    handleSubmit: handleAddUserFormSubmit,
    control: addUserFormControl,
    reset: resetAddUserForm,
    getValues: getAddUserFormValues,
    setValue: setAddUserFormValue,
    watch: watchAddUserForm
  } = useForm<FormProps3>({
    mode: 'all'
  });

  const watchUserClienUserClientEmail = watchAddUserForm("userClientUserClientEmail");

  const onAddUserFormSubmit: SubmitHandler<FormProps3> = (formProps3: FormProps3) => {
    if (!isUserClientLoading && props.userUuid !== null && formProps3.userClientUserClientUuid !== null) {
      props.userAdd(props.userUuid, formProps3.userClientUserClientUuid, formProps3.userClientUserClientMultiSetup, props.userClientPageableListPageNumber, props.userClientPageableListPageSize, props.userClientPageableListSort, props.userClientPageableListDirection)
      handleUserAddCloseClick();
    }
  }

  const handleUserAuthorityPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.userGetAuthorityPageableList(props.userUuid, 0, parseInt(event.target.value), props.userAuthorityPageableListSort, props.userAuthorityPageableListDirection);
  };

  const handleUserAuthorityPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.userGetAuthorityPageableList(props.userUuid, newPage, props.userAuthorityPageableListPageSize, props.userAuthorityPageableListSort, props.userAuthorityPageableListDirection)
  }

  const handleUserAuthorityDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.userAuthorityPageableListDirection === Direction.ASC) {
      props.userGetAuthorityPageableList(props.userUuid, props.userAuthorityPageableListPageNumber, props.userAuthorityPageableListPageSize, property, Direction.DESC);
    } else {
      props.userGetAuthorityPageableList(props.userUuid, props.userAuthorityPageableListPageNumber, props.userAuthorityPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleUserTokenPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.userGetTokenPageableList(props.userUuid, 0, parseInt(event.target.value), props.userTokenPageableListSort, props.userTokenPageableListDirection);
  };

  const handleUserTokenPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.userGetTokenPageableList(props.userUuid, newPage, props.userTokenPageableListPageSize, props.userTokenPageableListSort, props.userTokenPageableListDirection)
  }

  const handleUserTokenDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.userTokenPageableListDirection === Direction.ASC) {
      props.userGetTokenPageableList(props.userUuid, props.userTokenPageableListPageNumber, props.userTokenPageableListPageSize, property, Direction.DESC);
    } else {
      props.userGetTokenPageableList(props.userUuid, props.userTokenPageableListPageNumber, props.userTokenPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleAuthorityPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.authorityGetPageableList(0, parseInt(event.target.value), props.authorityPageableListSort, props.authorityPageableListDirection);
  };

  const handleAuthorityPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.authorityGetPageableList(newPage, props.authorityPageableListPageSize, props.authorityPageableListSort, props.authorityPageableListDirection)
  }

  const handleAuthorityDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.authorityPageableListDirection === Direction.ASC) {
      props.authorityGetPageableList(props.authorityPageableListPageNumber, props.authorityPageableListPageSize, property, Direction.DESC);
    } else {
      props.authorityGetPageableList(props.authorityPageableListPageNumber, props.authorityPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleParkPermitPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.getUserClientParkPermitPageableList(props.userUuid, 0, parseInt(event.target.value), props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
  };

  const handleParkPermitPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.getUserClientParkPermitPageableList(props.userUuid, newPage, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection)
  }

  const handleParkPermitDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkPermitPageableListDirection === Direction.ASC) {
      props.getUserClientParkPermitPageableList(props.userUuid, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.DESC);
    } else {
      props.getUserClientParkPermitPageableList(props.userUuid, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleParkingAreaPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.getUserClientAreaParkingAreaPageableList(props.userUuid, 0, parseInt(event.target.value), props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
  };

  const handleParkingAreaPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.getUserClientAreaParkingAreaPageableList(props.userUuid, newPage, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection)
  }

  const handleParkingAreaDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkingAreaPageableListDirection === Direction.ASC) {
      props.getUserClientAreaParkingAreaPageableList(props.userUuid, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, property, Direction.DESC);
    } else {
      props.getUserClientAreaParkingAreaPageableList(props.userUuid, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleUserClientPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.getUserClientAreaOperatorUserClientPageableList(props.userUuid, 0, parseInt(event.target.value), props.userClientPageableListSort, props.userClientPageableListDirection);
  };

  const handleUserClientPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.getUserClientAreaOperatorUserClientPageableList(props.userUuid, newPage, props.userClientPageableListPageSize, props.userClientPageableListSort, props.userClientPageableListDirection)
  }

  const handleUserClientDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.userClientPageableListDirection === Direction.ASC) {
      props.getUserClientAreaOperatorUserClientPageableList(props.userUuid, props.userClientPageableListPageNumber, props.userClientPageableListPageSize, property, Direction.DESC);
    } else {
      props.getUserClientAreaOperatorUserClientPageableList(props.userUuid, props.userClientPageableListPageNumber, props.userClientPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleAuthorityAddOpenClick = () => {
    if (!isAuthorityLoaded) {
      props.authorityGetPageableList(props.authorityPageableListPageNumber, props.authorityPageableListPageSize, props.authorityPageableListSort, props.authorityPageableListDirection);
      setAuthorityLoaded(true);
      setAuthorityAddDialogOpen(true);
    }
  }

  const handleAuthorityAddCloseClick = () => {
    resetAddAuthorityForm();
    setAuthorityLoaded(false)
    setAuthorityAddDialogOpen(false);
  }

  const handleAreaAddOpenClick = () => {
    setAreaAddDialogOpen(true);
  }

  const handleAreaAddCloseClick = () => {
    resetAddAreaForm();
    setAreaAddDialogOpen(false);
  }

  const handleUserAddOpenClick = () => {
    setUserAddDialogOpen(true);
  }

  const handleUserAddCloseClick = () => {
    resetAddUserForm();
    setUserAddDialogOpen(false);
  }

  const handleWelcomeUser = () => {
    if (!isViewLoading && props.userUuid !== null) {
      let v1WebWelcomeUserDto: V1WebWelcomeUserDTO = {
        abstractUserUuid: props.userUuid
      }

      v1WebUserService.welcomeUser(v1WebWelcomeUserDto).then((response: AxiosResponse) => {
        setUserActivated(response.data as boolean)
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      })
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.userUuid !== undefined && UUIDHelper.validateUUID(params.userUuid)) {
        props.userGet(params.userUuid);
      } else {
        navigate('404', { replace: true });
      }
      setParkPermitLoaded(false);
      setParkingAreaListLoaded(false);
      setUserClientListLoaded(false);
      setUserAuthorityLoaded(false);
      setTokenLoaded(false);
      setViewLoaded(true);
    }
  }, [isViewLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isParkPermitLoaded && props.userUuid !== undefined && props.userUuid !== "" && props.userUserType !== null && (props.userUserType === UserType.KIOSK || props.userUserType === UserType.OPERATOR || props.userUserType === UserType.PUBLIC)) {
      props.getUserClientParkPermitPageableList(props.userUuid, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
      setParkPermitLoaded(true);
    }
    if (!isParkingAreaListLoaded && props.userUuid !== undefined && props.userUuid !== "" && props.userUserType !== null && (props.userUserType === UserType.OPERATOR)) {
      props.getUserClientAreaParkingAreaPageableList(props.userUuid, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
      setParkingAreaListLoaded(true);
    }
    if (!isUserClientListLoaded && props.userUuid !== undefined && props.userUuid !== "" && props.userUserType !== null && (props.userUserType === UserType.OPERATOR)) {
      props.getUserClientAreaOperatorUserClientPageableList(props.userUuid, props.userClientPageableListPageNumber, props.userClientPageableListPageSize, props.userClientPageableListSort, props.userClientPageableListDirection);
      setUserClientListLoaded(true);
    }
    if (!isUserAuthorityLoaded && props.userUuid !== undefined && props.userUuid !== "") {
      props.userGetAuthorityPageableList(props.userUuid, props.userAuthorityPageableListPageNumber, props.userAuthorityPageableListPageSize, props.userAuthorityPageableListSort, props.userAuthorityPageableListDirection);
      setUserAuthorityLoaded(true);
    }

    if (!isTokenLoaded && props.userUuid !== undefined && props.userUuid !== "") {
      props.userGetTokenPageableList(props.userUuid, props.userTokenPageableListPageNumber, props.userTokenPageableListPageSize, props.userTokenPageableListSort, props.userTokenPageableListDirection);
      setTokenLoaded(true);
    }
  }, [props.userUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.userStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("userUuid", props.userUuid);
        setUpdateFormValue("userUsername", props.userUsername);
        setUpdateFormValue("userFirstName", props.userFirstName);
        setUpdateFormValue("userLastName", props.userLastName);
        setUpdateFormValue("userAddress", props.userAddress);
        setUpdateFormValue("userZip", props.userZip);
        setUpdateFormValue("userCity", props.userCity);
        setUpdateFormValue("userEmail", props.userEmail);
        setUpdateFormValue("userPhone", props.userPhone);
        setUpdateFormValue("userUserType", props.userUserType);
        setUpdateFormValue("userNotes", props.userNotes);
        setUpdateFormValue("userIsEnabled", props.userIsEnabled);
        setUpdateFormValue("userIsAccountNonExpired", props.userIsAccountNonExpired);
        setUpdateFormValue("userIsAccountNonLocked", props.userIsAccountNonLocked);
        setUpdateFormValue("userIsCredentialsNonExpired", props.userIsCredentialsNonExpired);
        setUpdateFormValue("userClientHasTextMessageNotificationService", props.userClientHasTextMessageNotificationService);
        setUpdateFormValue("userClientHasEmailNotificationService", props.userClientHasEmailNotificationService);
        setUpdateFormValue("userClientCustomerUuid", props.userClientCustomerUuid);
        setUpdateFormValue("userClientCustomerCompanyName", props.userClientCustomerCompanyName);
        setUpdateFormValue("userClientKioskParkingAreaUuid", props.userClientKioskParkingAreaUuid);
        setUpdateFormValue("userClientKioskParkingAreaParkingAreaCode", props.userClientKioskParkingAreaParkingAreaCode);
        setUpdateFormValue("userGuardTicketCount", props.userGuardTicketCount);
        setUpdateFormValue("userGuardGuardId", props.userGuardGuardId);

        if (props.userCredentialsDate !== null && props.userCredentialsDate !== undefined) {
          setUserActivated(true);
        }

        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.userStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.userAuthorityStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setUserAuthorityLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        let userAuthorities: any[] = [];

        props.userAuthorityPageableListContent.map(userAuthority => {
          userAuthorities.push(userAuthority[0]);
          return true;
        })

        setAddAuthorityFormValue("selectedAuthorityList", userAuthorities);
        setUserAuthorityLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setUserAuthorityLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.userAuthorityStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.userTokenStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setTokenLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setTokenLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setTokenLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.userTokenStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.authorityStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setAuthorityLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setAuthorityLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setAuthorityLoading(false);
        setShowDialogViewAlert(true);
        break;
      }
    }
  }, [props.userAuthorityStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkPermitStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setParkPermitLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setParkPermitLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setParkPermitLoading(false);
        setShowDialogViewAlert(true);
        break;
      }
    }
  }, [props.parkPermitStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingAreaStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setParkingAreaListLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setParkingAreaListLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setParkingAreaListLoading(false);
        setShowDialogViewAlert(true);
        break;
      }
    }
  }, [props.parkingAreaStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.userClientStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setUserClientListLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUserClientListLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setUserClientListLoading(false);
        setShowDialogViewAlert(true);
        break;
      }
    }
  }, [props.userClientStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchUserClientKioskParkingAreaParkingAreaCode !== undefined && watchUserClientKioskParkingAreaParkingAreaCode !== null && watchUserClientKioskParkingAreaParkingAreaCode.trim().length > 0) {
      setParkingAreaLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "areaCode",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getUpdateFormValues("userClientKioskParkingAreaParkingAreaCode")
      }

      v1WebParkingAreaService.searchParkingArea(v1KeywordDto, page).then((response: AxiosResponse) => {
        let parkingAreaPageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: Array<any[]> = [];
        parkingAreaPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress])
        });
        setParkingAreaOptions(pageableListContent);
        setParkingAreaLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchUserClientKioskParkingAreaParkingAreaCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchUserClientCustomerCompanyName !== undefined && watchUserClientCustomerCompanyName !== null && watchUserClientCustomerCompanyName.trim().length > 0) {
      setCustomerLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "companyName",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getUpdateFormValues("userClientCustomerCompanyName")
      }

      v1WebCustomerService.searchCustomer(v1KeywordDto, page).then((response: AxiosResponse) => {
        let customerPageableList = response.data as Response<V1WebCustomerListElementDTO>

        let pageableListContent: Array<any[]> = [];
        customerPageableList.content.forEach((value) => {
          pageableListContent.push([value.customerUuid, value.customerCompanyName])
        });
        setCustomerOptions(pageableListContent);
        setCustomerLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl. Prøv igen!")
      });
    }
  }, [watchUserClientCustomerCompanyName]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchUserClienUserClientEmail !== undefined && watchUserClienUserClientEmail !== null && watchUserClienUserClientEmail.trim().length > 0) {
      setUserClientLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "username",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getAddUserFormValues("userClientUserClientEmail")
      }

      v1WebUserService.searchUserClient(v1KeywordDto, page).then((response: AxiosResponse) => {
        let userClientPageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: Array<any[]> = [];
        userClientPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserEmail, value.abstractUserUsername])
        });
        setUserClientOptions(pageableListContent);
        setUserClientLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchUserClienUserClientEmail]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchUserClientParkingAreaParkingAreaCode !== undefined && watchUserClientParkingAreaParkingAreaCode !== null && watchUserClientParkingAreaParkingAreaCode.trim().length > 0) {
      setParkingAreaLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "areaCode",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getAddAreaFormValues("userClientParkingAreaParkingAreaCode")
      }

      v1WebParkingAreaService.searchParkingArea(v1KeywordDto, page).then((response: AxiosResponse) => {
        let parkingAreaPageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: Array<any[]> = [];
        parkingAreaPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress])
        });
        setParkingAreaOptions(pageableListContent);
        setParkingAreaLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchUserClientParkingAreaParkingAreaCode]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

  const parkPermitHeadCells: readonly HeadCell[] = [
    {
      id: "vehicle.vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    },
    {
      id: "abstractArea.areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "validFrom",
      numeric: false,
      disablePadding: false,
      label: "Gyldig fra"
    },
    {
      id: "validTo",
      numeric: false,
      disablePadding: false,
      label: "Gyldig til"
    }
  ]

  const parkingAreaHeadCells: readonly HeadCell[] = [
    {
      id: "areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Adresse"
    }
  ]

  const userClientHeadCells: readonly HeadCell[] = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Brugernavn"
    },
    {
      id: "userType",
      numeric: false,
      disablePadding: false,
      label: "Brugertype"
    }
  ]

  const userAuthorityHeadCells: readonly HeadCell[] = [
    {
      id: "displayName",
      numeric: false,
      disablePadding: false,
      label: "Rettighed"
    }
  ]

  const userTokenHeadCells: readonly HeadCell[] = [
    {
      id: "tokenType",
      numeric: false,
      disablePadding: false,
      label: "Token type"
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: false,
      label: "Note"
    }
  ]

  const authorityHeadCells: readonly HeadCell[] = [
    {
      id: "displayName",
      numeric: false,
      disablePadding: false,
      label: "Rettighed"
    },
    {
      id: "description",
      numeric: false,
      disablePadding: false,
      label: "Beskrivelse"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.userErrorCode} errorMessage={props.userErrorMessage} errorUuid={props.userErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Bruger
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    {props.userUserType === UserType.GUARD &&
                      <>
                        <CardHeader
                          subheader={"Denne bruger har vagt rolle"}
                          title="Vagt bruger"
                        />
                        <CardContent
                          sx={{
                            marginTop: -3
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userGuardGuardId"
                                control={updateFormControl}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Vagt ID"
                                    fullWidth
                                    value={value || ""}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userGuardTicketCount"
                                control={updateFormControl}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Afgifts tæller"
                                    fullWidth
                                    value={value || 0}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    }
                    {props.userUserType === UserType.KIOSK &&
                      <>
                        <CardHeader
                          subheader={"Denne bruger har kiosk rolle"}
                          title="Kiosk bruger"
                        />
                        <CardContent
                          sx={{
                            marginTop: -3
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userClientKioskParkingAreaParkingAreaCode"
                                control={updateFormControl}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Parkeringsområde er påkrævet"
                                  }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <Autocomplete
                                    inputValue={value || ""}
                                    onChange={(event: any, newValue: any[] | null) => {
                                      if (newValue !== null) {
                                        setUpdateFormValue("userClientKioskParkingAreaParkingAreaCode", newValue[1]);
                                        setUpdateFormValue("userClientKioskParkingAreaUuid", newValue[0]);
                                      } else {
                                        setUpdateFormValue("userClientKioskParkingAreaParkingAreaCode", "");
                                        setUpdateFormValue("userClientKioskParkingAreaUuid", "");
                                      }
                                    }}
                                    options={parkingAreaOptions}
                                    getOptionLabel={(option) => option[1] + " - " + option[2]}
                                    isOptionEqualToValue={(option, value) => option[0] === value[0]}
                                    noOptionsText={"Ingen resultater"}
                                    filterOptions={(x) => x}
                                    loading={isParkingAreaLoading}
                                    loadingText={"Vent venligst.."}
                                    openText={""}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Parkeringsområde"
                                        fullWidth
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : "Parkeringsområde tilknyttet kioskbrugeren"}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {isParkingAreaLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userClientHasTextMessageNotificationService"
                                control={updateFormControl}
                                render={({ field: { onChange, value } }) => (
                                  <FormControlLabel control={
                                    <Switch
                                      checked={value}
                                      onChange={onChange}
                                    />
                                  } label="SMS notifikationer" />
                                )}
                              />
                              <Controller
                                name="userClientHasEmailNotificationService"
                                control={updateFormControl}
                                render={({ field: { onChange, value } }) => (
                                  <FormControlLabel control={
                                    <Switch
                                      checked={value}
                                      onChange={onChange}
                                    />
                                  } label="E-mail notifikationer" />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userClientCustomerCompanyName"
                                control={updateFormControl}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <Autocomplete
                                    inputValue={value || ""}
                                    onChange={(event: any, newValue: any[] | null) => {
                                      if (newValue !== null) {
                                        setUpdateFormValue("userClientCustomerCompanyName", newValue[1]);
                                        setUpdateFormValue("userClientCustomerUuid", newValue[0]);
                                      } else {
                                        setUpdateFormValue("userClientCustomerCompanyName", "");
                                        setUpdateFormValue("userClientCustomerUuid", "");
                                      }
                                    }}
                                    options={customerOptions}
                                    getOptionLabel={(option) => option[1]}
                                    isOptionEqualToValue={(option, value) => option[0] === value[0]}
                                    noOptionsText={"Ingen resultater"}
                                    filterOptions={(x) => x}
                                    loading={isCustomerLoading}
                                    loadingText={"Vent venligst.."}
                                    openText={""}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Kunde"
                                        fullWidth
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : "Kunde tilknyttet betaling af notifikationer"}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {isCustomerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    }
                    {props.userUserType === UserType.OPERATOR &&
                      <>
                        <CardHeader
                          subheader={"Denne bruger har operator rolle"}
                          title="Operator bruger"
                        />
                        <CardContent
                          sx={{
                            marginTop: -3
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userClientHasTextMessageNotificationService"
                                control={updateFormControl}
                                render={({ field: { onChange, value } }) => (
                                  <FormControlLabel control={
                                    <Switch
                                      checked={value}
                                      onChange={onChange}
                                    />
                                  } label="SMS notifikationer" />
                                )}
                              />
                              <Controller
                                name="userClientHasEmailNotificationService"
                                control={updateFormControl}
                                render={({ field: { onChange, value } }) => (
                                  <FormControlLabel control={
                                    <Switch
                                      checked={value}
                                      onChange={onChange}
                                    />
                                  } label="E-mail notifikationer" />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="userClientCustomerCompanyName"
                                control={updateFormControl}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <Autocomplete
                                    inputValue={value || ""}
                                    onChange={(event: any, newValue: any[] | null) => {
                                      if (newValue !== null) {
                                        setUpdateFormValue("userClientCustomerCompanyName", newValue[1]);
                                        setUpdateFormValue("userClientCustomerUuid", newValue[0]);
                                      } else {
                                        setUpdateFormValue("userClientCustomerCompanyName", "");
                                        setUpdateFormValue("userClientCustomerUuid", "");
                                      }
                                    }}
                                    options={customerOptions}
                                    getOptionLabel={(option) => option[1]}
                                    isOptionEqualToValue={(option, value) => option[0] === value[0]}
                                    noOptionsText={"Ingen resultater"}
                                    filterOptions={(x) => x}
                                    loading={isCustomerLoading}
                                    loadingText={"Vent venligst.."}
                                    openText={""}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Kunde"
                                        fullWidth
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error ? error.message : "Kunde tilknyttet betaling af notifikationer"}
                                        InputProps={{
                                          ...params.InputProps,
                                          endAdornment: (
                                            <>
                                              {isCustomerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                              {params.InputProps.endAdornment}
                                            </>
                                          ),
                                        }}
                                      />
                                    )}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    }
                    <Divider />
                    <CardHeader
                      subheader={"Generelle oplysninger for brugeren"}
                      title="Bruger oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userUsername"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Brugernavn er påkrævet"
                              },
                              minLength: {
                                value: 4,
                                message: "Brugernavn skal være på minimum 4 tegn"
                              },
                              maxLength: {
                                value: 256,
                                message: "Brugernavn må ikke overstige 256 tegn"
                              },
                              pattern: {
                                value: /^[a-zA-Z0-9]+$/,
                                message: "Ugyldigt brugernavn"
                              },
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value.toLocaleLowerCase() === props.userUsername.toLocaleLowerCase()) {
                                      resolve(true)
                                    }
                                    if (value !== undefined && value.length >= 1) {
                                      v1WebUserService.existsUsernameOrEmail(value).then((res) => {
                                        if (res.data) {
                                          resolve("Brugernavn er allerede i brug")
                                        } else {
                                          resolve(true)
                                        }
                                      }).catch((error) => {
                                        resolve(error);
                                      })
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Brugernavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userEmail"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "E-mail er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "E-mail må ikke overstige 256 tegn"
                              },
                              pattern: {
                                value: /^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
                                message: "Ugyldig e-mail"
                              },
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value.toLocaleLowerCase() === props.userEmail.toLocaleLowerCase()) {
                                      resolve(true)
                                    }
                                    if (value !== undefined && value.length <= 256 && value.match(/^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/)) {
                                      v1WebUserService.existsUsernameOrEmail(value).then((res) => {
                                        if (res.data) {
                                          resolve("E-mail er allerede i brug")
                                        } else {
                                          resolve(true)
                                        }
                                      }).catch((error) => {
                                        resolve(error);
                                      })
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="E-mail"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userFirstName"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Fornavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Fornavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userLastName"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Efternavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Efternavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userAddress"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Adresse må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userPhone"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Telefon må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Telefon"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userZip"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Postnummer må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userCity"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "By må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userIsEnabled"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                />
                              } label="Aktiveret" />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userIsAccountNonExpired"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                  disabled={true}
                                />
                              } label="Er konto IKKE udløbet" />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userIsAccountNonLocked"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                  disabled={true}
                                />
                              } label="Er konto IKKE låst" />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="userIsCredentialsNonExpired"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                  disabled={true}
                                />
                              } label="Er legitimationsoplysninger IKKE udløbet" />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isUserActivated}
                        type="button"
                        onClick={handleWelcomeUser}
                        variant="outlined"
                      >
                        Send velkomsthilsen til bruger
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: -2,
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem bruger oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                {props.userUserType === UserType.KIOSK || props.userUserType === UserType.OPERATOR ? (
                  <>
                    <Card
                      sx={{
                        marginBottom: 3
                      }}
                    >
                      <CardHeader
                        subheader={"Alle tilladelser oprettet af, eller i relation, til denne bruger"}
                        title="Tilladelser" />
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            {parkPermitHeadCells.map((headCell) => (
                              <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                              >
                                {headCell.label}
                                <TableSortLabel
                                  active={props.parkPermitPageableListSort === headCell.id}
                                  direction={props.parkPermitPageableListDirection}
                                  onClick={handleParkPermitDirectionChange(headCell.id)}
                                >
                                </TableSortLabel>
                              </TableCell>
                            ))}
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isParkPermitLoading ? (
                            <>
                              <FetchingContainer tablePageSize={props.parkPermitPageableListContent === undefined || props.parkPermitPageableListContent.length === 0 ? props.parkPermitPageableListPageSize : props.parkPermitPageableListContent.length} tableColSpan={parkPermitHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.parkPermitPageableListContent === undefined || props.parkPermitPageableListCount === 0 ? (
                                <>
                                  <EmptyContainer tableColSpan={parkPermitHeadCells.length} />
                                </>
                              ) : (
                                <>
                                  {props.parkPermitPageableListContent.map(parkPermit => (
                                    <TableRow
                                      hover
                                      key={parkPermit[0]}
                                    >
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {parkPermit[8]}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {parkPermit[4]}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {(parkPermit[1] === undefined || parkPermit[1] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkPermit[1]))}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {(parkPermit[2] === undefined || parkPermit[2] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkPermit[2]))}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Tooltip title="Vis">
                                          <IconButton aria-label="Edit"
                                            edge='end'
                                            component={NavLink}
                                            to={"../parkpermit/" + parkPermit[0]}
                                          >
                                            <NavigateNext />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              colSpan={parkPermitHeadCells.length + 1}
                              count={props.parkPermitPageableListCount || 0}
                              rowsPerPage={props.parkPermitPageableListPageSize || 5}
                              page={props.parkPermitPageableListPageNumber || 0}
                              labelRowsPerPage="Antal pr. side:"
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                              onPageChange={handleParkPermitPageNumberChange}
                              onRowsPerPageChange={handleParkPermitPageSizeChange}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Card>
                  </>
                ) : (
                  <></>
                )}
                {props.userUserType === UserType.OPERATOR ? (
                  <>
                    <Card
                      sx={{
                        marginBottom: 3
                      }}
                    >
                      <CardHeader
                        subheader={"Alle områder brugeren har lov til at oprette tilladelser på"}
                        title="Områder" />
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            {parkingAreaHeadCells.map((headCell) => (
                              <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                              >
                                {headCell.label}
                                <TableSortLabel
                                  active={props.parkingAreaPageableListSort === headCell.id}
                                  direction={props.parkingAreaPageableListDirection}
                                  onClick={handleParkingAreaDirectionChange(headCell.id)}
                                >
                                </TableSortLabel>
                              </TableCell>
                            ))}
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isParkingAreaListLoading ? (
                            <>
                              <FetchingContainer tablePageSize={props.parkingAreaPageableListContent === undefined || props.parkingAreaPageableListContent.length === 0 ? props.parkingAreaPageableListPageSize : props.parkingAreaPageableListContent.length} tableColSpan={parkingAreaHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.parkingAreaPageableListContent === undefined || props.parkingAreaPageableListCount === 0 ? (
                                <>
                                  <EmptyContainer tableColSpan={parkingAreaHeadCells.length} />
                                </>
                              ) : (
                                <>
                                  {props.parkingAreaPageableListContent.map(parkingArea => (
                                    <TableRow
                                      hover
                                      key={parkingArea[0]}
                                    >
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {parkingArea[1]}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {parkingArea[2] + ", " + parkingArea[3] + " " + parkingArea[4]}
                                        </Typography>
                                      </TableCell>
                                      <TableCell align="right">
                                        <Tooltip title="Vis">
                                          <IconButton aria-label="Edit"
                                            edge='end'
                                            component={NavLink}
                                            to={"../parkingarea/" + parkingArea[0]}
                                          >
                                            <NavigateNext />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              colSpan={parkingAreaHeadCells.length + 1}
                              count={props.parkingAreaPageableListCount || 0}
                              rowsPerPage={props.parkingAreaPageableListPageSize || 5}
                              page={props.parkingAreaPageableListPageNumber || 0}
                              labelRowsPerPage="Antal pr. side:"
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                              onPageChange={handleParkingAreaPageNumberChange}
                              onRowsPerPageChange={handleParkingAreaPageSizeChange}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                      <CardContent>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              lg={6}
                              md={6}
                              xs={12}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  fullWidth
                                  disabled={isParkingAreaListLoading}
                                  onClick={handleAreaAddOpenClick}
                                  type="button"
                                  variant="outlined"
                                >
                                  Tilføj område
                                </Button>
                              </Box>
                            </Grid>

                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                ) : (
                  <></>
                )}
                {props.userUserType === UserType.OPERATOR &&
                  <>
                    <Card
                      sx={{
                        marginBottom: 3
                      }}
                    >
                      <CardHeader
                        subheader={"Alle klient brugere tilknyttet operator brugeren"}
                        title="Klient brugere" />
                      <Divider />
                      <Table>
                        <TableHead>
                          <TableRow>
                            {userClientHeadCells.map((headCell) => (
                              <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                              >
                                {headCell.label}
                                <TableSortLabel
                                  active={props.userClientPageableListSort === headCell.id}
                                  direction={props.userClientPageableListDirection}
                                  onClick={handleUserClientDirectionChange(headCell.id)}
                                >
                                </TableSortLabel>
                              </TableCell>
                            ))}
                            <TableCell>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isUserClientListLoading ? (
                            <>
                              <FetchingContainer tablePageSize={props.userClientPageableListContent === undefined || props.userClientPageableListContent.length === 0 ? props.userClientPageableListPageSize : props.userClientPageableListContent.length} tableColSpan={userClientHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.userClientPageableListContent === undefined || props.userClientPageableListCount === 0 ? (
                                <>
                                  <EmptyContainer tableColSpan={userClientHeadCells.length} />
                                </>
                              ) : (
                                <>
                                  {props.userClientPageableListContent.map(userClient => (
                                    <TableRow
                                      hover
                                      key={userClient[0]}
                                    >
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {userClient[1]}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          color="textPrimary"
                                          variant="body1"
                                        >
                                          {userClient[2]}
                                        </Typography>
                                      </TableCell>

                                      <TableCell align="right">
                                        <Tooltip title="Vis">
                                          <IconButton aria-label="Edit"
                                            edge='end'
                                            component={NavLink}
                                            to={"../user/" + userClient[0]}
                                            onClick={() => setViewLoaded(false)}
                                          >
                                            <NavigateNext />
                                          </IconButton>
                                        </Tooltip>
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </>
                              )}
                            </>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              colSpan={userClientHeadCells.length + 1}
                              count={props.userClientPageableListCount || 0}
                              rowsPerPage={props.userClientPageableListPageSize || 5}
                              page={props.userClientPageableListPageNumber || 0}
                              labelRowsPerPage="Antal pr. side:"
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                              onPageChange={handleUserClientPageNumberChange}
                              onRowsPerPageChange={handleUserClientPageSizeChange}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                      <CardContent>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Grid
                            container
                            spacing={1}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Grid
                              item
                              lg={6}
                              md={6}
                              xs={12}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Button
                                  color="primary"
                                  fullWidth
                                  disabled={isUserClientListLoading}
                                  onClick={handleUserAddOpenClick}
                                  type="button"
                                  variant="outlined"
                                >
                                  Tilføj bruger
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                }
                <Card>
                  <CardHeader
                    subheader={"Alle rettigheder tildelt denne bruger"}
                    title="Rettigheder" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {userAuthorityHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.userAuthorityPageableListSort === headCell.id}
                              direction={props.userAuthorityPageableListDirection}
                              onClick={handleUserAuthorityDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isUserAuthorityLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.userAuthorityPageableListContent === undefined || props.userAuthorityPageableListContent.length === 0 ? props.userAuthorityPageableListPageSize : props.userAuthorityPageableListContent.length} tableColSpan={userAuthorityHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.userAuthorityPageableListContent === undefined || props.userAuthorityPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={userAuthorityHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.userAuthorityPageableListContent.map(authority => (
                                <TableRow
                                  hover
                                  key={authority[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {authority[2]}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={userAuthorityHeadCells.length + 1}
                          count={props.userAuthorityPageableListCount || 0}
                          rowsPerPage={props.userAuthorityPageableListPageSize || 5}
                          page={props.userAuthorityPageableListPageNumber || 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleUserAuthorityPageNumberChange}
                          onRowsPerPageChange={handleUserAuthorityPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                  <CardContent>
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                    >
                      <Grid
                        container
                        spacing={1}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isUserAuthorityLoading}
                              onClick={() => { handleAuthorityAddOpenClick() }}
                              type="button"
                              variant="outlined"
                            >
                              Administrer rettigheder
                            </Button>
                          </Box>
                        </Grid>

                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
                <Card
                  sx={{
                    marginTop: 3
                  }}
                >
                  <CardHeader
                    subheader={"Alle tokens oprettet til denne bruger"}
                    title="Tokens" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {userTokenHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.userTokenPageableListSort === headCell.id}
                              direction={props.userTokenPageableListDirection}
                              onClick={handleUserTokenDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isTokenLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.userTokenPageableListContent === undefined || props.userTokenPageableListContent.length === 0 ? props.userTokenPageableListPageSize : props.userTokenPageableListContent.length} tableColSpan={userTokenHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.userTokenPageableListContent === undefined || props.userTokenPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={userTokenHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.userTokenPageableListContent.map(token => (
                                <TableRow
                                  hover
                                  key={token[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {token[1] === TokenType.API &&
                                        <>
                                          API token
                                        </>
                                      }
                                      {token[1] === TokenType.DEVICE &&
                                        <>
                                          Device token
                                        </>
                                      }
                                      {token[1] === TokenType.RESET &&
                                        <>
                                          Password reset token
                                        </>
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {token[2]}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={userTokenHeadCells.length + 1}
                          count={props.userTokenPageableListCount || 0}
                          rowsPerPage={props.userTokenPageableListPageSize || 5}
                          page={props.userTokenPageableListPageNumber || 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleUserTokenPageNumberChange}
                          onRowsPerPageChange={handleUserTokenPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
      <Dialog
        open={isAuthorityAddDialogOpen}
        onClose={handleAuthorityAddCloseClick}
        fullScreen
      >
        <form onSubmit={handleAddAuthorityFormSubmit(onAddAuthorityFormSubmit)}>
          <DialogTitle>Administrer rettigheder</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vælg brugerens rettigheder
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            {showDialogViewAlert ? (
              <ErrorContainer errorCode={props.userErrorCode} errorMessage={props.userErrorMessage} errorUuid={props.userErrorUuid} />
            ) : (
              <>
                <Grid
                  container
                  spacing={3}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            {authorityHeadCells.map((headCell) => (
                              <TableCell
                                key={headCell.id}
                                align={headCell.numeric ? 'right' : 'left'}
                                padding={headCell.disablePadding ? 'none' : 'normal'}
                              >
                                {headCell.label}
                                <TableSortLabel
                                  active={props.authorityPageableListSort === headCell.id}
                                  direction={props.authorityPageableListDirection}
                                  onClick={handleAuthorityDirectionChange(headCell.id)}
                                >
                                </TableSortLabel>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {isAuthorityLoading ? (
                            <>
                              <FetchingContainer tablePageSize={props.authorityPageableListContent === undefined || props.authorityPageableListContent.length === 0 ? props.authorityPageableListPageSize : props.authorityPageableListContent.length} tableColSpan={authorityHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.authorityPageableListContent === undefined || props.authorityPageableListCount === 0 ? (
                                <>
                                  <EmptyContainer tableColSpan={authorityHeadCells.length} />
                                </>
                              ) : (
                                <>
                                  {props.authorityPageableListContent.map(authority => {
                                    return (
                                      <TableRow
                                        hover
                                        key={authority[0]}
                                      >
                                        <TableCell>
                                          <Controller
                                            name="selectedAuthorityList"
                                            control={addAuthorityFormControl}
                                            render={({ field }) => (
                                              <>
                                                <FormControlLabel
                                                  key={authority[0]}
                                                  label={authority[2]}
                                                  control={
                                                    <Checkbox
                                                      value={authority[0]}
                                                      checked={getAddAuthorityFormValues("selectedAuthorityList").some(
                                                        (existingValue) => existingValue === authority[0]
                                                      )}
                                                      onChange={(event, checked) => {
                                                        if (checked) {
                                                          field.onChange([
                                                            ...field.value,
                                                            event.target.value
                                                          ]);
                                                        } else {
                                                          field.onChange(
                                                            field.value.filter(
                                                              (value) => value !== event.target.value
                                                            )
                                                          );
                                                        }
                                                      }}
                                                    />
                                                  }
                                                />
                                              </>
                                            )}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Typography
                                            color="textPrimary"
                                            variant="body1"
                                          >
                                            {authority[3]}
                                          </Typography>
                                        </TableCell>
                                      </TableRow>
                                    )
                                  })}
                                </>
                              )}
                            </>
                          )}
                        </TableBody>
                        <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 10, 25, 50, 100]}
                              colSpan={authorityHeadCells.length + 1}
                              count={props.authorityPageableListCount || 0}
                              rowsPerPage={props.authorityPageableListPageSize || 5}
                              page={props.authorityPageableListPageNumber || 0}
                              labelRowsPerPage="Antal pr. side:"
                              labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                              onPageChange={handleAuthorityPageNumberChange}
                              onRowsPerPageChange={handleAuthorityPageSizeChange}
                            />
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isAuthorityLoading}
                onClick={handleAuthorityAddCloseClick}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isAuthorityLoading}
                type="submit"
                variant="contained"
              >
                Opdater rettigheder
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isAreaAddDialogOpen}
        onClose={handleAreaAddCloseClick}
      >
        <form onSubmit={handleAddAreaFormSubmit(onAddAreaFormSubmit)}>
          <DialogTitle>Tilføj område</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vælg det område som brugeren skal have adgang til
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientParkingAreaParkingAreaCode"
                      control={addAreaFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Parkeringsområde er påkrævet"
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          inputValue={value || ""}
                          onChange={(event: any, newValue: any[] | null) => {
                            if (newValue !== null) {
                              setAddAreaFormValue("userClientParkingAreaParkingAreaCode", newValue[1]);
                              setAddAreaFormValue("userClientParkingAreaUuid", newValue[0]);
                            } else {
                              setAddAreaFormValue("userClientParkingAreaParkingAreaCode", "");
                              setAddAreaFormValue("userClientParkingAreaUuid", "");
                            }
                          }}
                          options={parkingAreaOptions}
                          getOptionLabel={(option) => option[1] + " - " + option[2]}
                          isOptionEqualToValue={(option, value) => option[0] === value[0]}
                          noOptionsText={"Ingen resultater"}
                          filterOptions={(x) => x}
                          loading={isParkingAreaLoading}
                          loadingText={"Vent venligst.."}
                          openText={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Parkeringsområde"
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : "Parkeringsområdet tilknyttes operatør brugeren"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isParkingAreaLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isParkingAreaListLoading}
                onClick={handleAreaAddCloseClick}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isParkingAreaListLoading}
                type="submit"
                variant="contained"
              >
                Tilføj område
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog >
      <Dialog
        open={isUserAddDialogOpen}
        onClose={handleUserAddCloseClick}
      >
        <form onSubmit={handleAddUserFormSubmit(onAddUserFormSubmit)}>
          <DialogTitle>Tilføj bruger</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vælg den bruger som skal have adgang til aktuel brugers data
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientUserClientEmail"
                      control={addUserFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Brugernavn er påkrævet"
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          inputValue={value || ""}
                          onChange={(event: any, newValue: any[] | null) => {
                            if (newValue !== null) {
                              setAddUserFormValue("userClientUserClientEmail", newValue[1]);
                              setAddUserFormValue("userClientUserClientUuid", newValue[0]);
                            } else {
                              setAddUserFormValue("userClientUserClientEmail", "");
                              setAddUserFormValue("userClientUserClientUuid", "");
                            }
                          }}
                          options={userClientOptions}
                          getOptionLabel={(option) => option[1] + " - " + option[2]}
                          isOptionEqualToValue={(option, value) => option[0] === value[0]}
                          noOptionsText={"Ingen resultater"}
                          filterOptions={(x) => x}
                          loading={isUserClientLoading}
                          loadingText={"Vent venligst.."}
                          openText={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Bruger"
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : "Brugerens data tilknyttes den aktuelle bruger"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isUserClientLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientUserClientMultiSetup"
                      control={addUserFormControl}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={
                          <Switch
                            checked={value || false}
                            onChange={onChange}
                          />
                        } label="Adgang skal også tildeles modsat bruger?" />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isUserClientListLoading}
                onClick={handleUserAddCloseClick}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isUserClientListLoading}
                type="submit"
                variant="contained"
              >
                Tilføj bruger
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    userStateStatus: redux.user.status,
    userUuid: redux.user.abstractUserUuid,
    userUsername: redux.user.abstractUserUsername,
    userFirstName: redux.user.abstractUserFirstName,
    userLastName: redux.user.abstractUserLastName,
    userAddress: redux.user.abstractUserAddress,
    userZip: redux.user.abstractUserZip,
    userCity: redux.user.abstractUserCity,
    userEmail: redux.user.abstractUserEmail,
    userPhone: redux.user.abstractUserPhone,
    userUserType: redux.user.abstractUserUserType,
    userNotes: redux.user.abstractUserNotes,
    userIsEnabled: redux.user.abstractUserIsEnabled,
    userIsAccountNonExpired: redux.user.abstractUserIsAccountNonExpired,
    userIsAccountNonLocked: redux.user.abstractUserIsAccountNonLocked,
    userIsCredentialsNonExpired: redux.user.abstractUserIsCredentialsNonExpired,
    userCredentialsDate: redux.user.abstractUserCredentialsDate,
    userClientHasTextMessageNotificationService: redux.user.abstractUserClientHasTextMessageNotificationService,
    userClientHasEmailNotificationService: redux.user.abstractUserClientHasEmailNotificationService,
    userClientCustomerUuid: redux.user.customerUuid,
    userClientCustomerCompanyName: redux.user.customerCompanyName,
    userClientKioskParkingAreaUuid: redux.user.abstractParkingAreaUuid,
    userClientKioskParkingAreaParkingAreaCode: redux.user.abstractParkingAreaParkingAreaCode,
    userGuardTicketCount: redux.user.userGuardTicketCount,
    userGuardGuardId: redux.user.userGuardGuardId,
    userAuthorityStatus: redux.user.authorityStatus,
    userAuthorityPageableListCount: redux.user.authorityPageableListCount,
    userAuthorityPageableListPageNumber: redux.user.authorityPageableListPageNumber,
    userAuthorityPageableListPageSize: redux.user.authorityPageableListPageSize,
    userAuthorityPageableListContent: redux.user.authorityPageableListContent,
    userAuthorityPageableListSort: redux.user.authorityPageableListSort,
    userAuthorityPageableListDirection: redux.user.authorityPageableListDirection,
    userTokenStatus: redux.user.tokenStatus,
    userTokenPageableListCount: redux.user.tokenPageableListCount,
    userTokenPageableListPageNumber: redux.user.tokenPageableListPageNumber,
    userTokenPageableListPageSize: redux.user.tokenPageableListPageSize,
    userTokenPageableListContent: redux.user.tokenPageableListContent,
    userTokenPageableListSort: redux.user.tokenPageableListSort,
    userTokenPageableListDirection: redux.user.tokenPageableListDirection,
    authorityStateStatus: redux.user.authorityStatus,
    authorityPageableListCount: redux.authority.pageableListCount,
    authorityPageableListPageNumber: redux.authority.pageableListPageNumber,
    authorityPageableListPageSize: redux.authority.pageableListPageSize,
    authorityPageableListContent: redux.authority.pageableListContent,
    authorityPageableListSort: redux.authority.pageableListSort,
    authorityPageableListDirection: redux.authority.pageableListDirection,
    parkPermitStateStatus: redux.parkPermit.status,
    parkPermitPageableListCount: redux.parkPermit.pageableListCount,
    parkPermitPageableListPageNumber: redux.parkPermit.pageableListPageNumber,
    parkPermitPageableListPageSize: redux.parkPermit.pageableListPageSize,
    parkPermitPageableListContent: redux.parkPermit.pageableListContent,
    parkPermitPageableListSort: redux.parkPermit.pageableListSort,
    parkPermitPageableListDirection: redux.parkPermit.pageableListDirection,
    parkingAreaStateStatus: redux.parkingArea.status,
    parkingAreaPageableListCount: redux.parkingArea.pageableListCount,
    parkingAreaPageableListPageNumber: redux.parkingArea.pageableListPageNumber,
    parkingAreaPageableListPageSize: redux.parkingArea.pageableListPageSize,
    parkingAreaPageableListContent: redux.parkingArea.pageableListContent,
    parkingAreaPageableListSort: redux.parkingArea.pageableListSort,
    parkingAreaPageableListDirection: redux.parkingArea.pageableListDirection,
    userClientStateStatus: redux.user.userClientStateStatus,
    userClientPageableListCount: redux.user.pageableListCount,
    userClientPageableListPageNumber: redux.user.pageableListPageNumber,
    userClientPageableListPageSize: redux.user.pageableListPageSize,
    userClientPageableListContent: redux.user.pageableListContent,
    userClientPageableListSort: redux.user.pageableListSort,
    userClientPageableListDirection: redux.user.pageableListDirection,
    userErrorCode: redux.user.errorCode,
    userErrorMessage: redux.user.errorMessage,
    userErrorUuid: redux.user.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getUserClientParkPermitPageableList: async (userUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getUserClientParkPermitPageableList(userUuid, pageNumber, rowsPerPage, sort, direction));
    },
    getUserClientAreaParkingAreaPageableList: async (userUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getUserClientAreaParkingAreaPageableList(userUuid, pageNumber, rowsPerPage, sort, direction))
    },
    getUserClientAreaOperatorUserClientPageableList: async (userUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getUserClientAreaOperatorUserClientPageableList(userUuid, pageNumber, rowsPerPage, sort, direction))
    },
    userGet: async (userUuid) => {
      await dispatch(userGet(userUuid));
    },
    userGetAuthorityPageableList: async (userUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(userGetAuthorityPageableList(userUuid, pageNumber, rowsPerPage, sort, direction));
    },
    userGetTokenPageableList: async (userUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(userGetTokenPageableList(userUuid, pageNumber, rowsPerPage, sort, direction));
    },
    userPut: async (userUuid, userUsername, userFirstName, userLastName, userAddress, userZip, userCity, userEmail, userPhone, userUserType, userNotes, userIsEnabled, userClientHasTextMessageNotificationService, userClientHasEmailNotificationService, userClientCustomerUuid, userClientKioskParkingAreaUuid) => {
      await dispatch(userPut(userUuid, userUsername, userFirstName, userLastName, userAddress, userZip, userCity, userEmail, userPhone, userUserType, userNotes, userIsEnabled, userClientHasTextMessageNotificationService, userClientHasEmailNotificationService, userClientCustomerUuid, userClientKioskParkingAreaUuid))
    },
    authorityGetPageableList: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(authorityGetPageableList(pageNumber, rowsPerPage, sort, direction));
    },
    userPutAuthorities: async (userUuid, pageNumber, rowsPerPage, sort, direction, authorityUuids) => {
      await dispatch(userPutAuthorities(userUuid, pageNumber, rowsPerPage, sort, direction, authorityUuids));
    },
    areaAdd: async (userUuid, areaUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(areaAdd(userUuid, areaUuid, pageNumber, rowsPerPage, sort, direction));
    },
    userAdd: async (userUuid, userClientUuid, multiSetup, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(userAdd(userUuid, userClientUuid, multiSetup, pageNumber, rowsPerPage, sort, direction));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorUserView);