import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootRedux } from "redux/store";
import { AppBar, Box, Hidden, IconButton, Toolbar } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

interface OwnProps {
  onMobileNavOpen(): void; 
}

interface DispatchProps {
}

interface StateProps {
}

type Props = StateProps & OwnProps & DispatchProps


const TopBar = (props: Props) => {
  return (
    <AppBar elevation={0} >
      <Toolbar>
        <img alt="Logo" src="/asset/topbar_logo.png" height="60" />
        <Box flexGrow={1} />
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={props.onMobileNavOpen}
          >
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)