import { Grid, Card, Box, Typography, CardContent } from "@mui/material"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { ThunkDispatch } from "redux-thunk"
import { RootRedux } from "redux/store"
import CustomContainer from "./customcontainer"

interface OwnProps {
  errorCode: number | null
  errorMessage: string | null
  errorUuid: string | null
}
  
interface DispatchProps {
}
  
interface StateProps {
}
  
type Props = StateProps & OwnProps & DispatchProps

const ErrorContainer = (props: Props) => {
  const [errorCode, setErrorCode] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorUuid, setErrorUuid] = useState("");

  useEffect(() => {
    setErrorCode(props.errorCode === null || props.errorCode === undefined ? 500 : props.errorCode);
    setErrorMessage(props.errorMessage === null || props.errorMessage === undefined ? "" : props.errorMessage);
    setErrorUuid(props.errorUuid === null || props.errorUuid === undefined ? "" : props.errorUuid);
  }, [props.errorCode, props.errorMessage, props.errorUuid]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <>
      <CustomContainer>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <CardContent>
                <Typography
                    variant='h1'
                    color="textPrimary"
                    align='center'
                >
                  HTTP {errorCode}
                </Typography>
                <Typography
                  variant='h5'
                  color="textSecondary"
                  align='center'
                >
                  {errorMessage}
                </Typography>
                <Box
                  display="flex"
                  justifyContent="center"
                >
                  <Grid
                    item
                    lg={8}
                    md={10}
                    xs={12}
                  >
                    <img
                      width="100%"
                      alt="Error"
                      src="/asset/error.gif"
                    />
                  </Grid>
                </Box>
                <Typography
                  variant='h5'
                  color="textSecondary"
                  align='center'
                >
                  Der er desværre opstået en fejl. Denne fejl er registreret og har fået tildelt et id: {errorUuid}. Kontakt Metropark og henvis til dette id.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorContainer);