import { Typography, Grid, Fab, Box, Button, Card, CardContent, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Dialog, DialogTitle, DialogContent, DialogContentText, Divider, DialogActions, FormLabel, Autocomplete, CircularProgress, InputAdornment, FormControl, RadioGroup, FormControlLabel, Radio, FormHelperText } from "@mui/material";
import CustomContainer from "component/customcontainer";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootRedux } from "redux/store";
import AddIcon from '@mui/icons-material/Add';
import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import HeadCell from "component/headcell";
import { NavigateNext } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import { getParkingLimitationPageableListForUserClientAsUserClient, searchParkingLimitationPageableListForUserClientAsUserClient } from "redux/parkinglimitation/parkinglimitation.action";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import { AxiosResponse } from "axios";
import Response from "dto/response";
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service";
import v1WebUserService from "service/v1/web/v1webuser.service";
import V1WebParkingLimitationDTO from "dto/v1/web/v1webparkinglimitation.dto";
import v1WebParkingLimitationService from "service/v1/web/v1webparkinglimitation.service";
import { LimitationType } from "type/limitation.type";

/**
 * @author Von Frank - https://vonfrank.dk
 */

interface OwnProps {
}

interface DispatchProps {
  getParkingLimitationPageableListForUserClientAsUserClient: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  searchParkingLimitationPageableListForUserClientAsUserClient: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  parkingLimitationStateStatus: StateStatus
  parkingLimitationPageableListCount: number
  parkingLimitationPageableListPageNumber: number
  parkingLimitationPageableListPageSize: number
  parkingLimitationPageableListContent: Array<any[]>
  parkingLimitationPageableListSort: string
  parkingLimitationPageableListDirection: Direction
  parkingLimitationPageableListKeyword: string
  parkingLimitationErrorCode: number
  parkingLimitationErrorMessage: string
  parkingLimitationErrorUuid: string
}

interface FormProps {
  parkingLimitationUnlimitedParkingLimitationType: LimitationType,
  parkingLimitationInitialUnlimitedParking: number,
  parkingLimitationRemainingUnlimitedParking: number,
  parkingLimitationConcurrentUnlimitedParking: number,
  parkingLimitationMinuteParkingLimitationType: LimitationType,
  parkingLimitationInitialMinuteParkingDays: number,
  parkingLimitationInitialMinuteParkingHours: number,
  parkingLimitationInitialMinuteParkingMinutes: number,
  parkingLimitationConcurrentMinuteParking: number,
  parkingLimitationIsEnabled: boolean,
  parkingLimitationNotes: string,
  parkingLimitationAbstractParkingAreaUuid: string,
  parkingLimitationAbstractParkingAreaParkingAreaCode: string,
  parkingLimitationAbstractParkingAreaAddress: string,
  parkingLimitationUserClientPublicUuid: string,
  parkingLimitationUserClientPublicUsername: string,
}

type Props = StateProps & OwnProps & DispatchProps

const OperatorResidentListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkingAreaLoading, setParkingAreaLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [parkingAreaOptions, setParkingAreaOptions] = useState(Array<any[]>());

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({ mode: 'all' });

  const onSearchFormSubmit: SubmitHandler<StateProps> = (stateProps: StateProps) => {
    if (!isViewLoading && stateProps.parkingLimitationPageableListKeyword.length > 0) {
      props.searchParkingLimitationPageableListForUserClientAsUserClient(stateProps.parkingLimitationPageableListKeyword, 0, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
    }
  }

  const {
    handleSubmit: handleCreateFormSubmit,
    control: createFormControl,
    getValues: getCreateFormValues,
    setValue: setCreateFormValue,
    reset: resetCreateForm,
    watch: watchCreateForm,
    clearErrors: clearCreateFormError
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      parkingLimitationIsEnabled: true
    }
  });

  const onCreateFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      let v1WebParkingLimitationDto = new V1WebParkingLimitationDTO();

      v1WebParkingLimitationDto.abstractParkingAreaUuid = formProps.parkingLimitationAbstractParkingAreaUuid;
      v1WebParkingLimitationDto.userClientPublicUsername = formProps.parkingLimitationUserClientPublicUsername;
      v1WebParkingLimitationDto.parkingLimitationInitialUnlimitedParking = formProps.parkingLimitationInitialUnlimitedParking;
      v1WebParkingLimitationDto.parkingLimitationConcurrentUnlimitedParking = formProps.parkingLimitationConcurrentUnlimitedParking;
      v1WebParkingLimitationDto.parkingLimitationUnlimitedParkingLimitationType = formProps.parkingLimitationUnlimitedParkingLimitationType;

      v1WebParkingLimitationDto.parkingLimitationInitialMinuteParkingV1DateTimeDto = {
        date: formProps.parkingLimitationInitialMinuteParkingDays,
        hour: formProps.parkingLimitationInitialMinuteParkingHours,
        minute: formProps.parkingLimitationInitialMinuteParkingMinutes,
        year: 0,
        month: 0,
        second: 0
      }

      v1WebParkingLimitationDto.parkingLimitationConcurrentMinuteParking = formProps.parkingLimitationConcurrentMinuteParking;
      v1WebParkingLimitationDto.parkingLimitationMinuteParkingLimitationType = formProps.parkingLimitationMinuteParkingLimitationType;
      v1WebParkingLimitationDto.parkingLimitationIsEnabled = formProps.parkingLimitationIsEnabled;
      v1WebParkingLimitationDto.parkingLimitationNotes = formProps.parkingLimitationNotes;

      setViewLoading(true);

      v1WebParkingLimitationService.createParkingLimitationAsUserClient(v1WebParkingLimitationDto).then((response: AxiosResponse) => {
        setViewLoading(false);
        alert("Beboer oprettet");

        if (props.parkingLimitationPageableListKeyword.length > 0) {
          props.searchParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListKeyword, props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
        } else {
          props.getParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
        }

        setCreateDialogOpen(false);
      }).catch((error) => {
        setViewLoading(false);
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }

  const watchParkingLimitationUnlimitedParkingLimitationType = watchCreateForm("parkingLimitationUnlimitedParkingLimitationType");
  const watchParkingLimitationMinuteParkingLimitationType = watchCreateForm("parkingLimitationMinuteParkingLimitationType");
  const watchParkingLimitationParkingAreaCode = watchCreateForm("parkingLimitationAbstractParkingAreaParkingAreaCode");

  const handleParkingLimitationPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.parkingLimitationPageableListKeyword.length > 0) {
      props.searchParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListKeyword, 0, parseInt(event.target.value), props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
    } else {
      props.getParkingLimitationPageableListForUserClientAsUserClient(0, parseInt(event.target.value), props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
    }
  };

  const handleParkingLimitationPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.parkingLimitationPageableListKeyword.length > 0) {
      props.searchParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListKeyword, newPage, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection)
    } else {
      props.getParkingLimitationPageableListForUserClientAsUserClient(newPage, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
    }
  }

  const handleParkingLimitationDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkingLimitationPageableListDirection === Direction.ASC) {
      if (props.parkingLimitationPageableListKeyword.length > 0) {
        props.searchParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListKeyword, props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, property, Direction.DESC);
      } else {
        props.getParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.parkingLimitationPageableListKeyword.length > 0) {
        props.searchParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListKeyword, props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, property, Direction.ASC);
      } else {
        props.getParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleParkingLimitationKeywordClear = () => {
    props.getParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
  }

  const toogleCreateDialogOpen = () => {
    resetCreateForm();
    setCreateDialogOpen(!isCreateDialogOpen);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.parkingLimitationPageableListKeyword.length > 0) {
        props.searchParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListKeyword, props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
      } else {
        props.getParkingLimitationPageableListForUserClientAsUserClient(props.parkingLimitationPageableListPageNumber, props.parkingLimitationPageableListPageSize, props.parkingLimitationPageableListSort, props.parkingLimitationPageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingLimitationStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("parkingLimitationPageableListKeyword", props.parkingLimitationPageableListKeyword);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkingLimitationStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchParkingLimitationParkingAreaCode !== undefined && watchParkingLimitationParkingAreaCode !== null && watchParkingLimitationParkingAreaCode.trim().length > 0) {
      setParkingAreaLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "areaCode",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getCreateFormValues("parkingLimitationAbstractParkingAreaParkingAreaCode")
      }

      v1WebParkingAreaService.searchParkingAreaPageableListForUserClientAreaAsUserClientArea(v1KeywordDto, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: Array<any[]> = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaDefaultInitialUnlimitedParking, value.abstractParkingAreaDefaultConcurrentUnlimitedParking, value.abstractParkingAreaDefaultInitialMinuteParking, value.abstractParkingAreaDefaultConcurrentMinuteParking])
        });
        setParkingAreaOptions(pageableListContent);
        setParkingAreaLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchParkingLimitationParkingAreaCode]); // eslint-disable-line react-hooks/exhaustive-deps

  const parkingLimitationHeadCells: readonly HeadCell[] = [
    {
      id: "abstractArea.areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "abstractArea.address",
      numeric: false,
      disablePadding: false,
      label: "Adresse"
    },
    {
      id: "userClientPublic.username",
      numeric: false,
      disablePadding: false,
      label: "Brugernavn"
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: false,
      label: "Note"
    },
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.parkingLimitationErrorCode} errorMessage={props.parkingLimitationErrorMessage} errorUuid={props.parkingLimitationErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Beboere
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="parkingLimitationPageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => { handleParkingLimitationKeywordClear() }}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {parkingLimitationHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.parkingLimitationPageableListSort === headCell.id}
                          direction={props.parkingLimitationPageableListDirection}
                          onClick={handleParkingLimitationDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.parkingLimitationPageableListContent === undefined || props.parkingLimitationPageableListContent.length === 0 ? props.parkingLimitationPageableListPageSize : props.parkingLimitationPageableListContent.length} tableColSpan={parkingLimitationHeadCells.length} />
                    </>
                  ) : (
                    <>
                      {props.parkingLimitationPageableListCount === undefined || props.parkingLimitationPageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={parkingLimitationHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.parkingLimitationPageableListContent.map(parkingLimitation => (
                            <TableRow
                              hover
                              key={parkingLimitation[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkingLimitation[2]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkingLimitation[3] + ", " + parkingLimitation[4] + " " + parkingLimitation[5]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkingLimitation[6]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkingLimitation[1]}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={parkingLimitation[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={parkingLimitationHeadCells.length + 1}
                      count={props.parkingLimitationPageableListCount || 0}
                      rowsPerPage={props.parkingLimitationPageableListPageSize || 5}
                      page={props.parkingLimitationPageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                      onPageChange={handleParkingLimitationPageNumberChange}
                      onRowsPerPageChange={handleParkingLimitationPageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
      <Dialog
        open={isCreateDialogOpen}
        onClose={toogleCreateDialogOpen}
        fullScreen
      >
        <form onSubmit={handleCreateFormSubmit(onCreateFormSubmit)}>
          <DialogTitle>Opret parkeringsbegrænsning</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld felterne nedenfor for at oprette en parkeringsbegrænsning mellem et område og en bruger
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Controller
                      name="parkingLimitationAbstractParkingAreaParkingAreaCode"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Parkeringsområde er påkrævet"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value !== undefined && value.length >= 1) {
                                if (parkingAreaOptions.find(option => option[1].toLowerCase().trim() === value.toLowerCase().trim())) {
                                  setCreateFormValue("parkingLimitationAbstractParkingAreaUuid", parkingAreaOptions[parkingAreaOptions.findIndex(option => option[1].toLowerCase().trim() === value.toLowerCase().trim())][0]);
                                  resolve(true);
                                } else {
                                  resolve("Ugyldigt parkeringsområde")
                                }
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          inputValue={value || ""}
                          onChange={(event: any, newValue: string | any[] | null) => {
                            if (newValue !== null) {
                              setCreateFormValue("parkingLimitationAbstractParkingAreaParkingAreaCode", newValue[1]);
                              setCreateFormValue("parkingLimitationAbstractParkingAreaUuid", newValue[0]);
                              if (newValue[3] === null) {
                                setCreateFormValue("parkingLimitationUnlimitedParkingLimitationType", LimitationType.UNLIMITED);
                                setCreateFormValue("parkingLimitationConcurrentUnlimitedParking", newValue[4]);
                              } else {
                                switch (newValue[3]) {
                                  case 0:
                                    setCreateFormValue("parkingLimitationUnlimitedParkingLimitationType", LimitationType.PROHIBITED)
                                    break;
                                  default:
                                    setCreateFormValue("parkingLimitationUnlimitedParkingLimitationType", LimitationType.LIMITED)
                                    setCreateFormValue("parkingLimitationConcurrentUnlimitedParking", newValue[4]);
                                    setCreateFormValue("parkingLimitationInitialUnlimitedParking", newValue[3]);
                                    break;
                                }
                              }
                              if (newValue[5] === null) {
                                setCreateFormValue("parkingLimitationMinuteParkingLimitationType", LimitationType.UNLIMITED);
                                setCreateFormValue("parkingLimitationConcurrentMinuteParking", newValue[6]);
                              } else {
                                switch (newValue[5]) {
                                  case 0:
                                    setCreateFormValue("parkingLimitationMinuteParkingLimitationType", LimitationType.PROHIBITED)
                                    break;
                                  default:
                                    setCreateFormValue("parkingLimitationMinuteParkingLimitationType", LimitationType.LIMITED)
                                    setCreateFormValue("parkingLimitationConcurrentMinuteParking", newValue[6]);
                                    setCreateFormValue("parkingLimitationInitialMinuteParkingMinutes", newValue[5]);
                                    break;
                                }
                              }
                              clearCreateFormError("parkingLimitationAbstractParkingAreaParkingAreaCode")
                            } else {
                              setCreateFormValue("parkingLimitationAbstractParkingAreaParkingAreaCode", "");
                              setCreateFormValue("parkingLimitationAbstractParkingAreaUuid", "");
                            }
                          }}
                          freeSolo={true}
                          options={parkingAreaOptions}
                          getOptionLabel={(option) => option[1] + " - " + option[2]}
                          isOptionEqualToValue={(option, value) => option[0] === value[0]}
                          noOptionsText={"Ingen resultater"}
                          filterOptions={(x) => x}
                          loading={isParkingAreaLoading}
                          loadingText={"Vent venligst.."}
                          openText={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Parkeringsområde"
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : "Parkeringsområde tilknyttet begrænsningen"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isParkingAreaLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Controller
                      name="parkingLimitationUserClientPublicUsername"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Brugernavn eller e-mail er påkrævet"
                        },
                        maxLength: {
                          value: 256,
                          message: "Indtastning må ikke overstige 256 tegn"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value !== undefined && value.length >= 4) {
                                v1WebUserService.existsUserClientPublicAsUserClientOperator(value).then((res) => {
                                  if (res.data) {
                                    resolve(true)
                                  } else {
                                    resolve(false)
                                  }
                                }).catch((error) => {
                                  resolve(false);
                                })
                              } else {
                                resolve(false);
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Brugernavn/E-mail"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Egenparkerings begrænsnings type er påkrævet"
                        }
                      }}
                      control={createFormControl}
                      name="parkingLimitationUnlimitedParkingLimitationType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          component="fieldset"
                          error={!!error}
                        >
                          <FormLabel component="legend">Egenparkering</FormLabel>
                          <RadioGroup
                            {...field}
                            row
                            value={field.value || ""}
                          >
                            <FormControlLabel
                              value={LimitationType.PROHIBITED}
                              control={<Radio />}
                              label="Forbudt"
                            />
                            <FormControlLabel
                              value={LimitationType.LIMITED}
                              control={<Radio />}
                              label="Begrænset"
                            />
                            <FormControlLabel
                              value={LimitationType.UNLIMITED}
                              control={<Radio />}
                              label="Ubegrænset"
                            />
                          </RadioGroup>
                          <FormHelperText sx={{
                            marginLeft: 0
                          }}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Gæsteparkerings begrænsnings type er påkrævet"
                        }
                      }}
                      control={createFormControl}
                      name="parkingLimitationMinuteParkingLimitationType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          component="fieldset"
                          error={!!error}
                        >
                          <FormLabel component="legend">Gæsteparkering</FormLabel>
                          <RadioGroup
                            {...field}
                            row
                            value={field.value || ""}
                          >
                            <FormControlLabel
                              value={LimitationType.PROHIBITED}
                              control={<Radio />}
                              label="Forbudt"
                            />
                            <FormControlLabel
                              value={LimitationType.LIMITED}
                              control={<Radio />}
                              label="Begrænset"
                            />
                            <FormControlLabel
                              value={LimitationType.UNLIMITED}
                              control={<Radio />}
                              label="Ubegrænset"
                            />
                          </RadioGroup>
                          <FormHelperText sx={{
                            marginLeft: 0
                          }}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      {(watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.LIMITED || watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.UNLIMITED) &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingLimitationConcurrentUnlimitedParking"
                              control={createFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Maksimale antal tiladte igangværende egenparkeringer"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                      {watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.LIMITED &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <DialogContentText marginBottom={-4}>
                              Udfyld feltet nedenfor for at definere det årlige antal tilladte registreringer/ændringer af egenparkeringer som tildeles ved årets start
                            </DialogContentText>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingLimitationInitialUnlimitedParking"
                              control={createFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      {(watchParkingLimitationMinuteParkingLimitationType === LimitationType.LIMITED || watchParkingLimitationMinuteParkingLimitationType === LimitationType.UNLIMITED) &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingLimitationConcurrentMinuteParking"
                              control={createFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Maksimale antal tiladte igangværende gæsteparkeringer"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                      {watchParkingLimitationMinuteParkingLimitationType === LimitationType.LIMITED &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <DialogContentText marginBottom={-4}>
                              Udfyld felterne nedenfor for at definere den månedlige mængde gæsteparkering som tildeles ved månedens start. Angiv enten i dage, timer, minutter eller en kombination heraf
                            </DialogContentText>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingLimitationInitialMinuteParkingDays"
                              control={createFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 31)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 31")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Dage</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingLimitationInitialMinuteParkingHours"
                              control={createFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 999)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 999")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Timer</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingLimitationInitialMinuteParkingMinutes"
                              control={createFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99999)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99999")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Minutter</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="parkingLimitationNotes"
                      control={createFormControl}
                      rules={{
                        maxLength: {
                          value: 256,
                          message: "Noter må ikke overstige 256 tegn"
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Noter"
                          fullWidth
                          multiline
                          rows={4}
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleCreateDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Opret
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Fab
        onClick={toogleCreateDialogOpen}
        color="primary"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed"
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkingLimitationStateStatus: redux.parkingLimitation.status,
    parkingLimitationPageableListCount: redux.parkingLimitation.pageableListCount,
    parkingLimitationPageableListPageNumber: redux.parkingLimitation.pageableListPageNumber,
    parkingLimitationPageableListPageSize: redux.parkingLimitation.pageableListPageSize,
    parkingLimitationPageableListContent: redux.parkingLimitation.pageableListContent,
    parkingLimitationPageableListSort: redux.parkingLimitation.pageableListSort,
    parkingLimitationPageableListDirection: redux.parkingLimitation.pageableListDirection,
    parkingLimitationPageableListKeyword: redux.parkingLimitation.pageableListKeyword,
    parkingLimitationErrorCode: redux.parkingLimitation.errorCode,
    parkingLimitationErrorMessage: redux.parkingLimitation.errorMessage,
    parkingLimitationErrorUuid: redux.parkingLimitation.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getParkingLimitationPageableListForUserClientAsUserClient: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getParkingLimitationPageableListForUserClientAsUserClient(pageNumber, rowsPerPage, sort, direction))
    },
    searchParkingLimitationPageableListForUserClientAsUserClient: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(searchParkingLimitationPageableListForUserClientAsUserClient(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OperatorResidentListView);