import { NavigateNext } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormLabel, Grid, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import { ticketGetPageableList, ticketSearchPageableList } from "redux/ticket/ticket.action";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { NavLink } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { da } from "date-fns/locale";
import V1WebTicketListDownloadDTO from "dto/v1/web/v1webticketlistdownload.dto";
import v1WebTicketService from "service/v1/web/v1webticket.service";
import { AxiosResponse } from "axios";

interface OwnProps {
}

interface DispatchProps {
  ticketGetPageableList: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  ticketSearchPageableList: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  ticketStateStatus: StateStatus
  ticketPageableListCount: number
  ticketPageableListPageNumber: number
  ticketPageableListPageSize: number
  ticketPageableListContent: Array<any[]>
  ticketPageableListSort: string
  ticketPageableListDirection: Direction
  ticketPageableListKeyword: string
  ticketErrorCode: number
  ticketErrorMessage: string
  ticketErrorUuid: string
}

interface FormProps {
  ticketListFromDate: Date;
  ticketListToDate: Date;
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorTicketListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isTicketListDownloadLoading, setTicketListDownloadLoading] = useState(false);
  const [isTicketListDownloadDialogOpen, setTicketListDownloadDialogOpen] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({ mode: 'all' });

  const onSearchFormSubmit: SubmitHandler<StateProps> = (stateProps: StateProps) => {
    if (!isViewLoading && stateProps.ticketPageableListKeyword.length > 0) {
      props.ticketSearchPageableList(stateProps.ticketPageableListKeyword, 0, props.ticketPageableListPageSize, props.ticketPageableListSort, props.ticketPageableListDirection);
    }
  }

  const {
    handleSubmit: handleTicketListDownloadFormSubmit,
    control: ticketListDownloadFormControl,
    reset: resetTicketListDownloadForm,
    setError: setTicketListDownloadFormError,
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      ticketListFromDate: new Date(),
      ticketListToDate: new Date()
    }
  });

  const onTicketListDownloadFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isTicketListDownloadLoading) {
      setTicketListDownloadLoading(true);
      let v1WebTicketListDownloadDto = new V1WebTicketListDownloadDTO();

      if (formProps.ticketListFromDate > formProps.ticketListToDate) {
        setTicketListDownloadFormError("ticketListFromDate", { type: "custom", message: "Ugyldig dato" });
        setTicketListDownloadFormError("ticketListToDate", { type: "custom", message: "Ugyldig dato" });
        setTicketListDownloadLoading(false);
        return;
      }

      v1WebTicketListDownloadDto.ticketListDownloadFromV1DateTimeDto = {
        month: formProps.ticketListFromDate.getMonth() + 1,
        date: formProps.ticketListFromDate.getDate(),
        year: formProps.ticketListFromDate.getFullYear(),
        hour: 0,
        minute: 0,
        second: 0
      }

      v1WebTicketListDownloadDto.ticketListDownloadToV1DateTimeDto = {
        month: formProps.ticketListToDate.getMonth() + 1,
        date: formProps.ticketListToDate.getDate(),
        year: formProps.ticketListToDate.getFullYear(),
        hour: 0,
        minute: 0,
        second: 0
      }

      v1WebTicketService.getTicketListAsZIP(v1WebTicketListDownloadDto).then((response: AxiosResponse) => {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(new Blob([response.data]));
        link.setAttribute('download', "afgiftsliste.zip");
        document.body.appendChild(link);
        link.click();
        resetTicketListDownloadForm();
        setTicketListDownloadLoading(false);
        setTicketListDownloadDialogOpen(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
        setTicketListDownloadLoading(false);
      });
    }
  }

  const handleTicketListDownloadDialogOpenClick = () => {
    setTicketListDownloadDialogOpen(true);
  }

  const handleTicketListDownloadDialogCloseClick = () => {
    resetTicketListDownloadForm();
    setTicketListDownloadDialogOpen(false);
  }

  const handleTicketPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.ticketPageableListKeyword.length > 0) {
      props.ticketSearchPageableList(props.ticketPageableListKeyword, 0, parseInt(event.target.value), props.ticketPageableListSort, props.ticketPageableListDirection);
    } else {
      props.ticketGetPageableList(0, parseInt(event.target.value), props.ticketPageableListSort, props.ticketPageableListDirection);
    }
  };

  const handleTicketPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.ticketPageableListKeyword.length > 0) {
      props.ticketSearchPageableList(props.ticketPageableListKeyword, newPage, props.ticketPageableListPageSize, props.ticketPageableListSort, props.ticketPageableListDirection)
    } else {
      props.ticketGetPageableList(newPage, props.ticketPageableListPageSize, props.ticketPageableListSort, props.ticketPageableListDirection)
    }
  }

  const handleTicketDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.ticketPageableListDirection === Direction.ASC) {
      if (props.ticketPageableListKeyword.length > 0) {
        props.ticketSearchPageableList(props.ticketPageableListKeyword, props.ticketPageableListPageNumber, props.ticketPageableListPageSize, property, Direction.DESC);
      } else {
        props.ticketGetPageableList(props.ticketPageableListPageNumber, props.ticketPageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.ticketPageableListKeyword.length > 0) {
        props.ticketSearchPageableList(props.ticketPageableListKeyword, props.ticketPageableListPageNumber, props.ticketPageableListPageSize, property, Direction.ASC);
      } else {
        props.ticketGetPageableList(props.ticketPageableListPageNumber, props.ticketPageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleTicketKeywordClear = () => {
    props.ticketGetPageableList(props.ticketPageableListPageNumber, props.ticketPageableListPageSize, props.ticketPageableListSort, props.ticketPageableListDirection);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.ticketPageableListKeyword.length > 0) {
        props.ticketSearchPageableList(props.ticketPageableListKeyword, props.ticketPageableListPageNumber, props.ticketPageableListPageSize, props.ticketPageableListSort, props.ticketPageableListDirection);
      } else {
        props.ticketGetPageableList(props.ticketPageableListPageNumber, props.ticketPageableListPageSize, props.ticketPageableListSort, props.ticketPageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.ticketStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("ticketPageableListKeyword", props.ticketPageableListKeyword);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.ticketStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const headCells: readonly HeadCell[] = [
    {
      id: "ticketId",
      numeric: false,
      disablePadding: false,
      label: "Afgiftsnummer"
    },
    {
      id: "vehicle.vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    },
    {
      id: "abstractArea.areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "isEnabled",
      numeric: false,
      disablePadding: false,
      label: "Status"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.ticketErrorCode} errorMessage={props.ticketErrorMessage} errorUuid={props.ticketErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Afgifter
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="ticketPageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => { handleTicketKeywordClear() }}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.ticketPageableListSort === headCell.id}
                          direction={props.ticketPageableListDirection}
                          onClick={handleTicketDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.ticketPageableListContent === undefined || props.ticketPageableListContent.length === 0 ? props.ticketPageableListPageSize : props.ticketPageableListContent.length} tableColSpan={headCells.length} />
                    </>
                  ) : (
                    <>
                      {props.ticketPageableListCount === undefined || props.ticketPageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={headCells.length} />
                        </>
                      ) : (
                        <>
                          {props.ticketPageableListContent.map(ticket => (
                            <TableRow
                              hover
                              key={ticket[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {ticket[1]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {ticket[2]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {ticket[3]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {ticket[4] === "true" ? (
                                    <>
                                      Aktiv
                                    </>
                                  ) : (
                                    <>
                                      Annulleret
                                    </>
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={ticket[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={headCells.length + 1}
                      count={props.ticketPageableListCount || 0}
                      rowsPerPage={props.ticketPageableListPageSize || 5}
                      page={props.ticketPageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                      onPageChange={handleTicketPageNumberChange}
                      onRowsPerPageChange={handleTicketPageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>

              <CardContent>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={12}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          color="primary"
                          fullWidth
                          disabled={isTicketListDownloadLoading}
                          onClick={() => { handleTicketListDownloadDialogOpenClick() }}
                          type="button"
                          variant="outlined"
                        >
                          Hent afgiftesliste
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
      <Dialog
        open={isTicketListDownloadDialogOpen}
        onClose={handleTicketListDownloadDialogCloseClick}
        fullScreen
      >
        <form onSubmit={handleTicketListDownloadFormSubmit(onTicketListDownloadFormSubmit)}>
          <DialogTitle>Hent afgiftsliste</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Vælg periode for afgiftsliste
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <FormLabel component="legend">Fra</FormLabel>
                    <Grid
                      container
                      sx={{
                        marginTop: 2
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <Controller
                          name="ticketListFromDate"
                          control={ticketListDownloadFormControl}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider adapterLocale={da} dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Dato"
                                value={value}
                                onChange={onChange}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled={isTicketListDownloadLoading}
                                  />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    lg={6}
                    md={6}
                    xs={12}
                  >
                    <FormLabel component="legend">Til</FormLabel>
                    <Grid
                      container
                      sx={{
                        marginTop: 2
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <Controller
                          name="ticketListToDate"
                          control={ticketListDownloadFormControl}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider adapterLocale={da} dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Dato"
                                value={value}
                                onChange={onChange}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    disabled={isTicketListDownloadLoading}
                                  />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isTicketListDownloadLoading}
                onClick={handleTicketListDownloadDialogCloseClick}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isTicketListDownloadLoading}
                type="submit"
                variant="contained"
              >
                Generér afgiftsliste
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    ticketStateStatus: redux.ticket.status,
    ticketPageableListCount: redux.ticket.pageableListCount,
    ticketPageableListPageNumber: redux.ticket.pageableListPageNumber,
    ticketPageableListPageSize: redux.ticket.pageableListPageSize,
    ticketPageableListContent: redux.ticket.pageableListContent,
    ticketPageableListSort: redux.ticket.pageableListSort,
    ticketPageableListDirection: redux.ticket.pageableListDirection,
    ticketPageableListKeyword: redux.ticket.pageableListKeyword,
    ticketErrorCode: redux.ticket.errorCode,
    ticketErrorMessage: redux.ticket.errorMessage,
    ticketErrorUuid: redux.ticket.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    ticketGetPageableList: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(ticketGetPageableList(pageNumber, rowsPerPage, sort, direction))
    },
    ticketSearchPageableList: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(ticketSearchPageableList(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorTicketListView);