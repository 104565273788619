import { Box, Typography, TextField, Button, Link, Alert } from "@mui/material";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";
import { RootRedux } from "redux/store";
import { useEffect, useState } from "react";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { login, logout, authenticationInit } from "redux/authentication/authentication.action";
import { UserType } from "type/user.type";
import { rootInitUser } from "redux/root/root.action";
import { useLocation, useNavigate } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

interface OwnProps {
}

interface DispatchProps {
  login: (usernameOrEmail: string, password: string) => void;
  logout: () => void;
  authenticationInit: () => void;
  rootInitUser: () => void;
}

interface StateProps {
  authenticationStateStatus: StateStatus;
  authenticated: boolean | null;
  authenticationErrorCode: number;
  authenticationErrorMessage: string | null;
  rootStateStatus: StateStatus;
  rootUserType: UserType | null;
  rootErrorMessage: string | null;
}

interface FormProps {
  usernameOrEmail: string;
  password: string;
}

interface LocationProps {
  hash: string;
  key: string;
  pathname: string;
  search: string;
  state: { from: { pathname: string, search: string, hash: string, state: string, key: string } | null } | null
}

type Props = StateProps & OwnProps & DispatchProps;

const LoginView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [viewAlert, setViewAlert] = useState(<></>);

  const navigate = useNavigate();
  const location = useLocation() as LocationProps;

  const {
    handleSubmit: handleLoginFormSubmit,
    control: loginFormControl
  } = useForm<FormProps>({
    mode: 'all'
  });

  const onLoginFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      if (formProps.usernameOrEmail.trim().length < 4 || formProps.usernameOrEmail.trim().length > 256 || !formProps.usernameOrEmail.trim().match(/[a-zA-Z0-9@.]/) || formProps.password.length < 6 || formProps.password.length > 256 || !formProps.password.match(/[a-z]/) || !formProps.password.match(/[A-Z]/) || !formProps.password.match(/[!@#$%&^=?+-]/)) {
        setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">Brugernavn eller adgangskode er forkert</Alert>);
        return;
      }

      props.login(formProps.usernameOrEmail, formProps.password);
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      props.authenticationInit();
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.authenticationStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        if (props.rootUserType === null) {
          props.rootInitUser();
        }
        break;
      }
      case FAILURE_STATUS: {
        if (props.authenticationErrorCode !== undefined) {
          switch (props.authenticationErrorCode) {
            case 401: {
              setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">{props.authenticationErrorMessage}</Alert>);
              break;
            }
            default: {
              setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">{props.authenticationErrorMessage}</Alert>);
              break;
            }
          }
        }

        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.authenticationStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.rootStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        if (location.state?.from?.pathname !== undefined) {
          navigate(location.state.from.pathname, { replace: true });
        } else {
          switch (props.rootUserType) {
            case UserType.ADMINISTRATOR: {
              navigate('/administrator', { replace: true });
              break;
            }
            case UserType.GUARD: {
              navigate('/guard', { replace: true });
              break;
            }
            case UserType.KIOSK: {
              navigate("/kiosk", { replace: true });
              break;
            }
            case UserType.OPERATOR: {
              navigate('/operator', { replace: true });
              break;
            }
            default: {
              setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">Denne brugertype er ikke understøttet</Alert>);
              props.logout();
              break;
            }
          }
        }
        break;
      }
      case FAILURE_STATUS: {
        if (props.rootErrorMessage !== undefined && props.rootErrorMessage !== "") {
          setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">{props.rootErrorMessage}</Alert>);
        }

        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.rootStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <form onSubmit={handleLoginFormSubmit(onLoginFormSubmit)}>
        <Box mb={3}>
          <Typography
            color="textPrimary"
            variant="h2"
          >
            Log ind
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Du skal angive brugernavn/e-mail og adgangskode.
          </Typography>
        </Box>
        <Controller
          name="usernameOrEmail"
          control={loginFormControl}
          rules={{
            required: {
              value: true,
              message: "Brugernavn eller e-mail er påkrævet"
            }
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Brugernavn/E-mail"
              fullWidth
              margin="normal"
              value={value || ""}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Controller
          name="password"
          control={loginFormControl}
          rules={{
            required: {
              value: true,
              message: "Adgangskode er påkrævet"
            }
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Adgangskode"
              type="password"
              fullWidth
              margin="normal"
              value={value || ""}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        {showViewAlert ? (
          viewAlert
        ) : (
          <></>
        )}
        <Box my={2}>
          <Button
            color="primary"
            disabled={isViewLoading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Log ind
          </Button>
        </Box>
      </form>
      <Typography>
        <Link
          underline="hover"
          href="/forgotpassword"
        >
          Glemt adgangskode?
        </Link>
      </Typography>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    authenticationStateStatus: redux.authentication.status,
    authenticated: redux.authentication.authenticated,
    authenticationErrorCode: redux.authentication.errorCode,
    authenticationErrorMessage: redux.authentication.errorMessage,
    rootStateStatus: redux.root.status,
    rootUserType: redux.root.userType,
    rootErrorMessage: redux.root.errorMessage
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    login: async (usernameOrEmail: string, password: string) => {
      await dispatch(login(usernameOrEmail, password));
    },
    logout: async () => {
      await dispatch(logout())
    },
    authenticationInit: async () => {
      await dispatch(authenticationInit());
    },
    rootInitUser: async () => {
      await dispatch(rootInitUser());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);