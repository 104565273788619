import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { ParkPermitAction, PARKPERMIT_GET, PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST, PARKPERMIT_GET_PAGEABLELIST, PARKPERMIT_PUT, PARKPERMIT_SEARCH_PAGEABLELIST } from "./parkpermit.action";

export type ParkPermitState = {
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  parkPermitUuid: string;
  parkPermitValidFrom: Date;
  parkPermitValidTo: Date;
  parkPermitDataPolicyAcceptedTimeStamp: Date;
  parkPermitDataPolicyRevision: string;
  parkPermitNotes: string;
  parkPermitIsEnabled: boolean;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  abstractParkingAreaAddress: string;
  abstractParkingAreaZip: string;
  abstractParkingAreaCity: string;
  vehicleUuid: string;
  vehicleVehicleId: string;
  abstractUserClientUuid: string;
  abstractUserClientUsername: string;
  notificationStatus: StateStatus;
  notificationPageableListCount: number;
  notificationPageableListPageNumber: number;
  notificationPageableListPageSize: number;
  notificationPageableListContent: Array<any[]>;
  notificationPageableListSort: string;
  notificationPageableListDirection: Direction;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: ParkPermitState = {
  status: IDLE_STATUS,
  pageableListCount: 0,
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "createdDate",
  pageableListDirection: Direction.DESC,
  pageableListKeyword: "",
  parkPermitUuid: "",
  parkPermitValidFrom: new Date(),
  parkPermitValidTo: new Date(),
  parkPermitDataPolicyAcceptedTimeStamp: new Date(),
  parkPermitDataPolicyRevision: "",
  parkPermitNotes: "",
  parkPermitIsEnabled: false,
  abstractParkingAreaUuid: "",
  abstractParkingAreaParkingAreaCode: "",
  abstractParkingAreaAddress: "",
  abstractParkingAreaZip: "",
  abstractParkingAreaCity: "",
  vehicleUuid: "",
  vehicleVehicleId: "",
  abstractUserClientUuid: "",
  abstractUserClientUsername: "",
  notificationStatus: IDLE_STATUS,
  notificationPageableListCount: 0,
  notificationPageableListPageNumber: 0,
  notificationPageableListPageSize: 5,
  notificationPageableListContent: Array<any[]>(),
  notificationPageableListSort: "createdDate",
  notificationPageableListDirection: Direction.DESC,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function parkPermitReducer(state: ParkPermitState = initialState, action: ParkPermitAction): ParkPermitState {
  switch (state.status) {
    case IDLE_STATUS: {
      return parkPermitIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return parkPermitLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return parkPermitSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return parkPermitFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default parkPermitReducer;

function parkPermitIdleReducer(state: ParkPermitState, action: ParkPermitAction): ParkPermitState {
  switch (action.type) {
    case PARKPERMIT_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST: {
      return {
        ...state,
        notificationStatus: action.status
      }
    }
    case PARKPERMIT_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function parkPermitLoadingReducer(state: ParkPermitState, action: ParkPermitAction): ParkPermitState {
  switch (action.type) {
    case PARKPERMIT_GET: {
      return {
        ...state,
        status: action.status,
        parkPermitUuid: action.parkPermitUuid,
        parkPermitValidFrom: action.parkPermitValidFrom,
        parkPermitValidTo: action.parkPermitValidTo,
        parkPermitDataPolicyAcceptedTimeStamp: action.parkPermitDataPolicyAcceptedTimeStamp,
        parkPermitDataPolicyRevision: action.parkPermitDataPolicyRevision,
        parkPermitNotes: action.parkPermitNotes,
        parkPermitIsEnabled: action.parkPermitIsEnabled,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        abstractParkingAreaAddress: action.abstractParkingAreaAddress,
        abstractParkingAreaZip: action.abstractParkingAreaZip,
        abstractParkingAreaCity: action.abstractParkingAreaCity,
        vehicleUuid: action.vehicleUuid,
        vehicleVehicleId: action.vehicleVehicleId,
        abstractUserClientUuid: action.abstractUserClientUuid,
        abstractUserClientUsername: action.abstractUserClientUsername,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKPERMIT_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        parkPermitUuid: "",
        parkPermitValidFrom: new Date(),
        parkPermitValidTo: new Date(),
        parkPermitDataPolicyAcceptedTimeStamp: new Date(),
        parkPermitDataPolicyRevision: "",
        parkPermitNotes: "",
        parkPermitIsEnabled: false,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        abstractUserClientUuid: "",
        abstractUserClientUsername: "",
        notificationStatus: IDLE_STATUS,
        notificationPageableListCount: 0,
        notificationPageableListPageNumber: 0,
        notificationPageableListPageSize: 5,
        notificationPageableListContent: Array<any[]>(),
        notificationPageableListSort: "createdDate",
        notificationPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKPERMIT_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        parkPermitUuid: "",
        parkPermitValidFrom: new Date(),
        parkPermitValidTo: new Date(),
        parkPermitDataPolicyAcceptedTimeStamp: new Date(),
        parkPermitDataPolicyRevision: "",
        parkPermitNotes: "",
        parkPermitIsEnabled: false,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        abstractUserClientUuid: "",
        abstractUserClientUsername: "",
        notificationStatus: IDLE_STATUS,
        notificationPageableListCount: 0,
        notificationPageableListPageNumber: 0,
        notificationPageableListPageSize: 5,
        notificationPageableListContent: Array<any[]>(),
        notificationPageableListSort: "createdDate",
        notificationPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST: {
      return {
        ...state,
        notificationStatus: action.status,
        notificationPageableListCount: action.notificationPageableListCount,
        notificationPageableListPageNumber: action.notificationPageableListPageNumber,
        notificationPageableListPageSize: action.notificationPageableListPageSize,
        notificationPageableListContent: action.notificationPageableListContent,
        notificationPageableListSort: action.notificationPageableListSort,
        notificationPageableListDirection: action.notificationPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKPERMIT_PUT: {
      return {
        ...state,
        status: action.status,
        parkPermitUuid: action.parkPermitUuid,
        parkPermitValidFrom: action.parkPermitValidFrom,
        parkPermitValidTo: action.parkPermitValidTo,
        parkPermitDataPolicyAcceptedTimeStamp: action.parkPermitDataPolicyAcceptedTimeStamp,
        parkPermitDataPolicyRevision: action.parkPermitDataPolicyRevision,
        parkPermitNotes: action.parkPermitNotes,
        parkPermitIsEnabled: action.parkPermitIsEnabled,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        abstractParkingAreaAddress: action.abstractParkingAreaAddress,
        abstractParkingAreaZip: action.abstractParkingAreaZip,
        abstractParkingAreaCity: action.abstractParkingAreaCity,
        vehicleUuid: action.vehicleUuid,
        vehicleVehicleId: action.vehicleVehicleId,
        abstractUserClientUuid: action.abstractUserClientUuid,
        abstractUserClientUsername: action.abstractUserClientUsername,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function parkPermitSuccessReducer(state: ParkPermitState, action: ParkPermitAction): ParkPermitState {
  switch (action.type) {
    case PARKPERMIT_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST: {
      switch (state.notificationStatus) {
        case IDLE_STATUS: {
          return parkPermitIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return parkPermitLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return parkPermitFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            notificationStatus: action.status
          }
        }
      }
    }
    case PARKPERMIT_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function parkPermitFailureReducer(state: ParkPermitState, action: ParkPermitAction): ParkPermitState {
  switch (action.type) {
    case PARKPERMIT_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST: {
      return {
        ...state,
        notificationStatus: action.status
      }
    }
    case PARKPERMIT_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}