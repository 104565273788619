import DTO from "dto/dto";
import V1DateTimeDTO from "../v1datetime.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebTicketListDownloadDTO implements DTO {
  
  ticketListDownloadFromV1DateTimeDto!: V1DateTimeDTO;
  ticketListDownloadToV1DateTimeDto!: V1DateTimeDTO;
}