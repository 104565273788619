import { Typography, Grid, Card, CardContent, CardHeader, Divider, TextField, Switch, Box, Button, FormControlLabel, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip } from "@mui/material"
import CustomContainer from "component/customcontainer"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { StateStatus, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from "redux/statestatus"
import { RootRedux } from "redux/store"
import ErrorContainer from "component/errorcontainer";
import SpinnerContainer from "component/spinnercontainer"
import { vehicleGet, vehicleGetParkPermitPageableList, vehicleGetTicketPageableList, vehiclePut } from "redux/vehicle/vehicle.action"
import HeadCell from "component/headcell"
import { NavigateNext } from "@mui/icons-material"
import { Direction } from "dto/direction"
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import UUIDHelper from "helper/uuidhelper"
import { Controller, SubmitHandler, useForm } from "react-hook-form"

interface OwnProps {
}

interface DispatchProps {
  vehicleGet: (vehicleUuid: string) => void;
  vehicleGetParkPermitPageableList: (vehicleUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  vehicleGetTicketPageableList: (vehicleUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  vehiclePut: (vehicleUuid: string, vehicleNotes: string, vehicleIsAllowedToPark: boolean) => void;
}

interface StateProps {
  vehicleStateStatus: StateStatus;
  vehicleUuid: string;
  vehicleVehicleId: string;
  vehicleNotes: string;
  vehicleIsAllowedToPark: boolean;
  vehicleParkPermitStateStatus: StateStatus;
  vehicleParkPermitPageableListCount: number;
  vehicleParkPermitPageableListPageNumber: number;
  vehicleParkPermitPageableListPageSize: number;
  vehicleParkPermitPageableListContent: Array<any[]>;
  vehicleParkPermitPageableListSort: string;
  vehicleParkPermitPageableListDirection: Direction;
  vehicleTicketStateStatus: StateStatus;
  vehicleTicketPageableListCount: number;
  vehicleTicketPageableListPageNumber: number;
  vehicleTicketPageableListPageSize: number;
  vehicleTicketPageableListContent: Array<any[]>;
  vehicleTicketPageableListSort: string;
  vehicleTicketPageableListDirection: Direction;
  vehicleErrorCode: number;
  vehicleErrorMessage: string;
  vehicleErrorUuid: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const AdministratorVehicleView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isParkPermitLoaded, setParkPermitLoaded] = useState(false);
  const [isTicketLoaded, setTicketLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkPermitLoading, setParkPermitLoading] = useState(true);
  const [isTicketLoading, setTicketLoading] = useState(true);
  const [showViewAlert, setShowViewAlert] = useState(false);

  const navigate = useNavigate();
  const params = useParams();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    setValue: setUpdateFormValue
  } = useForm<StateProps>({
    mode: 'all'
  });

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.vehiclePut(formProps.vehicleUuid, formProps.vehicleNotes, formProps.vehicleIsAllowedToPark)
    }
  }

  const handleVehicleParkPermitPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.vehicleGetParkPermitPageableList(props.vehicleUuid, 0, parseInt(event.target.value), props.vehicleParkPermitPageableListSort, props.vehicleParkPermitPageableListDirection);
  };

  const handleVehicleParkPermitPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.vehicleGetParkPermitPageableList(props.vehicleUuid, newPage, props.vehicleParkPermitPageableListPageSize, props.vehicleParkPermitPageableListSort, props.vehicleParkPermitPageableListDirection)
  }

  const handleVehicleParkPermitDirectionChange = (property: string) => () => {
    if (props.vehicleParkPermitPageableListDirection === Direction.ASC) {
      props.vehicleGetParkPermitPageableList(props.vehicleUuid, props.vehicleParkPermitPageableListPageNumber, props.vehicleParkPermitPageableListPageSize, property, Direction.DESC);
    } else {
      props.vehicleGetParkPermitPageableList(props.vehicleUuid, props.vehicleParkPermitPageableListPageNumber, props.vehicleParkPermitPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleVehicleTicketPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.vehicleGetTicketPageableList(props.vehicleUuid, 0, parseInt(event.target.value), props.vehicleTicketPageableListSort, props.vehicleTicketPageableListDirection);
  };

  const handleVehicleTicketPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.vehicleGetTicketPageableList(props.vehicleUuid, newPage, props.vehicleTicketPageableListPageSize, props.vehicleTicketPageableListSort, props.vehicleTicketPageableListDirection)
  }

  const handleVehicleTicketDirectionChange = (property: string) => () => {
    if (props.vehicleTicketPageableListDirection === Direction.ASC) {
      props.vehicleGetTicketPageableList(props.vehicleUuid, props.vehicleTicketPageableListPageNumber, props.vehicleTicketPageableListPageSize, property, Direction.DESC);
    } else {
      props.vehicleGetTicketPageableList(props.vehicleUuid, props.vehicleTicketPageableListPageNumber, props.vehicleTicketPageableListPageSize, property, Direction.ASC);
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.vehicleUuid !== undefined && UUIDHelper.validateUUID(params.vehicleUuid)) {
        props.vehicleGet(params.vehicleUuid);
        setViewLoaded(true);
      } else {
        navigate('404', { replace: true });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isParkPermitLoaded && props.vehicleUuid !== undefined && props.vehicleUuid !== "") {
      props.vehicleGetParkPermitPageableList(props.vehicleUuid, props.vehicleParkPermitPageableListPageNumber, props.vehicleParkPermitPageableListPageSize, props.vehicleParkPermitPageableListSort, props.vehicleParkPermitPageableListDirection);
      setParkPermitLoaded(true);
    }

    if (!isTicketLoaded && props.vehicleUuid !== undefined && props.vehicleUuid !== "") {
      props.vehicleGetTicketPageableList(props.vehicleUuid, props.vehicleTicketPageableListPageNumber, props.vehicleTicketPageableListPageSize, props.vehicleTicketPageableListSort, props.vehicleTicketPageableListDirection);
      setTicketLoaded(true);
    }
  }, [props.vehicleUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.vehicleStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("vehicleUuid", props.vehicleUuid);
        setUpdateFormValue("vehicleVehicleId", props.vehicleVehicleId);
        setUpdateFormValue("vehicleNotes", props.vehicleNotes);
        setUpdateFormValue("vehicleIsAllowedToPark", props.vehicleIsAllowedToPark);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.vehicleStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.vehicleParkPermitStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setParkPermitLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setParkPermitLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setParkPermitLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.vehicleParkPermitStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.vehicleTicketStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setTicketLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setTicketLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setTicketLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.vehicleTicketStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

  const vehicleParkPermitHeadCells: readonly HeadCell[] = [
    {
      id: "abstractArea.areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "validFrom",
      numeric: false,
      disablePadding: false,
      label: "Gyldig fra"
    },
    {
      id: "validTo",
      numeric: false,
      disablePadding: false,
      label: "Gyldig til"
    }
  ]

  const vehicleTicketHeadCells: readonly HeadCell[] = [
    {
      id: "ticketId",
      numeric: false,
      disablePadding: false,
      label: "Afgiftsnummer"
    },
    {
      id: "abstractArea.areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.vehicleErrorCode} errorMessage={props.vehicleErrorMessage} errorUuid={props.vehicleErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Køretøj
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    <CardHeader
                      subheader={"Generelle oplysninger for køretøjet"}
                      title="Køretøj oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="vehicleVehicleId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Køretøj"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="vehicleNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="vehicleIsAllowedToPark"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                />
                              } label="Aktiveret" />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem køretøj oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Card>
                  <CardHeader
                    subheader={"Alle tilladelser udsted til dette køretøj"}
                    title="Tilladelser" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {vehicleParkPermitHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.vehicleParkPermitPageableListSort === headCell.id}
                              direction={props.vehicleParkPermitPageableListDirection}
                              onClick={handleVehicleParkPermitDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isParkPermitLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.vehicleParkPermitPageableListContent === undefined || props.vehicleParkPermitPageableListContent.length === 0 ? props.vehicleParkPermitPageableListPageSize : props.vehicleParkPermitPageableListContent.length} tableColSpan={vehicleParkPermitHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.vehicleParkPermitPageableListCount === undefined || props.vehicleParkPermitPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={vehicleParkPermitHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.vehicleParkPermitPageableListContent.map(parkpermit => (
                                <TableRow
                                  hover
                                  key={parkpermit[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {parkpermit[4]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {(parkpermit[1] === undefined || parkpermit[1] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkpermit[1]))}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {(parkpermit[2] === undefined || parkpermit[2] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkpermit[2]))}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title="Vis">
                                      <IconButton aria-label="Edit"
                                        edge='end'
                                        component={NavLink}
                                        to={"../parkpermit/" + parkpermit[0]}
                                      >
                                        <NavigateNext />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={vehicleParkPermitHeadCells.length + 1}
                          count={props.vehicleParkPermitPageableListCount || 0}
                          rowsPerPage={props.vehicleParkPermitPageableListPageSize || 5}
                          page={props.vehicleParkPermitPageableListPageNumber || 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleVehicleParkPermitPageNumberChange}
                          onRowsPerPageChange={handleVehicleParkPermitPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
                <Card
                  sx={{
                    marginTop: 3
                  }}
                >
                  <CardHeader
                    subheader={"Alle afgifter udsted til dette køretøj"}
                    title="Afgifter" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {vehicleTicketHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.vehicleTicketPageableListSort === headCell.id}
                              direction={props.vehicleTicketPageableListDirection}
                              onClick={handleVehicleTicketDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isTicketLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.vehicleTicketPageableListContent === undefined || props.vehicleTicketPageableListContent.length === 0 ? props.vehicleTicketPageableListPageSize : props.vehicleTicketPageableListContent.length} tableColSpan={vehicleTicketHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.vehicleTicketPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={vehicleTicketHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.vehicleTicketPageableListContent.map(ticket => (
                                <TableRow
                                  hover
                                  key={ticket[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {ticket[1]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {ticket[3]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title="Vis">
                                      <IconButton aria-label="Edit"
                                        edge='end'
                                        component={NavLink}
                                        to={"../ticket/" + ticket[0]}
                                      >
                                        <NavigateNext />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={vehicleTicketHeadCells.length + 1}
                          count={props.vehicleTicketPageableListCount || 0}
                          rowsPerPage={props.vehicleTicketPageableListPageSize || 5}
                          page={props.vehicleTicketPageableListPageNumber || 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleVehicleTicketPageNumberChange}
                          onRowsPerPageChange={handleVehicleTicketPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    vehicleStateStatus: redux.vehicle.status,
    vehicleUuid: redux.vehicle.vehicleUuid,
    vehicleVehicleId: redux.vehicle.vehicleVehicleId,
    vehicleNotes: redux.vehicle.vehicleNotes,
    vehicleIsAllowedToPark: redux.vehicle.vehicleIsAllowedToPark,
    vehicleParkPermitStateStatus: redux.vehicle.parkPermitStatus,
    vehicleParkPermitPageableListCount: redux.vehicle.parkPermitPageableListCount,
    vehicleParkPermitPageableListPageNumber: redux.vehicle.parkPermitPageableListPageNumber,
    vehicleParkPermitPageableListPageSize: redux.vehicle.parkPermitPageableListPageSize,
    vehicleParkPermitPageableListContent: redux.vehicle.parkPermitPageableListContent,
    vehicleParkPermitPageableListSort: redux.vehicle.parkPermitPageableListSort,
    vehicleParkPermitPageableListDirection: redux.vehicle.parkPermitPageableListDirection,
    vehicleTicketStateStatus: redux.vehicle.ticketStatus,
    vehicleTicketPageableListCount: redux.vehicle.ticketPageableListCount,
    vehicleTicketPageableListPageNumber: redux.vehicle.ticketPageableListPageNumber,
    vehicleTicketPageableListPageSize: redux.vehicle.ticketPageableListPageSize,
    vehicleTicketPageableListContent: redux.vehicle.ticketPageableListContent,
    vehicleTicketPageableListSort: redux.vehicle.ticketPageableListSort,
    vehicleTicketPageableListDirection: redux.vehicle.ticketPageableListDirection,
    vehicleErrorCode: redux.vehicle.errorCode,
    vehicleErrorMessage: redux.vehicle.errorMessage,
    vehicleErrorUuid: redux.vehicle.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    vehicleGet: async (vehicleUuid) => {
      await dispatch(vehicleGet(vehicleUuid));
    },
    vehicleGetParkPermitPageableList: async (vehicleUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(vehicleGetParkPermitPageableList(vehicleUuid, pageNumber, rowsPerPage, sort, direction));
    },
    vehicleGetTicketPageableList: async (vehicleUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(vehicleGetTicketPageableList(vehicleUuid, pageNumber, rowsPerPage, sort, direction));
    },
    vehiclePut: async (vehicleUuid, vehicleNotes, vehicleIsAllowedToPark) => {
      await dispatch(vehiclePut(vehicleUuid, vehicleNotes, vehicleIsAllowedToPark));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorVehicleView);