import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebCustomerDTO from "dto/v1/web/v1webcustomer.dto";
import V1WebCustomerListElementDTO from "dto/v1/web/v1webcustomerlistelement.dto";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import V1WebTransactionListElementDTO from "dto/v1/web/v1webtransactionlistelement.dto";
import V1WebUserListElementDTO from "dto/v1/web/v1webuserlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebCustomerService from "service/v1/web/v1webcustomer.service";
import { CustomerState } from "./customer.reducer";

export const CUSTOMER_GET = "user/get";
export const CUSTOMER_GET_PAGEABLELIST = "customer/get/pageablelist";
export const CUSTOMER_SEARCH_PAGEABLELIST = "customer/search/pageablelist";
export const CUSTOMER_GET_PARKINGAREA_PAGEABLELIST = "customer/get/parkingarea/pageablelist";
export const CUSTOMER_GET_USERCLIENT_PAGEABLELIST = "customer/get/userclient/pageablelist";
export const CUSTOMER_GET_TRANSACTION_PAGEABLELIST = "customer/get/transaction/pageablelist";
export const CUSTOMER_PUT = "customer/put";

export interface CustomerAction {
  type:
    typeof CUSTOMER_GET |
    typeof CUSTOMER_GET_PAGEABLELIST |
    typeof CUSTOMER_SEARCH_PAGEABLELIST |
    typeof CUSTOMER_GET_PARKINGAREA_PAGEABLELIST |
    typeof CUSTOMER_GET_USERCLIENT_PAGEABLELIST |
    typeof CUSTOMER_GET_TRANSACTION_PAGEABLELIST |
    typeof CUSTOMER_PUT
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  customerUuid: string;
  customerCompanyName: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: string;
  customerZip: string;
  customerCity: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerVatNumber: string;
  customerBankName: string;
  customerBankAccount: string;
  customerNotes: string;
  parkingAreaStatus: StateStatus;
  parkingAreaPageableListCount: number;
  parkingAreaPageableListPageNumber: number;
  parkingAreaPageableListPageSize: number;
  parkingAreaPageableListContent: Array<any[]>;
  parkingAreaPageableListSort: string;
  parkingAreaPageableListDirection: Direction;
  userClientStatus: StateStatus;
  userClientPageableListCount: number;
  userClientPageableListPageNumber: number;
  userClientPageableListPageSize: number;
  userClientPageableListContent: Array<any[]>;
  userClientPageableListSort: string;
  userClientPageableListDirection: Direction;
  transactionStatus: StateStatus;
  transactionPageableListCount: number;
  transactionPageableListPageNumber: number;
  transactionPageableListPageSize: number;
  transactionPageableListContent: Array<any[]>;
  transactionPageableListSort: string;
  transactionPageableListDirection: Direction;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type CustomerThunkAction = ThunkAction<Promise<void>, CustomerState, any, CustomerAction>;

export function customerGet(customerUuid: string): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_GET,
        status: LOADING_STATUS
      });

      v1WebCustomerService.getCustomer(customerUuid).then((response: AxiosResponse) => {
        let v1WebCustomerDto = response.data as V1WebCustomerDTO;

        dispatch({
          type: CUSTOMER_GET,
          status: SUCCESS_STATUS,
          customerUuid: v1WebCustomerDto.customerUuid,
          customerCompanyName: v1WebCustomerDto.customerCompanyName,
          customerFirstName: v1WebCustomerDto.customerFirstName,
          customerLastName: v1WebCustomerDto.customerLastName,
          customerAddress: v1WebCustomerDto.customerAddress,
          customerZip: v1WebCustomerDto.customerZip,
          customerCity: v1WebCustomerDto.customerCity,
          customerEmail: v1WebCustomerDto.customerEmail,
          customerPhoneNumber: v1WebCustomerDto.customerPhoneNumber,
          customerVatNumber: v1WebCustomerDto.customerVatNumber,
          customerBankName: v1WebCustomerDto.customerBankName,
          customerBankAccount: v1WebCustomerDto.customerBankAccount,
          customerNotes: v1WebCustomerDto.customerNotes
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function customerGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebCustomerService.getCustomers(page).then((response: AxiosResponse) => {
        let customerPageableList = response.data as Response<V1WebCustomerListElementDTO>

        let pageableListContent: any[] = [];
        customerPageableList.content.forEach((value) => {
          pageableListContent.push([value.customerUuid, value.customerCompanyName])
        });

        dispatch({
          type: CUSTOMER_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: customerPageableList.totalElements,
          pageableListPageNumber: customerPageableList.pageable.pageNumber,
          pageableListPageSize: customerPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function customerSearchPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebCustomerService.searchCustomer(v1keywordDto, page).then((response: AxiosResponse) => {
        let customerPageableList = response.data as Response<V1WebCustomerListElementDTO>

        let pageableListContent: any[] = [];
        customerPageableList.content.forEach((value) => {
          pageableListContent.push([value.customerUuid, value.customerCompanyName])
        });

        dispatch({
          type: CUSTOMER_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: customerPageableList.totalElements,
          pageableListPageNumber: customerPageableList.pageable.pageNumber,
          pageableListPageSize: customerPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function customerGetParkingAreaPageableList(customerUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_GET_PARKINGAREA_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebCustomerService.getCustomerParkingArea(customerUuid, page).then((response: AxiosResponse) => {
        let parkingAreaPageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: any[] = [];
        parkingAreaPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity])
        });

        dispatch({
          type: CUSTOMER_GET_PARKINGAREA_PAGEABLELIST,
          status: SUCCESS_STATUS,
          parkingAreaPageableListCount: parkingAreaPageableList.totalElements,
          parkingAreaPageableListPageNumber: parkingAreaPageableList.pageable.pageNumber,
          parkingAreaPageableListPageSize: parkingAreaPageableList.pageable.pageSize,
          parkingAreaPageableListContent: pageableListContent,
          parkingAreaPageableListSort: sort,
          parkingAreaPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_GET_PARKINGAREA_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function customerGetUserClientPageableList(abstractUserUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_GET_USERCLIENT_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebCustomerService.getCustomerUserClient(abstractUserUuid, page).then((response: AxiosResponse) => {
        let userClientPageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: any[] = [];
        userClientPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserUsername, value.abstractUserUserType])
        });

        dispatch({
          type: CUSTOMER_GET_USERCLIENT_PAGEABLELIST,
          status: SUCCESS_STATUS,
          userClientPageableListCount: userClientPageableList.totalElements,
          userClientPageableListPageNumber: userClientPageableList.pageable.pageNumber,
          userClientPageableListPageSize: userClientPageableList.pageable.pageSize,
          userClientPageableListContent: pageableListContent,
          userClientPageableListSort: sort,
          userClientPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_GET_USERCLIENT_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function customerGetTransactionPageableList(abstractUserUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_GET_TRANSACTION_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebCustomerService.getCustomerTransaction(abstractUserUuid, page).then((response: AxiosResponse) => {
        let transactionPageableList = response.data as Response<V1WebTransactionListElementDTO>

        let pageableListContent: any[] = [];
        transactionPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractTransactionUuid, value.abstractTransactionCurrency, value.abstractTransactionTransactionCost, value.abstractTransactionTransactionType]);
        });

        dispatch({
          type: CUSTOMER_GET_TRANSACTION_PAGEABLELIST,
          status: SUCCESS_STATUS,
          transactionPageableListCount: transactionPageableList.totalElements,
          transactionPageableListPageNumber: transactionPageableList.pageable.pageNumber,
          transactionPageableListPageSize: transactionPageableList.pageable.pageSize,
          transactionPageableListContent: pageableListContent,
          transactionPageableListSort: sort,
          transactionPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_GET_TRANSACTION_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function customerPut(customerUuid: string, customerCompanyName: string, customerFirstName: string, customerLastName: string, customerAddress: string, customerZip: string, customerCity: string, customerEmail: string, customerPhoneNumber: string, customerVatNumber: string, customerBankName: string, customerBankAccount: string, customerNotes: string): CustomerThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: CUSTOMER_PUT,
        status: LOADING_STATUS
      });

      let v1WebCustomerDto = new V1WebCustomerDTO();

      v1WebCustomerDto.customerUuid = customerUuid;
      v1WebCustomerDto.customerCompanyName = customerCompanyName;
      v1WebCustomerDto.customerFirstName = customerFirstName;
      v1WebCustomerDto.customerLastName = customerLastName;
      v1WebCustomerDto.customerAddress = customerAddress;
      v1WebCustomerDto.customerZip = customerZip;
      v1WebCustomerDto.customerCity = customerCity;
      v1WebCustomerDto.customerEmail = customerEmail;
      v1WebCustomerDto.customerPhoneNumber = customerPhoneNumber;
      v1WebCustomerDto.customerVatNumber = customerVatNumber;
      v1WebCustomerDto.customerBankName = customerBankName;
      v1WebCustomerDto.customerBankAccount = customerBankAccount;
      v1WebCustomerDto.customerNotes = customerNotes;

      v1WebCustomerService.putCustomer(v1WebCustomerDto).then((response: AxiosResponse) => {
        let v1WebCustomerDto = response.data as V1WebCustomerDTO;

        dispatch({
          type: CUSTOMER_PUT,
          status: SUCCESS_STATUS,
          customerUuid: v1WebCustomerDto.customerUuid,
          customerCompanyName: v1WebCustomerDto.customerCompanyName,
          customerFirstName: v1WebCustomerDto.customerFirstName,
          customerLastName: v1WebCustomerDto.customerLastName,
          customerAddress: v1WebCustomerDto.customerAddress,
          customerZip: v1WebCustomerDto.customerZip,
          customerCity: v1WebCustomerDto.customerCity,
          customerEmail: v1WebCustomerDto.customerEmail,
          customerPhoneNumber: v1WebCustomerDto.customerPhoneNumber,
          customerVatNumber: v1WebCustomerDto.customerVatNumber,
          customerBankName: v1WebCustomerDto.customerBankName,
          customerBankAccount: v1WebCustomerDto.customerBankAccount,
          customerNotes: v1WebCustomerDto.customerNotes
        });
      }).catch((error) => {
        dispatch({
          type: CUSTOMER_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}