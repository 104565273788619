import UUIDHelper from "helper/uuidhelper";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store"
import ErrorContainer from "component/errorcontainer";
import { Typography, Grid, Card, CardHeader, CardContent, TextField, Box, Button, Divider } from "@mui/material";
import CustomContainer from "component/customcontainer";
import SpinnerContainer from "component/spinnercontainer";
import HeadCell from "component/headcell";
import V1WebParkPermitDTO from "dto/v1/web/v1webparkpermit.dto";
import v1WebParkPermitService from "service/v1/web/v1webparkpermit.service";
import { AxiosResponse } from "axios";
import { getParkPermitAsUserClient, putParkPermitAsUserClient } from "redux/parkpermit/parkpermit.action";

/**
 * @author Von Frank - https://vonfrank.dk
 */

interface OwnProps {
}

interface DispatchProps {
  getParkPermitAsUserClient: (parkPermitUuid: string) => void;
  putParkPermitAsUserClient: (parkPermitUuid: string, parkPermitNotes: string, parkPermitIsEnabled: boolean) => void;
}

interface StateProps {
  parkPermitStatus: StateStatus;
  parkPermitUuid: string;
  parkPermitValidFrom: Date | string;
  parkPermitValidTo: Date | string;
  parkPermitVehicleUuid: string;
  parkPermitVehicleVehicleId: string;
  parkPermitParkingAreaUuid: string;
  parkPermitParkingAreaParkingAreaCode: string;
  parkPermitParkingAreaAddress: string;
  parkPermitParkingAreaZip: string;
  parkPermitParkingAreaCity: string;
  parkPermitNotes: string;
  parkPermitErrorCode: number
  parkPermitErrorMessage: string
  parkPermitErrorUuid: string
}

type Props = StateProps & OwnProps & DispatchProps

const KioskParkPermitView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [showViewAlert, setShowViewAlert] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    getValues: getUpdateFormValues,
    setValue: setUpdateFormValue,
    watch: watchUpdateForm,
    clearErrors: clearUpdateFormError
  } = useForm<StateProps>({
    mode: 'all'
  });

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.putParkPermitAsUserClient(formProps.parkPermitUuid, formProps.parkPermitNotes, true);
    }
  }

  const handleUnsubscribePermit = () => {
    if (!isViewLoading && props.parkPermitUuid !== null) {
      if (window.confirm('Er du sikker på du vil afmelde tilladelse for køretøj: ' + props.parkPermitVehicleVehicleId)) {
        let v1WebParkPermitDto = new V1WebParkPermitDTO();

        v1WebParkPermitDto.parkPermitUuid = props.parkPermitUuid;
        v1WebParkPermitDto.parkPermitNotes = props.parkPermitNotes;
        v1WebParkPermitDto.parkPermitIsEnabled = false;

        v1WebParkPermitService.putParkPermitAsUserClient(v1WebParkPermitDto).then((response: AxiosResponse) => {
          navigate("/kiosk/parkpermit", { replace: true });
        }).catch((error) => {
          alert("Der skete en fejl: " + error.errorMessage);
        });
      } else {

      }
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.parkPermitUuid !== undefined && UUIDHelper.validateUUID(params.parkPermitUuid)) {
        props.getParkPermitAsUserClient(params.parkPermitUuid);
      } else {
        navigate('404', { replace: true });
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkPermitStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("parkPermitUuid", props.parkPermitUuid);
        setUpdateFormValue("parkPermitValidFrom", props.parkPermitValidFrom !== null ? formatter.format(Date.parse(props.parkPermitValidFrom.toString())) : "Ubegrænset");
        setUpdateFormValue("parkPermitValidTo", props.parkPermitValidTo !== null ? formatter.format(Date.parse(props.parkPermitValidTo.toString())) : "Ubegrænset");
        setUpdateFormValue("parkPermitVehicleUuid", props.parkPermitVehicleUuid);
        setUpdateFormValue("parkPermitVehicleVehicleId", props.parkPermitVehicleVehicleId);
        setUpdateFormValue("parkPermitParkingAreaUuid", props.parkPermitParkingAreaUuid);
        setUpdateFormValue("parkPermitParkingAreaParkingAreaCode", props.parkPermitParkingAreaParkingAreaCode);
        setUpdateFormValue("parkPermitParkingAreaAddress", props.parkPermitParkingAreaAddress);
        setUpdateFormValue("parkPermitParkingAreaZip", props.parkPermitParkingAreaZip);
        setUpdateFormValue("parkPermitParkingAreaCity", props.parkPermitParkingAreaCity);
        setUpdateFormValue("parkPermitNotes", props.parkPermitNotes);

        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkPermitStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric"
  });

  const parkPermitNotificationHeadCells: readonly HeadCell[] = [
    {
      id: "notificationType",
      numeric: false,
      disablePadding: false,
      label: "Notifikation type"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.parkPermitErrorCode} errorMessage={props.parkPermitErrorMessage} errorUuid={props.parkPermitErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Tilladelse
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    <CardHeader
                      subheader={"Generelle oplysninger for tilladelsen"}
                      title="Tilladelse oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaAddress"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaZip"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaCity"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitValidFrom"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Gyldig fra"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitValidTo"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Gyldig til"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="error"
                        disabled={isViewLoading}
                        type="button"
                        onClick={handleUnsubscribePermit}
                        variant="outlined"
                      >
                        Afmeld tilladelse
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: -2,
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem tilladelse oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Card>
                    <CardHeader
                      subheader={"Alle relationer tilknyttet denne tilladelse"}
                      title="Relationer" />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitVehicleVehicleId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Køretøj"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaParkingAreaCode"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parking Area Code"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkPermitStatus: redux.parkPermit.status,
    parkPermitUuid: redux.parkPermit.parkPermitUuid,
    parkPermitValidFrom: redux.parkPermit.parkPermitValidFrom,
    parkPermitValidTo: redux.parkPermit.parkPermitValidTo,
    parkPermitVehicleUuid: redux.parkPermit.vehicleUuid,
    parkPermitVehicleVehicleId: redux.parkPermit.vehicleVehicleId,
    parkPermitParkingAreaUuid: redux.parkPermit.abstractParkingAreaUuid,
    parkPermitParkingAreaParkingAreaCode: redux.parkPermit.abstractParkingAreaParkingAreaCode,
    parkPermitParkingAreaAddress: redux.parkPermit.abstractParkingAreaAddress,
    parkPermitParkingAreaZip: redux.parkPermit.abstractParkingAreaZip,
    parkPermitParkingAreaCity: redux.parkPermit.abstractParkingAreaCity,
    parkPermitNotes: redux.parkPermit.parkPermitNotes,
    parkPermitErrorCode: redux.parkPermit.errorCode,
    parkPermitErrorMessage: redux.parkPermit.errorMessage,
    parkPermitErrorUuid: redux.parkPermit.errorUuid,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getParkPermitAsUserClient: async (parkPermitUuid) => {
      await dispatch(getParkPermitAsUserClient(parkPermitUuid));
    },
    putParkPermitAsUserClient: async (parkPermitUuid, parkPermitNotes, parkPermitIsEnabled) => {
      await dispatch(putParkPermitAsUserClient(parkPermitUuid, parkPermitNotes, parkPermitIsEnabled))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(KioskParkPermitView);