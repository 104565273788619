import { Avatar, Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { accountGetUserDetails, accountPutUserDetails } from "redux/account/account.action";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import { UserType } from "type/user.type";
import v1WebAccountService from "service/v1/web/v1webaccount.service";
import ErrorContainer from "component/errorcontainer";
import SpinnerContainer from "component/spinnercontainer";
import { useForm, SubmitHandler, Controller } from "react-hook-form";

interface OwnProps {
}

interface DispatchProps {
  accountGetUserDetails: () => void;
  accountPutUserDetails: (username: string, firstName: string, lastName: string, address: string, zip: string, city: string, email: string, phone: string) => void;
}

interface StateProps {
  accountStateStatus: StateStatus;
  accountUsername: string;
  accountFirstName: string;
  accountLastName: string;
  accountAddress: string;
  accountZip: string;
  accountCity: string;
  accountEmail: string;
  accountPhone: string;
  accountUserType: UserType | null;
  accountErrorCode: number;
  accountErrorMessage: string;
  accountErrorUuid: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const AccountView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [showViewAlert, setShowViewAlert] = useState(false);

  const {
    handleSubmit: handleUserDetailsFormSubmit,
    control: userDetailsFormControl,
    setValue: setUserDetailsFormValue
  } = useForm<StateProps>({
    mode: 'all'
  });

  const onUserDetailsFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.accountPutUserDetails(formProps.accountUsername, formProps.accountFirstName, formProps.accountLastName, formProps.accountAddress, formProps.accountZip, formProps.accountCity, formProps.accountEmail, formProps.accountPhone);
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      props.accountGetUserDetails();
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.accountStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUserDetailsFormValue("accountUsername", props.accountUsername);
        setUserDetailsFormValue("accountFirstName", props.accountFirstName);
        setUserDetailsFormValue("accountLastName", props.accountLastName);
        setUserDetailsFormValue("accountAddress", props.accountAddress);
        setUserDetailsFormValue("accountZip", props.accountZip);
        setUserDetailsFormValue("accountCity", props.accountCity);
        setUserDetailsFormValue("accountEmail", props.accountEmail);
        setUserDetailsFormValue("accountPhone", props.accountPhone);
        setUserDetailsFormValue("accountUserType", props.accountUserType);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.accountStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.accountErrorCode} errorMessage={props.accountErrorMessage} errorUuid={props.accountErrorUuid}></ErrorContainer>
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Konto
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={6}
                xs={12}
              >
                <Card>
                  <CardContent>
                    <Box
                      alignItems="center"
                      display="flex"
                      flexDirection="column"
                      p={3}
                    >
                      <Avatar
                        sx={{
                          width: 64,
                          height: 64,
                          marginBottom: 2
                        }}
                      >
                        {props.accountFirstName !== undefined && props.accountFirstName !== null && props.accountFirstName.charAt(0) !== "" && props.accountLastName !== undefined && props.accountLastName !== null && props.accountLastName.charAt(0) !== "" ? props.accountFirstName.charAt(0) + props.accountLastName.charAt(0) : "NA"}
                      </Avatar>
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                        {props.accountFirstName !== "" ? props.accountFirstName : "N/A"} {props.accountLastName !== "" ? props.accountLastName : "N/A"}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {props.accountUsername}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {props.accountEmail}
                      </Typography>
                      <Typography
                        color="textSecondary"
                        variant="body2"
                      >
                        {props.accountUserType}
                      </Typography>
                    </Box>
                  </CardContent>
                  <Divider />
                  <Box
                    alignItems="center"
                    display="flex"
                    flexDirection="column"
                    sx={{
                      p: 2
                    }}
                  >
                    <Button
                      color="primary"
                      variant="text"
                      disabled
                    >
                      Upload billede
                    </Button>
                  </Box>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={6}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUserDetailsFormSubmit(onUserDetailsFormSubmit)}>
                    <CardHeader
                      subheader="Opdater dine brugeroplysninger her"
                      title="Brugeroplysninger" />
                    <Divider />
                    <CardContent>
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={6}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="accountUsername"
                            control={userDetailsFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Brugernavn er påkrævet"
                              },
                              minLength: {
                                value: 4,
                                message: "Brugernavn skal være på minimum 4 tegn"
                              },
                              maxLength: {
                                value: 256,
                                message: "Brugernavn må ikke overstige 256 tegn"
                              },
                              pattern: {
                                value: /^[a-zA-Z0-9]+$/,
                                message: "Ugyldigt brugernavn"
                              },
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value.toLocaleLowerCase() === props.accountUsername.toLocaleLowerCase()) {
                                      resolve(true)
                                    }
                                    if (value !== undefined && value.length >= 1) {
                                      v1WebAccountService.existsUsernameOrEmail(value).then((res) => {
                                        if (res.data) {
                                          resolve("Brugernavn er allerede i brug")
                                        } else {
                                          resolve(true)
                                        }
                                      }).catch((error) => {
                                        resolve(error);
                                      })
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Brugernavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="accountEmail"
                            control={userDetailsFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "E-mail er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "E-mail må ikke overstige 256 tegn"
                              },
                              pattern: {
                                value: /^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
                                message: "Ugyldig e-mail"
                              },
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value.toLocaleLowerCase() === props.accountEmail.toLocaleLowerCase()) {
                                      resolve(true)
                                    }
                                    if (value !== undefined && value.length <= 256 && value.match(/^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/)) {
                                      v1WebAccountService.existsUsernameOrEmail(value).then((res) => {
                                        if (res.data) {
                                          resolve("E-mail er allerede i brug")
                                        } else {
                                          resolve(true)
                                        }
                                      }).catch((error) => {
                                        resolve(error);
                                      })
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="E-mail"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="accountFirstName"
                            control={userDetailsFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Fornavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Fornavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="accountLastName"
                            control={userDetailsFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Efternavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Efternavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={7}
                          xs={7}
                        >
                          <Controller
                            name="accountAddress"
                            control={userDetailsFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Fornavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={5}
                          xs={5}
                        >
                          <Controller
                            name="accountPhone"
                            control={userDetailsFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Telefon må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Telefon"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={4}
                        >
                          <Controller
                            name="accountZip"
                            control={userDetailsFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Postnummer må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          xs={8}
                        >
                          <Controller
                            name="accountCity"
                            control={userDetailsFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "By må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem brugeroplysninger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    accountStateStatus: redux.account.status,
    accountUsername: redux.account.username,
    accountFirstName: redux.account.firstName,
    accountLastName: redux.account.lastName,
    accountAddress: redux.account.address,
    accountZip: redux.account.zip,
    accountCity: redux.account.city,
    accountEmail: redux.account.email,
    accountPhone: redux.account.phone,
    accountUserType: redux.account.userType,
    accountErrorCode: redux.account.errorCode,
    accountErrorMessage: redux.account.errorMessage,
    accountErrorUuid: redux.account.errorUuid,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    accountGetUserDetails: async () => {
      await dispatch(accountGetUserDetails());
    },
    accountPutUserDetails: async (username, firstName, lastName, address, zip, city, email, phone) => {
      await dispatch(accountPutUserDetails(username, firstName, lastName, address, zip, city, email, phone));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountView);