import { NavigateNext } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import { userGetPageableList, userSearchPageableList } from "redux/user/user.action";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { NavLink } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { UserType } from "type/user.type";
import V1WebAbstractUserDTO from "dto/v1/web/v1webabstractuser.dto";
import V1WebUserAdministratorDTO from "dto/v1/web/v1webuseradministrator.dto";
import V1WebUserGuardDTO from "dto/v1/web/v1webuserguard.dto";
import V1WebUserClientKioskDTO from "dto/v1/web/v1webuserclientkiosk.dto";
import V1WebUserClientAreaOperatorDTO from "dto/v1/web/v1webuserclientoperator.dto";
import V1WebAbstractUserClientDTO from "dto/v1/web/v1webabstractuserclient.dto";
import v1WebUserService from "service/v1/web/v1webuser.service";
import v1WebCustomerService from "service/v1/web/v1webcustomer.service";
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service";
import { AxiosResponse } from "axios";
import AddIcon from '@mui/icons-material/Add';
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebCustomerListElementDTO from "dto/v1/web/v1webcustomerlistelement.dto";
import Response from "dto/response";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import V1WebUserClientPublicDTO from "dto/v1/web/v1webuserclientpublic.dto";

interface OwnProps {
}

interface DispatchProps {
  userGetPageableList: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  userSearchPageableList: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  userStateStatus: StateStatus;
  userPageableListCount: number;
  userPageableListPageNumber: number;
  userPageableListPageSize: number;
  userPageableListContent: Array<any[]>;
  userPageableListSort: string;
  userPageableListDirection: Direction;
  userPageableListKeyword: string;
  userErrorCode: number;
  userErrorMessage: string;
  userErrorUuid: string;
}

interface FormProps {
  userUsername: string;
  userFirstName: string;
  userLastName: string;
  userAddress: string;
  userZip: string;
  userCity: string;
  userEmail: string;
  userPhone: string;
  userUserType: UserType;
  userNotes: string;
  userIsEnabled: boolean;
  userClientHasTextMessageNotificationService: boolean;
  userClientHasEmailNotificationService: boolean;
  userClientCustomerUuid: string;
  userClientCustomerCompanyName: string;
  userClientKioskParkingAreaUuid: string;
  userClientKioskParkingAreaParkingAreaCode: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const AdministratorUserListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkingAreaLoading, setParkingAreaLoading] = useState(false);
  const [isCustomerLoading, setCustomerLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [parkingAreaOptions, setParkingAreaOptions] = useState(Array<any[]>());
  const [customerOptions, setCustomerOptions] = useState(Array<any[]>());

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({ mode: 'all' });

  const onSearchFormSubmit: SubmitHandler<StateProps> = (stateProps: StateProps) => {
    if (!isViewLoading && stateProps.userPageableListKeyword.length > 0) {
      props.userSearchPageableList(stateProps.userPageableListKeyword, 0, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection);
    }
  }

  const {
    handleSubmit: handleCreateFormSubmit,
    control: createFormControl,
    getValues: getCreateFormValues,
    setValue: setCreateFormValue,
    reset: resetCreateForm,
    watch: watchCreateForm
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      userClientHasTextMessageNotificationService: false,
      userClientHasEmailNotificationService: false,
      userIsEnabled: true
    }
  });

  const onCreateFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading && formProps.userUserType !== null) {
      let v1WebAbstractUserDto: V1WebAbstractUserDTO;

      switch (formProps.userUserType) {
        case UserType.ADMINISTRATOR: {
          v1WebAbstractUserDto = new V1WebUserAdministratorDTO();
          break;
        }
        case UserType.GUARD: {
          v1WebAbstractUserDto = new V1WebUserGuardDTO();
          break;
        }
        case UserType.KIOSK: {
          v1WebAbstractUserDto = new V1WebUserClientKioskDTO();
          (v1WebAbstractUserDto as V1WebUserClientKioskDTO).abstractParkingAreaUuid = formProps.userClientKioskParkingAreaUuid;
          break;
        }
        case UserType.OPERATOR: {
          v1WebAbstractUserDto = new V1WebUserClientAreaOperatorDTO();
          break;
        }
        case UserType.PUBLIC: {
          v1WebAbstractUserDto = new V1WebUserClientPublicDTO();
          break;
        }
        default: {
          throw new Error("Unable to determine parking area type herp derp");
        }
      }

      if (v1WebAbstractUserDto instanceof V1WebAbstractUserClientDTO) {
        (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasTextMessageNotificationService = formProps.userClientHasTextMessageNotificationService;
        (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasEmailNotificationService = formProps.userClientHasEmailNotificationService;
        (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).customerUuid = formProps.userClientCustomerUuid;
      }

      v1WebAbstractUserDto.abstractUserUsername = formProps.userUsername;
      v1WebAbstractUserDto.abstractUserFirstName = formProps.userFirstName;
      v1WebAbstractUserDto.abstractUserLastName = formProps.userLastName;
      v1WebAbstractUserDto.abstractUserAddress = formProps.userAddress;
      v1WebAbstractUserDto.abstractUserZip = formProps.userZip;
      v1WebAbstractUserDto.abstractUserCity = formProps.userCity;
      v1WebAbstractUserDto.abstractUserEmail = formProps.userEmail;
      v1WebAbstractUserDto.abstractUserPhone = formProps.userPhone;
      v1WebAbstractUserDto.abstractUserNotes = formProps.userNotes;
      v1WebAbstractUserDto.abstractUserIsEnabled = formProps.userIsEnabled;

      setViewLoading(true);

      v1WebUserService.createUser(v1WebAbstractUserDto).then((response: AxiosResponse) => {
        setViewLoading(false);
        alert("Bruger oprettet");

        if (props.userPageableListKeyword.length > 0) {
          props.userSearchPageableList(props.userPageableListKeyword, props.userPageableListPageNumber, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection);
        } else {
          props.userGetPageableList(props.userPageableListPageNumber, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection);
        }

        setCreateDialogOpen(false);
      }).catch((error) => {
        setViewLoading(false);
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }

  const watchUserClientKioskParkingAreaParkingAreaCode = watchCreateForm("userClientKioskParkingAreaParkingAreaCode");
  const watchUserClientCustomerCompanyName = watchCreateForm("userClientCustomerCompanyName");
  const watchUserUserType = watchCreateForm("userUserType");

  const handleUserPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.userPageableListKeyword.length > 0) {
      props.userSearchPageableList(props.userPageableListKeyword, 0, parseInt(event.target.value), props.userPageableListSort, props.userPageableListDirection);
    } else {
      props.userGetPageableList(0, parseInt(event.target.value), props.userPageableListSort, props.userPageableListDirection);
    }
  };

  const handleUserPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.userPageableListKeyword.length > 0) {
      props.userSearchPageableList(props.userPageableListKeyword, newPage, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection)
    } else {
      props.userGetPageableList(newPage, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection)
    }
  }

  const handleUserDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.userPageableListDirection === Direction.ASC) {
      if (props.userPageableListKeyword.length > 0) {
        props.userSearchPageableList(props.userPageableListKeyword, props.userPageableListPageNumber, props.userPageableListPageSize, property, Direction.DESC);
      } else {
        props.userGetPageableList(props.userPageableListPageNumber, props.userPageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.userPageableListKeyword.length > 0) {
        props.userSearchPageableList(props.userPageableListKeyword, props.userPageableListPageNumber, props.userPageableListPageSize, property, Direction.ASC);
      } else {
        props.userGetPageableList(props.userPageableListPageNumber, props.userPageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleUserKeywordClear = () => {
    props.userGetPageableList(props.userPageableListPageNumber, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection);
  }

  const toogleCreateDialogOpen = () => {
    resetCreateForm();
    setCreateDialogOpen(!isCreateDialogOpen);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.userPageableListKeyword.length > 0) {
        props.userSearchPageableList(props.userPageableListKeyword, props.userPageableListPageNumber, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection);
      } else {
        props.userGetPageableList(props.userPageableListPageNumber, props.userPageableListPageSize, props.userPageableListSort, props.userPageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.userStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("userPageableListKeyword", props.userPageableListKeyword);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.userStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchUserClientKioskParkingAreaParkingAreaCode !== undefined && watchUserClientKioskParkingAreaParkingAreaCode !== null && watchUserClientKioskParkingAreaParkingAreaCode.trim().length > 0) {
      setParkingAreaLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "areaCode",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getCreateFormValues("userClientKioskParkingAreaParkingAreaCode")
      }

      v1WebParkingAreaService.searchParkingArea(v1KeywordDto, page).then((response: AxiosResponse) => {
        let parkingAreaPageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: Array<any[]> = [];
        parkingAreaPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress])
        });
        setParkingAreaOptions(pageableListContent);
        setParkingAreaLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchUserClientKioskParkingAreaParkingAreaCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchUserClientCustomerCompanyName !== undefined && watchUserClientCustomerCompanyName !== null && watchUserClientCustomerCompanyName.trim().length > 0) {
      setCustomerLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "companyName",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getCreateFormValues("userClientCustomerCompanyName")
      }

      v1WebCustomerService.searchCustomer(v1KeywordDto, page).then((response: AxiosResponse) => {
        let customerPageableList = response.data as Response<V1WebCustomerListElementDTO>

        let pageableListContent: Array<any[]> = [];
        customerPageableList.content.forEach((value) => {
          pageableListContent.push([value.customerUuid, value.customerCompanyName])
        });
        setCustomerOptions(pageableListContent);
        setCustomerLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchUserClientCustomerCompanyName]); // eslint-disable-line react-hooks/exhaustive-deps

  const headCells: readonly HeadCell[] = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Brugernavn"
    },
    {
      id: "userType",
      numeric: false,
      disablePadding: false,
      label: "Brugertype"
    },
    {
      id: "isEnabled",
      numeric: false,
      disablePadding: false,
      label: "Status"
    },
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.userErrorCode} errorMessage={props.userErrorMessage} errorUuid={props.userErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Brugere
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="userPageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => { handleUserKeywordClear() }}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.userPageableListSort === headCell.id}
                          direction={props.userPageableListDirection}
                          onClick={handleUserDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.userPageableListContent === undefined || props.userPageableListContent.length === 0 ? props.userPageableListPageSize : props.userPageableListContent.length} tableColSpan={headCells.length} />
                    </>
                  ) : (
                    <>
                      {props.userPageableListCount === undefined || props.userPageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={headCells.length} />
                        </>
                      ) : (
                        <>
                          {props.userPageableListContent.map(user => (
                            <TableRow
                              hover
                              key={user[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {user[1]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {user[2] === UserType.ADMINISTRATOR &&
                                    <>
                                      Administrator
                                    </>
                                  }
                                  {user[2] === UserType.GUARD &&
                                    <>
                                      Vagt
                                    </>
                                  }
                                  {user[2] === UserType.KIOSK &&
                                    <>
                                      Kiosk
                                    </>
                                  }
                                  {user[2] === UserType.OPERATOR &&
                                    <>
                                      Operatør
                                    </>
                                  }
                                  {user[2] === UserType.PUBLIC &&
                                    <>
                                      Offentlig
                                    </>
                                  }
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {user[3] as boolean === true ? (
                                    <>
                                      Aktiv
                                    </>
                                  ) : (
                                    <>
                                      Inaktiv
                                    </>
                                  )}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={user[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={headCells.length + 1}
                      count={props.userPageableListCount || 0}
                      rowsPerPage={props.userPageableListPageSize || 5}
                      page={props.userPageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                      onPageChange={handleUserPageNumberChange}
                      onRowsPerPageChange={handleUserPageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
      <Dialog
        open={isCreateDialogOpen}
        onClose={toogleCreateDialogOpen}
        fullScreen
      >
        <form onSubmit={handleCreateFormSubmit(onCreateFormSubmit)}>
          <DialogTitle>Opret bruger</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld felterne nedenfor for at oprette en bruger
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Brugertype er påkrævet"
                    }
                  }}
                  control={createFormControl}
                  name="userUserType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      component="fieldset"
                      error={!!error}
                    >
                      <FormLabel component="legend">Brugertype</FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        value={field.value || ""}
                      >
                        <FormControlLabel
                          value={UserType.ADMINISTRATOR}
                          control={<Radio />}
                          label="Administrator"
                        />
                        <FormControlLabel
                          value={UserType.GUARD}
                          control={<Radio />}
                          label="Vagt"
                        />
                        <FormControlLabel
                          value={UserType.KIOSK}
                          control={<Radio />}
                          label="Kiosk"
                        />
                        <FormControlLabel
                          value={UserType.OPERATOR}
                          control={<Radio />}
                          label="Operator"
                        />
                        <FormControlLabel
                          value={UserType.PUBLIC}
                          control={<Radio />}
                          label="Offentlig"
                        />
                      </RadioGroup>
                      <FormHelperText sx={{
                        marginLeft: 0
                      }}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {watchUserUserType === UserType.KIOSK &&
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientKioskParkingAreaParkingAreaCode"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Parkeringsområde er påkrævet"
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          inputValue={value || ""}
                          onChange={(event: any, newValue: any[] | null) => {
                            if (newValue !== null) {
                              setCreateFormValue("userClientKioskParkingAreaParkingAreaCode", newValue[1]);
                              setCreateFormValue("userClientKioskParkingAreaUuid", newValue[0]);
                            } else {
                              setCreateFormValue("userClientKioskParkingAreaParkingAreaCode", "");
                              setCreateFormValue("userClientKioskParkingAreaUuid", "");
                            }
                          }}
                          options={parkingAreaOptions}
                          getOptionLabel={(option) => option[1] + " - " + option[2]}
                          isOptionEqualToValue={(option, value) => option[0] === value[0]}
                          noOptionsText={"Ingen resultater"}
                          filterOptions={(x) => x}
                          loading={isParkingAreaLoading}
                          loadingText={"Vent venligst.."}
                          openText={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Parkeringsområde"
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : "Parkeringsområde tilknyttet kioskbrugeren"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isParkingAreaLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientHasTextMessageNotificationService"
                      control={createFormControl}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={
                          <Switch
                            checked={value}
                            onChange={onChange}
                          />
                        } label="SMS notifikationer" />
                      )}
                    />
                    <Controller
                      name="userClientHasEmailNotificationService"
                      control={createFormControl}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={
                          <Switch
                            checked={value}
                            onChange={onChange}
                          />
                        } label="E-mail notifikationer" />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientCustomerCompanyName"
                      control={createFormControl}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          inputValue={value || ""}
                          onChange={(event: any, newValue: any[] | null) => {
                            if (newValue !== null) {
                              setCreateFormValue("userClientCustomerCompanyName", newValue[1]);
                              setCreateFormValue("userClientCustomerUuid", newValue[0]);
                            } else {
                              setCreateFormValue("userClientCustomerCompanyName", "");
                              setCreateFormValue("userClientCustomerUuid", "");
                            }
                          }}
                          options={customerOptions}
                          getOptionLabel={(option) => option[1]}
                          isOptionEqualToValue={(option, value) => option[0] === value[0]}
                          noOptionsText={"Ingen resultater"}
                          filterOptions={(x) => x}
                          loading={isCustomerLoading}
                          loadingText={"Vent venligst.."}
                          openText={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Kunde"
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : "Kunde tilknyttet betaling af notifikationer"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isCustomerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </>
              }
              {watchUserUserType === UserType.OPERATOR &&
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientHasTextMessageNotificationService"
                      control={createFormControl}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={
                          <Switch
                            checked={value}
                            onChange={onChange}
                          />
                        } label="SMS notifikationer" />
                      )}
                    />
                    <Controller
                      name="userClientHasEmailNotificationService"
                      control={createFormControl}
                      render={({ field: { onChange, value } }) => (
                        <FormControlLabel control={
                          <Switch
                            checked={value}
                            onChange={onChange}
                          />
                        } label="E-mail notifikationer" />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="userClientCustomerCompanyName"
                      control={createFormControl}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <Autocomplete
                          inputValue={value || ""}
                          onChange={(event: any, newValue: any[] | null) => {
                            if (newValue !== null) {
                              setCreateFormValue("userClientCustomerCompanyName", newValue[1]);
                              setCreateFormValue("userClientCustomerUuid", newValue[0]);
                            } else {
                              setCreateFormValue("userClientCustomerCompanyName", "");
                              setCreateFormValue("userClientCustomerUuid", "");
                            }
                          }}
                          options={customerOptions}
                          getOptionLabel={(option) => option[1]}
                          isOptionEqualToValue={(option, value) => option[0] === value[0]}
                          noOptionsText={"Ingen resultater"}
                          filterOptions={(x) => x}
                          loading={isCustomerLoading}
                          loadingText={"Vent venligst.."}
                          openText={""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Kunde"
                              fullWidth
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : "Kunde tilknyttet betaling af notifikationer"}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>
                                    {isCustomerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                  </>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </>
              }
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="userUsername"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Brugernavn er påkrævet"
                    },
                    minLength: {
                      value: 4,
                      message: "Brugernavn skal være på minimum 4 tegn"
                    },
                    maxLength: {
                      value: 256,
                      message: "Brugernavn må ikke overstige 256 tegn"
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9]+$/,
                      message: "Ugyldigt brugernavn"
                    },
                    validate: {
                      value: ((value) => {
                        return new Promise((resolve) => {
                          if (value !== undefined && value.length >= 1) {
                            v1WebUserService.existsUsernameOrEmail(value).then((res) => {
                              if (res.data) {
                                resolve("Brugernavn er allerede i brug")
                              } else {
                                resolve(true)
                              }
                            }).catch((error) => {
                              resolve(error);
                            })
                          }
                        });
                      })
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Brugernavn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="userEmail"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "E-mail er påkrævet"
                    },
                    maxLength: {
                      value: 256,
                      message: "E-mail må ikke overstige 256 tegn"
                    },
                    pattern: {
                      value: /^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
                      message: "Ugyldig e-mail"
                    },
                    validate: {
                      value: ((value) => {
                        return new Promise((resolve) => {
                          if (value !== undefined && value.length <= 256 && value.match(/^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/)) {
                            v1WebUserService.existsUsernameOrEmail(value).then((res) => {
                              if (res.data) {
                                resolve("E-mail er allerede i brug")
                              } else {
                                resolve(true)
                              }
                            }).catch((error) => {
                              resolve(error);
                            })
                          }
                        });
                      })
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="E-mail"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="userFirstName"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Fornavn må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Fornavn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="userLastName"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Efternavn må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Efternavn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={7}
                xs={7}
              >
                <Controller
                  name="userAddress"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Adresse må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Adresse"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={5}
                xs={5}
              >
                <Controller
                  name="userPhone"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Telefon må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Telefon"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xs={4}
              >
                <Controller
                  name="userZip"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Postnummer må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Postnummer"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={8}
              >
                <Controller
                  name="userCity"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "By må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="By"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="userNotes"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Noter må ikke overstige 256 tegn"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Noter"
                      fullWidth
                      multiline
                      rows={4}
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="userIsEnabled"
                  control={createFormControl}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel control={
                      <Switch
                        checked={value}
                        onChange={onChange}
                      />
                    } label="Aktiveret" />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleCreateDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Opret
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Fab
        onClick={toogleCreateDialogOpen}
        color="primary"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed"
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    userStateStatus: redux.user.status,
    userPageableListCount: redux.user.pageableListCount,
    userPageableListPageNumber: redux.user.pageableListPageNumber,
    userPageableListPageSize: redux.user.pageableListPageSize,
    userPageableListContent: redux.user.pageableListContent,
    userPageableListSort: redux.user.pageableListSort,
    userPageableListDirection: redux.user.pageableListDirection,
    userPageableListKeyword: redux.user.pageableListKeyword,
    userErrorCode: redux.user.errorCode,
    userErrorMessage: redux.user.errorMessage,
    userErrorUuid: redux.user.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    userGetPageableList: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(userGetPageableList(pageNumber, rowsPerPage, sort, direction))
    },
    userSearchPageableList: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(userSearchPageableList(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorUserListView);