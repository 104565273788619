import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebParkPermitDTO from "dto/v1/web/v1webparkpermit.dto";

const baseUrl = "v1/web/parkpermit";

class V1WebParkPermitService {
  createParkPermit(v1WebParkPermitDto: V1WebParkPermitDTO) {
    return axiosConfig.post(baseUrl, v1WebParkPermitDto);
  }
  createParkPermitAsUserClient(v1WebParkPermitDto: V1WebParkPermitDTO) {
    return axiosConfig.post(baseUrl + "/userclient", v1WebParkPermitDto);
  }
  getParkPermit(parkPermitUuid: string) {
    return axiosConfig.get(baseUrl + "/" + parkPermitUuid);
  }
  getParkPermitAsUserClient(parkPermitUuid: string) {
    return axiosConfig.get(baseUrl + "/userclient/" + parkPermitUuid);
  }
  getParkPermitPageableList(page: Page) {
    return axiosConfig.get(baseUrl + "?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getParkPermitPageableListForUserClientAsUserClient(page: Page) {
    return axiosConfig.get(baseUrl + "/userclient?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getParkPermitNotificationPageableList(parkPermitUuid: string, page: Page) {
    return axiosConfig.get(baseUrl + "/" + parkPermitUuid + "/notification?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchParkPermitPageableList(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post(baseUrl + "/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  searchParkPermitPageableListForUserClientAsUserClient(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post(baseUrl + "/userclient/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  putParkPermit(v1WebParkPermitDto: V1WebParkPermitDTO) {
    return axiosConfig.put(baseUrl, v1WebParkPermitDto);
  }
  putParkPermitAsUserClient(v1WebParkPermitDto: V1WebParkPermitDTO) {
    return axiosConfig.put(baseUrl + "/userclient", v1WebParkPermitDto);
  }
}

export default new V1WebParkPermitService();