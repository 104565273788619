import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { UserType } from "type/user.type";
import { RootAction, ROOT_INIT_USER } from "./root.action";

export type RootState = {
  status: StateStatus
  username: string
  emailAddress: string
  firstName: string
  lastName: string
  userType: UserType | null
  addons: string[]
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: RootState = {
  status: IDLE_STATUS,
  username: "",
  emailAddress: "",
  firstName: "",
  lastName: "",
  userType: null,
  addons: [],
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function rootReducer(state: RootState = initialState, action: RootAction): RootState {
  switch(state.status) {
    case IDLE_STATUS: {
      return rootIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return rootLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return rootSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return rootFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default rootReducer;

function rootIdleReducer(state: RootState, action: RootAction): RootState {
  switch(action.type) {
    case ROOT_INIT_USER: {
      return {
        ...state,
        status: action.status,
      }
    }
    default: {
      return state;
    }
  }
}

function rootLoadingReducer(state: RootState, action: RootAction): RootState {
  switch(action.type) {
    case ROOT_INIT_USER: {
      return {
        status: action.status,
        username: action.username,
        emailAddress: action.emailAddress,
        firstName: action.firstName,
        lastName: action.lastName,
        userType: action.userType,
        addons: action.addons,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function rootSuccessReducer(state: RootState, action: RootAction): RootState {
  switch(action.type) {
    case ROOT_INIT_USER: {
      return initialState;
    }
    default:
      return state;
  }
}

function rootFailureReducer(state: RootState, action: RootAction): RootState {
  switch(action.type) {
    case ROOT_INIT_USER: {
      return {
        ...state,
        status: action.status,
      }
    }
    default:
      return state;
  }
}