import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebVehicleDTO from "dto/v1/web/v1webvehicle.dto";

class V1WebVehicleService {
  getVehicle(vehicleUuid: string) {
    return axiosConfig.get("v1/web/vehicle/" + vehicleUuid);
  }
  getVehicles(page: Page) {
    return axiosConfig.get("v1/web/vehicle?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchVehicle(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/vehicle/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  getVehicleParkPermit(vehicleUuid: string, page: Page) {
    return axiosConfig.get("v1/web/vehicle/" + vehicleUuid + "/parkpermit?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getVehicleTicket(vehicleUuid: string, page: Page) {
    return axiosConfig.get("v1/web/vehicle/" + vehicleUuid + "/ticket?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  putVehicle(v1WebVehicleDto: V1WebVehicleDTO) {
    return axiosConfig.put("v1/web/vehicle/", v1WebVehicleDto);
  }
}

export default new V1WebVehicleService();