import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1DateTimeDTO from "dto/v1/v1datetime.dto";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebNotificationListElementDTO from "dto/v1/web/v1webnotificationlistelement.dto";
import V1WebParkPermitDTO from "dto/v1/web/v1webparkpermit.dto";
import V1WebParkPermitListElementDTO from "dto/v1/web/v1webparkpermitlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebParkPermitService from "service/v1/web/v1webparkpermit.service";
import { ParkPermitType } from "type/parkpermit.type";
import { ParkPermitState } from "./parkpermit.reducer";

export const PARKPERMIT_GET = "parkpermit/get";
export const PARKPERMIT_GET_PAGEABLELIST = "parkpermit/get/pageablelist";
export const PARKPERMIT_SEARCH_PAGEABLELIST = "parkpermit/search/pageablelist";
export const PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST = "parkpermit/get/notification/pageablelist";
export const PARKPERMIT_PUT = "parkpermit/put";

export interface ParkPermitAction {
  type:
  typeof PARKPERMIT_GET |
  typeof PARKPERMIT_GET_PAGEABLELIST |
  typeof PARKPERMIT_SEARCH_PAGEABLELIST |
  typeof PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST |
  typeof PARKPERMIT_PUT
  status: StateStatus;
  pageableListCount: number;
  pageableListPageNumber: number;
  pageableListPageSize: number;
  pageableListContent: Array<any[]>;
  pageableListSort: string;
  pageableListDirection: Direction;
  pageableListKeyword: string;
  parkPermitUuid: string;
  parkPermitValidFrom: Date;
  parkPermitValidTo: Date;
  parkPermitDataPolicyAcceptedTimeStamp: Date;
  parkPermitDataPolicyRevision: string;
  parkPermitNotes: string;
  parkPermitIsEnabled: boolean;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  abstractParkingAreaAddress: string;
  abstractParkingAreaZip: string;
  abstractParkingAreaCity: string;
  vehicleUuid: string;
  vehicleVehicleId: string;
  abstractUserClientUuid: string;
  abstractUserClientUsername: string;
  notificationStatus: StateStatus;
  notificationPageableListCount: number;
  notificationPageableListPageNumber: number;
  notificationPageableListPageSize: number;
  notificationPageableListContent: Array<any[]>;
  notificationPageableListSort: string;
  notificationPageableListDirection: Direction;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type ParkPermitThunkAction = ThunkAction<Promise<void>, ParkPermitState, any, ParkPermitAction>;

export function getParkPermit(parkPermitUuid: string): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_GET,
        status: LOADING_STATUS
      });

      v1WebParkPermitService.getParkPermit(parkPermitUuid).then((response: AxiosResponse) => {
        let v1WebParkPermitDto = response.data as V1WebParkPermitDTO;

        dispatch({
          type: PARKPERMIT_GET,
          status: SUCCESS_STATUS,
          parkPermitUuid: v1WebParkPermitDto.parkPermitUuid,
          parkPermitValidFrom: v1WebParkPermitDto.parkPermitValidFrom,
          parkPermitValidTo: v1WebParkPermitDto.parkPermitValidTo,
          parkPermitDataPolicyAcceptedTimeStamp: v1WebParkPermitDto.parkPermitDataPolicyAcceptedTimeStamp,
          parkPermitDataPolicyRevision: v1WebParkPermitDto.parkPermitDataPolicyRevision,
          parkPermitNotes: v1WebParkPermitDto.parkPermitNotes,
          parkPermitIsEnabled: v1WebParkPermitDto.parkPermitIsEnabled,
          abstractParkingAreaUuid: v1WebParkPermitDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkPermitDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkPermitDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkPermitDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkPermitDto.abstractParkingAreaCity,
          vehicleUuid: v1WebParkPermitDto.vehicleUuid,
          vehicleVehicleId: v1WebParkPermitDto.vehicleVehicleId,
          abstractUserClientUuid: v1WebParkPermitDto.abstractUserClientUuid,
          abstractUserClientUsername: v1WebParkPermitDto.abstractUserClientUsername
        })
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function getParkPermitAsUserClient(parkPermitUuid: string): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_GET,
        status: LOADING_STATUS
      });

      v1WebParkPermitService.getParkPermitAsUserClient(parkPermitUuid).then((response: AxiosResponse) => {
        let v1WebParkPermitDto = response.data as V1WebParkPermitDTO;

        dispatch({
          type: PARKPERMIT_GET,
          status: SUCCESS_STATUS,
          parkPermitUuid: v1WebParkPermitDto.parkPermitUuid,
          parkPermitValidFrom: v1WebParkPermitDto.parkPermitValidFrom,
          parkPermitValidTo: v1WebParkPermitDto.parkPermitValidTo,
          parkPermitDataPolicyAcceptedTimeStamp: v1WebParkPermitDto.parkPermitDataPolicyAcceptedTimeStamp,
          parkPermitDataPolicyRevision: v1WebParkPermitDto.parkPermitDataPolicyRevision,
          parkPermitNotes: v1WebParkPermitDto.parkPermitNotes,
          parkPermitIsEnabled: v1WebParkPermitDto.parkPermitIsEnabled,
          abstractParkingAreaUuid: v1WebParkPermitDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkPermitDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkPermitDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkPermitDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkPermitDto.abstractParkingAreaCity,
          abstractUserClientPublicUuid: v1WebParkPermitDto.abstractUserClientUuid,
          abstractUserClientUsername: v1WebParkPermitDto.abstractUserClientUsername,
          vehicleUuid: v1WebParkPermitDto.vehicleUuid,
          vehicleVehicleId: v1WebParkPermitDto.vehicleVehicleId
        })
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function getParkPermitPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkPermitService.getParkPermitPageableList(page).then((response: AxiosResponse) => {
        let parkPermitPageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        parkPermitPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId, value.parkPermitIsEnabled])
        });

        dispatch({
          type: PARKPERMIT_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: parkPermitPageableList.totalElements,
          pageableListPageNumber: parkPermitPageableList.pageable.pageNumber,
          pageableListPageSize: parkPermitPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function getParkPermitPageableListForUserClientAsUserClient(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkPermitService.getParkPermitPageableListForUserClientAsUserClient(page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId, value.abstractUserClientUsername, value.abstractUserClientFirstName, value.abstractUserClientLastName])
        });

        dispatch({
          type: PARKPERMIT_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function searchParkPermitPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebParkPermitService.searchParkPermitPageableList(v1keywordDto, page).then((response: AxiosResponse) => {
        let parkPermitPageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        parkPermitPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId, value.parkPermitIsEnabled])
        });

        dispatch({
          type: PARKPERMIT_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: parkPermitPageableList.totalElements,
          pageableListPageNumber: parkPermitPageableList.pageable.pageNumber,
          pageableListPageSize: parkPermitPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function searchParkPermitPageableListForUserClientAsUserClient(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebParkPermitService.searchParkPermitPageableListForUserClientAsUserClient(v1keywordDto, page).then((response: AxiosResponse) => {
        let parkPermitPageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        parkPermitPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId, value.abstractUserClientUsername, value.abstractUserClientFirstName, value.abstractUserClientLastName])
        });

        dispatch({
          type: PARKPERMIT_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: parkPermitPageableList.totalElements,
          pageableListPageNumber: parkPermitPageableList.pageable.pageNumber,
          pageableListPageSize: parkPermitPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function getParkPermitNotificationPageableList(parkPermitUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkPermitService.getParkPermitNotificationPageableList(parkPermitUuid, page).then((response: AxiosResponse) => {
        let notificationPageableList = response.data as Response<V1WebNotificationListElementDTO>

        let pageableListContent: any[] = [];
        notificationPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractNotificationUuid, value.abstractNotificationNotificationType])
        });

        dispatch({
          type: PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST,
          status: SUCCESS_STATUS,
          notificationPageableListCount: notificationPageableList.totalElements,
          notificationPageableListPageNumber: notificationPageableList.pageable.pageNumber,
          notificationPageableListPageSize: notificationPageableList.pageable.pageSize,
          notificationPageableListContent: pageableListContent,
          notificationPageableListSort: sort,
          notificationPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_GET_NOTIFICATION_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function putParkPermit(parkPermitUuid: string, parkPermitNotes: string, parkPermitIsEnabled: boolean, abstractUserClientUuid: string): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_PUT,
        status: LOADING_STATUS
      });

      let v1WebParkPermitDto: V1WebParkPermitDTO = {
        parkPermitUuid: parkPermitUuid,
        parkPermitValidFrom: new Date(),
        parkPermitValidTo: new Date(),
        parkPermitValidFromV1DateTimeDto: new V1DateTimeDTO(),
        parkPermitValidToV1DateTimeDto: new V1DateTimeDTO(),
        parkPermitDataPolicyAcceptedTimeStamp: new Date(),
        parkPermitDataPolicyRevision: "",
        parkPermitNotes: parkPermitNotes,
        parkPermitIsEnabled: parkPermitIsEnabled,
        parkPermitParkPermitType: ParkPermitType.EXTENDED,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        parkingLimitationUuid: "",
        abstractUserClientUuid: abstractUserClientUuid,
        abstractUserClientUsername: ""
      }

      v1WebParkPermitService.putParkPermit(v1WebParkPermitDto).then((response: AxiosResponse) => {
        let v1WebParkPermitDto = response.data as V1WebParkPermitDTO;

        dispatch({
          type: PARKPERMIT_PUT,
          status: SUCCESS_STATUS,
          parkPermitUuid: v1WebParkPermitDto.parkPermitUuid,
          parkPermitValidFrom: v1WebParkPermitDto.parkPermitValidFrom,
          parkPermitValidTo: v1WebParkPermitDto.parkPermitValidTo,
          parkPermitDataPolicyAcceptedTimeStamp: v1WebParkPermitDto.parkPermitDataPolicyAcceptedTimeStamp,
          parkPermitDataPolicyRevision: v1WebParkPermitDto.parkPermitDataPolicyRevision,
          parkPermitNotes: v1WebParkPermitDto.parkPermitNotes,
          parkPermitIsEnabled: v1WebParkPermitDto.parkPermitIsEnabled,
          abstractParkingAreaUuid: v1WebParkPermitDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkPermitDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkPermitDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkPermitDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkPermitDto.abstractParkingAreaCity,
          vehicleUuid: v1WebParkPermitDto.vehicleUuid,
          vehicleVehicleId: v1WebParkPermitDto.vehicleVehicleId,
          abstractUserClientUuid: v1WebParkPermitDto.abstractUserClientUuid,
          abstractUserClientUsername: v1WebParkPermitDto.abstractUserClientUsername
        })
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function putParkPermitAsUserClient(parkPermitUuid: string, parkPermitNotes: string, parkPermitIsEnabled: boolean): ParkPermitThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_PUT,
        status: LOADING_STATUS
      });

      let v1WebParkPermitDto: V1WebParkPermitDTO = {
        parkPermitUuid: parkPermitUuid,
        parkPermitValidFrom: new Date(),
        parkPermitValidTo: new Date(),
        parkPermitValidFromV1DateTimeDto: new V1DateTimeDTO(),
        parkPermitValidToV1DateTimeDto: new V1DateTimeDTO(),
        parkPermitDataPolicyAcceptedTimeStamp: new Date(),
        parkPermitDataPolicyRevision: "",
        parkPermitNotes: parkPermitNotes,
        parkPermitIsEnabled: parkPermitIsEnabled,
        parkPermitParkPermitType: ParkPermitType.LIMITED,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        parkingLimitationUuid: "",
        abstractUserClientUuid: "",
        abstractUserClientUsername: ""
      }

      v1WebParkPermitService.putParkPermitAsUserClient(v1WebParkPermitDto).then((response: AxiosResponse) => {
        let v1WebParkPermitDto = response.data as V1WebParkPermitDTO;

        dispatch({
          type: PARKPERMIT_PUT,
          status: SUCCESS_STATUS,
          parkPermitUuid: v1WebParkPermitDto.parkPermitUuid,
          parkPermitValidFrom: v1WebParkPermitDto.parkPermitValidFrom,
          parkPermitValidTo: v1WebParkPermitDto.parkPermitValidTo,
          parkPermitDataPolicyAcceptedTimeStamp: v1WebParkPermitDto.parkPermitDataPolicyAcceptedTimeStamp,
          parkPermitDataPolicyRevision: v1WebParkPermitDto.parkPermitDataPolicyRevision,
          parkPermitNotes: v1WebParkPermitDto.parkPermitNotes,
          parkPermitIsEnabled: v1WebParkPermitDto.parkPermitIsEnabled,
          abstractParkingAreaUuid: v1WebParkPermitDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkPermitDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkPermitDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkPermitDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkPermitDto.abstractParkingAreaCity,
          vehicleUuid: v1WebParkPermitDto.vehicleUuid,
          vehicleVehicleId: v1WebParkPermitDto.vehicleVehicleId,
          abstractUserClientUuid: v1WebParkPermitDto.abstractUserClientUuid,
          abstractUserClientUsername: v1WebParkPermitDto.abstractUserClientUsername
        })
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}