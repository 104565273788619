import { Typography, Grid, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Card, CardContent, TextField, CardHeader, InputAdornment, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Tooltip, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import CustomContainer from "component/customcontainer";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootRedux } from "redux/store";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import ErrorContainer from "component/errorcontainer";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import UUIDHelper from "helper/uuidhelper";
import { getParkingLimitationAsUserClient, getParkingLimitationParkPermitPageableListAsUserClient, putParkingLimitationAsUserClient } from "redux/parkinglimitation/parkinglimitation.action";
import SpinnerContainer from "component/spinnercontainer";
import V1DateTimeDTO from "dto/v1/v1datetime.dto";
import v1WebParkingLimitationService from "service/v1/web/v1webparkinglimitation.service";
import V1WebParkingLimitationDTO from "dto/v1/web/v1webparkinglimitation.dto";
import { Direction } from "dto/direction";
import HeadCell from "component/headcell";
import { NavigateNext } from "@mui/icons-material";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { LimitationType } from "type/limitation.type";

interface OwnProps {
}

interface DispatchProps {
  getParkingLimitationAsUserClient: (parkingLimitationUuid: string) => void;
  putParkingLimitationAsUserClient: (parkingLimitationUuid: string, parkingLimitationUnlimitedParkingLimitationType: LimitationType | null, parkingLimitationInitialUnlimitedParking: number, parkingLimitationRemainingUnlimitedParking: number, parkingLimitationConcurrentUnlimitedParking: number, parkingLimitationAddRemainingUnlimitedParking: number | null, parkingLimitationMinuteParkingLimitationType: LimitationType | null, parkingLimitationInitialMinuteParkingV1DateTimeDto: V1DateTimeDTO | null, parkingLimitationRemainingMinuteParkingV1DateTimeDto: V1DateTimeDTO | null, parkingLimitationConcurrentMinuteParking: number, parkingLimitationIsEnabled: boolean, parkingLimitationNotes: string) => void;
  getParkingLimitationParkPermitPageableListAsUserClient: (parkingLimitationUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  parkingLimitationStateStatus: StateStatus;
  parkingLimitationUuid: string;
  parkingLimitationInitialUnlimitedParking: number | string;
  parkingLimitationRemainingUnlimitedParking: number | string;
  parkingLimitationConcurrentUnlimitedParking: number | string;
  parkingLimitationInitialMinuteParking: number | string;
  parkingLimitationRemainingMinuteParking: number | string;
  parkingLimitationConcurrentMinuteParking: number | string;
  parkingLimitationIsEnabled: boolean;
  parkingLimitationNotes: string;
  parkingLimitationAbstractParkingAreaUuid: string;
  parkingLimitationAbstractParkingAreaParkingAreaCode: string;
  parkingLimitationAbstractParkingAreaAddress: string;
  parkingLimitationUserClientPublicUuid: string;
  parkingLimitationUserClientPublicUsername: string;
  parkingLimitationErrorCode: number
  parkingLimitationErrorMessage: string
  parkingLimitationErrorUuid: string
  parkPermitStateStatus: StateStatus
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  parkPermitPageableListKeyword: string
  parkPermitErrorCode: number
  parkPermitErrorMessage: string
  parkPermitErrorUuid: string
}

interface FormProps {
  parkingLimitationUnlimitedParkingLimitationType: LimitationType,
  parkingLimitationInitialUnlimitedParking: number,
  parkingLimitationConcurrentUnlimitedParking: number,
  parkingLimitationMinuteParkingLimitationType: LimitationType,
  parkingLimitationInitialMinuteParkingDays: number,
  parkingLimitationInitialMinuteParkingHours: number,
  parkingLimitationInitialMinuteParkingMinutes: number,
  parkingLimitationConcurrentMinuteParking: number,
  parkingLimitationRemainingMinuteParkingDays: number,
  parkingLimitationRemainingMinuteParkingHours: number,
  parkingLimitationRemainingMinuteParkingMinutes: number,
  parkingLimitationRemainingUnlimitedParking: number
}

type Props = StateProps & OwnProps & DispatchProps

const OperatorResidentView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isParkPermitLoaded, setParkPermitLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkPermitLoading, setParkPermitLoading] = useState(true);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [isSetUnlimitedParkingLimitationDialogOpen, setSetUnlimitedParkingLimitationDialogOpen] = useState(false);
  const [isSetMinuteParkingLimitationDialogOpen, setSetMinuteParkingLimitationDialogOpen] = useState(false);
  const [isAddRemainingUnlimitedParkingDialogOpen, setAddRemainingUnlimitedParkingDialogOpen] = useState(false);
  const [isAddRemainingMinuteParkingDialogOpen, setAddRemainingMinuteParkingDialogOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    setValue: setUpdateFormValue  } = useForm<StateProps>({
    mode: 'all'
  });

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.putParkingLimitationAsUserClient(props.parkingLimitationUuid, null, props.parkingLimitationInitialUnlimitedParking as number, props.parkingLimitationRemainingUnlimitedParking as number, props.parkingLimitationConcurrentUnlimitedParking as number, null, null, null, null, props.parkingLimitationConcurrentMinuteParking as number, true, formProps.parkingLimitationNotes);
    }
  }

  const {
    handleSubmit: handleSetUnlimitedParkingLimitationFormSubmit,
    control: setUnlimitedParkingLimitationFormControl,
    reset: resetSetUnlimitedParkingLimitationForm,
    watch: watchSetUnlimitedParkingLimitationForm  } = useForm<FormProps>({
    mode: 'all'
  });

  const onSetUnlimitedParkingLimitationFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      props.putParkingLimitationAsUserClient(props.parkingLimitationUuid, formProps.parkingLimitationUnlimitedParkingLimitationType, formProps.parkingLimitationInitialUnlimitedParking as number, props.parkingLimitationRemainingUnlimitedParking as number, formProps.parkingLimitationConcurrentUnlimitedParking, null, null, null, null, props.parkingLimitationConcurrentMinuteParking as number, true, props.parkingLimitationNotes);
      toogleSetUnlimitedParkingLimitationDialogOpen();
    }
  }

  const watchParkingLimitationUnlimitedParkingLimitationType = watchSetUnlimitedParkingLimitationForm("parkingLimitationUnlimitedParkingLimitationType");

  const {
    handleSubmit: handleSetMinuteParkingLimitationFormSubmit,
    control: setMinuteParkingLimitationFormControl,
    reset: resetSetMinuteParkingLimitationForm,
    watch: watchSetMinuteParkingLimitationForm  } = useForm<FormProps>({
    mode: 'all'
  });

  const onSetMinuteParkingLimitationFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      props.putParkingLimitationAsUserClient(props.parkingLimitationUuid, null, props.parkingLimitationInitialUnlimitedParking as number, props.parkingLimitationRemainingUnlimitedParking as number, props.parkingLimitationConcurrentUnlimitedParking as number, null, formProps.parkingLimitationMinuteParkingLimitationType, { date: formProps.parkingLimitationInitialMinuteParkingDays, hour: formProps.parkingLimitationInitialMinuteParkingHours, minute: formProps.parkingLimitationInitialMinuteParkingMinutes, year: 0, month: 0, second: 0 }, null, formProps.parkingLimitationConcurrentMinuteParking, true, props.parkingLimitationNotes);
      toogleSetMinuteParkingLimitationDialogOpen();
    }
  }

  const watchParkingLimitationMinuteParkingLimitationType = watchSetMinuteParkingLimitationForm("parkingLimitationMinuteParkingLimitationType");

  const {
    handleSubmit: handleAddRemainingUnlimitedParkingFormSubmit,
    control: addRemainingUnlimitedParkingFormControl,
    reset: resetAddRemainingUnlimitedParkingForm  } = useForm<FormProps>({
    mode: 'all'
  });

  const onAddRemainingUnlimitedParkingFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      props.putParkingLimitationAsUserClient(props.parkingLimitationUuid, null, props.parkingLimitationInitialUnlimitedParking as number, props.parkingLimitationRemainingUnlimitedParking as number, props.parkingLimitationConcurrentUnlimitedParking as number, formProps.parkingLimitationRemainingUnlimitedParking, null, null, null, props.parkingLimitationConcurrentMinuteParking as number, true, props.parkingLimitationNotes);
      toogleAddRemainingUnlimitedParkingDialogOpen();
    }
  }

  const {
    handleSubmit: handleAddRemainingMinuteParkingFormSubmit,
    control: addRemainingMinuteParkingFormControl,
    reset: resetAddRemainingMinuteParkingForm  } = useForm<FormProps>({
    mode: 'all'
  });

  const onAddRemainingMinuteParkingFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      props.putParkingLimitationAsUserClient(props.parkingLimitationUuid, null, props.parkingLimitationInitialUnlimitedParking as number, props.parkingLimitationRemainingUnlimitedParking as number, props.parkingLimitationConcurrentUnlimitedParking as number, null, null, null, { date: formProps.parkingLimitationRemainingMinuteParkingDays, hour: formProps.parkingLimitationRemainingMinuteParkingHours, minute: formProps.parkingLimitationRemainingMinuteParkingMinutes, year: 0, month: 0, second: 0 }, props.parkingLimitationConcurrentMinuteParking as number, true, props.parkingLimitationNotes);
      toogleAddRemainingMinuteParkingDialogOpen();
    }
  }

  const handleUnsubscribeLimitation = () => {
    if (!isViewLoading && props.parkingLimitationUuid !== null) {
      if (window.confirm('Er du sikker på du vil afmelde denne beboer?')) {
        let v1WebParkingLimitationDto = new V1WebParkingLimitationDTO();

        v1WebParkingLimitationDto.parkingLimitationUuid = props.parkingLimitationUuid;
        v1WebParkingLimitationDto.parkingLimitationNotes = props.parkingLimitationNotes;
        v1WebParkingLimitationDto.parkingLimitationIsEnabled = false;

        v1WebParkingLimitationService.putParkingLimitationAsUserClient(v1WebParkingLimitationDto).then(() => {
          navigate("/operator/resident", { replace: true });
        }).catch((error) => {
          alert("Der skete en fejl: " + error.errorMessage);
        });
      } else {

      }
    }
  }

  const handleParkPermitPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.getParkingLimitationParkPermitPageableListAsUserClient(props.parkingLimitationUuid, 0, parseInt(event.target.value), props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
  };

  const handleParkPermitPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.getParkingLimitationParkPermitPageableListAsUserClient(props.parkingLimitationUuid, newPage, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection)
  }

  const handleParkPermitDirectionChange = (property: string) => () => {
    if (props.parkPermitPageableListDirection === Direction.ASC) {
      props.getParkingLimitationParkPermitPageableListAsUserClient(props.parkingLimitationUuid, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.DESC);
    } else {
      props.getParkingLimitationParkPermitPageableListAsUserClient(props.parkingLimitationUuid, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.ASC);
    }
  }

  const toogleSetUnlimitedParkingLimitationDialogOpen = () => {
    setSetUnlimitedParkingLimitationDialogOpen(!isSetUnlimitedParkingLimitationDialogOpen);
    resetSetUnlimitedParkingLimitationForm();
  }

  const toogleSetMinuteParkingLimitationDialogOpen = () => {
    setSetMinuteParkingLimitationDialogOpen(!isSetMinuteParkingLimitationDialogOpen);
    resetSetMinuteParkingLimitationForm();
  }

  const toogleAddRemainingUnlimitedParkingDialogOpen = () => {
    setAddRemainingUnlimitedParkingDialogOpen(!isAddRemainingUnlimitedParkingDialogOpen);
    resetAddRemainingUnlimitedParkingForm();
  }

  const toogleAddRemainingMinuteParkingDialogOpen = () => {
    setAddRemainingMinuteParkingDialogOpen(!isAddRemainingMinuteParkingDialogOpen);
    resetAddRemainingMinuteParkingForm();
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.residentUuid !== undefined && UUIDHelper.validateUUID(params.residentUuid)) {
        props.getParkingLimitationAsUserClient(params.residentUuid);
      } else {
        navigate('404', { replace: true });
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isViewLoaded && props.parkingLimitationUuid !== undefined && props.parkingLimitationUuid !== "") {
      props.getParkingLimitationParkPermitPageableListAsUserClient(props.parkingLimitationUuid, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
      setParkPermitLoaded(true);
    }
  }, [props.parkingLimitationUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingLimitationStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("parkingLimitationUuid", props.parkingLimitationUuid);
        setUpdateFormValue("parkingLimitationInitialUnlimitedParking", props.parkingLimitationInitialUnlimitedParking === null ? "Ubegrænset" : props.parkingLimitationInitialUnlimitedParking);
        setUpdateFormValue("parkingLimitationRemainingUnlimitedParking", props.parkingLimitationRemainingUnlimitedParking === null ? "Ubegrænset" : props.parkingLimitationRemainingUnlimitedParking);
        setUpdateFormValue("parkingLimitationConcurrentUnlimitedParking", props.parkingLimitationConcurrentUnlimitedParking === null ? "Ubegrænset" : props.parkingLimitationConcurrentUnlimitedParking);
        setUpdateFormValue("parkingLimitationInitialMinuteParking", props.parkingLimitationInitialMinuteParking === null ? "Ubegrænset" : props.parkingLimitationInitialMinuteParking);
        setUpdateFormValue("parkingLimitationRemainingMinuteParking", props.parkingLimitationRemainingMinuteParking === null ? "Ubegrænset" : props.parkingLimitationRemainingMinuteParking);
        setUpdateFormValue("parkingLimitationConcurrentMinuteParking", props.parkingLimitationConcurrentMinuteParking === null ? "Ubegrænset" : props.parkingLimitationConcurrentMinuteParking);
        setUpdateFormValue("parkingLimitationNotes", props.parkingLimitationNotes);
        setUpdateFormValue("parkingLimitationAbstractParkingAreaUuid", props.parkingLimitationAbstractParkingAreaUuid);
        setUpdateFormValue("parkingLimitationAbstractParkingAreaParkingAreaCode", props.parkingLimitationAbstractParkingAreaParkingAreaCode);
        setUpdateFormValue("parkingLimitationAbstractParkingAreaAddress", props.parkingLimitationAbstractParkingAreaAddress);
        setUpdateFormValue("parkingLimitationUserClientPublicUuid", props.parkingLimitationUserClientPublicUuid);
        setUpdateFormValue("parkingLimitationUserClientPublicUsername", props.parkingLimitationUserClientPublicUsername);

        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkingLimitationStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkPermitStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setParkPermitLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setParkPermitLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setParkPermitLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkPermitStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

  const parkPermitHeadCells: readonly HeadCell[] = [
    {
      id: "vehicle.vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    },
    {
      id: "validFrom",
      numeric: false,
      disablePadding: false,
      label: "Gyldig fra"
    },
    {
      id: "validTo",
      numeric: false,
      disablePadding: false,
      label: "Gyldig til"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.parkingLimitationErrorCode} errorMessage={props.parkingLimitationErrorMessage} errorUuid={props.parkingLimitationErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Beboer
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    <CardHeader
                      subheader={'Definerer det årlige antal tilladte registreringer/ændringer af egenparkeringer som tildeles ved årets start'}
                      title="Egenparkering"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationConcurrentUnlimitedParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Maksimale antal tiladte igangværende egenparkeringer"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationInitialUnlimitedParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Button
                            color="primary"
                            disabled={isViewLoading}
                            onClick={toogleSetUnlimitedParkingLimitationDialogOpen}
                            type="button"
                            fullWidth
                            variant="outlined"
                          >
                            Ændre egenparkering
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardHeader
                      subheader={'Definerer den månedlige mængde gæsteparkering som tildeles ved månedens start'}
                      title="Gæsteparkeringer"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationConcurrentMinuteParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Maksimale antal tiladte igangværende gæsteparkeringer"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationInitialMinuteParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Månedlig tilladt gæsteparkering"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">minutter</InputAdornment>,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Button
                            color="primary"
                            disabled={isViewLoading}
                            onClick={toogleSetMinuteParkingLimitationDialogOpen}
                            type="button"
                            fullWidth
                            variant="outlined"
                          >
                            Ændre gæsteparkering
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardHeader
                      subheader={"Angiver den resterende tilladt egenparkering for indeværende år, samt resterende tilladt gæsteparkering for indeværende måned"}
                      title="Resterende parkering"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={8}
                          md={8}
                          xs={8}
                        >
                          <Controller
                            name="parkingLimitationRemainingUnlimitedParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Resterende egenparkeringer"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />

                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={4}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              marginTop: 1.1
                            }}
                          >
                            <Button
                              color="primary"
                              disabled={isViewLoading}
                              onClick={toogleAddRemainingUnlimitedParkingDialogOpen}
                              type="button"
                              variant="outlined"
                            >
                              Tilføj
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={8}
                          md={8}
                          xs={8}
                        >
                          <Controller
                            name="parkingLimitationRemainingMinuteParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Resterende gæsteparkering"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">minutter</InputAdornment>,
                                }}
                              />
                            )}
                          />

                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={4}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "right",
                              marginTop: 1.1
                            }}
                          >
                            <Button
                              color="primary"
                              disabled={isViewLoading}
                              onClick={toogleAddRemainingMinuteParkingDialogOpen}
                              type="button"
                              variant="outlined"
                            >
                              Tilføj
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardHeader
                      subheader={"Generelle oplysninger for begrænsningen"}
                      title="Begrænsning oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="error"
                        disabled={isViewLoading}
                        type="button"
                        onClick={handleUnsubscribeLimitation}
                        variant="outlined"
                      >
                        Afmeld begrænsning
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: -2,
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem begrænsning oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Card
                    sx={{
                      marginBottom: 3
                    }}
                  >
                    <CardHeader
                      subheader={"Alle relationer tilknyttet denne begrænsning"}
                      title="Relationer" />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationAbstractParkingAreaParkingAreaCode"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parking Area Code"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Controller
                            name="parkingLimitationUserClientPublicUsername"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Offentlig bruger"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Card
                  sx={{
                    marginBottom: 3
                  }}
                >
                  <CardHeader
                    subheader={"Alle tilladelser oprettet vha. denne begrænsning"}
                    title="Tilladelser" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {parkPermitHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.parkPermitPageableListSort === headCell.id}
                              direction={props.parkPermitPageableListDirection}
                              onClick={handleParkPermitDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isParkPermitLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.parkPermitPageableListContent === undefined || props.parkPermitPageableListContent.length === 0 ? props.parkPermitPageableListPageSize : props.parkPermitPageableListContent.length} tableColSpan={parkPermitHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.parkPermitPageableListContent === undefined || props.parkPermitPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={parkPermitHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.parkPermitPageableListContent.map(parkPermit => (
                                <TableRow
                                  hover
                                  key={parkPermit[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {parkPermit[8]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {(parkPermit[1] === undefined || parkPermit[1] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkPermit[1]))}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {(parkPermit[2] === undefined || parkPermit[2] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkPermit[2]))}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title="Vis">
                                      <IconButton aria-label="Edit"
                                        edge='end'
                                        component={NavLink}
                                        to={"../parkpermit/" + parkPermit[0]}
                                      >
                                        <NavigateNext />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={parkPermitHeadCells.length + 1}
                          count={props.parkPermitPageableListCount || 0}
                          rowsPerPage={props.parkPermitPageableListPageSize || 5}
                          page={props.parkPermitPageableListPageNumber || 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleParkPermitPageNumberChange}
                          onRowsPerPageChange={handleParkPermitPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
      <Dialog
        open={isSetUnlimitedParkingLimitationDialogOpen}
        onClose={toogleSetUnlimitedParkingLimitationDialogOpen}
      >
        <form onSubmit={handleSetUnlimitedParkingLimitationFormSubmit(onSetUnlimitedParkingLimitationFormSubmit)}>
          <DialogTitle>Ændre egenparkering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld formularen for at ændre begrænsningen til egenparkering. Ændringerne overskriver den eksisterende begrænsning for egenparkering
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Egenparkerings begrænsnings type er påkrævet"
                        }
                      }}
                      control={setUnlimitedParkingLimitationFormControl}
                      name="parkingLimitationUnlimitedParkingLimitationType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          component="fieldset"
                          error={!!error}
                        >
                          <RadioGroup
                            {...field}
                            row
                            value={field.value || ""}
                          >
                            <FormControlLabel
                              value={LimitationType.PROHIBITED}
                              control={<Radio />}
                              label="Forbudt"
                            />
                            <FormControlLabel
                              value={LimitationType.LIMITED}
                              control={<Radio />}
                              label="Begrænset"
                            />
                            <FormControlLabel
                              value={LimitationType.UNLIMITED}
                              control={<Radio />}
                              label="Ubegrænset"
                            />
                          </RadioGroup>
                          <FormHelperText sx={{
                            marginLeft: 0
                          }}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      {(watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.LIMITED || watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.UNLIMITED) &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingLimitationConcurrentUnlimitedParking"
                              control={setUnlimitedParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Maksimale antal tiladte igangværende egenparkeringer"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                      {watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.LIMITED &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <DialogContentText marginBottom={-4}>
                              Udfyld feltet nedenfor for at definere det årlige antal tilladte registreringer/ændringer af egenparkeringer som tildeles ved årets start
                            </DialogContentText>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingLimitationInitialUnlimitedParking"
                              control={setUnlimitedParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleSetUnlimitedParkingLimitationDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Gem
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isSetMinuteParkingLimitationDialogOpen}
        onClose={toogleSetMinuteParkingLimitationDialogOpen}
      >
        <form onSubmit={handleSetMinuteParkingLimitationFormSubmit(onSetMinuteParkingLimitationFormSubmit)}>
          <DialogTitle>Ændre gæsteparkering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld formularen for at ændre begrænsningen til gæsteparkering. Ændringerne overskriver den eksisterende begrænsning for gæsteparkering
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Gæsteparkerings begrænsnings type er påkrævet"
                        }
                      }}
                      control={setMinuteParkingLimitationFormControl}
                      name="parkingLimitationMinuteParkingLimitationType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          component="fieldset"
                          error={!!error}
                        >
                          <RadioGroup
                            {...field}
                            row
                            value={field.value || ""}
                          >
                            <FormControlLabel
                              value={LimitationType.PROHIBITED}
                              control={<Radio />}
                              label="Forbudt"
                            />
                            <FormControlLabel
                              value={LimitationType.LIMITED}
                              control={<Radio />}
                              label="Begrænset"
                            />
                            <FormControlLabel
                              value={LimitationType.UNLIMITED}
                              control={<Radio />}
                              label="Ubegrænset"
                            />
                          </RadioGroup>
                          <FormHelperText sx={{
                            marginLeft: 0
                          }}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      {(watchParkingLimitationMinuteParkingLimitationType === LimitationType.LIMITED || watchParkingLimitationMinuteParkingLimitationType === LimitationType.UNLIMITED) &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingLimitationConcurrentMinuteParking"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Maksimale antal tiladte igangværende gæsteparkeringer"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                      {watchParkingLimitationMinuteParkingLimitationType === LimitationType.LIMITED &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <DialogContentText marginBottom={-4}>
                              Udfyld felterne nedenfor for at definere den månedlige mængde gæsteparkering som tildeles ved månedens start. Angiv enten i dage, timer, minutter eller en kombination heraf
                            </DialogContentText>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingLimitationInitialMinuteParkingDays"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 31)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 31")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Dage</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingLimitationInitialMinuteParkingHours"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 999)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 999")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Timer</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingLimitationInitialMinuteParkingMinutes"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 999)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 999")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Minutter</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleSetMinuteParkingLimitationDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Gem
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isAddRemainingUnlimitedParkingDialogOpen}
        onClose={toogleAddRemainingUnlimitedParkingDialogOpen}
      >
        <form onSubmit={handleAddRemainingUnlimitedParkingFormSubmit(onAddRemainingUnlimitedParkingFormSubmit)}>
          <DialogTitle>Tilføj egenparkering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld formularen for at tilføje egenparkering til begrænsningen for indeværende år
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="parkingLimitationRemainingUnlimitedParking"
                      control={addRemainingUnlimitedParkingFormControl}
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value === undefined || (value >= 0 && value <= 99)) {
                                resolve(true)
                              } else {
                                resolve("Angiv venligst et tal mellem 0 og 99")
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleAddRemainingUnlimitedParkingDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Tilføj
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isAddRemainingMinuteParkingDialogOpen}
        onClose={toogleAddRemainingMinuteParkingDialogOpen}
      >
        <form onSubmit={handleAddRemainingMinuteParkingFormSubmit(onAddRemainingMinuteParkingFormSubmit)}>
          <DialogTitle>Tilføj gæsteparkering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld formularen for at tilføje gæsteparkering til begrænsningen for indeværende måned
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <Controller
                      name="parkingLimitationRemainingMinuteParkingDays"
                      control={addRemainingMinuteParkingFormControl}
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value === undefined || (value >= 0 && value <= 31)) {
                                resolve(true)
                              } else {
                                resolve("Angiv venligst et tal mellem 0 og 31")
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label=""
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Dage</InputAdornment>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <Controller
                      name="parkingLimitationRemainingMinuteParkingHours"
                      control={addRemainingMinuteParkingFormControl}
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value === undefined || (value >= 0 && value <= 999)) {
                                resolve(true)
                              } else {
                                resolve("Angiv venligst et tal mellem 0 og 999")
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label=""
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Timer</InputAdornment>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <Controller
                      name="parkingLimitationRemainingMinuteParkingMinutes"
                      control={addRemainingMinuteParkingFormControl}
                      rules={{
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value === undefined || (value >= 0 && value <= 999)) {
                                resolve(true)
                              } else {
                                resolve("Angiv venligst et tal mellem 0 og 999")
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label=""
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">Minutter</InputAdornment>,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleAddRemainingMinuteParkingDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Tilføj
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkingLimitationStateStatus: redux.parkingLimitation.status,
    parkingLimitationUuid: redux.parkingLimitation.parkingLimitationUuid,
    parkingLimitationInitialUnlimitedParking: redux.parkingLimitation.parkingLimitationInitialUnlimitedParking,
    parkingLimitationRemainingUnlimitedParking: redux.parkingLimitation.parkingLimitationRemainingUnlimitedParking,
    parkingLimitationConcurrentUnlimitedParking: redux.parkingLimitation.parkingLimitationConcurrentUnlimitedParking,
    parkingLimitationInitialMinuteParking: redux.parkingLimitation.parkingLimitationInitialMinuteParking,
    parkingLimitationRemainingMinuteParking: redux.parkingLimitation.parkingLimitationRemainingMinuteParking,
    parkingLimitationConcurrentMinuteParking: redux.parkingLimitation.parkingLimitationConcurrentMinuteParking,
    parkingLimitationIsEnabled: redux.parkingLimitation.parkingLimitationIsEnabled,
    parkingLimitationNotes: redux.parkingLimitation.parkingLimitationNotes,
    parkingLimitationAbstractParkingAreaUuid: redux.parkingLimitation.abstractParkingAreaUuid,
    parkingLimitationAbstractParkingAreaParkingAreaCode: redux.parkingLimitation.abstractParkingAreaParkingAreaCode,
    parkingLimitationAbstractParkingAreaAddress: redux.parkingLimitation.abstractParkingAreaAddress,
    parkingLimitationUserClientPublicUuid: redux.parkingLimitation.userClientPublicUuid,
    parkingLimitationUserClientPublicUsername: redux.parkingLimitation.userClientPublicUsername,
    parkingLimitationErrorCode: redux.parkingLimitation.errorCode,
    parkingLimitationErrorMessage: redux.parkingLimitation.errorMessage,
    parkingLimitationErrorUuid: redux.parkingLimitation.errorUuid,
    parkPermitStateStatus: redux.parkPermit.status,
    parkPermitPageableListCount: redux.parkPermit.pageableListCount,
    parkPermitPageableListPageNumber: redux.parkPermit.pageableListPageNumber,
    parkPermitPageableListPageSize: redux.parkPermit.pageableListPageSize,
    parkPermitPageableListContent: redux.parkPermit.pageableListContent,
    parkPermitPageableListSort: redux.parkPermit.pageableListSort,
    parkPermitPageableListDirection: redux.parkPermit.pageableListDirection,
    parkPermitPageableListKeyword: redux.parkPermit.pageableListKeyword,
    parkPermitErrorCode: redux.parkPermit.errorCode,
    parkPermitErrorMessage: redux.parkPermit.errorMessage,
    parkPermitErrorUuid: redux.parkPermit.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getParkingLimitationAsUserClient: async (parkingLimitationUuid) => {
      await dispatch(getParkingLimitationAsUserClient(parkingLimitationUuid));
    },
    putParkingLimitationAsUserClient: async (parkingLimitationUuid, parkingLimitationUnlimitedParkingLimitationType, parkingLimitationInitialUnlimitedParking, parkingLimitationRemainingUnlimitedParking, parkingLimitationConcurrentUnlimitedParking, parkingLimitationAddRemainingUnlimitedParking, parkingLimitationMinuteParkingLimitationType, parkingLimitationInitialMinuteParkingV1DateTimeDto, parkingLimitationRemainingMinuteParkingV1DateTimeDto, parkingLimitationConcurrentMinuteParking, parkingLimitationIsEnabled, parkingLimitationNotes) => {
      await dispatch(putParkingLimitationAsUserClient(parkingLimitationUuid, parkingLimitationUnlimitedParkingLimitationType, parkingLimitationInitialUnlimitedParking, parkingLimitationRemainingUnlimitedParking, parkingLimitationConcurrentUnlimitedParking, parkingLimitationAddRemainingUnlimitedParking, parkingLimitationMinuteParkingLimitationType, parkingLimitationInitialMinuteParkingV1DateTimeDto, parkingLimitationRemainingMinuteParkingV1DateTimeDto, parkingLimitationConcurrentMinuteParking, parkingLimitationIsEnabled, parkingLimitationNotes));
    },
    getParkingLimitationParkPermitPageableListAsUserClient: async (parkingLimitationUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getParkingLimitationParkPermitPageableListAsUserClient(parkingLimitationUuid, pageNumber, rowsPerPage, sort, direction))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(OperatorResidentView);