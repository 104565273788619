import { Direction } from "dto/direction";
import UUIDHelper from "helper/uuidhelper";
import { useEffect, useState } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { getParkPermit, getParkPermitNotificationPageableList, putParkPermit } from "redux/parkpermit/parkpermit.action";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store"
import ErrorContainer from "component/errorcontainer";
import { Typography, Grid, Card, CardHeader, CardContent, TextField, FormControlLabel, Switch, Box, Button, Divider, Autocomplete, CircularProgress, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel } from "@mui/material";
import CustomContainer from "component/customcontainer";
import SpinnerContainer from "component/spinnercontainer";
import { AxiosResponse } from "axios";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebUserListElementDTO from "dto/v1/web/v1webuserlistelement.dto";
import v1WebUserService from "service/v1/web/v1webuser.service";
import Response from "dto/response";
import HeadCell from "component/headcell";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";

interface OwnProps {
}

interface DispatchProps {
  getParkPermit: (parkPermitUuid: string) => void;
  getParkPermitNotificationPageableList: (parkPermitUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  putParkPermit: (parkPermitUuid: string, parkPermitNotes: string, parkPermitIsEnabled: boolean, abstractUserClientUuid: string) => void;
}

interface StateProps {
  parkPermitStatus: StateStatus;
  parkPermitUuid: string;
  parkPermitValidFrom: Date | string;
  parkPermitValidTo: Date | string;
  parkPermitVehicleUuid: string;
  parkPermitVehicleVehicleId: string;
  parkPermitParkingAreaUuid: string;
  parkPermitParkingAreaParkingAreaCode: string;
  parkPermitParkingAreaAddress: string;
  parkPermitParkingAreaZip: string;
  parkPermitParkingAreaCity: string;
  parkPermitUserClientUuid: string;
  parkPermitUserClientUsername: string;
  parkPermitIsEnabled: boolean;
  parkPermitNotes: string;
  parkPermitNotificationStatus: StateStatus;
  parkPermitNotificationPageableListCount: number;
  parkPermitNotificationPageableListPageNumber: number;
  parkPermitNotificationPageableListPageSize: number;
  parkPermitNotificationPageableListContent: Array<any[]>;
  parkPermitNotificationPageableListSort: string;
  parkPermitNotificationPageableListDirection: Direction;
  parkPermitErrorCode: number
  parkPermitErrorMessage: string
  parkPermitErrorUuid: string
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorParkPermitView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isNotificationLoaded, setNotificationLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isNotificationLoading, setNotificationLoading] = useState(true);
  const [isUserClientLoading, setUserClientLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [userClientOptions, setUserClientOptions] = useState(Array<any[]>());

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    getValues: getUpdateFormValues,
    setValue: setUpdateFormValue,
    watch: watchUpdateForm,
    clearErrors: clearUpdateFormError
  } = useForm<StateProps>({
    mode: 'all'
  });

  const watchParkPermitUserClientUsername = watchUpdateForm("parkPermitUserClientUsername");

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.putParkPermit(formProps.parkPermitUuid, formProps.parkPermitNotes, formProps.parkPermitIsEnabled, formProps.parkPermitUserClientUuid);
    }
  }

  const handleParkPermitNotificationPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.getParkPermitNotificationPageableList(props.parkPermitUuid, 0, parseInt(event.target.value), props.parkPermitNotificationPageableListSort, props.parkPermitNotificationPageableListDirection);
  };

  const handleParkPermitNotificationPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.getParkPermitNotificationPageableList(props.parkPermitUuid, newPage, props.parkPermitNotificationPageableListPageSize, props.parkPermitNotificationPageableListSort, props.parkPermitNotificationPageableListDirection)
  }

  const handleParkPermitNotificationDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkPermitNotificationPageableListDirection === Direction.ASC) {
      props.getParkPermitNotificationPageableList(props.parkPermitUuid, props.parkPermitNotificationPageableListPageNumber, props.parkPermitNotificationPageableListPageSize, property, Direction.DESC);
    } else {
      props.getParkPermitNotificationPageableList(props.parkPermitUuid, props.parkPermitNotificationPageableListPageNumber, props.parkPermitNotificationPageableListPageSize, property, Direction.ASC);
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.parkPermitUuid !== undefined && UUIDHelper.validateUUID(params.parkPermitUuid)) {
        props.getParkPermit(params.parkPermitUuid);
      } else {
        navigate('404', { replace: true });
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNotificationLoaded && props.parkPermitUuid !== undefined && props.parkPermitUuid !== "") {
      props.getParkPermitNotificationPageableList(props.parkPermitUuid, props.parkPermitNotificationPageableListPageNumber, props.parkPermitNotificationPageableListPageSize, props.parkPermitNotificationPageableListSort, props.parkPermitNotificationPageableListDirection);
      setNotificationLoaded(true);
    }
  }, [props.parkPermitUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkPermitStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("parkPermitUuid", props.parkPermitUuid);
        setUpdateFormValue("parkPermitValidFrom", props.parkPermitValidFrom !== null ? formatter.format(Date.parse(props.parkPermitValidFrom.toString())) : "Ubegrænset");
        setUpdateFormValue("parkPermitValidTo", props.parkPermitValidTo !== null ? formatter.format(Date.parse(props.parkPermitValidTo.toString())) : "Ubegrænset");
        setUpdateFormValue("parkPermitVehicleUuid", props.parkPermitVehicleUuid);
        setUpdateFormValue("parkPermitVehicleVehicleId", props.parkPermitVehicleVehicleId);
        setUpdateFormValue("parkPermitParkingAreaUuid", props.parkPermitParkingAreaUuid);
        setUpdateFormValue("parkPermitParkingAreaParkingAreaCode", props.parkPermitParkingAreaParkingAreaCode);
        setUpdateFormValue("parkPermitParkingAreaAddress", props.parkPermitParkingAreaAddress);
        setUpdateFormValue("parkPermitParkingAreaZip", props.parkPermitParkingAreaZip);
        setUpdateFormValue("parkPermitParkingAreaCity", props.parkPermitParkingAreaCity);
        setUpdateFormValue("parkPermitUserClientUuid", props.parkPermitUserClientUuid);
        setUpdateFormValue("parkPermitUserClientUsername", props.parkPermitUserClientUsername);
        setUpdateFormValue("parkPermitIsEnabled", props.parkPermitIsEnabled);
        setUpdateFormValue("parkPermitNotes", props.parkPermitNotes);

        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkPermitStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkPermitNotificationStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setNotificationLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setNotificationLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setNotificationLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkPermitNotificationStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchParkPermitUserClientUsername !== undefined && watchParkPermitUserClientUsername !== null && watchParkPermitUserClientUsername.trim().length > 0) {
      setUserClientLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "username",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getUpdateFormValues("parkPermitUserClientUsername")
      }

      v1WebUserService.searchUserClient(v1KeywordDto, page).then((response: AxiosResponse) => {
        let userPageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: Array<any[]> = [];
        userPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserUsername, value.abstractUserUserType])
        });
        setUserClientOptions(pageableListContent);
        setUserClientLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchParkPermitUserClientUsername]); // eslint-disable-line react-hooks/exhaustive-deps


  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric"
  });

  const parkPermitNotificationHeadCells: readonly HeadCell[] = [
    {
      id: "notificationType",
      numeric: false,
      disablePadding: false,
      label: "Notifikation type"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.parkPermitErrorCode} errorMessage={props.parkPermitErrorMessage} errorUuid={props.parkPermitErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Tilladelse
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    <CardHeader
                      subheader={"Generelle oplysninger for tilladelsen"}
                      title="Tilladelse oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaAddress"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaZip"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaCity"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitValidFrom"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Gyldig fra"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitValidTo"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Gyldig til"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitIsEnabled"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                />
                              } label="Aktiveret" />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem tilladelse oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Card>
                    <CardHeader
                      subheader={"Alle relationer tilknyttet denne tilladelse"}
                      title="Relationer" />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitVehicleVehicleId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Køretøj"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitParkingAreaParkingAreaCode"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parking Area Code"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <Controller
                            name="parkPermitUserClientUsername"
                            control={updateFormControl}
                            rules={{
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value !== null && value !== undefined && value.length >= 1) {
                                      if (userClientOptions.find(option => option[1].toLowerCase().trim() === value.toLowerCase().trim())) {
                                        setUpdateFormValue("parkPermitUserClientUuid", userClientOptions[userClientOptions.findIndex(option => option[1].toLowerCase().trim() === value.toLowerCase().trim())][0]);
                                        resolve(true);
                                      } else {
                                        resolve("Ugyldig bruger")
                                      }
                                    } else {
                                      setUpdateFormValue("parkPermitUserClientUuid", "");
                                      resolve(true);
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                inputValue={value || ""}
                                onChange={(event: any, newValue: string | any[] | null) => {
                                  if (newValue !== null) {
                                    setUpdateFormValue("parkPermitUserClientUsername", newValue[1]);
                                    setUpdateFormValue("parkPermitUserClientUuid", newValue[0]);
                                    clearUpdateFormError("parkPermitUserClientUsername")
                                  } else {
                                    setUpdateFormValue("parkPermitUserClientUsername", "");
                                    setUpdateFormValue("parkPermitUserClientUuid", "");
                                  }
                                }}
                                freeSolo={true}
                                options={userClientOptions}
                                getOptionLabel={(option) => option[1]}
                                isOptionEqualToValue={(option, value) => option[0] === value[0]}
                                noOptionsText={"Ingen resultater"}
                                filterOptions={(x) => x}
                                loading={isUserClientLoading}
                                loadingText={"Vent venligst.."}
                                openText={""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Klient bruger"
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : "Klient bruger tilknyttet tilladelsen"}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {isUserClientLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Card
                    sx={{
                      marginTop: 3
                    }}
                  >
                    <CardHeader
                      subheader={"Alle notifikationer tildelt denne tilladelse"}
                      title="Notifikationer" />
                    <Divider />
                    <Table>
                      <TableHead>
                        <TableRow>
                          {parkPermitNotificationHeadCells.map((headCell) => (
                            <TableCell
                              key={headCell.id}
                              align={headCell.numeric ? 'right' : 'left'}
                              padding={headCell.disablePadding ? 'none' : 'normal'}
                            >
                              {headCell.label}
                              <TableSortLabel
                                active={props.parkPermitNotificationPageableListSort === headCell.id}
                                direction={props.parkPermitNotificationPageableListDirection}
                                onClick={handleParkPermitNotificationDirectionChange(headCell.id)}
                              >
                              </TableSortLabel>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {isNotificationLoading ? (
                          <>
                            <FetchingContainer tablePageSize={props.parkPermitNotificationPageableListContent === undefined || props.parkPermitNotificationPageableListContent.length === 0 ? props.parkPermitNotificationPageableListPageSize : props.parkPermitNotificationPageableListContent.length} tableColSpan={parkPermitNotificationHeadCells.length} />
                          </>
                        ) : (
                          <>
                            {props.parkPermitNotificationPageableListContent === undefined || props.parkPermitNotificationPageableListCount === 0 ? (
                              <>
                                <EmptyContainer tableColSpan={parkPermitNotificationHeadCells.length} />
                              </>
                            ) : (
                              <>
                                {props.parkPermitNotificationPageableListContent.map(notification => (
                                  <TableRow
                                    hover
                                    key={notification[0]}
                                  >
                                    <TableCell>
                                      <Typography
                                        color="textPrimary"
                                        variant="body1"
                                      >
                                        {notification[1]}
                                      </Typography>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </TableBody>
                      <TableFooter>
                        <TableRow>
                          <TablePagination
                            rowsPerPageOptions={[5, 10, 25, 50, 100]}
                            colSpan={parkPermitNotificationHeadCells.length + 1}
                            count={props.parkPermitNotificationPageableListCount !== undefined ? props.parkPermitNotificationPageableListCount : 0}
                            rowsPerPage={props.parkPermitNotificationPageableListPageSize !== undefined ? props.parkPermitNotificationPageableListPageSize : 5}
                            page={props.parkPermitNotificationPageableListPageNumber !== undefined ? props.parkPermitNotificationPageableListPageNumber : 0}
                            labelRowsPerPage="Antal pr. side:"
                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                            onPageChange={handleParkPermitNotificationPageNumberChange}
                            onRowsPerPageChange={handleParkPermitNotificationPageSizeChange}
                          />
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkPermitStatus: redux.parkPermit.status,
    parkPermitUuid: redux.parkPermit.parkPermitUuid,
    parkPermitValidFrom: redux.parkPermit.parkPermitValidFrom,
    parkPermitValidTo: redux.parkPermit.parkPermitValidTo,
    parkPermitVehicleUuid: redux.parkPermit.vehicleUuid,
    parkPermitVehicleVehicleId: redux.parkPermit.vehicleVehicleId,
    parkPermitParkingAreaUuid: redux.parkPermit.abstractParkingAreaUuid,
    parkPermitParkingAreaParkingAreaCode: redux.parkPermit.abstractParkingAreaParkingAreaCode,
    parkPermitParkingAreaAddress: redux.parkPermit.abstractParkingAreaAddress,
    parkPermitParkingAreaZip: redux.parkPermit.abstractParkingAreaZip,
    parkPermitParkingAreaCity: redux.parkPermit.abstractParkingAreaCity,
    parkPermitUserClientUuid: redux.parkPermit.abstractUserClientUuid,
    parkPermitUserClientUsername: redux.parkPermit.abstractUserClientUsername,
    parkPermitIsEnabled: redux.parkPermit.parkPermitIsEnabled,
    parkPermitNotes: redux.parkPermit.parkPermitNotes,
    parkPermitNotificationStatus: redux.parkPermit.notificationStatus,
    parkPermitNotificationPageableListCount: redux.parkPermit.notificationPageableListCount,
    parkPermitNotificationPageableListPageNumber: redux.parkPermit.notificationPageableListPageNumber,
    parkPermitNotificationPageableListPageSize: redux.parkPermit.notificationPageableListPageSize,
    parkPermitNotificationPageableListContent: redux.parkPermit.notificationPageableListContent,
    parkPermitNotificationPageableListSort: redux.parkPermit.notificationPageableListSort,
    parkPermitNotificationPageableListDirection: redux.parkPermit.notificationPageableListDirection,
    parkPermitErrorCode: redux.parkPermit.errorCode,
    parkPermitErrorMessage: redux.parkPermit.errorMessage,
    parkPermitErrorUuid: redux.parkPermit.errorUuid,
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getParkPermit: async (parkPermitUuid) => {
      await dispatch(getParkPermit(parkPermitUuid));
    },
    getParkPermitNotificationPageableList: async (parkPermitUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getParkPermitNotificationPageableList(parkPermitUuid, pageNumber, rowsPerPage, sort, direction));
    },
    putParkPermit: async (parkPermitUuid, parkPermitNotes, parkPermitIsEnabled, abstractUserClientUuid) => {
      await dispatch(putParkPermit(parkPermitUuid, parkPermitNotes, parkPermitIsEnabled, abstractUserClientUuid))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdministratorParkPermitView);