import MenuItem from "component/menuitem";

const AdministratorMenuItems: MenuItem[] = [
  {
    href: "/administrator/vehicle",
    icon: "directions_car",
    title: "Køretøjer"
  },
  {
    href: "/administrator/parkingarea",
    icon: "place",
    title: "Parkeringsområder"
  },
  {
    href: "/administrator/parkpermit",
    icon: "assignment_turned_in",
    title: "Tilladelser"
  },
  {
    href: "/administrator/customer",
    icon: "contacts",
    title: "Kunder"
  },
  {
    href: "/administrator/ticket",
    icon: "border_color",
    title: "Afgifter"
  },
  {
    href: "/administrator/user",
    icon: "people",
    title: "Brugere"
  }
]

export default AdministratorMenuItems;