import { UserType } from "type/user.type";
import V1WebAbstractUserClientDTO from "./v1webabstractuserclient.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebUserClientPublicDTO extends V1WebAbstractUserClientDTO {

  constructor() {
    super();
    this.abstractUserUserType = UserType.PUBLIC;
  }
}