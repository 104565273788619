import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1DateTimeDTO from "dto/v1/v1datetime.dto";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebParkingLimitationDTO from "dto/v1/web/v1webparkinglimitation.dto";
import V1WebParkingLimitationListElementDTO from "dto/v1/web/v1webparkinglimitationlistelement.dto";
import V1WebParkPermitListElementDTO from "dto/v1/web/v1webparkpermitlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { PARKPERMIT_GET_PAGEABLELIST } from "redux/parkpermit/parkpermit.action";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebParkingLimitationService from "service/v1/web/v1webparkinglimitation.service";
import { LimitationType } from "type/limitation.type";
import { ParkingLimitationState } from "./parkinglimitation.reducer";

export const PARKINGLIMITATION_GET = "parkinglimitation/get";
export const PARKINGLIMITATION_GET_PAGEABLELIST = "parkinglimitation/get/pageablelist";
export const PARKINGLIMITATION_SEARCH_PAGEABLELIST = "parkinglimitation/search/pageablelist";
export const PARKINGLIMITATION_GET_PARKPERMIT_PAGEABLELIST = "parkinglimitation/get/parkpermit/pageablelist";
export const PARKINGLIMITATION_PUT = "parkinglimitation/put";

export interface ParkingLimitationAction {
  type:
  typeof PARKINGLIMITATION_GET |
  typeof PARKINGLIMITATION_GET_PAGEABLELIST | 
  typeof PARKINGLIMITATION_SEARCH_PAGEABLELIST |
  typeof PARKINGLIMITATION_GET_PARKPERMIT_PAGEABLELIST |
  typeof PARKINGLIMITATION_PUT
  status: StateStatus;
  pageableListCount: number;
  pageableListPageNumber: number;
  pageableListPageSize: number;
  pageableListContent: Array<any[]>;
  pageableListSort: string;
  pageableListDirection: Direction;
  pageableListKeyword: string;
  parkingLimitationUuid: string;
  parkingLimitationInitialUnlimitedParking: number;
  parkingLimitationRemainingUnlimitedParking: number;
  parkingLimitationConcurrentUnlimitedParking: number;
  parkingLimitationInitialMinuteParking: number;
  parkingLimitationRemainingMinuteParking: number;
  parkingLimitationConcurrentMinuteParking: number;
  parkingLimitationConcurrentTotalParking: number;
  parkingLimitationIsEnabled: boolean;
  parkingLimitationNotes: string;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  abstractParkingAreaAddress: string;
  userClientPublicUuid: string;
  userClientPublicUsername: string;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type ParkingLimitationThunkAction = ThunkAction<Promise<void>, ParkingLimitationState, any, ParkingLimitationAction>;

export function getParkingLimitation(parkingLimitationUuid: string): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_GET,
        status: LOADING_STATUS
      });

      v1WebParkingLimitationService.getParkingLimitation(parkingLimitationUuid).then((response: AxiosResponse) => {
        let v1WebParkingLimitationDto = response.data as V1WebParkingLimitationDTO;

        dispatch({
          type: PARKINGLIMITATION_GET,
          status: SUCCESS_STATUS,
          parkingLimitationUuid: v1WebParkingLimitationDto.parkingLimitationUuid,
          parkingLimitationInitialUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationInitialUnlimitedParking,
          parkingLimitationRemainingUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationRemainingUnlimitedParking,
          parkingLimitationConcurrentUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationConcurrentUnlimitedParking,
          parkingLimitationInitialMinuteParking: v1WebParkingLimitationDto.parkingLimitationInitialMinuteParking,
          parkingLimitationRemainingMinuteParking: v1WebParkingLimitationDto.parkingLimitationRemainingMinuteParking,
          parkingLimitationConcurrentMinuteParking: v1WebParkingLimitationDto.parkingLimitationConcurrentMinuteParking,
          parkingLimitationIsEnabled: v1WebParkingLimitationDto.parkingLimitationIsEnabled,
          parkingLimitationNotes: v1WebParkingLimitationDto.parkingLimitationNotes,
          abstractParkingAreaUuid: v1WebParkingLimitationDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkingLimitationDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkingLimitationDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkingLimitationDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkingLimitationDto.abstractParkingAreaCity,
          userClientPublicUuid: v1WebParkingLimitationDto.userClientPublicUuid,
          userClientPublicUsername: v1WebParkingLimitationDto.userClientPublicUsername,
        })
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function getParkingLimitationAsUserClient(parkingLimitationUuid: string): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_GET,
        status: LOADING_STATUS
      });

      v1WebParkingLimitationService.getParkingLimitationAsUserClient(parkingLimitationUuid).then((response: AxiosResponse) => {
        let v1WebParkingLimitationDto = response.data as V1WebParkingLimitationDTO;

        dispatch({
          type: PARKINGLIMITATION_GET,
          status: SUCCESS_STATUS,
          parkingLimitationUuid: v1WebParkingLimitationDto.parkingLimitationUuid,
          parkingLimitationInitialUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationInitialUnlimitedParking,
          parkingLimitationRemainingUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationRemainingUnlimitedParking,
          parkingLimitationConcurrentUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationConcurrentUnlimitedParking,
          parkingLimitationInitialMinuteParking: v1WebParkingLimitationDto.parkingLimitationInitialMinuteParking,
          parkingLimitationRemainingMinuteParking: v1WebParkingLimitationDto.parkingLimitationRemainingMinuteParking,
          parkingLimitationConcurrentMinuteParking: v1WebParkingLimitationDto.parkingLimitationConcurrentMinuteParking,
          parkingLimitationIsEnabled: v1WebParkingLimitationDto.parkingLimitationIsEnabled,
          parkingLimitationNotes: v1WebParkingLimitationDto.parkingLimitationNotes,
          abstractParkingAreaUuid: v1WebParkingLimitationDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkingLimitationDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkingLimitationDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkingLimitationDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkingLimitationDto.abstractParkingAreaCity,
          userClientPublicUuid: v1WebParkingLimitationDto.userClientPublicUuid,
          userClientPublicUsername: v1WebParkingLimitationDto.userClientPublicUsername,
        })
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function getParkingLimitationPageableListForUserClientAsUserClient(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkingLimitationService.getParkingLimitationPageableListForUserClientAsUserClient(page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkingLimitationListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.parkingLimitationUuid, value.parkingLimitationNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.userClientPublicUsername])
        });

        dispatch({
          type: PARKINGLIMITATION_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function searchParkingLimitationPageableListForUserClientAsUserClient(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebParkingLimitationService.searchParkingLimitationPageableListForUserClientAsUserClient(v1keywordDto, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkingLimitationListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.parkingLimitationUuid, value.parkingLimitationNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.userClientPublicUsername])
        });

        dispatch({
          type: PARKINGLIMITATION_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function getParkingLimitationParkPermitPageableListAsUserClient(parkingLimitationUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKPERMIT_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkingLimitationService.getParkingLimitationParkPermitPageableListAsUserClient(parkingLimitationUuid, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId, value.parkPermitIsEnabled])
        });

        dispatch({
          type: PARKPERMIT_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKPERMIT_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function putParkingLimitation(parkingLimitationUuid: string, parkingLimitationUnlimitedParkingLimitationType: LimitationType, parkingLimitationInitialUnlimitedParking: number,  parkingLimitationRemainingUnlimitedParking: number, parkingLimitationConcurrentUnlimitedParking: number, parkingLimitationAddRemainingUnlimitedParking: number | null, parkingLimitationMinuteParkingLimitationType: LimitationType, parkingLimitationInitialMinuteParkingV1DateTimeDto: V1DateTimeDTO, parkingLimitationRemainingMinuteParkingV1DateTimeDto: V1DateTimeDTO, parkingLimitationConcurrentMinuteParking: number, parkingLimitationConcurrentTotalParking: number,parkingLimitationNotes: string, parkingLimitationIsEnabled: boolean): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_PUT,
        status: LOADING_STATUS
      });

      let v1WebParkingLimitationDto: V1WebParkingLimitationDTO = {
        parkingLimitationUuid: parkingLimitationUuid,
        parkingLimitationUnlimitedParkingLimitationType: parkingLimitationUnlimitedParkingLimitationType,
        parkingLimitationInitialUnlimitedParking: parkingLimitationInitialUnlimitedParking,
        parkingLimitationRemainingUnlimitedParking: parkingLimitationRemainingUnlimitedParking,
        parkingLimitationConcurrentUnlimitedParking: parkingLimitationConcurrentUnlimitedParking,
        parkingLimitationAddRemainingUnlimitedParking: parkingLimitationAddRemainingUnlimitedParking,
        parkingLimitationMinuteParkingLimitationType: parkingLimitationMinuteParkingLimitationType,
        parkingLimitationInitialMinuteParkingV1DateTimeDto: parkingLimitationInitialMinuteParkingV1DateTimeDto,
        parkingLimitationInitialMinuteParking: 0,
        parkingLimitationRemainingMinuteParkingV1DateTimeDto: parkingLimitationRemainingMinuteParkingV1DateTimeDto,
        parkingLimitationRemainingMinuteParking: 0,
        parkingLimitationConcurrentMinuteParking: parkingLimitationConcurrentMinuteParking,
        parkingLimitationIsEnabled: parkingLimitationIsEnabled,
        parkingLimitationNotes: parkingLimitationNotes,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        userClientPublicUuid: "",
        userClientPublicUsername: "",
      }

      v1WebParkingLimitationService.putParkingLimitation(v1WebParkingLimitationDto).then((response: AxiosResponse) => {
        let v1WebParkingLimitationDto = response.data as V1WebParkingLimitationDTO;

        dispatch({
          type: PARKINGLIMITATION_PUT,
          status: SUCCESS_STATUS,
          parkingLimitationUuid: v1WebParkingLimitationDto.parkingLimitationUuid,
          parkingLimitationInitialUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationInitialUnlimitedParking,
          parkingLimitationRemainingUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationRemainingUnlimitedParking,
          parkingLimitationConcurrentUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationConcurrentUnlimitedParking,
          parkingLimitationInitialMinuteParking: v1WebParkingLimitationDto.parkingLimitationInitialMinuteParking,
          parkingLimitationRemainingMinuteParking: v1WebParkingLimitationDto.parkingLimitationRemainingMinuteParking,
          parkingLimitationConcurrentMinuteParking: v1WebParkingLimitationDto.parkingLimitationConcurrentMinuteParking,
          parkingLimitationIsEnabled: v1WebParkingLimitationDto.parkingLimitationIsEnabled,
          parkingLimitationNotes: v1WebParkingLimitationDto.parkingLimitationNotes,
          abstractParkingAreaUuid: v1WebParkingLimitationDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkingLimitationDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkingLimitationDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkingLimitationDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkingLimitationDto.abstractParkingAreaCity,
          userClientPublicUuid: v1WebParkingLimitationDto.userClientPublicUuid,
          userClientPublicUsername: v1WebParkingLimitationDto.userClientPublicUsername,
        })
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function putParkingLimitationAsUserClient(parkingLimitationUuid: string, parkingLimitationUnlimitedParkingLimitationType: LimitationType | null, parkingLimitationInitialUnlimitedParking: number,  parkingLimitationRemainingUnlimitedParking: number, parkingLimitationConcurrentUnlimitedParking: number, parkingLimitationAddRemainingUnlimitedParking: number | null, parkingLimitationMinuteParkingLimitationType: LimitationType | null, parkingLimitationInitialMinuteParkingV1DateTimeDto: V1DateTimeDTO | null, parkingLimitationRemainingMinuteParkingV1DateTimeDto: V1DateTimeDTO | null, parkingLimitationConcurrentMinuteParking: number, parkingLimitationIsEnabled: boolean, parkingLimitationNotes: string): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_PUT,
        status: LOADING_STATUS
      });

      let v1WebParkingLimitationDto: V1WebParkingLimitationDTO = {
        parkingLimitationUuid: parkingLimitationUuid,
        parkingLimitationUnlimitedParkingLimitationType: parkingLimitationUnlimitedParkingLimitationType,
        parkingLimitationInitialUnlimitedParking: parkingLimitationInitialUnlimitedParking,
        parkingLimitationRemainingUnlimitedParking: parkingLimitationRemainingUnlimitedParking,
        parkingLimitationConcurrentUnlimitedParking: parkingLimitationConcurrentUnlimitedParking,
        parkingLimitationAddRemainingUnlimitedParking: parkingLimitationAddRemainingUnlimitedParking,
        parkingLimitationMinuteParkingLimitationType: parkingLimitationMinuteParkingLimitationType,
        parkingLimitationInitialMinuteParkingV1DateTimeDto: parkingLimitationInitialMinuteParkingV1DateTimeDto,
        parkingLimitationInitialMinuteParking: 0,
        parkingLimitationRemainingMinuteParkingV1DateTimeDto: parkingLimitationRemainingMinuteParkingV1DateTimeDto,
        parkingLimitationRemainingMinuteParking: 0,
        parkingLimitationConcurrentMinuteParking: parkingLimitationConcurrentMinuteParking,
        parkingLimitationIsEnabled: parkingLimitationIsEnabled,
        parkingLimitationNotes: parkingLimitationNotes,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        userClientPublicUuid: "",
        userClientPublicUsername: "",
      }

      v1WebParkingLimitationService.putParkingLimitationAsUserClient(v1WebParkingLimitationDto).then((response: AxiosResponse) => {
        let v1WebParkingLimitationDto = response.data as V1WebParkingLimitationDTO;

        dispatch({
          type: PARKINGLIMITATION_PUT,
          status: SUCCESS_STATUS,
          parkingLimitationUuid: v1WebParkingLimitationDto.parkingLimitationUuid,
          parkingLimitationInitialUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationInitialUnlimitedParking,
          parkingLimitationRemainingUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationRemainingUnlimitedParking,
          parkingLimitationConcurrentUnlimitedParking: v1WebParkingLimitationDto.parkingLimitationConcurrentUnlimitedParking,
          parkingLimitationInitialMinuteParking: v1WebParkingLimitationDto.parkingLimitationInitialMinuteParking,
          parkingLimitationRemainingMinuteParking: v1WebParkingLimitationDto.parkingLimitationRemainingMinuteParking,
          parkingLimitationConcurrentMinuteParking: v1WebParkingLimitationDto.parkingLimitationConcurrentMinuteParking,
          parkingLimitationIsEnabled: v1WebParkingLimitationDto.parkingLimitationIsEnabled,
          parkingLimitationNotes: v1WebParkingLimitationDto.parkingLimitationNotes,
          abstractParkingAreaUuid: v1WebParkingLimitationDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebParkingLimitationDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebParkingLimitationDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebParkingLimitationDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebParkingLimitationDto.abstractParkingAreaCity,
          userClientPublicUuid: v1WebParkingLimitationDto.userClientPublicUuid,
          userClientPublicUsername: v1WebParkingLimitationDto.userClientPublicUsername,
        })
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

/*
export function parkingLimitationGetParkPermitPageableList(parkingLimitationUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingLimitationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGLIMITATION_GET_PARKPERMIT_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkingLimitationService.getParkingLimitationParkPermit(parkingLimitationUuid, page).then((response: AxiosResponse) => {
        let parkingLimitationPageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        parkingLimitationPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkingLimitationUuid, value.parkingLimitationValidFrom, value.parkingLimitationValidTo, value.parkingLimitationNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId])
        });

        dispatch({
          type: PARKINGLIMITATION_GET_PARKPERMIT_PAGEABLELIST,
          status: SUCCESS_STATUS,
          parkingLimitationPageableListCount: parkingLimitationPageableList.totalElements,
          parkingLimitationPageableListPageNumber: parkingLimitationPageableList.pageable.pageNumber,
          parkingLimitationPageableListPageSize: parkingLimitationPageableList.pageable.pageSize,
          parkingLimitationPageableListContent: pageableListContent,
          parkingLimitationPageableListSort: sort,
          parkingLimitationPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGLIMITATION_GET_PARKPERMIT_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}*/
