import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { UserType } from "type/user.type";
import { UserAction, USER_GET, USER_GET_AUTHORITY_PAGEABLELIST, USER_GET_PAGEABLELIST, USER_GET_TOKEN_PAGEABLELIST, USER_PUT, USER_SEARCH_PAGEABLELIST, USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST, USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST, USER_PUT_AUTHORITIES, USER_GET_USERCLIENT_PAGEABLELIST, USER_PUT_USER_CLIENT } from "./user.action";

export type UserState = {
  status: StateStatus;
  pageableListCount: number;
  pageableListPageNumber: number;
  pageableListPageSize: number;
  pageableListContent: Array<any[]>;
  pageableListSort: string;
  pageableListDirection: Direction;
  pageableListKeyword: string;
  abstractUserUuid: string;
  abstractUserUsername: string;
  abstractUserFirstName: string;
  abstractUserLastName: string;
  abstractUserAddress: string;
  abstractUserZip: string;
  abstractUserCity: string;
  abstractUserEmail: string;
  abstractUserPhone: string;
  abstractUserUserType: UserType | null;
  abstractUserNotes: string;
  abstractUserIsEnabled: boolean;
  abstractUserIsAccountNonExpired: boolean;
  abstractUserIsAccountNonLocked: boolean;
  abstractUserIsCredentialsNonExpired: boolean;
  abstractUserCredentialsDate: Date | null;
  abstractUserClientHasTextMessageNotificationService: boolean;
  abstractUserClientHasEmailNotificationService: boolean;
  customerUuid: string;
  customerCompanyName: string;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  userGuardTicketCount: number;
  userGuardGuardId: number;
  authorityStatus: StateStatus
  authorityPageableListCount: number
  authorityPageableListPageNumber: number
  authorityPageableListPageSize: number
  authorityPageableListContent: Array<any[]>
  authorityPageableListSort: string
  authorityPageableListDirection: Direction
  tokenStatus: StateStatus
  tokenPageableListCount: number
  tokenPageableListPageNumber: number
  tokenPageableListPageSize: number
  tokenPageableListContent: Array<any[]>
  tokenPageableListSort: string
  tokenPageableListDirection: Direction
  userOperatorParkingLimitationStatus: StateStatus
  userOperatorParkingLimitationPageableListCount: number
  userOperatorParkingLimitationPageableListPageNumber: number
  userOperatorParkingLimitationPageableListPageSize: number
  userOperatorParkingLimitationPageableListContent: Array<any[]>
  userOperatorParkingLimitationPageableListSort: string
  userOperatorParkingLimitationPageableListDirection: Direction
  userOperatorParkingLimitationPageableListKeyword: string

  userClientStateStatus: StateStatus

  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: UserState = {
  status: IDLE_STATUS,
  pageableListCount: 0,
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "username",
  pageableListDirection: Direction.ASC,
  pageableListKeyword: "",
  abstractUserUuid: "",
  abstractUserUsername: "",
  abstractUserFirstName: "",
  abstractUserLastName: "",
  abstractUserAddress: "",
  abstractUserZip: "",
  abstractUserCity: "",
  abstractUserEmail: "",
  abstractUserPhone: "",
  abstractUserUserType: null,
  abstractUserNotes: "",
  abstractUserIsEnabled: false,
  abstractUserIsAccountNonExpired: false,
  abstractUserIsAccountNonLocked: false,
  abstractUserIsCredentialsNonExpired: false,
  abstractUserCredentialsDate: null,
  abstractUserClientHasTextMessageNotificationService: false,
  abstractUserClientHasEmailNotificationService: false,
  customerUuid: "",
  customerCompanyName: "",
  abstractParkingAreaUuid: "",
  abstractParkingAreaParkingAreaCode: "",
  userGuardTicketCount: 0,
  userGuardGuardId: 0,
  authorityStatus: IDLE_STATUS,
  authorityPageableListCount: 0,
  authorityPageableListPageNumber: 0,
  authorityPageableListPageSize: 5,
  authorityPageableListContent: Array<any[]>(),
  authorityPageableListSort: "name",
  authorityPageableListDirection: Direction.DESC,
  tokenStatus: IDLE_STATUS,
  tokenPageableListCount: 0,
  tokenPageableListPageNumber: 0,
  tokenPageableListPageSize: 5,
  tokenPageableListContent: Array<any[]>(),
  tokenPageableListSort: "createdDate",
  tokenPageableListDirection: Direction.DESC,
  userOperatorParkingLimitationStatus: IDLE_STATUS,
  userOperatorParkingLimitationPageableListCount: 0,
  userOperatorParkingLimitationPageableListPageNumber: 0,
  userOperatorParkingLimitationPageableListPageSize: 5,
  userOperatorParkingLimitationPageableListContent: Array<any[]>(),
  userOperatorParkingLimitationPageableListSort: "createdDate",
  userOperatorParkingLimitationPageableListDirection: Direction.DESC,
  userOperatorParkingLimitationPageableListKeyword: "",
  userClientStateStatus: IDLE_STATUS,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function userReducer(state: UserState = initialState, action: UserAction): UserState {
  switch (state.status) {
    case IDLE_STATUS: {
      return userIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return userLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return userSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return userFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default userReducer;

function userIdleReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case USER_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_USERCLIENT_PAGEABLELIST: {
      return {
        ...state,
        userClientStateStatus: action.status
      }
    }
    case USER_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_AUTHORITY_PAGEABLELIST: {
      return {
        ...state,
        authorityStatus: action.status
      }
    }
    case USER_GET_TOKEN_PAGEABLELIST: {
      return {
        ...state,
        tokenStatus: action.status
      }
    }
    case USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_PUT_AUTHORITIES: {
      return {
        ...state,
        authorityStatus: action.status
      }
    }
    case USER_PUT_USER_CLIENT: {
      return {
        ...state,
        userClientStateStatus: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function userLoadingReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case USER_GET: {
      return {
        ...state,
        status: action.status,
        abstractUserUuid: action.abstractUserUuid,
        abstractUserUsername: action.abstractUserUsername,
        abstractUserFirstName: action.abstractUserFirstName,
        abstractUserLastName: action.abstractUserLastName,
        abstractUserAddress: action.abstractUserAddress,
        abstractUserZip: action.abstractUserZip,
        abstractUserCity: action.abstractUserCity,
        abstractUserEmail: action.abstractUserEmail,
        abstractUserPhone: action.abstractUserPhone,
        abstractUserUserType: action.abstractUserUserType,
        abstractUserNotes: action.abstractUserNotes,
        abstractUserIsEnabled: action.abstractUserIsEnabled,
        abstractUserIsAccountNonExpired: action.abstractUserIsAccountNonExpired,
        abstractUserIsAccountNonLocked: action.abstractUserIsAccountNonLocked,
        abstractUserIsCredentialsNonExpired: action.abstractUserIsCredentialsNonExpired,
        abstractUserCredentialsDate: action.abstractUserCredentialsDate,
        abstractUserClientHasTextMessageNotificationService: action.abstractUserClientHasTextMessageNotificationService,
        abstractUserClientHasEmailNotificationService: action.abstractUserClientHasEmailNotificationService,
        customerUuid: action.customerUuid,
        customerCompanyName: action.customerCompanyName,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        userGuardTicketCount: action.userGuardTicketCount,
        userGuardGuardId: action.userGuardGuardId,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        abstractUserUuid: "",
        abstractUserUsername: "",
        abstractUserFirstName: "",
        abstractUserLastName: "",
        abstractUserAddress: "",
        abstractUserZip: "",
        abstractUserCity: "",
        abstractUserEmail: "",
        abstractUserPhone: "",
        abstractUserUserType: null,
        abstractUserNotes: "",
        abstractUserIsEnabled: false,
        abstractUserIsAccountNonExpired: false,
        abstractUserIsAccountNonLocked: false,
        abstractUserIsCredentialsNonExpired: false,
        abstractUserCredentialsDate: null,
        abstractUserClientHasTextMessageNotificationService: false,
        abstractUserClientHasEmailNotificationService: false,
        customerUuid: "",
        customerCompanyName: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        userGuardTicketCount: 0,
        userGuardGuardId: 0,
        authorityStatus: IDLE_STATUS,
        authorityPageableListCount: 0,
        authorityPageableListPageNumber: 0,
        authorityPageableListPageSize: 5,
        authorityPageableListContent: Array<any[]>(),
        authorityPageableListSort: "name",
        authorityPageableListDirection: Direction.ASC,
        tokenStatus: IDLE_STATUS,
        tokenPageableListCount: 0,
        tokenPageableListPageNumber: 0,
        tokenPageableListPageSize: 5,
        tokenPageableListContent: Array<any[]>(),
        tokenPageableListSort: "createdDate",
        tokenPageableListDirection: Direction.ASC,
        userOperatorParkingLimitationStatus: IDLE_STATUS,
        userOperatorParkingLimitationPageableListCount: 0,
        userOperatorParkingLimitationPageableListPageNumber: 0,
        userOperatorParkingLimitationPageableListPageSize: 0,
        userOperatorParkingLimitationPageableListContent: Array<any[]>(),
        userOperatorParkingLimitationPageableListSort: "createdDate",
        userOperatorParkingLimitationPageableListDirection: Direction.DESC,
        userOperatorParkingLimitationPageableListKeyword: "",
        userClientStateStatus: IDLE_STATUS,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_GET_USERCLIENT_PAGEABLELIST: {
      return {
        ...state,
        userClientStateStatus: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        abstractUserUuid: "",
        abstractUserUsername: "",
        abstractUserFirstName: "",
        abstractUserLastName: "",
        abstractUserAddress: "",
        abstractUserZip: "",
        abstractUserCity: "",
        abstractUserEmail: "",
        abstractUserPhone: "",
        abstractUserUserType: null,
        abstractUserNotes: "",
        abstractUserIsEnabled: false,
        abstractUserIsAccountNonExpired: false,
        abstractUserIsAccountNonLocked: false,
        abstractUserIsCredentialsNonExpired: false,
        abstractUserCredentialsDate: null,
        abstractUserClientHasTextMessageNotificationService: false,
        abstractUserClientHasEmailNotificationService: false,
        customerUuid: "",
        customerCompanyName: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        userGuardTicketCount: 0,
        userGuardGuardId: 0,
        authorityStatus: IDLE_STATUS,
        authorityPageableListCount: 0,
        authorityPageableListPageNumber: 0,
        authorityPageableListPageSize: 5,
        authorityPageableListContent: Array<any[]>(),
        authorityPageableListSort: "name",
        authorityPageableListDirection: Direction.ASC,
        tokenStatus: IDLE_STATUS,
        tokenPageableListCount: 0,
        tokenPageableListPageNumber: 0,
        tokenPageableListPageSize: 5,
        tokenPageableListContent: Array<any[]>(),
        tokenPageableListSort: "createdDate",
        tokenPageableListDirection: Direction.ASC,
        userOperatorParkingLimitationStatus: IDLE_STATUS,
        userOperatorParkingLimitationPageableListCount: 0,
        userOperatorParkingLimitationPageableListPageNumber: 0,
        userOperatorParkingLimitationPageableListPageSize: 0,
        userOperatorParkingLimitationPageableListContent: Array<any[]>(),
        userOperatorParkingLimitationPageableListSort: "createdDate",
        userOperatorParkingLimitationPageableListDirection: Direction.DESC,
        userOperatorParkingLimitationPageableListKeyword: "",
        userClientStateStatus: IDLE_STATUS,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: 0,
        pageableListPageNumber: 0,
        pageableListPageSize: 5,
        pageableListContent: Array<any[]>(),
        pageableListSort: "username",
        pageableListDirection: Direction.ASC,
        pageableListKeyword: "",
        abstractUserUuid: "",
        abstractUserUsername: "",
        abstractUserFirstName: "",
        abstractUserLastName: "",
        abstractUserAddress: "",
        abstractUserZip: "",
        abstractUserCity: "",
        abstractUserEmail: "",
        abstractUserPhone: "",
        abstractUserUserType: null,
        abstractUserNotes: "",
        abstractUserIsEnabled: false,
        abstractUserIsAccountNonExpired: false,
        abstractUserIsAccountNonLocked: false,
        abstractUserIsCredentialsNonExpired: false,
        abstractUserCredentialsDate: null,
        abstractUserClientHasTextMessageNotificationService: false,
        abstractUserClientHasEmailNotificationService: false,
        customerUuid: "",
        customerCompanyName: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        userGuardTicketCount: 0,
        userGuardGuardId: 0,
        authorityStatus: IDLE_STATUS,
        authorityPageableListCount: 0,
        authorityPageableListPageNumber: 0,
        authorityPageableListPageSize: 5,
        authorityPageableListContent: Array<any[]>(),
        authorityPageableListSort: "name",
        authorityPageableListDirection: Direction.ASC,
        tokenStatus: IDLE_STATUS,
        tokenPageableListCount: 0,
        tokenPageableListPageNumber: 0,
        tokenPageableListPageSize: 5,
        tokenPageableListContent: Array<any[]>(),
        tokenPageableListSort: "createdDate",
        tokenPageableListDirection: Direction.ASC,
        userOperatorParkingLimitationStatus: IDLE_STATUS,
        userOperatorParkingLimitationPageableListCount: action.userOperatorParkingLimitationPageableListCount,
        userOperatorParkingLimitationPageableListPageNumber: action.userOperatorParkingLimitationPageableListPageNumber,
        userOperatorParkingLimitationPageableListPageSize: action.userOperatorParkingLimitationPageableListPageSize,
        userOperatorParkingLimitationPageableListContent: action.userOperatorParkingLimitationPageableListContent,
        userOperatorParkingLimitationPageableListSort: action.userOperatorParkingLimitationPageableListSort,
        userOperatorParkingLimitationPageableListDirection: action.userOperatorParkingLimitationPageableListDirection,
        userOperatorParkingLimitationPageableListKeyword: action.userOperatorParkingLimitationPageableListKeyword,
        userClientStateStatus: IDLE_STATUS,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_GET_AUTHORITY_PAGEABLELIST: {
      return {
        ...state,
        authorityStatus: action.status,
        authorityPageableListCount: action.authorityPageableListCount,
        authorityPageableListPageNumber: action.authorityPageableListPageNumber,
        authorityPageableListPageSize: action.authorityPageableListPageSize,
        authorityPageableListContent: action.authorityPageableListContent,
        authorityPageableListSort: action.authorityPageableListSort,
        authorityPageableListDirection: action.authorityPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_GET_TOKEN_PAGEABLELIST: {
      return {
        ...state,
        tokenStatus: action.status,
        tokenPageableListCount: action.tokenPageableListCount,
        tokenPageableListPageNumber: action.tokenPageableListPageNumber,
        tokenPageableListPageSize: action.tokenPageableListPageSize,
        tokenPageableListContent: action.tokenPageableListContent,
        tokenPageableListSort: action.tokenPageableListSort,
        tokenPageableListDirection: action.tokenPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: 0,
        pageableListPageNumber: 0,
        pageableListPageSize: 5,
        pageableListContent: Array<any[]>(),
        pageableListSort: "username",
        pageableListDirection: Direction.ASC,
        pageableListKeyword: "",
        abstractUserUuid: "",
        abstractUserUsername: "",
        abstractUserFirstName: "",
        abstractUserLastName: "",
        abstractUserAddress: "",
        abstractUserZip: "",
        abstractUserCity: "",
        abstractUserEmail: "",
        abstractUserPhone: "",
        abstractUserUserType: null,
        abstractUserNotes: "",
        abstractUserIsEnabled: false,
        abstractUserIsAccountNonExpired: false,
        abstractUserIsAccountNonLocked: false,
        abstractUserIsCredentialsNonExpired: false,
        abstractUserCredentialsDate: null,
        abstractUserClientHasTextMessageNotificationService: false,
        abstractUserClientHasEmailNotificationService: false,
        customerUuid: "",
        customerCompanyName: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        userGuardTicketCount: 0,
        userGuardGuardId: 0,
        authorityStatus: IDLE_STATUS,
        authorityPageableListCount: 0,
        authorityPageableListPageNumber: 0,
        authorityPageableListPageSize: 5,
        authorityPageableListContent: Array<any[]>(),
        authorityPageableListSort: "name",
        authorityPageableListDirection: Direction.ASC,
        tokenStatus: IDLE_STATUS,
        tokenPageableListCount: 0,
        tokenPageableListPageNumber: 0,
        tokenPageableListPageSize: 5,
        tokenPageableListContent: Array<any[]>(),
        tokenPageableListSort: "createdDate",
        tokenPageableListDirection: Direction.ASC,
        userOperatorParkingLimitationStatus: IDLE_STATUS,
        userOperatorParkingLimitationPageableListCount: action.userOperatorParkingLimitationPageableListCount,
        userOperatorParkingLimitationPageableListPageNumber: action.userOperatorParkingLimitationPageableListPageNumber,
        userOperatorParkingLimitationPageableListPageSize: action.userOperatorParkingLimitationPageableListPageSize,
        userOperatorParkingLimitationPageableListContent: action.userOperatorParkingLimitationPageableListContent,
        userOperatorParkingLimitationPageableListSort: action.userOperatorParkingLimitationPageableListSort,
        userOperatorParkingLimitationPageableListDirection: action.userOperatorParkingLimitationPageableListDirection,
        userOperatorParkingLimitationPageableListKeyword: "",
        userClientStateStatus: IDLE_STATUS,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_PUT: {
      return {
        ...state,
        status: action.status,
        abstractUserUuid: action.abstractUserUuid,
        abstractUserUsername: action.abstractUserUsername,
        abstractUserFirstName: action.abstractUserFirstName,
        abstractUserLastName: action.abstractUserLastName,
        abstractUserAddress: action.abstractUserAddress,
        abstractUserZip: action.abstractUserZip,
        abstractUserCity: action.abstractUserCity,
        abstractUserEmail: action.abstractUserEmail,
        abstractUserPhone: action.abstractUserPhone,
        abstractUserUserType: action.abstractUserUserType,
        abstractUserNotes: action.abstractUserNotes,
        abstractUserIsEnabled: action.abstractUserIsEnabled,
        abstractUserIsAccountNonExpired: action.abstractUserIsAccountNonExpired,
        abstractUserIsAccountNonLocked: action.abstractUserIsAccountNonLocked,
        abstractUserIsCredentialsNonExpired: action.abstractUserIsCredentialsNonExpired,
        abstractUserCredentialsDate: action.abstractUserCredentialsDate,
        abstractUserClientHasTextMessageNotificationService: action.abstractUserClientHasTextMessageNotificationService,
        abstractUserClientHasEmailNotificationService: action.abstractUserClientHasEmailNotificationService,
        customerUuid: action.customerUuid,
        customerCompanyName: action.customerCompanyName,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        userGuardTicketCount: action.userGuardTicketCount,
        userGuardGuardId: action.userGuardGuardId,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_PUT_AUTHORITIES: {
      return {
        ...state,
        authorityStatus: action.status,
        authorityPageableListCount: action.authorityPageableListCount,
        authorityPageableListPageNumber: action.authorityPageableListPageNumber,
        authorityPageableListPageSize: action.authorityPageableListPageSize,
        authorityPageableListContent: action.authorityPageableListContent,
        authorityPageableListSort: action.authorityPageableListSort,
        authorityPageableListDirection: action.authorityPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case USER_PUT_USER_CLIENT: {
      return {
        ...state,
        userClientStateStatus: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function userSuccessReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case USER_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_USERCLIENT_PAGEABLELIST: {
      switch (state.userClientStateStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            userClientStateStatus: action.status
          }
        }
      }
    }
    case USER_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_AUTHORITY_PAGEABLELIST: {
      switch (state.authorityStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            authorityStatus: action.status
          }
        }
      }
    }
    case USER_GET_TOKEN_PAGEABLELIST: {
      switch (state.tokenStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            tokenStatus: action.status
          }
        }
      }
    }
    case USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_PUT_AUTHORITIES: {
      switch (state.authorityStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            authorityStatus: action.status
          }
        }
      }
    }
    case USER_PUT_USER_CLIENT: {
      switch (state.userClientStateStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            userClientStateStatus: action.status
          }
        }
      }
    }
    default: {
      return state;
    }
  }
}

function userFailureReducer(state: UserState, action: UserAction): UserState {
  switch (action.type) {
    case USER_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_GET_USERCLIENT_PAGEABLELIST: {
      switch (state.userClientStateStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            userClientStateStatus: action.status
          }
        }
      }
    }
    case USER_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        ...state,
        userOperatorParkingLimitationStatus: action.status
      }
    }
    case USER_GET_AUTHORITY_PAGEABLELIST: {
      return {
        ...state,
        authorityStatus: action.status
      }
    }
    case USER_GET_TOKEN_PAGEABLELIST: {
      return {
        ...state,
        tokenStatus: action.status
      }
    }
    case USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    case USER_PUT_USER_CLIENT: {
      switch (state.userClientStateStatus) {
        case IDLE_STATUS: {
          return userIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return userLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return userFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            userClientStateStatus: action.status
          }
        }
      }
    }
    default: {
      return state;
    }
  }
}