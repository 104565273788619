import { ParkingAreaType } from "type/parkingarea.type";
import V1WebAbstractParkingAreaDTO from "./v1webabstractparkingarea.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebParkingAreaMinuteDTO extends V1WebAbstractParkingAreaDTO {

  parkingAreaMinuteAllowedParkingMinutes!: number;

  constructor() {
    super();
    this.abstractParkingAreaAreaType = ParkingAreaType.MINUTE;
  }
}