import MenuItem from "component/menuitem";

const KioskMenuItems: MenuItem[] = [
  {
    href: "/kiosk/parkpermit",
    icon: "assignment_turned_in",
    title: "Tilladelser"
  }
]

export default KioskMenuItems;