import { NavigateNext } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, Radio, RadioGroup, Switch, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { NavLink } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import { ParkPermitType } from "type/parkpermit.type";
import { AxiosResponse } from "axios";
import v1WebParkPermitService from "service/v1/web/v1webparkpermit.service";
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service";
import v1WebUserService from "service/v1/web/v1webuser.service";
import V1WebParkPermitDTO from "dto/v1/web/v1webparkpermit.dto";
import { getParkPermitPageableListForUserClientAsUserClient, searchParkPermitPageableListForUserClientAsUserClient } from "redux/parkpermit/parkpermit.action";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { da } from "date-fns/locale";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import Response from "dto/response";
import V1WebUserListElementDTO from "dto/v1/web/v1webuserlistelement.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

interface OwnProps {
}

interface DispatchProps {
  getParkPermitPageableListForUserClientAsUserClient: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  searchParkPermitPageableListForUserClientAsUserClient: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  parkPermitStateStatus: StateStatus
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  parkPermitPageableListKeyword: string
  parkPermitErrorCode: number
  parkPermitErrorMessage: string
  parkPermitErrorUuid: string
}

interface FormProps {
  parkPermitFromDate: Date;
  parkPermitFromTime: Date;
  parkPermitToDate: Date;
  parkPermitToTime: Date;
  parkPermitVehicleId: string;
  parkPermitParkPermitType: ParkPermitType;
  parkPermitParkingAreaUuid: string;
  parkPermitParkingAreaCode: string;
  parkPermitIsEnabled: boolean;
  parkPermitNotes: string;
}

type Props = StateProps & OwnProps & DispatchProps

const OperatorParkPermitListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkingAreaLoading, setParkingAreaLoading] = useState(false);
  const [isUserClientLoading, setUserClientLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [parkingAreaOptions, setParkingAreaOptions] = useState(Array<any[]>());
  const [userClientOptions, setUserClientOptions] = useState(Array<any[]>());

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({ mode: 'all' });

  const onSearchFormSubmit: SubmitHandler<StateProps> = (stateProps: StateProps) => {
    if (!isViewLoading && stateProps.parkPermitPageableListKeyword.length > 0) {
      props.searchParkPermitPageableListForUserClientAsUserClient(stateProps.parkPermitPageableListKeyword, 0, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection)
    }
  }

  const {
    handleSubmit: handleCreateFormSubmit,
    control: createFormControl,
    getValues: getCreateFormValues,
    setValue: setCreateFormValue,
    reset: resetCreateForm,
    watch: watchCreateForm,
    setError: setCreateFormError,
    clearErrors: clearCreateFormError
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
      parkPermitFromDate: new Date(),
      parkPermitFromTime: new Date(),
      parkPermitToDate: new Date(),
      parkPermitToTime: new Date(),
      parkPermitIsEnabled: true
    }
  });

  const onCreateFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      let v1WebParkPermitDto = new V1WebParkPermitDTO();

      switch (formProps.parkPermitParkPermitType) {
        case ParkPermitType.EXTENDED: {
          if (formProps.parkPermitFromDate > formProps.parkPermitToDate) {
            setCreateFormError("parkPermitFromDate", { type: "custom", message: "Ugyldig dato" })
            return;
          }

          if (formProps.parkPermitFromDate >= formProps.parkPermitToDate && formProps.parkPermitFromTime > formProps.parkPermitToTime) {
            setCreateFormError("parkPermitFromTime", { type: "custom", message: "Ugyldig tidspunkt" })
            return;
          }

          v1WebParkPermitDto.parkPermitValidFromV1DateTimeDto = {
            month: formProps.parkPermitFromDate.getMonth() + 1,
            date: formProps.parkPermitFromDate.getDate(),
            year: formProps.parkPermitFromDate.getFullYear(),
            hour: formProps.parkPermitFromTime.getHours(),
            minute: formProps.parkPermitFromTime.getMinutes(),
            second: formProps.parkPermitFromTime.getSeconds()
          }
          v1WebParkPermitDto.parkPermitValidToV1DateTimeDto = {
            month: formProps.parkPermitToDate.getMonth() + 1,
            date: formProps.parkPermitToDate.getDate(),
            year: formProps.parkPermitToDate.getFullYear(),
            hour: formProps.parkPermitToTime.getHours(),
            minute: formProps.parkPermitToTime.getMinutes(),
            second: formProps.parkPermitToTime.getSeconds()
          }

          break;
        }
        case ParkPermitType.LIMITED: {
          break;
        }
        case ParkPermitType.UNLIMITED: {
          break;
        }
      }

      v1WebParkPermitDto.parkPermitParkPermitType = formProps.parkPermitParkPermitType;
      v1WebParkPermitDto.vehicleVehicleId = formProps.parkPermitVehicleId;
      v1WebParkPermitDto.abstractParkingAreaUuid = formProps.parkPermitParkingAreaUuid;
      v1WebParkPermitDto.parkPermitNotes = formProps.parkPermitNotes;
      v1WebParkPermitDto.parkPermitIsEnabled = formProps.parkPermitIsEnabled;

      setViewLoading(true);

      v1WebParkPermitService.createParkPermitAsUserClient(v1WebParkPermitDto).then((response: AxiosResponse) => {
        setViewLoading(false);
        alert("Tilladelse oprettet");

        if (props.parkPermitPageableListKeyword.length > 0) {
          props.searchParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListKeyword, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
        } else {
          props.getParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
        }

        setCreateDialogOpen(false);
      }).catch((error) => {
        setViewLoading(false);
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }

  const watchParkPermitRestriction = watchCreateForm("parkPermitParkPermitType");
  const watchParkPermitParkingAreaCode = watchCreateForm("parkPermitParkingAreaCode");

  const handleParkPermitPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.parkPermitPageableListKeyword.length > 0) {
      props.searchParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListKeyword, 0, parseInt(event.target.value), props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
    } else {
      props.getParkPermitPageableListForUserClientAsUserClient(0, parseInt(event.target.value), props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
    }
  };

  const handleParkPermitPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.parkPermitPageableListKeyword.length > 0) {
      props.searchParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListKeyword, newPage, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection)
    } else {
      props.getParkPermitPageableListForUserClientAsUserClient(newPage, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
    }
  }

  const handleParkPermitDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkPermitPageableListDirection === Direction.ASC) {
      if (props.parkPermitPageableListKeyword.length > 0) {
        props.searchParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListKeyword, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.DESC);
      } else {
        props.getParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.parkPermitPageableListKeyword.length > 0) {
        props.searchParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListKeyword, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.ASC);
      } else {
        props.getParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleParkPermitKeywordClear = () => {
    props.getParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
  }

  const toogleCreateDialogOpen = () => {
    resetCreateForm();
    setCreateDialogOpen(!isCreateDialogOpen);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.parkPermitPageableListKeyword.length > 0) {
        props.searchParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListKeyword, props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
      } else {
        props.getParkPermitPageableListForUserClientAsUserClient(props.parkPermitPageableListPageNumber, props.parkPermitPageableListPageSize, props.parkPermitPageableListSort, props.parkPermitPageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkPermitStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("parkPermitPageableListKeyword", props.parkPermitPageableListKeyword);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkPermitStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchParkPermitParkingAreaCode !== undefined && watchParkPermitParkingAreaCode !== null && watchParkPermitParkingAreaCode.trim().length > 0) {
      setParkingAreaLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "areaCode",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getCreateFormValues("parkPermitParkingAreaCode")
      }

      v1WebParkingAreaService.searchParkingAreaPageableListForUserClientAreaAsUserClientArea(v1KeywordDto, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: Array<any[]> = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress])
        });
        setParkingAreaOptions(pageableListContent);
        setParkingAreaLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchParkPermitParkingAreaCode]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });


  const headCells: readonly HeadCell[] = [
    {
      id: "vehicle.vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    },
    {
      id: "abstractArea.areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "abstractArea.address",
      numeric: false,
      disablePadding: false,
      label: "Adresse"
    },
    {
      id: "validFrom",
      numeric: false,
      disablePadding: false,
      label: "Gyldig fra"
    },
    {
      id: "validTo",
      numeric: false,
      disablePadding: false,
      label: "Gyldig til"
    },
    {
      id: "notes",
      numeric: false,
      disablePadding: false,
      label: "Noter"
    },
    {
      id: "abstractUserClient.username",
      numeric: false,
      disablePadding: false,
      label: "Brugernavn"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.parkPermitErrorCode} errorMessage={props.parkPermitErrorMessage} errorUuid={props.parkPermitErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Tilladelser
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="parkPermitPageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => { handleParkPermitKeywordClear() }}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.parkPermitPageableListSort === headCell.id}
                          direction={props.parkPermitPageableListDirection}
                          onClick={handleParkPermitDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.parkPermitPageableListContent === undefined || props.parkPermitPageableListContent.length === 0 ? props.parkPermitPageableListPageSize : props.parkPermitPageableListContent.length} tableColSpan={headCells.length} />
                    </>
                  ) : (
                    <>
                      {props.parkPermitPageableListCount === undefined || props.parkPermitPageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={headCells.length} />
                        </>
                      ) : (
                        <>
                          {props.parkPermitPageableListContent.map(parkpermit => (
                            <TableRow
                              hover
                              key={parkpermit[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkpermit[8]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkpermit[4]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkpermit[5] + ", " + parkpermit[6] + " " + parkpermit[7]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {(parkpermit[1] === undefined || parkpermit[1] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkpermit[1]))}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {(parkpermit[2] === undefined || parkpermit[2] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkpermit[2]))}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkpermit[3]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkpermit[9]}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={parkpermit[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={headCells.length + 1}
                      count={props.parkPermitPageableListCount || 0}
                      rowsPerPage={props.parkPermitPageableListPageSize || 5}
                      page={props.parkPermitPageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                      onPageChange={handleParkPermitPageNumberChange}
                      onRowsPerPageChange={handleParkPermitPageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
      <Dialog
        open={isCreateDialogOpen}
        onClose={toogleCreateDialogOpen}
        fullScreen
      >
        <form onSubmit={handleCreateFormSubmit(onCreateFormSubmit)}>
          <DialogTitle>Opret parkeringstilladelse</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld felterne nedenfor for at oprette en parkeringstilladelse
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Tilladelsestype er påkrævet"
                    }
                  }}
                  control={createFormControl}
                  name="parkPermitParkPermitType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      component="fieldset"
                      error={!!error}
                    >
                      <FormLabel component="legend">Begræsninger</FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        value={field.value || ""}
                      >
                        <FormControlLabel
                          value={ParkPermitType.LIMITED}
                          control={<Radio />}
                          label="Begrænset"
                        />
                        <FormControlLabel
                          value={ParkPermitType.UNLIMITED}
                          control={<Radio />}
                          label="Ubegrænset"
                        />
                        <FormControlLabel
                          value={ParkPermitType.EXTENDED}
                          control={<Radio />}
                          label="Udvidet"
                        />
                      </RadioGroup>
                      <FormHelperText sx={{
                        marginLeft: 0
                      }}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {watchParkPermitRestriction === ParkPermitType.EXTENDED &&
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <FormLabel component="legend">Gyldig fra</FormLabel>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={6}
                      >
                        <Controller
                          name="parkPermitFromDate"
                          control={createFormControl}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider adapterLocale={da} dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Dato"
                                value={value}
                                onChange={onChange}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={6}
                      >
                        <Controller
                          name="parkPermitFromTime"
                          control={createFormControl}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={['hours', 'minutes']}
                                inputFormat="HH:mm"
                                mask="__:__"
                                label="Tidspunkt"
                                value={value}
                                onChange={onChange}
                                onOpen={() => {
                                  setTimeout(() => {
                                    const el = document.activeElement;
                                    if (el) {
                                      (el as HTMLElement).blur();
                                    }
                                  });
                                }}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={2}
                      sx={{
                        marginTop: 2
                      }}
                    >
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <FormLabel component="legend">Gyldig til</FormLabel>
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={6}
                      >
                        <Controller
                          name="parkPermitToDate"
                          control={createFormControl}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider adapterLocale={da} dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Dato"
                                value={value}
                                onChange={onChange}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={6}
                        md={6}
                        xs={6}
                      >
                        <Controller
                          name="parkPermitToTime"
                          control={createFormControl}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <TimePicker
                                ampm={false}
                                openTo="hours"
                                views={['hours', 'minutes']}
                                inputFormat="HH:mm"
                                mask="__:__"
                                label="Tidspunkt"
                                value={value}
                                onChange={onChange}
                                onOpen={() => {
                                  setTimeout(() => {
                                    const el = document.activeElement;
                                    if (el) {
                                      (el as HTMLElement).blur();
                                    }
                                  });
                                }}
                                renderInput={(params) =>
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                  />}
                              />
                            </LocalizationProvider>
                          )}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              }
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  name="parkPermitVehicleId"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Køretøj er påkrævet"
                    },
                    minLength: {
                      value: 2,
                      message: "Køretøj skal være på minimum 2 tegn"
                    },
                    maxLength: {
                      value: 17,
                      message: "Køretøj må ikke overstige 17 tegn"
                    },
                    pattern: {
                      value: /[a-zA-ZæøåöüäÆØÅÖÜÄ0-9 ]+$/,
                      message: "Ugyldigt køretøj"
                    },
                    validate: {
                      value: ((value) => {
                        return new Promise((resolve) => {
                          if (value.trim().length >= 2) {
                            resolve(true);
                          } else {
                            resolve("Ugyldigt køretøj");
                          }
                        });
                      })
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Køretøj"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      onKeyPress={(e) => {
                        if (!e.key.match(/[a-zA-ZæøåöüäÆØÅÖÜÄ0-9 ]+$/)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  name="parkPermitParkingAreaCode"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Parkeringsområde er påkrævet"
                    },
                    validate: {
                      value: ((value) => {
                        return new Promise((resolve) => {
                          if (value !== undefined && value.length >= 1) {
                            if (parkingAreaOptions.find(option => option[1].toLowerCase().trim() === value.toLowerCase().trim())) {
                              setCreateFormValue("parkPermitParkingAreaUuid", parkingAreaOptions[parkingAreaOptions.findIndex(option => option[1].toLowerCase().trim() === value.toLowerCase().trim())][0]);
                              resolve(true);
                            } else {
                              resolve("Ugyldigt parkeringsområde")
                            }
                          }
                        });
                      })
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                      inputValue={value || ""}
                      onChange={(event: any, newValue: string | any[] | null) => {
                        if (newValue !== null) {
                          setCreateFormValue("parkPermitParkingAreaCode", newValue[1]);
                          setCreateFormValue("parkPermitParkingAreaUuid", newValue[0]);
                          clearCreateFormError("parkPermitParkingAreaCode")
                        } else {
                          setCreateFormValue("parkPermitParkingAreaCode", "");
                          setCreateFormValue("parkPermitParkingAreaUuid", "");
                        }
                      }}
                      freeSolo={true}
                      options={parkingAreaOptions}
                      getOptionLabel={(option) => option[1] + " - " + option[2]}
                      isOptionEqualToValue={(option, value) => option[0] === value[0]}
                      noOptionsText={"Ingen resultater"}
                      filterOptions={(x) => x}
                      loading={isParkingAreaLoading}
                      loadingText={"Vent venligst.."}
                      openText={""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Parkeringsområde"
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : "Parkeringsområde tilknyttet tilladelsen"}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isParkingAreaLoading ? <CircularProgress color="inherit" size={20} /> : null}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="parkPermitNotes"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Noter må ikke overstige 256 tegn"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Noter"
                      fullWidth
                      multiline
                      rows={4}
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="parkPermitIsEnabled"
                  control={createFormControl}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel control={
                      <Switch
                        checked={value}
                        onChange={onChange}
                      />
                    } label="Aktiveret" />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleCreateDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Opret
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Fab
        onClick={toogleCreateDialogOpen}
        color="primary"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed"
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkPermitStateStatus: redux.parkPermit.status,
    parkPermitPageableListCount: redux.parkPermit.pageableListCount,
    parkPermitPageableListPageNumber: redux.parkPermit.pageableListPageNumber,
    parkPermitPageableListPageSize: redux.parkPermit.pageableListPageSize,
    parkPermitPageableListContent: redux.parkPermit.pageableListContent,
    parkPermitPageableListSort: redux.parkPermit.pageableListSort,
    parkPermitPageableListDirection: redux.parkPermit.pageableListDirection,
    parkPermitPageableListKeyword: redux.parkPermit.pageableListKeyword,
    parkPermitErrorCode: redux.parkPermit.errorCode,
    parkPermitErrorMessage: redux.parkPermit.errorMessage,
    parkPermitErrorUuid: redux.parkPermit.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    getParkPermitPageableListForUserClientAsUserClient: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(getParkPermitPageableListForUserClientAsUserClient(pageNumber, rowsPerPage, sort, direction))
    },
    searchParkPermitPageableListForUserClientAsUserClient: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(searchParkPermitPageableListForUserClientAsUserClient(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OperatorParkPermitListView);