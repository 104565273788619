import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebPictureListElementDTO from "dto/v1/web/v1webpicturelistelement.dto";
import V1WebTicketDTO from "dto/v1/web/v1webticket.dto";
import V1WebTicketListElementDTO from "dto/v1/web/v1webticketlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebTicketService from "service/v1/web/v1webticket.service";
import { TicketState } from "./ticket.reducer";

export const TICKET_GET = "ticket/get";
export const TICKET_GET_PAGEABLELIST = "ticket/get/pageablelist";
export const TICKET_SEARCH_PAGEABLELIST = "ticket/search/pageablelist";
export const TICKET_GET_PICTURE_LIST = "ticket/get/picture/list";
export const TICKET_PUT = "ticket/put";

export interface TicketAction {
  type:
  typeof TICKET_GET |
  typeof TICKET_GET_PAGEABLELIST |
  typeof TICKET_SEARCH_PAGEABLELIST |
  typeof TICKET_GET_PICTURE_LIST |
  typeof TICKET_PUT
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  ticketStateStatus: StateStatus;
  ticketUuid: string;
  ticketTicketId: string;
  ticketGuardId: number;
  ticketParkingAreaCode: string;
  ticketAddress: string;
  ticketZip: string;
  ticketCity: string;
  ticketVehicleId: string;
  ticketNationality: string;
  ticketObservationStart: Date;
  ticketObservationStop: Date;
  ticketPaymentAmount: number;
  ticketParkingTime: string;
  ticketParkingMeter: string;
  ticketBrand: string;
  ticketViolation: string;
  ticketNotes: string;
  ticketIsPayed: boolean;
  ticketIsEnabled: boolean;
  ticketIsPrinted: boolean;
  userGuardUuid: string;
  userGuardUsername: string;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  vehicleUuid: string;
  vehicleVehicleId: string;
  pictureStateStatus: StateStatus;
  pictureListContent: Array<any[]>
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type TicketThunkAction = ThunkAction<Promise<void>, TicketState, any, TicketAction>;

export function ticketGet(ticketUuid: string): TicketThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: TICKET_GET,
        status: LOADING_STATUS
      });

      v1WebTicketService.getTicket(ticketUuid).then((response: AxiosResponse) => {
        let v1WebTicketDto = response.data as V1WebTicketDTO;

        dispatch({
          type: TICKET_GET,
          status: SUCCESS_STATUS,
          ticketUuid: v1WebTicketDto.ticketUuid,
          ticketTicketId: v1WebTicketDto.ticketTicketId,
          ticketGuardId: v1WebTicketDto.ticketGuardId,
          ticketParkingAreaCode: v1WebTicketDto.ticketParkingAreaCode,
          ticketAddress: v1WebTicketDto.ticketAddress,
          ticketZip: v1WebTicketDto.ticketZip,
          ticketCity: v1WebTicketDto.ticketCity,
          ticketVehicleId: v1WebTicketDto.ticketVehicleId,
          ticketNationality: v1WebTicketDto.ticketNationality,
          ticketObservationStart: v1WebTicketDto.ticketObservationStart,
          ticketObservationStop: v1WebTicketDto.ticketObservationStop,
          ticketPaymentAmount: v1WebTicketDto.ticketPaymentAmount,
          ticketParkingTime: v1WebTicketDto.ticketParkingTime,
          ticketParkingMeter: v1WebTicketDto.ticketParkingMeter,
          ticketBrand: v1WebTicketDto.ticketBrand,
          ticketViolation: v1WebTicketDto.ticketViolation,
          ticketNotes: v1WebTicketDto.ticketNotes,
          ticketIsPayed: v1WebTicketDto.ticketIsPayed,
          ticketIsEnabled: v1WebTicketDto.ticketIsEnabled,
          ticketIsPrinted: v1WebTicketDto.ticketIsPrinted,
          userGuardUuid: v1WebTicketDto.userGuardUuid,
          userGuardUsername: v1WebTicketDto.userGuardUsername,
          abstractParkingAreaUuid: v1WebTicketDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebTicketDto.abstractParkingAreaParkingAreaCode,
          vehicleUuid: v1WebTicketDto.vehicleUuid,
          vehicleVehicleId: v1WebTicketDto.vehicleVehicleId
        });
      }).catch((error) => {
        dispatch({
          type: TICKET_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function ticketGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): TicketThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: TICKET_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebTicketService.getTickets(page).then((response: AxiosResponse) => {
        let ticketPageableList = response.data as Response<V1WebTicketListElementDTO>

        let pageableListContent: any[] = [];
        ticketPageableList.content.forEach((value) => {
          pageableListContent.push([value.ticketUuid, value.ticketTicketId, value.ticketVehicleId, value.ticketParkingAreaCode, value.ticketIsEnabled])
        });

        dispatch({
          type: TICKET_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: ticketPageableList.totalElements,
          pageableListPageNumber: ticketPageableList.pageable.pageNumber,
          pageableListPageSize: ticketPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: TICKET_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function ticketSearchPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): TicketThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: TICKET_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebTicketService.searchTicket(v1keywordDto, page).then((response: AxiosResponse) => {
        let ticketPageableList = response.data as Response<V1WebTicketListElementDTO>

        let pageableListContent: any[] = [];
        ticketPageableList.content.forEach((value) => {
          pageableListContent.push([value.ticketUuid, value.ticketTicketId, value.ticketVehicleId, value.ticketParkingAreaCode, value.ticketIsEnabled])
        });

        dispatch({
          type: TICKET_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: ticketPageableList.totalElements,
          pageableListPageNumber: ticketPageableList.pageable.pageNumber,
          pageableListPageSize: ticketPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: TICKET_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function ticketGetPictureList(ticketUuid: string): TicketThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: TICKET_GET_PICTURE_LIST,
        status: LOADING_STATUS
      });

      v1WebTicketService.getTicketPicture(ticketUuid).then((response: AxiosResponse) => {
        let pictureList = response.data as Array<V1WebPictureListElementDTO>;

        let pageableListContent: any[] = [];
        pictureList.forEach((value) => {
          pageableListContent.push([value.pictureUuid, value.pictureThumbnailBase64ByteString])
        });

        dispatch({
          type: TICKET_GET_PICTURE_LIST,
          status: SUCCESS_STATUS,
          pictureListContent: pageableListContent
        });
      }).catch((error) => {
        dispatch({
          type: TICKET_GET_PICTURE_LIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function ticketPut(ticketUuid: string, ticketNotes: string, ticketIsEnabled: boolean): TicketThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: TICKET_PUT,
        status: LOADING_STATUS
      });

      let v1WebTicketDto = new V1WebTicketDTO();

      v1WebTicketDto.ticketUuid = ticketUuid;
      v1WebTicketDto.ticketNotes = ticketNotes;
      v1WebTicketDto.ticketIsEnabled = ticketIsEnabled;

      v1WebTicketService.putTicket(v1WebTicketDto).then((response: AxiosResponse) => {
        let v1WebTicketDto = response.data as V1WebTicketDTO;

        dispatch({
          type: TICKET_PUT,
          status: SUCCESS_STATUS,
          ticketUuid: v1WebTicketDto.ticketUuid,
          ticketTicketId: v1WebTicketDto.ticketTicketId,
          ticketGuardId: v1WebTicketDto.ticketGuardId,
          ticketParkingAreaCode: v1WebTicketDto.ticketParkingAreaCode,
          ticketAddress: v1WebTicketDto.ticketAddress,
          ticketZip: v1WebTicketDto.ticketZip,
          ticketCity: v1WebTicketDto.ticketCity,
          ticketVehicleId: v1WebTicketDto.ticketVehicleId,
          ticketNationality: v1WebTicketDto.ticketNationality,
          ticketObservationStart: v1WebTicketDto.ticketObservationStart,
          ticketObservationStop: v1WebTicketDto.ticketObservationStop,
          ticketPaymentAmount: v1WebTicketDto.ticketPaymentAmount,
          ticketParkingTime: v1WebTicketDto.ticketParkingTime,
          ticketParkingMeter: v1WebTicketDto.ticketParkingMeter,
          ticketBrand: v1WebTicketDto.ticketBrand,
          ticketViolation: v1WebTicketDto.ticketViolation,
          ticketNotes: v1WebTicketDto.ticketNotes,
          ticketIsPayed: v1WebTicketDto.ticketIsPayed,
          ticketIsEnabled: v1WebTicketDto.ticketIsEnabled,
          ticketIsPrinted: v1WebTicketDto.ticketIsPrinted,
          userGuardUuid: v1WebTicketDto.userGuardUuid,
          userGuardUsername: v1WebTicketDto.userGuardUsername,
          abstractParkingAreaUuid: v1WebTicketDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebTicketDto.abstractParkingAreaParkingAreaCode,
          vehicleUuid: v1WebTicketDto.vehicleUuid,
          vehicleVehicleId: v1WebTicketDto.vehicleVehicleId
        });
      }).catch((error) => {
        dispatch({
          type: TICKET_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}