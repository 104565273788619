import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { authenticationInit } from "redux/authentication/authentication.action";
import { rootInitUser } from "redux/root/root.action";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, StateStatus } from "redux/statestatus";
import { RootRedux } from "redux/store";

interface OwnProps {
  children?: React.ReactNode;
}

interface DispatchProps {
  authenticationInit: () => void;
}

interface StateProps {
  authenticationStateStatus: StateStatus;
  authenticated: boolean | null;
}

type Props = StateProps & OwnProps & DispatchProps;

const AuthenticatedView = (props: Props) => {
  const [isAuthenticationLoaded, setAuthenticationLoaded] = useState(false);
  const [isAuthenticationLoading, setAuthenticationLoading] = useState(false);
  
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticationLoaded) {
      props.authenticationInit();
      setAuthenticationLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.authenticationStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setAuthenticationLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setAuthenticationLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setAuthenticationLoading(false);
        break;
      }
    }
  }, [props.authenticationStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
     {(isAuthenticationLoaded && !isAuthenticationLoading) && (
      <>
        {props.authenticated ? (
          <>{props.children}</>
        ) : (
          <><Navigate to="/login" state={{ from: location }} replace /></>
        )}
      </>
     )}
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    authenticationStateStatus: redux.authentication.status,
    authenticated: redux.authentication.authenticated
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    authenticationInit: async () => {
      await dispatch(authenticationInit());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedView);