import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import V1WebAuthorityListElementDTO from "dto/v1/web/v1webauthoritylistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebAuthorityService from "service/v1/web/v1webauthority.service";
import { AuthorityState } from "./authority.reducer";
import Response from "dto/response";

export const AUTHORITY_GET_PAGEABLELIST = "authority/get/pageablelist";

export interface AuthorityAction {
  type:
  typeof AUTHORITY_GET_PAGEABLELIST
  stateStatus: StateStatus
  pageableListCount: number;
  pageableListPageNumber: number;
  pageableListPageSize: number;
  pageableListContent: Array<any[]>;
  pageableListSort: string;
  pageableListDirection: Direction;
  pageableListKeyword: string;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type AuthorityThunkAction = ThunkAction<Promise<void>, AuthorityState, any, AuthorityAction>;

export function authorityGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): AuthorityThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: AUTHORITY_GET_PAGEABLELIST,
        stateStatus: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebAuthorityService.getAuthorities(page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebAuthorityListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.authorityUuid, value.authorityName, value.authorityDisplayName, value.authorityDescription])
        });

        dispatch({
          type: AUTHORITY_GET_PAGEABLELIST,
          stateStatus: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        })

      }).catch((error) => {
        dispatch({
          type: AUTHORITY_GET_PAGEABLELIST,
          stateStatus: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}