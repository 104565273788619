export enum RestrictionType {
  ACCOUNTDISABLED = "ACCOUNTDISABLED",
  ACCOUNTNOTACTIVATED = "ACCOUNTNOTACTIVATED",
  ACCOUNTEXPIRED = "ACCOUNTEXPIRED",
  ACCOUNTISACTIVATED = "ACCOUNTISACTIVATED",
  ACCOUNTLOCKED = "ACCOUNTLOCKED",
  AUTHORITY = "AUTHORITY",
  BADAUTHENTICATION = "BADAUTHENTICATION",
  CREDENTIALS = "CREDENTIALS",
  CREDENTIALSEXPIRED = "CREDENTIALSEXPIRED",
  OWNERSHIP = "OWNERSHIP",
  ROLE = "ROLE",
  TOKENEXPIRED = "TOKENEXPIRED",
  TOKENDISABLED = "TOKENDISABLED",
  TOKENTYPEINVALID = "TOKENTYPEINVALID",
  TOKENUNVERIFIED = "TOKENUNVERIFIED",
}