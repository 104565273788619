import MenuItem from "component/menuitem";

const OperatorMenuItems: MenuItem[] = [
  {
    href: "/operator/parkpermit",
    icon: "assignment_turned_in",
    title: "Tilladelser"
  }
]

export default OperatorMenuItems;