import { UserType } from "type/user.type";
import V1WebAbstractUserDTO from "./v1webabstractuser.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebUserGuardDTO extends V1WebAbstractUserDTO {

    userGuardTicketCount!: number;
    userGuardGuardId!: number;

  constructor() {
    super();
    this.abstractUserUserType = UserType.GUARD;
  }
}