import { Avatar, Box, Button, Divider, Drawer, Hidden, Icon, List, ListItem, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { logout } from "redux/authentication/authentication.action";
import { StateStatus, IDLE_STATUS, LOADING_STATUS, SUCCESS_STATUS, FAILURE_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import theme from "theme/theme";
import MenuItem from "./menuitem";
import NavItem from "./navitem";

interface OwnProps {
  menuItems: MenuItem[];
  addonItems: MenuItem[];
  onMobileClose(): void;
  openMobile: boolean;
}

interface DispatchProps {
  logout: () => void;
}

interface StateProps {
  rootStateStatus: StateStatus
  rootUsername: string
  rootFirstName: string
  rootLastName: string
}

type Props = StateProps & OwnProps & DispatchProps

const NavBar = (props: Props) => {
  const [username, setUsername] = useState(props.rootUsername);
  const [firstName, setFirstName] = useState(props.rootFirstName);
  const [lastName, setLastName] = useState(props.rootLastName);
  const location = useLocation();

  useEffect(() => {
    switch (props.rootStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        break;
      }
      case SUCCESS_STATUS: {
        setUsername(props.rootUsername === null || props.rootUsername === undefined ? "" : props.rootUsername);
        setFirstName(props.rootFirstName === null || props.rootFirstName === undefined ? "" : props.rootFirstName);
        setLastName(props.rootLastName === null || props.rootLastName === undefined ? "" : props.rootLastName);
        break;
      }
      case FAILURE_STATUS: {
        break;
      }
    }
  }, [props.rootStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.openMobile && props.onMobileClose) {
      props.onMobileClose();
    }
  }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={props.onMobileClose}
          open={props.openMobile}
          variant="temporary"
          sx={{
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 256, boxSizing: "border-box" },
          }}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              p={2}
            >
              <Avatar
                sx={{
                  width: 64,
                  height: 64
                }}
              >
                {firstName !== undefined && firstName !== null && firstName.charAt(0) !== "" && lastName !== undefined && lastName !== null && lastName.charAt(0) !== "" ? firstName.charAt(0) + lastName.charAt(0) : "NA"}
              </Avatar>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {firstName !== "" ? firstName : "N/A"} {lastName !== "" ? lastName : "N/A"}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {username}
              </Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <List>
                {props.menuItems.map((item) => (
                  <NavItem
                    key={item.title}
                    menuItem={item}
                  />
                ))}
              </List>
            </Box>
            {props.addonItems?.length > 0 ? (
              <>
                <Divider />
                <Box p={2}>
                  <List>
                    {props.addonItems.map((item) => (
                      <NavItem
                        key={item.title}
                        menuItem={item}
                      />
                    ))}
                  </List>
                </Box>
              </>
            ) : (
              <></>
            )}
            <Divider light />
            <Box p={2}>
              <List>
                <NavItem
                  key="Konto"
                  menuItem={{
                    href: "account",
                    icon: "person",
                    title: "Konto"
                  }}
                />
                <ListItem
                  disableGutters
                  sx={{
                    display: "flex",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                >
                  <Button
                    component={NavLink}
                    to=""
                    onClick={() => props.logout()}
                    sx={{
                      color: theme.palette.text.secondary,
                      fontWeight: theme.typography.fontWeightMedium,
                      justifyContent: "flex-start",
                      letterSpacing: 0,
                      padding: "10px 8px",
                      textTransform: "none",
                      width: "100%"
                    }}>
                    <Icon
                      sx={{
                        marginRight: 3
                      }}>
                      logout
                    </Icon>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{
                        marginRight: "auto",

                      }}>
                      Log ud
                    </Typography>
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          sx={{
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: 256, boxSizing: "border-box", top: 64, height: "calc(100% - 64px)" },
          }}
        >
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
          >
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              p={2}
            >
              <Avatar
                sx={{
                  width: 64,
                  height: 64
                }}
              >
                {firstName !== null && firstName.charAt(0) !== "" && lastName !== null && lastName.charAt(0) !== "" ? firstName.charAt(0) + lastName.charAt(0) : "NA"}
              </Avatar>
              <Typography
                color="textPrimary"
                variant="h5"
              >
                {firstName !== "" ? firstName : "N/A"} {lastName !== "" ? lastName : "N/A"}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {username}
              </Typography>
            </Box>
            <Divider />
            <Box p={2}>
              <List>
                {props.menuItems.map((item) => (
                  <NavItem
                    key={item.title}
                    menuItem={item}
                  />
                ))}
              </List>
            </Box>
            {props.addonItems?.length > 0 ? (
              <>
                <Divider />
                <Box p={2}>
                  <List>
                    {props.addonItems.map((item) => (
                      <NavItem
                        key={item.title}
                        menuItem={item}
                      />
                    ))}
                  </List>
                </Box>
              </>
            ) : (
              <></>
            )}
            <Divider light />
            <Box p={2}>
              <List>
                <NavItem
                  key="Konto"
                  menuItem={{
                    href: "account",
                    icon: "person",
                    title: "Konto"
                  }}
                />
                <ListItem
                  disableGutters
                  sx={{
                    display: "flex",
                    paddingTop: 0,
                    paddingBottom: 0
                  }}
                >
                  <Button
                    component={NavLink}
                    to=""
                    onClick={() => props.logout()}
                    sx={{
                      color: theme.palette.text.secondary,
                      fontWeight: theme.typography.fontWeightMedium,
                      justifyContent: "flex-start",
                      letterSpacing: 0,
                      padding: "10px 8px",
                      textTransform: "none",
                      width: "100%"
                    }}>
                    <Icon
                      sx={{
                        marginRight: 3
                      }}>
                      logout
                    </Icon>
                    <Typography
                      color="textPrimary"
                      variant="h6"
                      sx={{
                        marginRight: "auto",

                      }}>
                      Log ud
                    </Typography>
                  </Button>
                </ListItem>
              </List>
            </Box>
          </Box>
        </Drawer>
      </Hidden>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    rootStateStatus: redux.root.status,
    rootUsername: redux.root.username,
    rootFirstName: redux.root.firstName,
    rootLastName: redux.root.lastName
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    logout: async () => {
      await dispatch(logout());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)