import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { ScaleLoader } from "react-spinners";
import CustomContainer from "./customcontainer";

interface OwnProps {
  children: never | never[];
}

interface DispatchProps {
}

interface StateProps {
}

type Props = StateProps & OwnProps & DispatchProps

const SpinnerContainer = (props: Props) => {
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowSpinner(true), 250);

    return () => clearTimeout(timer);
  })
  return (
    <>
      {showSpinner ? (
        <>
          <CustomContainer>
            <Grid
              container
              spacing={3}
              direction="row"
              alignItems="flex-start"
              justifyContent="center"
              sx={{
                marginTop: 0
              }}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <div style={{textAlign: "center"}}>
                  <ScaleLoader color={"#009c54"} loading={true} />
                </div>
              </Grid>
            </Grid>
          </CustomContainer>
        </>
      ) : (
        <>
        </>
      )}
    </>
  );
}

export default SpinnerContainer;