import { AuthenticationAction, AUTHENTICATION_INIT, AUTHENTICATION_LOGIN } from "redux/authentication/authentication.action";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";

export type AuthenticationState = {
  status: StateStatus,
  authenticated: boolean | null,
  errorCode: number,
  errorMessage: string,
  errorUuid: string
}

const initialState: AuthenticationState = {
  status: IDLE_STATUS,
  authenticated: null,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function authenticationReducer(state: AuthenticationState = initialState, action: AuthenticationAction): AuthenticationState {
  switch(state.status) {
    case IDLE_STATUS: {
      return authenticationIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return authenticationLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return authenticationSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return authenticationFailureReducer(state, action)
    }
    default: {
      return state;
    }
  }
}

export default authenticationReducer;

function authenticationIdleReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case AUTHENTICATION_INIT: {
      return {
        ...state,
        status: action.status,
      }
    }
    default: {
      return state;
    }
  }
}

function authenticationLoadingReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case AUTHENTICATION_INIT: {
      return {
        status: action.status,
        authenticated: action.status === SUCCESS_STATUS ? true : false,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case AUTHENTICATION_LOGIN: {
      return {
        status: action.status,
        authenticated: action.status === SUCCESS_STATUS ? true : false,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function authenticationSuccessReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case AUTHENTICATION_INIT: {
      return {
        ...state,
        status: action.status,
      }
    }
    default: {
      return state;
    }
  }
}

function authenticationFailureReducer(state: AuthenticationState, action: AuthenticationAction): AuthenticationState {
  switch (action.type) {
    case AUTHENTICATION_LOGIN: {
      return {
        ...state,
        status: action.status,
      }
    }
    default: {
      return state;
    }
  }
}