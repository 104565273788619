import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { TicketAction, TICKET_GET, TICKET_GET_PAGEABLELIST, TICKET_GET_PICTURE_LIST, TICKET_PUT, TICKET_SEARCH_PAGEABLELIST } from "./ticket.action";

export type TicketState = {
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  ticketUuid: string;
  ticketTicketId: string;
  ticketGuardId: number;
  ticketParkingAreaCode: string;
  ticketAddress: string;
  ticketZip: string;
  ticketCity: string;
  ticketVehicleId: string;
  ticketNationality: string;
  ticketObservationStart: Date;
  ticketObservationStop: Date;
  ticketPaymentAmount: number;
  ticketParkingTime: string;
  ticketParkingMeter: string;
  ticketBrand: string;
  ticketViolation: string;
  ticketNotes: string;
  ticketIsPayed: boolean;
  ticketIsEnabled: boolean;
  ticketIsPrinted: boolean;
  userGuardUuid: string;
  userGuardUsername: string;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  vehicleUuid: string;
  vehicleVehicleId: string;
  pictureStateStatus: StateStatus;
  pictureListContent: Array<any[]>
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: TicketState = {
  status: IDLE_STATUS,
  pageableListCount: 0,
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "createdDate",
  pageableListDirection: Direction.DESC,
  pageableListKeyword: "",
  ticketUuid: "",
  ticketTicketId: "",
  ticketGuardId: 0,
  ticketParkingAreaCode: "",
  ticketAddress: "",
  ticketZip: "",
  ticketCity: "",
  ticketVehicleId: "",
  ticketNationality: "",
  ticketObservationStart: new Date(),
  ticketObservationStop: new Date(),
  ticketPaymentAmount: 0,
  ticketParkingTime: "",
  ticketParkingMeter: "",
  ticketBrand: "",
  ticketViolation: "",
  ticketNotes: "",
  ticketIsPayed: false,
  ticketIsEnabled: false,
  ticketIsPrinted: false,
  userGuardUuid: "",
  userGuardUsername: "",
  abstractParkingAreaUuid: "",
  abstractParkingAreaParkingAreaCode: "",
  vehicleUuid: "",
  vehicleVehicleId: "",
  pictureStateStatus: IDLE_STATUS,
  pictureListContent: Array<any[]>(),
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function ticketReducer(state: TicketState = initialState, action: TicketAction): TicketState {
  switch (state.status) {
    case IDLE_STATUS: {
      return ticketIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return ticketLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return ticketSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return ticketFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default ticketReducer;

function ticketIdleReducer(state: TicketState, action: TicketAction): TicketState {
  switch (action.type) {
    case TICKET_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_GET_PICTURE_LIST: {
      return {
        ...state,
        pictureStateStatus: action.status
      }
    }
    case TICKET_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function ticketLoadingReducer(state: TicketState, action: TicketAction): TicketState {
  switch (action.type) {
    case TICKET_GET: {
      return {
        ...state,
        status: action.status,
        ticketUuid: action.ticketUuid,
        ticketTicketId: action.ticketTicketId,
        ticketGuardId: action.ticketGuardId,
        ticketParkingAreaCode: action.ticketParkingAreaCode,
        ticketAddress: action.ticketAddress,
        ticketZip: action.ticketZip,
        ticketCity: action.ticketCity,
        ticketVehicleId: action.ticketVehicleId,
        ticketNationality: action.ticketNationality,
        ticketObservationStart: action.ticketObservationStart,
        ticketObservationStop: action.ticketObservationStop,
        ticketPaymentAmount: action.ticketPaymentAmount,
        ticketParkingTime: action.ticketParkingTime,
        ticketParkingMeter: action.ticketParkingMeter,
        ticketBrand: action.ticketBrand,
        ticketViolation: action.ticketViolation,
        ticketNotes: action.ticketNotes,
        ticketIsPayed: action.ticketIsPayed,
        ticketIsEnabled: action.ticketIsEnabled,
        ticketIsPrinted: action.ticketIsPrinted,
        userGuardUuid: action.userGuardUuid,
        userGuardUsername: action.userGuardUsername,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        vehicleUuid: action.vehicleUuid,
        vehicleVehicleId: action.vehicleVehicleId,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case TICKET_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        ticketUuid: "",
        ticketTicketId: "",
        ticketGuardId: 0,
        ticketParkingAreaCode: "",
        ticketAddress: "",
        ticketZip: "",
        ticketCity: "",
        ticketVehicleId: "",
        ticketNationality: "",
        ticketObservationStart: new Date(),
        ticketObservationStop: new Date(),
        ticketPaymentAmount: 0,
        ticketParkingTime: "",
        ticketParkingMeter: "",
        ticketBrand: "",
        ticketViolation: "",
        ticketNotes: "",
        ticketIsPayed: false,
        ticketIsEnabled: false,
        ticketIsPrinted: false,
        userGuardUuid: "",
        userGuardUsername: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        pictureStateStatus: IDLE_STATUS,
        pictureListContent: Array<any[]>(),
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case TICKET_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        ticketUuid: "",
        ticketTicketId: "",
        ticketGuardId: 0,
        ticketParkingAreaCode: "",
        ticketAddress: "",
        ticketZip: "",
        ticketCity: "",
        ticketVehicleId: "",
        ticketNationality: "",
        ticketObservationStart: new Date(),
        ticketObservationStop: new Date(),
        ticketPaymentAmount: 0,
        ticketParkingTime: "",
        ticketParkingMeter: "",
        ticketBrand: "",
        ticketViolation: "",
        ticketNotes: "",
        ticketIsPayed: false,
        ticketIsEnabled: false,
        ticketIsPrinted: false,
        userGuardUuid: "",
        userGuardUsername: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        vehicleUuid: "",
        vehicleVehicleId: "",
        pictureStateStatus: IDLE_STATUS,
        pictureListContent: Array<any[]>(),
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case TICKET_GET_PICTURE_LIST: {
      return {
        ...state,
        pictureStateStatus: action.status,
        pictureListContent: action.pictureListContent,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case TICKET_PUT: {
      return {
        ...state,
        status: action.status,
        ticketUuid: action.ticketUuid,
        ticketTicketId: action.ticketTicketId,
        ticketGuardId: action.ticketGuardId,
        ticketParkingAreaCode: action.ticketParkingAreaCode,
        ticketAddress: action.ticketAddress,
        ticketZip: action.ticketZip,
        ticketCity: action.ticketCity,
        ticketVehicleId: action.ticketVehicleId,
        ticketNationality: action.ticketNationality,
        ticketObservationStart: action.ticketObservationStart,
        ticketObservationStop: action.ticketObservationStop,
        ticketPaymentAmount: action.ticketPaymentAmount,
        ticketParkingTime: action.ticketParkingTime,
        ticketParkingMeter: action.ticketParkingMeter,
        ticketBrand: action.ticketBrand,
        ticketViolation: action.ticketViolation,
        ticketNotes: action.ticketNotes,
        ticketIsPayed: action.ticketIsPayed,
        ticketIsEnabled: action.ticketIsEnabled,
        ticketIsPrinted: action.ticketIsPrinted,
        userGuardUuid: action.userGuardUuid,
        userGuardUsername: action.userGuardUsername,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        vehicleUuid: action.vehicleUuid,
        vehicleVehicleId: action.vehicleVehicleId,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function ticketSuccessReducer(state: TicketState, action: TicketAction): TicketState {
  switch (action.type) {
    case TICKET_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_GET_PICTURE_LIST: {
      switch (state.pictureStateStatus) {
        case IDLE_STATUS: {
          return ticketIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return ticketLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return ticketFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            pictureStateStatus: action.status
          }
        }
      }
    }
    case TICKET_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function ticketFailureReducer(state: TicketState, action: TicketAction): TicketState {
  switch (action.type) {
    case TICKET_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case TICKET_GET_PICTURE_LIST: {
      return {
        ...state,
        pictureStateStatus: action.status
      }
    }
    case TICKET_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}