import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { ParkingAreaType } from "type/parkingarea.type";
import { ParkingAreaAction, PARKINGAREA_GET, PARKINGAREA_GET_PAGEABLELIST, PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST, PARKINGAREA_GET_TICKET_PAGEABLELIST, PARKINGAREA_PUT, PARKINGAREA_SEARCH_PAGEABLELIST, PARKINGAREA_PUT_USERCLIENT } from "./parkingarea.action";

export type ParkingAreaState = {
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  abstractParkingAreaUuid: string
  abstractParkingAreaParkingAreaCode: string
  abstractParkingAreaAddress: string
  abstractParkingAreaZip: string
  abstractParkingAreaCity: string
  abstractParkingAreaWarningMinutes: number
  abstractParkingAreaTicketPrice: number
  abstractParkingAreaDefaultInitialUnlimitedParking: number
  abstractParkingAreaDefaultConcurrentUnlimitedParking: number
  abstractParkingAreaDefaultInitialMinuteParking: number
  abstractParkingAreaDefaultConcurrentMinuteParking: number
  abstractParkingAreaParkingAreaType: ParkingAreaType | null
  abstractParkingAreaNotes: string
  customerUuid: string
  customerCompanyName: string;
  parkingAreaDayNumberOfDays: number
  parkingAreaDayHourOfDay: number
  parkingAreaDayMinuteOfHour: number
  parkingAreaMinuteAllowedParkingMinutes: number
  parkPermitStatus: StateStatus
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  ticketStatus: StateStatus
  ticketPageableListCount: number
  ticketPageableListPageNumber: number
  ticketPageableListPageSize: number
  ticketPageableListContent: Array<any[]>
  ticketPageableListSort: string
  ticketPageableListDirection: Direction
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: ParkingAreaState = {
  status: IDLE_STATUS,
  pageableListCount: 0,
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "areaCode",
  pageableListDirection: Direction.ASC,
  pageableListKeyword: "",
  abstractParkingAreaUuid: "",
  abstractParkingAreaParkingAreaCode: "",
  abstractParkingAreaAddress: "",
  abstractParkingAreaZip: "",
  abstractParkingAreaCity: "",
  abstractParkingAreaWarningMinutes: 0,
  abstractParkingAreaTicketPrice: 0,
  abstractParkingAreaDefaultInitialUnlimitedParking: 0,
  abstractParkingAreaDefaultConcurrentUnlimitedParking: 0,
  abstractParkingAreaDefaultInitialMinuteParking: 0,
  abstractParkingAreaDefaultConcurrentMinuteParking: 0,
  abstractParkingAreaParkingAreaType: null,
  abstractParkingAreaNotes: "",
  customerUuid: "",
  customerCompanyName: "",
  parkingAreaDayNumberOfDays: 0,
  parkingAreaDayHourOfDay: 0,
  parkingAreaDayMinuteOfHour: 0,
  parkingAreaMinuteAllowedParkingMinutes: 0,
  parkPermitStatus: IDLE_STATUS,
  parkPermitPageableListCount: 0,
  parkPermitPageableListPageNumber: 0,
  parkPermitPageableListPageSize: 5,
  parkPermitPageableListContent: Array<any[]>(),
  parkPermitPageableListSort: "createdDate",
  parkPermitPageableListDirection: Direction.DESC,
  ticketStatus: IDLE_STATUS,
  ticketPageableListCount: 0,
  ticketPageableListPageNumber: 0,
  ticketPageableListPageSize: 5,
  ticketPageableListContent: Array<any[]>(),
  ticketPageableListSort: "createdDate",
  ticketPageableListDirection: Direction.ASC,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function parkingAreaReducer(state: ParkingAreaState = initialState, action: ParkingAreaAction): ParkingAreaState {
  switch (state.status) {
    case IDLE_STATUS: {
      return parkingAreaIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return parkingAreaLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return parkingAreaSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return parkingAreaFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default parkingAreaReducer;

function parkingAreaIdleReducer(state: ParkingAreaState, action: ParkingAreaAction): ParkingAreaState {
  switch (action.type) {
    case PARKINGAREA_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST: {
      return {
        ...state,
        parkPermitStatus: action.status
      }
    }
    case PARKINGAREA_GET_TICKET_PAGEABLELIST: {
      return {
        ...state,
        ticketStatus: action.status
      }
    }
    case PARKINGAREA_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_PUT_USERCLIENT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function parkingAreaLoadingReducer(state: ParkingAreaState, action: ParkingAreaAction): ParkingAreaState {
  switch (action.type) {
    case PARKINGAREA_GET: {
      return {
        ...state,
        status: action.status,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        abstractParkingAreaAddress: action.abstractParkingAreaAddress,
        abstractParkingAreaZip: action.abstractParkingAreaZip,
        abstractParkingAreaCity: action.abstractParkingAreaCity,
        abstractParkingAreaWarningMinutes: action.abstractParkingAreaWarningMinutes,
        abstractParkingAreaTicketPrice: action.abstractParkingAreaTicketPrice,
        abstractParkingAreaDefaultInitialUnlimitedParking: action.abstractParkingAreaDefaultInitialUnlimitedParking,
        abstractParkingAreaDefaultConcurrentUnlimitedParking: action.abstractParkingAreaDefaultConcurrentUnlimitedParking,
        abstractParkingAreaDefaultInitialMinuteParking: action.abstractParkingAreaDefaultInitialMinuteParking,
        abstractParkingAreaDefaultConcurrentMinuteParking: action.abstractParkingAreaDefaultConcurrentMinuteParking,
        abstractParkingAreaParkingAreaType: action.abstractParkingAreaParkingAreaType,
        abstractParkingAreaNotes: action.abstractParkingAreaNotes,
        customerUuid: action.customerUuid,
        customerCompanyName: action.customerCompanyName,
        parkingAreaDayNumberOfDays: action.parkingAreaDayNumberOfDays,
        parkingAreaDayHourOfDay: action.parkingAreaDayHourOfDay,
        parkingAreaDayMinuteOfHour: action.parkingAreaDayMinuteOfHour,
        parkingAreaMinuteAllowedParkingMinutes: action.parkingAreaMinuteAllowedParkingMinutes,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGAREA_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        abstractParkingAreaWarningMinutes: 0,
        abstractParkingAreaTicketPrice: 0,
        abstractParkingAreaDefaultInitialUnlimitedParking: 0,
        abstractParkingAreaDefaultConcurrentUnlimitedParking: 0,
        abstractParkingAreaDefaultInitialMinuteParking: 0,
        abstractParkingAreaDefaultConcurrentMinuteParking: 0,
        abstractParkingAreaParkingAreaType: null,
        abstractParkingAreaNotes: "",
        customerUuid: "",
        customerCompanyName: "",
        parkingAreaDayNumberOfDays: 0,
        parkingAreaDayHourOfDay: 0,
        parkingAreaDayMinuteOfHour: 0,
        parkingAreaMinuteAllowedParkingMinutes: 0,
        parkPermitStatus: IDLE_STATUS,
        parkPermitPageableListCount: 0,
        parkPermitPageableListPageNumber: 0,
        parkPermitPageableListPageSize: 5,
        parkPermitPageableListContent: Array<any[]>(),
        parkPermitPageableListSort: "createdDate",
        parkPermitPageableListDirection: Direction.DESC,
        ticketStatus: IDLE_STATUS,
        ticketPageableListCount: 0,
        ticketPageableListPageNumber: 0,
        ticketPageableListPageSize: 5,
        ticketPageableListContent: Array<any[]>(),
        ticketPageableListSort: "createdDate",
        ticketPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGAREA_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        abstractParkingAreaZip: "",
        abstractParkingAreaCity: "",
        abstractParkingAreaWarningMinutes: 0,
        abstractParkingAreaTicketPrice: 0,
        abstractParkingAreaDefaultInitialUnlimitedParking: 0,
        abstractParkingAreaDefaultConcurrentUnlimitedParking: 0,
        abstractParkingAreaDefaultInitialMinuteParking: 0,
        abstractParkingAreaDefaultConcurrentMinuteParking: 0,
        abstractParkingAreaParkingAreaType: null,
        abstractParkingAreaNotes: "",
        customerUuid: "",
        customerCompanyName: "",
        parkingAreaDayNumberOfDays: 0,
        parkingAreaDayHourOfDay: 0,
        parkingAreaDayMinuteOfHour: 0,
        parkingAreaMinuteAllowedParkingMinutes: 0,
        parkPermitStatus: IDLE_STATUS,
        parkPermitPageableListCount: 0,
        parkPermitPageableListPageNumber: 0,
        parkPermitPageableListPageSize: 5,
        parkPermitPageableListContent: Array<any[]>(),
        parkPermitPageableListSort: "createdDate",
        parkPermitPageableListDirection: Direction.DESC,
        ticketStatus: IDLE_STATUS,
        ticketPageableListCount: 0,
        ticketPageableListPageNumber: 0,
        ticketPageableListPageSize: 5,
        ticketPageableListContent: Array<any[]>(),
        ticketPageableListSort: "createdDate",
        ticketPageableListDirection: Direction.DESC,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST: {
      return {
        ...state,
        parkPermitStatus: action.status,
        parkPermitPageableListCount: action.parkPermitPageableListCount,
        parkPermitPageableListPageNumber: action.parkPermitPageableListPageNumber,
        parkPermitPageableListPageSize: action.parkPermitPageableListPageSize,
        parkPermitPageableListContent: action.parkPermitPageableListContent,
        parkPermitPageableListSort: action.parkPermitPageableListSort,
        parkPermitPageableListDirection: action.parkPermitPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGAREA_GET_TICKET_PAGEABLELIST: {
      return {
        ...state,
        ticketStatus: action.status,
        ticketPageableListCount: action.ticketPageableListCount,
        ticketPageableListPageNumber: action.ticketPageableListPageNumber,
        ticketPageableListPageSize: action.ticketPageableListPageSize,
        ticketPageableListContent: action.ticketPageableListContent,
        ticketPageableListSort: action.ticketPageableListSort,
        ticketPageableListDirection: action.ticketPageableListDirection,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGAREA_PUT: {
      return {
        ...state,
        status: action.status,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        abstractParkingAreaAddress: action.abstractParkingAreaAddress,
        abstractParkingAreaZip: action.abstractParkingAreaZip,
        abstractParkingAreaCity: action.abstractParkingAreaCity,
        abstractParkingAreaWarningMinutes: action.abstractParkingAreaWarningMinutes,
        abstractParkingAreaTicketPrice: action.abstractParkingAreaTicketPrice,
        abstractParkingAreaDefaultInitialUnlimitedParking: action.abstractParkingAreaDefaultInitialUnlimitedParking,
        abstractParkingAreaDefaultConcurrentUnlimitedParking: action.abstractParkingAreaDefaultConcurrentUnlimitedParking,
        abstractParkingAreaDefaultInitialMinuteParking: action.abstractParkingAreaDefaultInitialMinuteParking,
        abstractParkingAreaDefaultConcurrentMinuteParking: action.abstractParkingAreaDefaultConcurrentMinuteParking,
        abstractParkingAreaParkingAreaType: action.abstractParkingAreaParkingAreaType,
        abstractParkingAreaNotes: action.abstractParkingAreaNotes,
        customerUuid: action.customerUuid,
        customerCompanyName: action.customerCompanyName,
        parkingAreaDayNumberOfDays: action.parkingAreaDayNumberOfDays,
        parkingAreaDayHourOfDay: action.parkingAreaDayHourOfDay,
        parkingAreaDayMinuteOfHour: action.parkingAreaDayMinuteOfHour,
        parkingAreaMinuteAllowedParkingMinutes: action.parkingAreaMinuteAllowedParkingMinutes,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGAREA_PUT_USERCLIENT: {
      return {
        ...state,
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function parkingAreaSuccessReducer(state: ParkingAreaState, action: ParkingAreaAction): ParkingAreaState {
  switch (action.type) {
    case PARKINGAREA_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST: {
      switch (state.parkPermitStatus) {
        case IDLE_STATUS: {
          return parkingAreaIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return parkingAreaLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return parkingAreaFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            parkPermitStatus: action.status
          }
        }
      }
    }
    case PARKINGAREA_GET_TICKET_PAGEABLELIST: {
      switch (state.ticketStatus) {
        case IDLE_STATUS: {
          return parkingAreaIdleReducer(state, action);
        }
        case LOADING_STATUS: {
          return parkingAreaLoadingReducer(state, action);
        }
        case FAILURE_STATUS: {
          return parkingAreaFailureReducer(state, action);
        }
        default: {
          return {
            ...state,
            ticketStatus: action.status
          }
        }
      }
    }
    case PARKINGAREA_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_PUT_USERCLIENT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function parkingAreaFailureReducer(state: ParkingAreaState, action: ParkingAreaAction): ParkingAreaState {
  switch (action.type) {
    case PARKINGAREA_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST: {
      return {
        ...state,
        parkPermitStatus: action.status
      }
    }
    case PARKINGAREA_GET_TICKET_PAGEABLELIST: {
      return {
        ...state,
        ticketStatus: action.status
      }
    }
    case PARKINGAREA_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGAREA_PUT_USERCLIENT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}