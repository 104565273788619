import { axiosConfig } from "axios/axios.config";
import V1WebAccountUserDetailsDTO from "dto/v1/web/v1webaccountuserdetails.dto";

class V1WebAccountService {
  getUserDetails() {
    return axiosConfig.get("v1/web/account/userdetails/");
  }
  existsUsernameOrEmail(usernameOrEmail: string | undefined) {
    return axiosConfig.get("v1/web/account/usernameoremail/" + usernameOrEmail);
  }
  putUserDetails(v1WebAccountUserDetailsDTO: V1WebAccountUserDetailsDTO) {
    return axiosConfig.put("v1/web/account/userdetails", v1WebAccountUserDetailsDTO);
  }
}

export default new V1WebAccountService();