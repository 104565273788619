import V1WebAbstractUserDTO from "./v1webabstractuser.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default abstract class V1WebAbstractUserClientDTO extends V1WebAbstractUserDTO {

  abstractUserClientHasTextMessageNotificationService!: boolean;
  abstractUserClientHasEmailNotificationService!: boolean;
  customerUuid!: string;
  customerCompanyName!: string;
}