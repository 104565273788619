import UUIDHelper from "helper/uuidhelper";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store"
import { ticketGet, ticketGetPictureList, ticketPut } from "redux/ticket/ticket.action";
import ErrorContainer from "component/errorcontainer";
import CustomContainer from "component/customcontainer";
import { Typography, Grid, Card, CardContent, CardHeader, TextField, FormControlLabel, Switch, Box, Button, Divider, ImageList, ImageListItem } from "@mui/material";
import SpinnerContainer from "component/spinnercontainer";
import v1WebTicketService from "service/v1/web/v1webticket.service";
import { AxiosResponse } from "axios";

interface OwnProps {
}

interface DispatchProps {
  ticketGet: (ticketUuid: string) => void;
  ticketGetPictureList: (ticketUuid: string) => void;
  ticketPut: (ticketUuid: string, ticketNotes: string, ticketIsEnabled: boolean) => void;
}

interface StateProps {
  ticketStateStatus: StateStatus;
  ticketUuid: string;
  ticketTicketId: string;
  ticketGuardId: number;
  ticketParkingAreaCode: string;
  ticketAddress: string;
  ticketZip: string;
  ticketCity: string;
  ticketVehicleId: string;
  ticketNationality: string;
  ticketObservationStart: Date | string;
  ticketObservationStop: Date | string;
  ticketPaymentAmount: number;
  ticketParkingTime: string;
  ticketParkingMeter: string;
  ticketBrand: string;
  ticketViolation: string;
  ticketNotes: string;
  ticketIsPayed: boolean;
  ticketIsEnabled: boolean;
  ticketIsPrinted: boolean;
  ticketUserGuardUuid: string;
  ticketUserGuardUsername: string;
  ticketAbstractParkingAreaUuid: string;
  ticketAbstractParkingAreaParkingAreaCode: string;
  ticketVehicleUuid: string;
  ticketVehicleVehicleId: string;
  ticketPictureStateStatus: StateStatus;
  ticketPictureListContent: Array<any[]>;
  ticketErrorCode: number
  ticketErrorMessage: string
  ticketErrorUuid: string
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorTicketView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isPictureLoaded, setPictureLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isPictureLoading, setPictureLoading] = useState(false);
  const [isTicketDownloadLoading, setTicketDownloadLoading] = useState(false);
  const [isPictureDownloadLoading, setPictureDownloadLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    setValue: setUpdateFormValue 
  } = useForm<StateProps>({
    mode: 'all'
  });

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.ticketPut(formProps.ticketUuid, formProps.ticketNotes, formProps.ticketIsEnabled);
    }
  }

  const handleDownloadTicketClick = () => {
    if (!isTicketDownloadLoading) {
      if (props.ticketUuid !== undefined) {
        setTicketDownloadLoading(true);
        v1WebTicketService.getTicketAsPDF(props.ticketUuid).then((response: AxiosResponse) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.setAttribute('download', props.ticketTicketId + ".pdf");
          document.body.appendChild(link);
          link.click();
          setTicketDownloadLoading(false);
        }).catch((error) => {
          alert("Der skete en fejl: " + error.errorMessage);
          setTicketDownloadLoading(false);
        });
      }
    }
  }

  const handleDownloadPictureClick = () => {
    if (!isPictureDownloadLoading) {
      if (props.ticketUuid !== undefined) {
        setPictureDownloadLoading(true);
        v1WebTicketService.getTicketPictureAsZIP(props.ticketUuid).then((response: AxiosResponse) => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.setAttribute('download', props.ticketTicketId + ".zip");
          document.body.appendChild(link);
          link.click();
          setPictureDownloadLoading(false);
        }).catch((error) => {
          alert("Der skete en fejl: " + error.errorMessage);
          setPictureDownloadLoading(false);
        });
      }
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.ticketUuid !== undefined && UUIDHelper.validateUUID(params.ticketUuid)) {
        props.ticketGet(params.ticketUuid);
      } else {
        navigate('404', { replace: true });
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isPictureLoaded && props.ticketUuid !== undefined && props.ticketUuid !== "") {
      props.ticketGetPictureList(props.ticketUuid);
      setPictureLoaded(true);
    }
  }, [props.ticketUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.ticketStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("ticketUuid", props.ticketUuid);
        setUpdateFormValue("ticketTicketId", props.ticketTicketId);
        setUpdateFormValue("ticketGuardId", props.ticketGuardId);
        setUpdateFormValue("ticketParkingAreaCode", props.ticketParkingAreaCode);
        setUpdateFormValue("ticketAddress", props.ticketAddress);
        setUpdateFormValue("ticketZip", props.ticketZip);
        setUpdateFormValue("ticketCity", props.ticketCity);
        setUpdateFormValue("ticketVehicleId", props.ticketVehicleId);
        setUpdateFormValue("ticketNationality", props.ticketNationality);
        setUpdateFormValue("ticketObservationStart", formatter.format(Date.parse(props.ticketObservationStart.toString())));
        setUpdateFormValue("ticketObservationStop", formatter.format(Date.parse(props.ticketObservationStop.toString())));
        setUpdateFormValue("ticketPaymentAmount", props.ticketPaymentAmount);
        setUpdateFormValue("ticketParkingTime", props.ticketParkingTime);
        setUpdateFormValue("ticketParkingMeter", props.ticketParkingMeter);
        setUpdateFormValue("ticketBrand", props.ticketBrand);
        setUpdateFormValue("ticketViolation", props.ticketViolation);
        setUpdateFormValue("ticketNotes", props.ticketNotes);
        setUpdateFormValue("ticketIsPayed", props.ticketIsPayed);
        setUpdateFormValue("ticketIsEnabled", props.ticketIsEnabled);
        setUpdateFormValue("ticketIsPrinted", props.ticketIsPrinted);
        setUpdateFormValue("ticketUserGuardUuid", props.ticketUserGuardUuid);
        setUpdateFormValue("ticketUserGuardUsername", props.ticketUserGuardUsername);
        setUpdateFormValue("ticketAbstractParkingAreaUuid", props.ticketAbstractParkingAreaUuid);
        setUpdateFormValue("ticketAbstractParkingAreaParkingAreaCode", props.ticketAbstractParkingAreaParkingAreaCode);
        setUpdateFormValue("ticketVehicleUuid", props.ticketVehicleUuid);
        setUpdateFormValue("ticketVehicleVehicleId", props.ticketVehicleVehicleId);

        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.ticketStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.ticketPictureStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setPictureLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setPictureLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setPictureLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.ticketPictureStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "numeric"
  });

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.ticketErrorCode} errorMessage={props.ticketErrorMessage} errorUuid={props.ticketErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Afgift
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    <CardHeader
                      subheader={"Printede oplysninger for afgiften"}
                      title="Afgift oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketTicketId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Afgiftsnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketGuardId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Vagtnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketParkingAreaCode"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parking Area Code"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketAddress"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketZip"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketCity"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketVehicleId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Køretøj"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketNationality"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Nationalitet"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketObservationStart"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Observation påbegyndt"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketObservationStop"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Observation afsluttet"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketPaymentAmount"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Beløb"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketParkingTime"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Tilladt parkeringstid"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketParkingMeter"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parkeringsskive viste"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketBrand"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Fabrikat"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketViolation"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Overtrædelse"
                                fullWidth
                                multiline
                                rows={3}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketIsEnabled"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                />
                              } label="Aktiveret" />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="ticketIsPrinted"
                            control={updateFormControl}
                            render={({ field: { onChange, value } }) => (
                              <FormControlLabel control={
                                <Switch
                                  checked={value}
                                  onChange={onChange}
                                  disabled={true}
                                />
                              } label="Er afgift printet" />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isTicketDownloadLoading}
                        onClick={handleDownloadTicketClick}
                        variant="outlined"
                      >
                        Hent afgift som PDF
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: -2,
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem afgift oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Card>
                    <CardHeader
                      subheader={"Alle relationer tilknyttet denne afgift. Relationer stemmer nødvendigvis IKKE overens med de printede oplysninger"}
                      title="Relationer" />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <Controller
                            name="ticketUserGuardUsername"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Vagt"
                                fullWidth
                                value={value || 0}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <Controller
                            name="ticketAbstractParkingAreaParkingAreaCode"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parking Area Code"
                                fullWidth
                                value={value || 0}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={4}
                          md={4}
                          xs={12}
                        >
                          <Controller
                            name="ticketVehicleVehicleId"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Køretøj"
                                fullWidth
                                value={value || 0}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                >
                  <Card
                    sx={{
                      marginTop: 3
                    }}
                  >
                    <CardHeader
                      subheader={"Afgiftens foto(s) i forbindelse med observation"}
                      title="Foto dokumentation" />
                    <CardContent
                      sx={{
                        marginTop: -5
                      }}
                    >{isPictureLoading ? (
                      <>
                        <SpinnerContainer>
                        </SpinnerContainer>
                      </>
                    ) : (
                      <>
                        <ImageList cols={4}>
                          {props.ticketPictureListContent.map(picture => (
                            <ImageListItem key={picture[0]}>
                              <img
                                src={"data:image/png;base64," + picture[1]}
                                alt={picture[0]}
                                loading="lazy"
                              />
                            </ImageListItem>
                          ))}
                        </ImageList>
                      </>
                    )}
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isPictureDownloadLoading}
                        onClick={handleDownloadPictureClick}
                        variant="outlined"
                      >
                        Hent billeder som ZIP
                      </Button>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    ticketStateStatus: redux.ticket.status,
    ticketUuid: redux.ticket.ticketUuid,
    ticketTicketId: redux.ticket.ticketTicketId,
    ticketGuardId: redux.ticket.ticketGuardId,
    ticketParkingAreaCode: redux.ticket.ticketParkingAreaCode,
    ticketAddress: redux.ticket.ticketAddress,
    ticketZip: redux.ticket.ticketZip,
    ticketCity: redux.ticket.ticketCity,
    ticketVehicleId: redux.ticket.ticketVehicleId,
    ticketNationality: redux.ticket.ticketNationality,
    ticketObservationStart: redux.ticket.ticketObservationStart,
    ticketObservationStop: redux.ticket.ticketObservationStop,
    ticketPaymentAmount: redux.ticket.ticketPaymentAmount,
    ticketParkingTime: redux.ticket.ticketParkingTime,
    ticketParkingMeter: redux.ticket.ticketParkingMeter,
    ticketBrand: redux.ticket.ticketBrand,
    ticketViolation: redux.ticket.ticketViolation,
    ticketNotes: redux.ticket.ticketNotes,
    ticketIsPayed: redux.ticket.ticketIsPayed,
    ticketIsEnabled: redux.ticket.ticketIsEnabled,
    ticketIsPrinted: redux.ticket.ticketIsPrinted,
    ticketUserGuardUuid: redux.ticket.userGuardUuid,
    ticketUserGuardUsername: redux.ticket.userGuardUsername,
    ticketAbstractParkingAreaUuid: redux.ticket.abstractParkingAreaUuid,
    ticketAbstractParkingAreaParkingAreaCode: redux.ticket.abstractParkingAreaParkingAreaCode,
    ticketVehicleUuid: redux.ticket.vehicleUuid,
    ticketVehicleVehicleId: redux.ticket.vehicleVehicleId,
    ticketPictureStateStatus: redux.ticket.pictureStateStatus,
    ticketPictureListContent: redux.ticket.pictureListContent,
    ticketErrorCode: redux.ticket.errorCode,
    ticketErrorMessage: redux.ticket.errorMessage,
    ticketErrorUuid: redux.ticket.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    ticketGet: async (ticketUuid) => {
      await dispatch(ticketGet(ticketUuid));
    },
    ticketGetPictureList: async (ticketUuid) => {
      await dispatch(ticketGetPictureList(ticketUuid));
    },
    ticketPut: async (ticketUuid, ticketNotes, ticketIsEnabled) => {
      await dispatch(ticketPut(ticketUuid, ticketNotes, ticketIsEnabled))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorTicketView);