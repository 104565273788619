import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import v1AuthenticationService from "service/v1/v1authentication.service";
import { RootRedux } from "redux/store";
import { AxiosResponse } from "axios";
import V1ForgotPasswordDTO from "dto/v1/v1forgotpassword.dto";
import { ClientType } from "type/client.type";

interface OwnProps {
}

interface DispatchProps {
}

interface StateProps {
}

interface FormProps {
  usernameOrEmail: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const ForgotPasswordView = (props: Props) => {
  const [isViewLoading, setViewLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [viewAlert, setViewAlert] = useState(<></>);

  const {
    handleSubmit: handleForgotPasswordFormSubmit,
    control: forgotPasswordFormControl
  } = useForm<FormProps>({
    mode: 'all'
  });

  const onForgotPasswordFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      if (formProps.usernameOrEmail.trim().length < 4 || formProps.usernameOrEmail.trim().length > 256 || !formProps.usernameOrEmail.trim().match(/[a-zA-Z0-9@.]/)) {
        setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">Brugernavn eller adgangskode er forkert</Alert>);
        return;
      }

      let v1ForgotPasswordDTO: V1ForgotPasswordDTO = {
        usernameOrEmail: formProps.usernameOrEmail,
        clientType: ClientType.WEB
      }

      v1AuthenticationService.forgotPassword(v1ForgotPasswordDTO).then((response: AxiosResponse) => {
        setViewAlert(<Alert sx={{ marginTop: 1 }} severity="success">Hvis det indtastede brugernavn/e-mail eksisterer, vil der være sendt en e-mail til ejeren af denne bruger med et link til gendannelse af adgangskoden.</Alert>);
      }).catch((error) => {
        switch (error.errorCode) {
          default: {
            setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">{error.errorMessage}</Alert>);
            break;
          }
        }

      });

      setViewLoading(true);
      setShowViewAlert(true);
    }
  }

  return (
    <>
      <form onSubmit={handleForgotPasswordFormSubmit(onForgotPasswordFormSubmit)}>
        <Box mb={3}>
          <Typography
            color="textPrimary"
            variant="h2"
          >
            Glemt adgangskode
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Du skal angive det brugernavn eller den e-mail tilknyttet din konto for at få tilsendt et link til at nulstille din adgangskode.
          </Typography>
        </Box>
        <Controller
          name="usernameOrEmail"
          control={forgotPasswordFormControl}
          rules={{
            required: {
              value: true,
              message: "Brugernavn eller e-mail er påkrævet"
            },
            minLength: {
              value: 4,
              message: "Indtastning skal være på minimum 4 tegn"
            },
            maxLength: {
              value: 256,
              message: "Indtastning må ikke overstige 256 tegn"
            },
            validate: {
              value: ((value) => {
                return new Promise((resolve) => {
                  if (!value.match(/[a-zA-Z0-9@.]/)) {
                    resolve("Ugyldigt brugernavn eller e-mail")
                  }

                  resolve(true);
                })
              })
            }
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Brugernavn/E-mail"
              fullWidth
              margin="normal"
              value={value || ""}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        {showViewAlert ? (
          viewAlert
        ) : (
          <></>
        )}
        <Box my={2}>
          <Button
            color="primary"
            disabled={isViewLoading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Send link
          </Button>
        </Box>
      </form>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordView);