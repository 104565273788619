import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { PARKINGLIMITATION_GET_PAGEABLELIST, PARKINGLIMITATION_SEARCH_PAGEABLELIST } from "./parkinglimitation.action";
import { ParkingLimitationAction, PARKINGLIMITATION_GET, PARKINGLIMITATION_PUT } from "./parkinglimitation.action";

export type ParkingLimitationState = {
  status: StateStatus;
  pageableListCount: number;
  pageableListPageNumber: number;
  pageableListPageSize: number;
  pageableListContent: Array<any[]>;
  pageableListSort: string;
  pageableListDirection: Direction;
  pageableListKeyword: string;
  parkingLimitationUuid: string;
  parkingLimitationInitialUnlimitedParking: number;
  parkingLimitationRemainingUnlimitedParking: number;
  parkingLimitationConcurrentUnlimitedParking: number;
  parkingLimitationInitialMinuteParking: number;
  parkingLimitationRemainingMinuteParking: number;
  parkingLimitationConcurrentMinuteParking: number;
  parkingLimitationConcurrentTotalParking: number;
  parkingLimitationIsEnabled: boolean;
  parkingLimitationNotes: string;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  abstractParkingAreaAddress: string;
  userClientPublicUuid: string;
  userClientPublicUsername: string;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: ParkingLimitationState = {
  status: IDLE_STATUS,
  pageableListCount: 0,
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "createdDate",
  pageableListDirection: Direction.DESC,
  pageableListKeyword: "",
  parkingLimitationUuid: "",
  parkingLimitationInitialUnlimitedParking: 0,
  parkingLimitationRemainingUnlimitedParking: 0,
  parkingLimitationConcurrentUnlimitedParking: 0,
  parkingLimitationInitialMinuteParking: 0,
  parkingLimitationRemainingMinuteParking: 0,
  parkingLimitationConcurrentMinuteParking: 0,
  parkingLimitationConcurrentTotalParking: 0,
  parkingLimitationIsEnabled: false,
  parkingLimitationNotes: "",
  abstractParkingAreaUuid: "",
  abstractParkingAreaParkingAreaCode: "",
  abstractParkingAreaAddress: "",
  userClientPublicUuid: "",
  userClientPublicUsername: "",
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function parkingLimitationReducer(state: ParkingLimitationState = initialState, action: ParkingLimitationAction): ParkingLimitationState {
  switch (state.status) {
    case IDLE_STATUS: {
      return parkingLimitationIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return parkingLimitationLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return parkingLimitationSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return parkingLimitationFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default parkingLimitationReducer;

function parkingLimitationIdleReducer(state: ParkingLimitationState, action: ParkingLimitationAction): ParkingLimitationState {
  switch (action.type) {
    case PARKINGLIMITATION_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function parkingLimitationLoadingReducer(state: ParkingLimitationState, action: ParkingLimitationAction): ParkingLimitationState {
  switch (action.type) {
    case PARKINGLIMITATION_GET: {
      return {
        ...state,
        status: action.status,
        parkingLimitationUuid: action.parkingLimitationUuid,
        parkingLimitationInitialUnlimitedParking: action.parkingLimitationInitialUnlimitedParking,
        parkingLimitationRemainingUnlimitedParking: action.parkingLimitationRemainingUnlimitedParking,
        parkingLimitationConcurrentUnlimitedParking: action.parkingLimitationConcurrentUnlimitedParking,
        parkingLimitationInitialMinuteParking: action.parkingLimitationInitialMinuteParking,
        parkingLimitationRemainingMinuteParking: action.parkingLimitationRemainingMinuteParking,
        parkingLimitationConcurrentMinuteParking: action.parkingLimitationConcurrentMinuteParking,
        parkingLimitationConcurrentTotalParking: action.parkingLimitationConcurrentTotalParking,
        parkingLimitationIsEnabled: action.parkingLimitationIsEnabled,
        parkingLimitationNotes: action.parkingLimitationNotes,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        abstractParkingAreaAddress: action.abstractParkingAreaAddress,
        userClientPublicUuid: action.userClientPublicUuid,
        userClientPublicUsername: action.userClientPublicUsername,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGLIMITATION_GET_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        parkingLimitationUuid: "",
        parkingLimitationInitialUnlimitedParking: 0,
        parkingLimitationRemainingUnlimitedParking: 0,
        parkingLimitationConcurrentUnlimitedParking: 0,
        parkingLimitationInitialMinuteParking: 0,
        parkingLimitationRemainingMinuteParking: 0,
        parkingLimitationConcurrentMinuteParking: 0,
        parkingLimitationConcurrentTotalParking: 0,
        parkingLimitationIsEnabled: false,
        parkingLimitationNotes: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        userClientPublicUuid: "",
        userClientPublicUsername: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGLIMITATION_SEARCH_PAGEABLELIST: {
      return {
        status: action.status,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: action.pageableListKeyword,
        parkingLimitationUuid: "",
        parkingLimitationInitialUnlimitedParking: 0,
        parkingLimitationRemainingUnlimitedParking: 0,
        parkingLimitationConcurrentUnlimitedParking: 0,
        parkingLimitationInitialMinuteParking: 0,
        parkingLimitationRemainingMinuteParking: 0,
        parkingLimitationConcurrentMinuteParking: 0,
        parkingLimitationConcurrentTotalParking: 0,
        parkingLimitationIsEnabled: false,
        parkingLimitationNotes: "",
        abstractParkingAreaUuid: "",
        abstractParkingAreaParkingAreaCode: "",
        abstractParkingAreaAddress: "",
        userClientPublicUuid: "",
        userClientPublicUsername: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    case PARKINGLIMITATION_PUT: {
      return {
        ...state,
        status: action.status,
        parkingLimitationUuid: action.parkingLimitationUuid,
        parkingLimitationInitialUnlimitedParking: action.parkingLimitationInitialUnlimitedParking,
        parkingLimitationRemainingUnlimitedParking: action.parkingLimitationRemainingUnlimitedParking,
        parkingLimitationConcurrentUnlimitedParking: action.parkingLimitationConcurrentUnlimitedParking,
        parkingLimitationInitialMinuteParking: action.parkingLimitationInitialMinuteParking,
        parkingLimitationRemainingMinuteParking: action.parkingLimitationRemainingMinuteParking,
        parkingLimitationConcurrentMinuteParking: action.parkingLimitationConcurrentMinuteParking,
        parkingLimitationConcurrentTotalParking: action.parkingLimitationConcurrentTotalParking,
        parkingLimitationIsEnabled: action.parkingLimitationIsEnabled,
        parkingLimitationNotes: action.parkingLimitationNotes,
        abstractParkingAreaUuid: action.abstractParkingAreaUuid,
        abstractParkingAreaParkingAreaCode: action.abstractParkingAreaParkingAreaCode,
        abstractParkingAreaAddress: action.abstractParkingAreaAddress,
        userClientPublicUuid: action.userClientPublicUuid,
        userClientPublicUsername: action.userClientPublicUsername,
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function parkingLimitationSuccessReducer(state: ParkingLimitationState, action: ParkingLimitationAction): ParkingLimitationState {
  switch (action.type) {
    case PARKINGLIMITATION_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}

function parkingLimitationFailureReducer(state: ParkingLimitationState, action: ParkingLimitationAction): ParkingLimitationState {
  switch (action.type) {
    case PARKINGLIMITATION_GET: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_GET_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_SEARCH_PAGEABLELIST: {
      return {
        ...state,
        status: action.status
      }
    }
    case PARKINGLIMITATION_PUT: {
      return {
        ...state,
        status: action.status
      }
    }
    default: {
      return state;
    }
  }
}