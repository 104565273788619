import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebAbstractParkingAreaDTO from "dto/v1/web/v1webabstractparkingarea.dto";
import V1WebParkingAreaDayDTO from "dto/v1/web/v1webparkingareaday.dto";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import V1WebParkingAreaMinuteDTO from "dto/v1/web/v1webparkingareaminute.dto";
import V1WebParkPermitListElementDTO from "dto/v1/web/v1webparkpermitlistelement.dto";
import V1WebTicketListElementDTO from "dto/v1/web/v1webticketlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service";
import { ParkingAreaType } from "type/parkingarea.type";
import { ParkingAreaState } from "./parkingarea.reducer";
import { LimitationType } from "type/limitation.type";
import V1DateTimeDTO from "dto/v1/v1datetime.dto";

export const PARKINGAREA_GET = "parkingarea/get";
export const PARKINGAREA_GET_PAGEABLELIST = "parkingarea/get/pageablelist";
export const PARKINGAREA_SEARCH_PAGEABLELIST = "parkingarea/search/pageablelist";
export const PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST = "parkingarea/get/parkpermit/pageablelist";
export const PARKINGAREA_GET_TICKET_PAGEABLELIST = "parkingarea/get/ticket/pageablelist";
export const PARKINGAREA_PUT = "parkingarea/put";
export const PARKINGAREA_PUT_USERCLIENT = "parkingarea/put/userclient";

export interface ParkingAreaAction {
  type:
  typeof PARKINGAREA_GET |
  typeof PARKINGAREA_GET_PAGEABLELIST |
  typeof PARKINGAREA_SEARCH_PAGEABLELIST |
  typeof PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST |
  typeof PARKINGAREA_GET_TICKET_PAGEABLELIST |
  typeof PARKINGAREA_PUT |
  typeof PARKINGAREA_PUT_USERCLIENT
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  abstractParkingAreaUuid: string
  abstractParkingAreaParkingAreaCode: string
  abstractParkingAreaAddress: string
  abstractParkingAreaZip: string
  abstractParkingAreaCity: string
  abstractParkingAreaWarningMinutes: number
  abstractParkingAreaTicketPrice: number
  abstractParkingAreaDefaultInitialUnlimitedParking: number
  abstractParkingAreaDefaultConcurrentUnlimitedParking: number
  abstractParkingAreaDefaultInitialMinuteParking: number
  abstractParkingAreaDefaultConcurrentMinuteParking: number
  abstractParkingAreaParkingAreaType: ParkingAreaType
  abstractParkingAreaNotes: string
  customerUuid: string
  customerCompanyName: string
  parkingAreaDayNumberOfDays: number
  parkingAreaDayHourOfDay: number
  parkingAreaDayMinuteOfHour: number
  parkingAreaMinuteAllowedParkingMinutes: number
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  parkPermitPageableListKeyword: string
  ticketPageableListCount: number
  ticketPageableListPageNumber: number
  ticketPageableListPageSize: number
  ticketPageableListContent: Array<any[]>
  ticketPageableListSort: string
  ticketPageableListDirection: Direction
  ticketPageableListKeyword: string
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type ParkingAreaThunkAction = ThunkAction<Promise<void>, ParkingAreaState, any, ParkingAreaAction>;

export function parkingAreaGet(abstractParkingAreaUuid: string): ParkingAreaThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGAREA_GET,
        status: LOADING_STATUS
      });

      v1WebParkingAreaService.getParkingArea(abstractParkingAreaUuid).then((response: AxiosResponse) => {
        let v1WebAbstractParkingAreaDto = response.data as V1WebAbstractParkingAreaDTO;

        dispatch({
          type: PARKINGAREA_GET,
          status: SUCCESS_STATUS,
          abstractParkingAreaUuid: v1WebAbstractParkingAreaDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebAbstractParkingAreaDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebAbstractParkingAreaDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebAbstractParkingAreaDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebAbstractParkingAreaDto.abstractParkingAreaCity,
          abstractParkingAreaWarningMinutes: v1WebAbstractParkingAreaDto.abstractParkingAreaWarningMinutes,
          abstractParkingAreaTicketPrice: v1WebAbstractParkingAreaDto.abstractParkingAreaTicketPrice,
          abstractParkingAreaDefaultInitialUnlimitedParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialUnlimitedParking,
          abstractParkingAreaDefaultConcurrentUnlimitedParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentUnlimitedParking,
          abstractParkingAreaDefaultInitialMinuteParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialMinuteParking,
          abstractParkingAreaDefaultConcurrentMinuteParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentMinuteParking,
          abstractParkingAreaParkingAreaType: v1WebAbstractParkingAreaDto.abstractParkingAreaAreaType,
          abstractParkingAreaNotes: v1WebAbstractParkingAreaDto.abstractParkingAreaNotes,
          customerUuid: v1WebAbstractParkingAreaDto.customerUuid,
          customerCompanyName: v1WebAbstractParkingAreaDto.customerCompanyName,
          parkingAreaDayNumberOfDays: (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayNumberOfDays,
          parkingAreaDayHourOfDay: (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayHourOfDay,
          parkingAreaDayMinuteOfHour: (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayMinuteOfHour,
          parkingAreaMinuteAllowedParkingMinutes: (v1WebAbstractParkingAreaDto as V1WebParkingAreaMinuteDTO).parkingAreaMinuteAllowedParkingMinutes
        })
      }).catch((error) => {
        dispatch({
          type: PARKINGAREA_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function parkingAreaGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingAreaThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGAREA_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkingAreaService.getParkingAreas(page).then((response: AxiosResponse) => {
        let parkingAreaPageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: any[] = [];
        parkingAreaPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity])
        });

        dispatch({
          type: PARKINGAREA_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: parkingAreaPageableList.totalElements,
          pageableListPageNumber: parkingAreaPageableList.pageable.pageNumber,
          pageableListPageSize: parkingAreaPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGAREA_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function parkingAreaSearchPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingAreaThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGAREA_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebParkingAreaService.searchParkingArea(v1keywordDto, page).then((response: AxiosResponse) => {
        let parkingAreaPageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: any[] = [];
        parkingAreaPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity])
        });

        dispatch({
          type: PARKINGAREA_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: parkingAreaPageableList.totalElements,
          pageableListPageNumber: parkingAreaPageableList.pageable.pageNumber,
          pageableListPageSize: parkingAreaPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGAREA_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function parkingAreaGetParkPermitPageableList(abstractParkingAreaUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingAreaThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkingAreaService.getParkingAreaParkPermit(abstractParkingAreaUuid, page).then((response: AxiosResponse) => {
        let parkPermitPageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        parkPermitPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId])
        });

        dispatch({
          type: PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST,
          status: SUCCESS_STATUS,
          parkPermitPageableListCount: parkPermitPageableList.totalElements,
          parkPermitPageableListPageNumber: parkPermitPageableList.pageable.pageNumber,
          parkPermitPageableListPageSize: parkPermitPageableList.pageable.pageSize,
          parkPermitPageableListContent: pageableListContent,
          parkPermitPageableListSort: sort,
          parkPermitPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGAREA_GET_PARKPERMIT_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function parkingAreaGetTicketPageableList(abstractParkingAreaUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): ParkingAreaThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGAREA_GET_TICKET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebParkingAreaService.getParkingAreaTicket(abstractParkingAreaUuid, page).then((response: AxiosResponse) => {
        let ticketPageableList = response.data as Response<V1WebTicketListElementDTO>

        let pageableListContent: any[] = [];
        ticketPageableList.content.forEach((value) => {
          pageableListContent.push([value.ticketUuid, value.ticketTicketId, value.ticketVehicleId, value.ticketParkingAreaCode, value.ticketIsEnabled])
        });

        dispatch({
          type: PARKINGAREA_GET_TICKET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          ticketPageableListCount: ticketPageableList.totalElements,
          ticketPageableListPageNumber: ticketPageableList.pageable.pageNumber,
          ticketPageableListPageSize: ticketPageableList.pageable.pageSize,
          ticketPageableListContent: pageableListContent,
          ticketPageableListSort: sort,
          ticketPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: PARKINGAREA_GET_TICKET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function parkingAreaPut(abstractParkingAreaUuid: string, abstractParkingAreaParkingAreaCode: string, abstractParkingAreaAddress: string, abstractParkingAreaZip: string, abstractParkingAreaCity: string, abstractParkingAreaWarningMinutes: number, abstractParkingAreaTicketPrice: number, abstractParkingAreaDefaultInitialUnlimitedParking: number, abstractParkingAreaDefaultConcurrentUnlimitedParking: number, abstractParkingAreaDefaultUnlimitedParkingLimitationType: LimitationType | null, abstractParkingAreaDefaultMinuteParkingV1DateTimeDto: V1DateTimeDTO | null, abstractParkingAreaDefaultInitialMinuteParking: number | null, abstractParkingAreaDefaultConcurrentMinuteParking: number, abstractParkingAreaDefaultMinuteParkingLimitationType: LimitationType | null, abstractParkingAreaNotes: string, abstractParkingAreaType: ParkingAreaType | null, customerUuid: string, parkingAreaDayNumberOfDays: number, parkingAreaDayHourOfDay: number, parkingAreaDayMinuteOfHour: number, parkingAreaMinuteAllowedParkingMinutes: number): ParkingAreaThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: PARKINGAREA_PUT,
        status: LOADING_STATUS
      });

      let v1WebAbstractParkingAreaDto: V1WebAbstractParkingAreaDTO;

      switch (abstractParkingAreaType) {
        case ParkingAreaType.DAY: {
          v1WebAbstractParkingAreaDto = new V1WebParkingAreaDayDTO();
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayNumberOfDays = parkingAreaDayNumberOfDays;
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayHourOfDay = parkingAreaDayHourOfDay;
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayMinuteOfHour = parkingAreaDayMinuteOfHour;
          break;
        }
        case ParkingAreaType.MINUTE: {
          v1WebAbstractParkingAreaDto = new V1WebParkingAreaMinuteDTO();
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaMinuteDTO).parkingAreaMinuteAllowedParkingMinutes = parkingAreaMinuteAllowedParkingMinutes;
          break;
        }
        default: {
          throw new Error("Unable to determine parking area type herp");
        }
      }

      v1WebAbstractParkingAreaDto.abstractParkingAreaUuid = abstractParkingAreaUuid;
      v1WebAbstractParkingAreaDto.abstractParkingAreaParkingAreaCode = abstractParkingAreaParkingAreaCode;
      v1WebAbstractParkingAreaDto.abstractParkingAreaAddress = abstractParkingAreaAddress;
      v1WebAbstractParkingAreaDto.abstractParkingAreaZip = abstractParkingAreaZip;
      v1WebAbstractParkingAreaDto.abstractParkingAreaCity = abstractParkingAreaCity;
      v1WebAbstractParkingAreaDto.abstractParkingAreaWarningMinutes = abstractParkingAreaWarningMinutes;
      v1WebAbstractParkingAreaDto.abstractParkingAreaTicketPrice = abstractParkingAreaTicketPrice;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialUnlimitedParking = abstractParkingAreaDefaultInitialUnlimitedParking;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentUnlimitedParking = abstractParkingAreaDefaultConcurrentUnlimitedParking;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultUnlimitedParkingLimitationType = abstractParkingAreaDefaultUnlimitedParkingLimitationType;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultMinuteParkingV1DateTimeDto = abstractParkingAreaDefaultMinuteParkingV1DateTimeDto;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialMinuteParking = 0;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentMinuteParking = abstractParkingAreaDefaultConcurrentMinuteParking;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultMinuteParkingLimitationType = abstractParkingAreaDefaultMinuteParkingLimitationType;
      v1WebAbstractParkingAreaDto.abstractParkingAreaNotes = abstractParkingAreaNotes;
      v1WebAbstractParkingAreaDto.customerUuid = customerUuid;

      v1WebParkingAreaService.putParkingArea(v1WebAbstractParkingAreaDto).then((response: AxiosResponse) => {
        let v1WebAbstractParkingAreaDto = response.data as V1WebAbstractParkingAreaDTO;

        dispatch({
          type: PARKINGAREA_PUT,
          status: SUCCESS_STATUS,
          abstractParkingAreaUuid: v1WebAbstractParkingAreaDto.abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: v1WebAbstractParkingAreaDto.abstractParkingAreaParkingAreaCode,
          abstractParkingAreaAddress: v1WebAbstractParkingAreaDto.abstractParkingAreaAddress,
          abstractParkingAreaZip: v1WebAbstractParkingAreaDto.abstractParkingAreaZip,
          abstractParkingAreaCity: v1WebAbstractParkingAreaDto.abstractParkingAreaCity,
          abstractParkingAreaWarningMinutes: v1WebAbstractParkingAreaDto.abstractParkingAreaWarningMinutes,
          abstractParkingAreaTicketPrice: v1WebAbstractParkingAreaDto.abstractParkingAreaTicketPrice,
          abstractParkingAreaDefaultInitialUnlimitedParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialUnlimitedParking,
          abstractParkingAreaDefaultConcurrentUnlimitedParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentUnlimitedParking,
          abstractParkingAreaDefaultInitialMinuteParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialMinuteParking,
          abstractParkingAreaDefaultConcurrentMinuteParking: v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentMinuteParking,
          abstractParkingAreaNotes: v1WebAbstractParkingAreaDto.abstractParkingAreaNotes,
          abstractParkingAreaParkingAreaType: v1WebAbstractParkingAreaDto.abstractParkingAreaAreaType,
          customerUuid: v1WebAbstractParkingAreaDto.customerUuid,
          customerCompanyName: v1WebAbstractParkingAreaDto.customerCompanyName,
          parkingAreaDayNumberOfDays: (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayNumberOfDays,
          parkingAreaDayHourOfDay: (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayHourOfDay,
          parkingAreaDayMinuteOfHour: (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayMinuteOfHour,
          parkingAreaMinuteAllowedParkingMinutes: (v1WebAbstractParkingAreaDto as V1WebParkingAreaMinuteDTO).parkingAreaMinuteAllowedParkingMinutes
        })
      }).catch((error) => {
        dispatch({
          type: PARKINGAREA_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}