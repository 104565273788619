import { NavigateNext } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import { customerGetPageableList, customerSearchPageableList } from "redux/customer/customer.action";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { NavLink } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import v1WebCustomerService from 'service/v1/web/v1webcustomer.service';
import V1WebCustomerDTO from "dto/v1/web/v1webcustomer.dto";
import { AxiosResponse } from "axios";

interface OwnProps {
}

interface DispatchProps {
  customerGetPageableList: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  customerSearchPageableList: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  customerStateStatus: StateStatus
  customerPageableListCount: number
  customerPageableListPageNumber: number
  customerPageableListPageSize: number
  customerPageableListContent: Array<any[]>
  customerPageableListSort: string
  customerPageableListDirection: Direction
  customerPageableListKeyword: string
  customerErrorCode: number
  customerErrorMessage: string
  customerErrorUuid: string
}

interface FormProps {
  customerCompanyName: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: string;
  customerZip: string;
  customerCity: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerVatNumber: string;
  customerBankName: string;
  customerBankAccount: string;
  customerNotes: string;
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorCustomerListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({mode: 'all'});

  const onSearchFormSubmit: SubmitHandler<StateProps> = (stateProps: StateProps) => {
    if (!isViewLoading && stateProps.customerPageableListKeyword.length > 0) {
      props.customerSearchPageableList(stateProps.customerPageableListKeyword, 0, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection)
    }
  }

  const {
    handleSubmit: handleCreateFormSubmit,
    control: createFormControl,
    reset: resetCreateForm
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
    }
  });

  const onCreateFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      let v1WebCustomerDto: V1WebCustomerDTO = new V1WebCustomerDTO();

      v1WebCustomerDto.customerCompanyName = formProps.customerCompanyName;
      v1WebCustomerDto.customerFirstName = formProps.customerFirstName;
      v1WebCustomerDto.customerLastName = formProps.customerLastName;
      v1WebCustomerDto.customerAddress = formProps.customerAddress;
      v1WebCustomerDto.customerZip = formProps.customerZip;
      v1WebCustomerDto.customerCity = formProps.customerCity;
      v1WebCustomerDto.customerEmail = formProps.customerEmail;
      v1WebCustomerDto.customerPhoneNumber = formProps.customerPhoneNumber;
      v1WebCustomerDto.customerVatNumber = formProps.customerVatNumber;
      v1WebCustomerDto.customerBankName = formProps.customerBankName;
      v1WebCustomerDto.customerBankAccount = formProps.customerBankAccount;
      v1WebCustomerDto.customerNotes = formProps.customerNotes;

      setViewLoading(true);

      v1WebCustomerService.createCustomer(v1WebCustomerDto).then((response: AxiosResponse) => {
        setViewLoading(false);
        alert("Kunde oprettet");

        if(props.customerPageableListKeyword.length > 0) {
          props.customerSearchPageableList(props.customerPageableListKeyword, props.customerPageableListPageNumber, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
        } else {
          props.customerGetPageableList(props.customerPageableListPageNumber, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
        }

        setCreateDialogOpen(false);
      }).catch((error) => {
        setViewLoading(false);
        alert("Der skete en fejl: " + error.errorMessage);
      })
    }
  }

  const handleCustomerPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.customerPageableListKeyword.length > 0) {
      props.customerSearchPageableList(props.customerPageableListKeyword, 0, parseInt(event.target.value), props.customerPageableListSort, props.customerPageableListDirection);
    } else {
      props.customerGetPageableList(0, parseInt(event.target.value), props.customerPageableListSort, props.customerPageableListDirection);
    }
  };

  const handleCustomerPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.customerPageableListKeyword.length > 0) {
      props.customerSearchPageableList(props.customerPageableListKeyword, newPage, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
    } else {
      props.customerGetPageableList(newPage, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
    }
  }

  const handleCustomerDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if(props.customerPageableListDirection === Direction.ASC) {
      if (props.customerPageableListKeyword.length > 0) {
        props.customerSearchPageableList(props.customerPageableListKeyword, props.customerPageableListPageNumber, props.customerPageableListPageSize, property, Direction.DESC);
      } else {
        props.customerGetPageableList(props.customerPageableListPageNumber, props.customerPageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.customerPageableListKeyword.length > 0) {
        props.customerSearchPageableList(props.customerPageableListKeyword, props.customerPageableListPageNumber, props.customerPageableListPageSize, property, Direction.ASC);
      } else {
        props.customerGetPageableList(props.customerPageableListPageNumber, props.customerPageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleCustomerKeywordClear = () => {
    props.customerGetPageableList(props.customerPageableListPageNumber, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
  }

  const toogleCreateDialogOpen = () => {
    resetCreateForm();
    setCreateDialogOpen(!isCreateDialogOpen);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.customerPageableListKeyword.length > 0) {
        props.customerSearchPageableList(props.customerPageableListKeyword, props.customerPageableListPageNumber, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
      } else {
        props.customerGetPageableList(props.customerPageableListPageNumber, props.customerPageableListPageSize, props.customerPageableListSort, props.customerPageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch(props.customerStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("customerPageableListKeyword", props.customerPageableListKeyword)
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.customerStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const headCells: readonly HeadCell[] = [
    {
      id: "companyName",
      numeric: false,
      disablePadding: false,
      label: "Firma navn"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.customerErrorCode} errorMessage={props.customerErrorMessage} errorUuid={props.customerErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Kunder
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="customerPageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => {handleCustomerKeywordClear()}}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.customerPageableListSort === headCell.id}
                          direction={props.customerPageableListDirection}
                          onClick={handleCustomerDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.customerPageableListContent === undefined || props.customerPageableListContent.length === 0 ? props.customerPageableListPageSize : props.customerPageableListContent.length} tableColSpan={headCells.length} />
                    </>
                  ) : (
                    <>
                      {props.customerPageableListCount === undefined || props.customerPageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={headCells.length} />
                        </>
                      ) : (
                        <>
                          {props.customerPageableListContent.map(customer => (
                            <TableRow
                              hover
                              key={customer[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {customer[1]}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={customer[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={headCells.length + 1}
                      count={props.customerPageableListCount || 0}
                      rowsPerPage={props.customerPageableListPageSize || 5}
                      page={props.customerPageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({from, to, count}) => `${from}-${to} af ${count}`}
                      onPageChange={handleCustomerPageNumberChange}
                      onRowsPerPageChange={handleCustomerPageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
      <Dialog
        open={isCreateDialogOpen}
        onClose={toogleCreateDialogOpen}
        fullScreen
      >
        <form onSubmit={handleCreateFormSubmit(onCreateFormSubmit)}>
        <DialogTitle>Opret kunde</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld felterne nedenfor for at oprette en kunde
            </DialogContentText>
            <Divider />
            </DialogContent>
            <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerCompanyName"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Firma navn er påkrævet"
                    },
                    maxLength: {
                      value: 256,
                      message: "Firma navn må ikke overstige 256 tegn"
                    },
                    validate: {
                      value: ((value) => {
                        return new Promise((resolve) => {
                          if (value !== undefined && value.length >= 1) {
                            v1WebCustomerService.existsCompanyName(value).then((res) => {
                              if (res.data) {
                                resolve("Firma navn er allerede i brug")
                              } else {
                                resolve(true)
                              }
                            }).catch((error) => {
                              resolve(error);
                            })
                          }
                        });
                      })
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Firma navn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerVatNumber"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "CVR nummer må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="CVR nummer"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerEmail"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "E-mail må ikke overstige 256 tegn"
                    },
                    pattern: {
                      value: /^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
                      message: "Ugyldig e-mail"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="E-mail"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerFirstName"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Fornavn må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Fornavn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerLastName"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Efternavn må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Efternavn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={7}
                xs={7}
              >
                <Controller
                  name="customerAddress"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Adresse må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Adresse"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={5}
                xs={5}
              >
                <Controller
                  name="customerPhoneNumber"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Telefon må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Telefon"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xs={4}
              >
                <Controller
                  name="customerZip"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Postnummer må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Postnummer"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={8}
              >
                <Controller
                  name="customerCity"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "By må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="By"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerBankName"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Bank navn må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Bank navn"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerBankAccount"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Bank konto må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Bank konto"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="customerNotes"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Noter må ikke overstige 256 tegn"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Noter"
                      fullWidth
                      multiline
                      rows={4}
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleCreateDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Opret
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Fab
        onClick={toogleCreateDialogOpen}
        color="primary"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed"
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    customerStateStatus: redux.customer.status,
    customerPageableListCount: redux.customer.pageableListCount,
    customerPageableListPageNumber: redux.customer.pageableListPageNumber,
    customerPageableListPageSize: redux.customer.pageableListPageSize,
    customerPageableListContent: redux.customer.pageableListContent,
    customerPageableListSort: redux.customer.pageableListSort,
    customerPageableListDirection: redux.customer.pageableListDirection,
    customerPageableListKeyword: redux.customer.pageableListKeyword,
    customerErrorCode: redux.customer.errorCode,
    customerErrorMessage: redux.customer.errorMessage,
    customerErrorUuid: redux.customer.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    customerGetPageableList: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(customerGetPageableList(pageNumber, rowsPerPage, sort, direction))
    },
    customerSearchPageableList: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(customerSearchPageableList(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdministratorCustomerListView);