import { Store, configureStore, combineReducers } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import accountReducer from "./account/account.reducer";
import { AUTHENTICATION_LOGOUT } from "./authentication/authentication.action";
import authenticationReducer from "./authentication/authentication.reducer";
import authorityReducer from "./authority/authority.reducer";
import customerReducer from "./customer/customer.reducer";
import errorReducer from "./error/error.reducer";
import parkingAreaReducer from "./parkingarea/parkingarea.reducer";
import parkingLimitationReducer from "./parkinglimitation/parkinglimitation.reducer";
import parkPermitReducer from "./parkpermit/parkpermit.reducer";
import rootReducer from "./root/root.reducer";
import { FAILURE_STATUS } from "./statestatus";
import ticketReducer from "./ticket/ticket.reducer";
import userReducer from "./user/user.reducer";
import vehicleReducer from "./vehicle/vehicle.reducer";

const appReducer = combineReducers({
  account: accountReducer,
  authentication: authenticationReducer,
  authority: authorityReducer,
  customer: customerReducer,
  error: errorReducer,
  parkingArea: parkingAreaReducer,
  parkingLimitation: parkingLimitationReducer,
  parkPermit: parkPermitReducer,
  root: rootReducer,
  ticket: ticketReducer,
  user: userReducer,
  vehicle: vehicleReducer
})

const reducer = (state: any, action: any) => {
  if(action.type === AUTHENTICATION_LOGOUT) {
    localStorage.clear();
    const { authentication } = state;
    state = { authentication };
    state.authentication.status = FAILURE_STATUS;
    state.authentication.authenticated = false;
    state.authentication.errorMessage = action.errorMessage;
    state.authentication.errorCode = action.errorCode;
    
    return appReducer(state, action);
  }

  return appReducer(state, action);
}

export type RootRedux = ReturnType<typeof reducer>

export const store: Store = configureStore({ reducer, middleware: [thunk] });