import { axiosConfig } from "axios/axios.config";
import V2WebLoginDTO from "dto/v2/web/v2weblogin.dto";

const baseUrl = "authentication/v2/web";

class V2WebAuthenticationService {
  login(v2WebLoginDTO: V2WebLoginDTO) {
    return axiosConfig.post(baseUrl + "/login", v2WebLoginDTO);
  }
}

export default new V2WebAuthenticationService;