import { Direction } from "dto/direction";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { AuthorityAction, AUTHORITY_GET_PAGEABLELIST } from "./authority.action";

export type AuthorityState = {
  stateStatus: StateStatus;
  pageableListCount: number;
  pageableListPageNumber: number;
  pageableListPageSize: number;
  pageableListContent: Array<any[]>;
  pageableListSort: string;
  pageableListDirection: Direction;
  pageableListKeyword: string;
  errorCode: number
  errorMessage: string
  errorUuid: string
}

const initialState: AuthorityState = {
  stateStatus: IDLE_STATUS,
  pageableListCount: 0,
  pageableListPageNumber: 0,
  pageableListPageSize: 5,
  pageableListContent: Array<any[]>(),
  pageableListSort: "displayName",
  pageableListDirection: Direction.ASC,
  pageableListKeyword: "",
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function authorityReducer(state: AuthorityState = initialState, action: AuthorityAction): AuthorityState {
  switch (state.stateStatus) {
    case IDLE_STATUS: {
      return authorityIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return authorityLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return authoritySuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return authorityFailureReducer(state, action);
    }
    default: {
      return state;
    }
  }
}

export default authorityReducer;

function authorityIdleReducer(state: AuthorityState, action: AuthorityAction): AuthorityState {
  switch (action.type) {
    case AUTHORITY_GET_PAGEABLELIST: {
      return {
        ...state,
        stateStatus: action.stateStatus
      }
    }
    default: {
      return state;
    }
  }
}

function authorityLoadingReducer(state: AuthorityState, action: AuthorityAction): AuthorityState {
  switch (action.type) {
    case AUTHORITY_GET_PAGEABLELIST: {
      return {
        stateStatus: action.stateStatus,
        pageableListCount: action.pageableListCount,
        pageableListPageNumber: action.pageableListPageNumber,
        pageableListPageSize: action.pageableListPageSize,
        pageableListContent: action.pageableListContent,
        pageableListSort: action.pageableListSort,
        pageableListDirection: action.pageableListDirection,
        pageableListKeyword: "",
        errorCode: action.errorCode,
        errorMessage: action.errorMessage,
        errorUuid: action.errorUuid
      }
    }
    default: {
      return state;
    }
  }
}

function authoritySuccessReducer(state: AuthorityState, action: AuthorityAction): AuthorityState {
  switch (action.type) {
    case AUTHORITY_GET_PAGEABLELIST: {
      return {
        ...state,
        stateStatus: action.stateStatus
      }
    }
    default: {
      return state;
    }
  }
}

function authorityFailureReducer(state: AuthorityState, action: AuthorityAction): AuthorityState {
  switch (action.type) {
    case AUTHORITY_GET_PAGEABLELIST: {
      return {
        ...state,
        stateStatus: action.stateStatus
      }
    }
    default: {
      return state;
    }
  }
}