import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebTicketDTO from "dto/v1/web/v1webticket.dto";
import V1WebTicketListDownloadDTO from "dto/v1/web/v1webticketlistdownload.dto";

class V1WebTicketService {
  getTicket(ticketUuid: string) {
    return axiosConfig.get("v1/web/ticket/" + ticketUuid);
  }
  getTicketAsPDF(ticketUuid: string) {
    return axiosConfig.get("v1/web/ticket/" + ticketUuid + "/pdf", {
      responseType: 'arraybuffer',
    });
  }
  getTickets(page: Page) {
    return axiosConfig.get("v1/web/ticket?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getTicketListAsZIP(v1WebTicketListDownloadDto: V1WebTicketListDownloadDTO) {
    return axiosConfig.post("v1/web/ticket/zip", v1WebTicketListDownloadDto, {
      responseType: 'arraybuffer',
    });
  }
  searchTicket(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/ticket/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  getTicketPicture(ticketUuid: string) {
    return axiosConfig.get("v1/web/ticket/" + ticketUuid + "/picture");
  }
  getTicketPictureAsZIP(ticketUuid: string) {
    return axiosConfig.get("v1/web/ticket/" + ticketUuid + "/picture/zip", {
      responseType: 'arraybuffer',
    });
  }
  putTicket(v1WebTicketDto: V1WebTicketDTO) {
    return axiosConfig.put("v1/web/ticket/", v1WebTicketDto);
  }
}

export default new V1WebTicketService();