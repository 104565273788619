import { Button, Icon, ListItem, Typography } from "@mui/material";
import MenuItem from "./menuitem";
import { NavLink } from "react-router-dom";
import theme from "theme/theme";

interface Props {
    menuItem: MenuItem;
}

const NavItem = (props: Props) => {
  return (
    <ListItem
      disableGutters
      sx={{
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0
      }}
    >
      <Button
      
      component={NavLink}
      to={props.menuItem.href}
      sx={{
        color: theme.palette.text.secondary,
        fontWeight: theme.typography.fontWeightMedium,
        justifyContent: "flex-start",
        letterSpacing: 0,
        padding: "10px 8px",
        textTransform: "none",
        width: "100%",
        "&.active": {
          color: theme.palette.primary.main,
        }
      }}>
        <Icon
        sx={{
          marginRight: 3
        }}>{props.menuItem.icon}</Icon>
        <Typography
          color="textPrimary"
          variant="h6"
          sx={{
            marginRight: "auto",

          }}>
          {props.menuItem.title}
        </Typography>
      </Button>
    </ListItem>
  );
}

export default NavItem;