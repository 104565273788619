import { UserType } from "type/user.type";
import V1WebAbstractUserDTO from "./v1webabstractuser.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebUserAdministratorDTO extends V1WebAbstractUserDTO {

  constructor() {
    super();
    this.abstractUserUserType = UserType.ADMINISTRATOR;
  }
}