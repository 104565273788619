import DTO from "dto/dto";
import { LimitationType } from "type/limitation.type";
import V1DateTimeDTO from "../v1datetime.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebParkingLimitationDTO implements DTO {

  parkingLimitationUuid!: string;
  parkingLimitationUnlimitedParkingLimitationType!: LimitationType | null;
  parkingLimitationInitialUnlimitedParking!: number;
  parkingLimitationRemainingUnlimitedParking!: number;
  parkingLimitationConcurrentUnlimitedParking!: number;
  parkingLimitationAddRemainingUnlimitedParking!: number | null;
  parkingLimitationMinuteParkingLimitationType!: LimitationType | null;
  parkingLimitationInitialMinuteParkingV1DateTimeDto!: V1DateTimeDTO | null;
  parkingLimitationInitialMinuteParking!: number;
  parkingLimitationRemainingMinuteParking!: number;
  parkingLimitationRemainingMinuteParkingV1DateTimeDto!: V1DateTimeDTO | null;
  parkingLimitationConcurrentMinuteParking!: number;
  parkingLimitationIsEnabled!: boolean;
  parkingLimitationNotes!: string;
  abstractParkingAreaUuid!: string;
  abstractParkingAreaParkingAreaCode!: string;
  abstractParkingAreaAddress!: string;
  abstractParkingAreaZip!: string;
  abstractParkingAreaCity!: string;
  userClientPublicUuid!: string;
  userClientPublicUsername!: string;
}