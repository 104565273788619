import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebParkPermitListElementDTO from "dto/v1/web/v1webparkpermitlistelement.dto";
import V1WebTicketListElementDTO from "dto/v1/web/v1webticketlistelement.dto";
import V1WebVehicleDTO from "dto/v1/web/v1webvehicle.dto";
import V1WebVehicleListElementDTO from "dto/v1/web/v1webvehiclelistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebVehicleService from "service/v1/web/v1webvehicle.service";
import { VehicleState } from "./vehicle.reducer";

export const VEHICLE_GET = "vehicle/get"
export const VEHICLE_GET_PAGEABLELIST = "vehicle/get/pageablelist";
export const VEHICLE_SEARCH_PAGEABLELIST = "vehicle/search/pageablelist";
export const VEHICLE_GET_PARKPERMIT_PAGEABLELIST = "vehicle/get/parkpermit/pageablelist";
export const VEHICLE_GET_TICKET_PAGEABLELIST = "vehicle/get/ticket/pageablelist";
export const VEHICLE_PUT = "vehicle/put";

export interface VehicleAction {
  type:
    typeof VEHICLE_GET |
    typeof VEHICLE_GET_PAGEABLELIST |
    typeof VEHICLE_SEARCH_PAGEABLELIST |
    typeof VEHICLE_GET_PARKPERMIT_PAGEABLELIST |
    typeof VEHICLE_GET_TICKET_PAGEABLELIST |
    typeof VEHICLE_PUT
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  vehicleUuid: string
  vehicleVehicleId: string
  vehicleNotes: string
  vehicleIsAllowedToPark: boolean
  parkPermitPageableListCount: number
  parkPermitPageableListPageNumber: number
  parkPermitPageableListPageSize: number
  parkPermitPageableListContent: Array<any[]>
  parkPermitPageableListSort: string
  parkPermitPageableListDirection: Direction
  parkPermitPageableListKeyword: string
  ticketPageableListCount: number
  ticketPageableListPageNumber: number
  ticketPageableListPageSize: number
  ticketPageableListContent: Array<any[]>
  ticketPageableListSort: string
  ticketPageableListDirection: Direction
  ticketPageableListKeyword: string
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type VehicleThunkAction = ThunkAction<Promise<void>, VehicleState, any, VehicleAction>;

export function vehicleGet(vehicleUuid: string): VehicleThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: VEHICLE_GET,
        status: LOADING_STATUS
      });

      v1WebVehicleService.getVehicle(vehicleUuid).then((response: AxiosResponse) => {
        let v1WebVehicleDto = response.data as V1WebVehicleDTO

        dispatch({
          type: VEHICLE_GET,
          status: SUCCESS_STATUS,
          vehicleUuid: v1WebVehicleDto.vehicleUuid,
          vehicleVehicleId: v1WebVehicleDto.vehicleVehicleId,
          vehicleNotes: v1WebVehicleDto.vehicleNotes,
          vehicleIsAllowedToPark: v1WebVehicleDto.vehicleIsAllowedToPark
        });
      }).catch((error) => {
        dispatch({
          type: VEHICLE_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function vehicleGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): VehicleThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: VEHICLE_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebVehicleService.getVehicles(page).then((response: AxiosResponse) => {
        let vehiclePageableList = response.data as Response<V1WebVehicleListElementDTO>

        let pageableListContent: any[] = [];
        vehiclePageableList.content.forEach((value) => {
          pageableListContent.push([value.vehicleUuid, value.vehicleVehicleId])
        });

        dispatch({
          type: VEHICLE_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: vehiclePageableList.totalElements,
          pageableListPageNumber: vehiclePageableList.pageable.pageNumber,
          pageableListPageSize: vehiclePageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: VEHICLE_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function vehicleSearchPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): VehicleThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: VEHICLE_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebVehicleService.searchVehicle(v1KeywordDto, page).then((response: AxiosResponse) => {
        let vehiclePageableList = response.data as Response<V1WebVehicleListElementDTO>

        let pageableListContent: any[] = [];
        vehiclePageableList.content.forEach((value) => {
          pageableListContent.push([value.vehicleUuid, value.vehicleVehicleId])
        });

        dispatch({
          type: VEHICLE_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: vehiclePageableList.totalElements,
          pageableListPageNumber: vehiclePageableList.pageable.pageNumber,
          pageableListPageSize: vehiclePageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: VEHICLE_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function vehicleGetParkPermitPageableList(uuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): VehicleThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: VEHICLE_GET_PARKPERMIT_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebVehicleService.getVehicleParkPermit(uuid, page).then((response: AxiosResponse) => {
        let parkPermitPageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        parkPermitPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId])
        });

        dispatch({
          type: VEHICLE_GET_PARKPERMIT_PAGEABLELIST,
          status: SUCCESS_STATUS,
          parkPermitPageableListCount: parkPermitPageableList.totalElements,
          parkPermitPageableListPageNumber: parkPermitPageableList.pageable.pageNumber,
          parkPermitPageableListPageSize: parkPermitPageableList.pageable.pageSize,
          parkPermitPageableListContent: pageableListContent,
          parkPermitPageableListSort: sort,
          parkPermitPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: VEHICLE_GET_PARKPERMIT_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function vehicleGetTicketPageableList(vehicleUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): VehicleThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: VEHICLE_GET_TICKET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebVehicleService.getVehicleTicket(vehicleUuid, page).then((response: AxiosResponse) => {
        let ticketPageableList = response.data as Response<V1WebTicketListElementDTO>

        let pageableListContent: any[] = [];
        ticketPageableList.content.forEach((value) => {
          pageableListContent.push([value.ticketUuid, value.ticketTicketId, value.ticketVehicleId, value.ticketParkingAreaCode])
        });

        dispatch({
          type: VEHICLE_GET_TICKET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          ticketPageableListCount: ticketPageableList.totalElements,
          ticketPageableListPageNumber: ticketPageableList.pageable.pageNumber,
          ticketPageableListPageSize: ticketPageableList.pageable.pageSize,
          ticketPageableListContent: pageableListContent,
          ticketPageableListSort: sort,
          ticketPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: VEHICLE_GET_TICKET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function vehiclePut(vehicleUuid: string, vehicleNotes: string, vehicleIsAllowedToPark: boolean): VehicleThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: VEHICLE_PUT,
        status: LOADING_STATUS
      });

      let v1WebVehicleDto: V1WebVehicleDTO = {
        vehicleUuid: vehicleUuid,
        vehicleVehicleId: "",
        vehicleNotes: vehicleNotes,
        vehicleIsAllowedToPark: vehicleIsAllowedToPark
      }

      v1WebVehicleService.putVehicle(v1WebVehicleDto).then((response: AxiosResponse) => {
        v1WebVehicleDto = response.data as V1WebVehicleDTO;

        dispatch({
          type: VEHICLE_PUT,
          status: SUCCESS_STATUS,
          vehicleUuid: v1WebVehicleDto.vehicleUuid,
          vehicleNotes: v1WebVehicleDto.vehicleNotes,
          vehicleIsAllowedToPark: v1WebVehicleDto.vehicleIsAllowedToPark
        })
      }).catch((error) => {
        dispatch({
          type: VEHICLE_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        });
      });

      resolve();
    });
  }
}