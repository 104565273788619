import { Alert, Box, Button, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ThunkDispatch } from "redux-thunk";
import { RootRedux } from "redux/store";
import { Buffer } from "buffer";
import V1ResetPasswordDTO from "dto/v1/v1resetpassword.dto";
import v1AuthenticationService from "service/v1/v1authentication.service";
import { AxiosResponse } from "axios";
import { logout } from "redux/authentication/authentication.action";

interface OwnProps {
}

interface DispatchProps {
  logout: () => void;
}

interface StateProps {
}

interface FormProps {
  newPassword: string;
  newPasswordConfirm: string;
}

type Props = StateProps & OwnProps & DispatchProps;

const ResetPasswordView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [viewAlert, setViewAlert] = useState(<></>);

  const navigate = useNavigate();
  const params = useParams();

  const {
    handleSubmit: handleResetPasswordFormSubmit,
    control: resetPasswordFormControl,
    setError: setResetPasswordError
  } = useForm<FormProps>({
    mode: 'all'
  });

  const onResetPasswordFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      if (formProps.newPassword === formProps.newPasswordConfirm && params.base64ResetToken !== undefined) {
        setViewLoading(true);

        let v1ResetPasswordDTO: V1ResetPasswordDTO = {
          newPassword: formProps.newPassword,
          newPasswordConfirm: formProps.newPasswordConfirm,
          base64ResetToken: params.base64ResetToken
        }

        v1AuthenticationService.resetPassword(v1ResetPasswordDTO).then((response: AxiosResponse) => {
          alert("Adgangskode er nu nulstillet");
          props.logout();
          navigate('/', { replace: false });
        }).catch((error) => {
          setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">{error.errorMessage}</Alert>);
          setViewLoading(true);
          setShowViewAlert(true);
        });

      } else {
        setResetPasswordError("newPasswordConfirm", { type: "custom", message: "Adgangskoder er ikke ens" });
      }
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.base64ResetToken !== undefined) {
        let resetToken = Buffer.from(params.base64ResetToken, "base64").toString();
        let tokenSplit: string[] = resetToken.split(".");

        if (tokenSplit.length === 3) {
          try {
            let tokenBody = JSON.parse(Buffer.from(tokenSplit[1], "base64").toString());

            if (Date.now() >= tokenBody.exp * 1000) {
              setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">Gendannelses link er udløbet</Alert>);
              setViewLoading(true);
              setShowViewAlert(true);
            }
          } catch (error) {
            setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">Ugyldigt gendannelses link</Alert>);
            setViewLoading(true);
            setShowViewAlert(true);
          }
        } else {
          setViewAlert(<Alert sx={{ marginTop: 1 }} severity="error">Ugyldigt gendannelses link</Alert>);
          setViewLoading(true);
          setShowViewAlert(true);
        }
      }

      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <form onSubmit={handleResetPasswordFormSubmit(onResetPasswordFormSubmit)}>
        <Box mb={3}>
          <Typography
            color="textPrimary"
            variant="h2"
          >
            Nulstil adgangskode
          </Typography>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="body2"
          >
            Angiv venligst en ny adgangskode til din konto og bekræft herefter ved at indtaste den samme adgangskode igen.
          </Typography>
        </Box>
        <Controller
          name="newPassword"
          control={resetPasswordFormControl}
          rules={{
            required: {
              value: true,
              message: "Adgangskode er påkrævet"
            },
            minLength: {
              value: 6,
              message: "Adgangskode skal være på minimum 6 tegn"
            },
            maxLength: {
              value: 256,
              message: "Indtastning må ikke overstige 256 tegn"
            },
            validate: {
              value: ((value) => {
                return new Promise((resolve) => {
                  if (!value.match(/[a-z]/)) {
                    resolve("Adgangskode skal indeholde minimum et lille bogstav")
                  }

                  if (!value.match(/[A-Z]/)) {
                    resolve("Adgangskode skal indeholde minimum et stort bogstav")
                  }

                  if (!value.match(/[0-9]/)) {
                    resolve("Adgangskode skal indeholde minimum et tal")
                  }

                  if (!value.match(/[!@#$%&^=?+-]/)) {
                    resolve("Adgangskode skal indeholde minimum et af følgende tegn: !@#$%&^=?+-")
                  }

                  resolve(true);
                })
              })
            }
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Adgangskode"
              type="password"
              fullWidth
              margin="normal"
              value={value || ""}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        <Controller
          name="newPasswordConfirm"
          control={resetPasswordFormControl}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              label="Bekræft adgangskode"
              type="password"
              fullWidth
              margin="normal"
              value={value || ""}
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
            />
          )}
        />
        {showViewAlert ? (
          viewAlert
        ) : (
          <></>
        )}
        <Box my={2}>
          <Button
            color="primary"
            disabled={isViewLoading}
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Nulstil adgangskode
          </Button>
        </Box>
      </form>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    logout: async () => {
      await dispatch(logout())
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordView);