import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebParkingLimitationDTO from "dto/v1/web/v1webparkinglimitation.dto";
import V1WebParkPermitDTO from "dto/v1/web/v1webparkpermit.dto";

const baseUrl = "v1/web/parkinglimitation";

class V1WebParkingLimitationService {
  createParkingLimitation(v1WebParkingLimitationDto: V1WebParkPermitDTO) {
    return axiosConfig.post(baseUrl, v1WebParkingLimitationDto);
  }
  createParkingLimitationAsUserClient(v1WebParkingLimitationDto: V1WebParkingLimitationDTO) {
    return axiosConfig.post(baseUrl + "/operator", v1WebParkingLimitationDto);
  }
  getParkingLimitation(parkingLimitationUuid: string) {
    return axiosConfig.get(baseUrl + "/" + parkingLimitationUuid);
  }
  getParkingLimitationAsUserClient(parkingLimitationUuid: string) {
    return axiosConfig.get(baseUrl + "/operator/" + parkingLimitationUuid);
  }
  getParkingLimitationPageableList(page: Page) {
    return axiosConfig.get(baseUrl + "?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getParkingLimitationPageableListForUserClientAsUserClient(page: Page) {
    return axiosConfig.get(baseUrl + "/operator?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchParkingLimitationPageableList(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post(baseUrl + "/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  searchParkingLimitationPageableListForUserClientAsUserClient(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post(baseUrl + "/operator/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  getParkingLimitationParkPermitPageableListAsUserClient(parkingLimitationUuid: string, page: Page) {
    return axiosConfig.get(baseUrl + "/operator/" + parkingLimitationUuid + "/parkpermit?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  putParkingLimitation(v1WebParkinglimitationDto: V1WebParkingLimitationDTO) {
    return axiosConfig.put(baseUrl, v1WebParkinglimitationDto);
  }
  putParkingLimitationAsUserClient(v1WebParkinglimitationDto: V1WebParkingLimitationDTO) {
    return axiosConfig.put(baseUrl + "/userclient", v1WebParkinglimitationDto);
  }
}

export default new V1WebParkingLimitationService();