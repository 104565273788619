import { AxiosResponse } from "axios";
import V1WebAccountUserDetailsDTO from "dto/v1/web/v1webaccountuserdetails.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ROOT_INIT_USER } from "redux/root/root.action";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebAccountService from "service/v1/web/v1webaccount.service";
import { UserType } from "type/user.type";
import { AccountState } from "./account.reducer";

export const ACCOUNT_GET_USERDETAILS = "account/get/userdetails";
export const ACCOUNT_PUT_USERDETAILS = "account/put/userdetails";

export interface AccountAction {
  type: 
    typeof ACCOUNT_GET_USERDETAILS |
    typeof ACCOUNT_PUT_USERDETAILS
  status: StateStatus
  username: string
  firstName: string
  lastName: string
  address: string
  zip: string
  city: string
  email: string
  phone: string
  userType: UserType
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type AccountThunkAction = ThunkAction<Promise<void>, AccountState, any, AccountAction>;

export function accountGetUserDetails(): AccountThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ACCOUNT_GET_USERDETAILS,
        status: LOADING_STATUS
      })

      v1WebAccountService.getUserDetails().then((response: AxiosResponse) => {
        let userDetails = response.data as V1WebAccountUserDetailsDTO;

        dispatch({
          type: ACCOUNT_GET_USERDETAILS,
          status: SUCCESS_STATUS,
          username: userDetails.abstractUserUsername,
          firstName: userDetails.abstractUserFirstName,
          lastName: userDetails.abstractUserLastName,
          address: userDetails.abstractUserAddress,
          zip: userDetails.abstractUserZip,
          city: userDetails.abstractUserCity,
          email: userDetails.abstractUserEmail,
          phone: userDetails.abstractUserPhone,
          userType: userDetails.abstractUserUserType
        });
      }).catch((error) => {
        dispatch({
          type: ACCOUNT_GET_USERDETAILS,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        });
      });

      resolve();
    });
  }
}

export function accountPutUserDetails(username: string, firstName: string, lastName: string, address: string, zip: string, city: string, email: string, phone: string): AccountThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
          type: ACCOUNT_PUT_USERDETAILS,
          status: LOADING_STATUS
      })

      let v1WebAccountUserDetailsDTO: V1WebAccountUserDetailsDTO = {
        abstractUserUsername: username,
        abstractUserFirstName: firstName,
        abstractUserLastName: lastName,
        abstractUserAddress: address,
        abstractUserZip: zip,
        abstractUserCity: city,
        abstractUserEmail: email,
        abstractUserPhone: phone,
        abstractUserUserType: null
      }

      v1WebAccountService.putUserDetails(v1WebAccountUserDetailsDTO).then((response: AxiosResponse) => {
        v1WebAccountUserDetailsDTO = response.data as V1WebAccountUserDetailsDTO;

        dispatch({
          type: ACCOUNT_PUT_USERDETAILS,
          status: SUCCESS_STATUS,
          username: v1WebAccountUserDetailsDTO.abstractUserUsername,
          firstName: v1WebAccountUserDetailsDTO.abstractUserFirstName,
          lastName: v1WebAccountUserDetailsDTO.abstractUserLastName,
          address: v1WebAccountUserDetailsDTO.abstractUserAddress,
          zip: v1WebAccountUserDetailsDTO.abstractUserZip,
          city: v1WebAccountUserDetailsDTO.abstractUserCity,
          email: v1WebAccountUserDetailsDTO.abstractUserEmail,
          phone: v1WebAccountUserDetailsDTO.abstractUserPhone,
          userType: v1WebAccountUserDetailsDTO.abstractUserUserType
        });

        dispatch({
          type: ROOT_INIT_USER
        });
      }).catch((error) => {
        dispatch({
          type: ACCOUNT_PUT_USERDETAILS,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        });
      });

      resolve();
    })
  }
}