import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import UUIDHelper from "helper/uuidhelper";
import { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { connect } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { customerGet, customerGetParkingAreaPageableList, customerGetTransactionPageableList, customerGetUserClientPageableList, customerPut } from "redux/customer/customer.action";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store"
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import ErrorContainer from "component/errorcontainer";
import { Typography, Grid, Card, CardContent, CardHeader, TextField, Box, Button, Divider, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, IconButton, Tooltip } from "@mui/material";
import CustomContainer from "component/customcontainer";
import SpinnerContainer from "component/spinnercontainer";
import v1WebCustomerService from 'service/v1/web/v1webcustomer.service';
import { TransactionType } from "type/transaction.type";
import { NavigateNext } from "@mui/icons-material";

interface OwnProps {
}

interface DispatchProps {
  customerGet: (customerUuid: string) => void;
  customerGetParkingAreaPageableList: (customerUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  customerGetUserClientPageableList: (customerUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  customerGetTransactionPageableList: (customerUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  customerPut: (customerUuid: string, customerCompanyName: string, customerFirstName: string, customerLastName: string, customerAddress: string, customerZip: string, customerCity: string, customerEmail: string, customerPhoneNumber: string, customerVatNumber: string, customerBankName: string, customerBankAccount: string, customerNotes: string) => void;
}

interface StateProps {
  customerStateStatus: StateStatus;
  customerUuid: string;
  customerCompanyName: string;
  customerFirstName: string;
  customerLastName: string;
  customerAddress: string;
  customerZip: string;
  customerCity: string;
  customerEmail: string;
  customerPhoneNumber: string;
  customerVatNumber: string;
  customerBankName: string;
  customerBankAccount: string;
  customerNotes: string;
  customerParkingAreaStatus: StateStatus;
  customerParkingAreaPageableListCount: number;
  customerParkingAreaPageableListPageNumber: number;
  customerParkingAreaPageableListPageSize: number;
  customerParkingAreaPageableListContent: Array<any[]>;
  customerParkingAreaPageableListSort: string;
  customerParkingAreaPageableListDirection: Direction;
  customerUserClientStatus: StateStatus;
  customerUserClientPageableListCount: number;
  customerUserClientPageableListPageNumber: number;
  customerUserClientPageableListPageSize: number;
  customerUserClientPageableListContent: Array<any[]>;
  customerUserClientPageableListSort: string;
  customerUserClientPageableListDirection: Direction;
  customerTransactionStatus: StateStatus;
  customerTransactionPageableListCount: number;
  customerTransactionPageableListPageNumber: number;
  customerTransactionPageableListPageSize: number;
  customerTransactionPageableListContent: Array<any[]>;
  customerTransactionPageableListSort: string;
  customerTransactionPageableListDirection: Direction;
  customerErrorCode: number;
  customerErrorMessage: string;
  customerErrorUuid: string;
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorCustomerView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isParkingAreaLoaded, setParkingAreaLoaded] = useState(false);
  const [isUserClientLoaded, setUserClientLoaded] = useState(false);
  const [isTransactionLoaded, setTransactionLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkingAreaLoading, setParkingAreaLoading] = useState(true);
  const [isUserClientLoading, setUserClientLoading] = useState(true);
  const [isTransactionLoading, setTransactionLoading] = useState(true);
  const [hasViewError, setViewError] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    setValue: setUpdateFormValue,
  } = useForm<StateProps>({
    mode: 'all'
  });

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading) {
      props.customerPut(formProps.customerUuid, formProps.customerCompanyName, formProps.customerFirstName, formProps.customerLastName, formProps.customerAddress, formProps.customerZip, formProps.customerCity, formProps.customerEmail, formProps.customerPhoneNumber, formProps.customerVatNumber, formProps.customerBankName, formProps.customerBankAccount, formProps.customerNotes);
    }
  }

  const handleCustomerParkingAreaPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.customerGetParkingAreaPageableList(props.customerUuid, 0, parseInt(event.target.value), props.customerParkingAreaPageableListSort, props.customerParkingAreaPageableListDirection);
  };

  const handleCustomerParkingAreaPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.customerGetParkingAreaPageableList(props.customerUuid, newPage, props.customerParkingAreaPageableListPageSize, props.customerParkingAreaPageableListSort, props.customerParkingAreaPageableListDirection)
  }

  const handleCustomerParkingAreaDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.customerParkingAreaPageableListDirection === Direction.ASC) {
      props.customerGetParkingAreaPageableList(props.customerUuid, props.customerParkingAreaPageableListPageNumber, props.customerParkingAreaPageableListPageSize, property, Direction.DESC);
    } else {
      props.customerGetParkingAreaPageableList(props.customerUuid, props.customerParkingAreaPageableListPageNumber, props.customerParkingAreaPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleCustomerUserClientPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.customerGetUserClientPageableList(props.customerUuid, 0, parseInt(event.target.value), props.customerUserClientPageableListSort, props.customerUserClientPageableListDirection);
  };

  const handleCustomerUserClientPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.customerGetUserClientPageableList(props.customerUuid, newPage, props.customerUserClientPageableListPageSize, props.customerUserClientPageableListSort, props.customerUserClientPageableListDirection)
  }

  const handleCustomerUserClientDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.customerUserClientPageableListDirection === Direction.ASC) {
      props.customerGetUserClientPageableList(props.customerUuid, props.customerUserClientPageableListPageNumber, props.customerUserClientPageableListPageSize, property, Direction.DESC);
    } else {
      props.customerGetUserClientPageableList(props.customerUuid, props.customerUserClientPageableListPageNumber, props.customerUserClientPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleCustomerTransactionPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.customerGetTransactionPageableList(props.customerUuid, 0, parseInt(event.target.value), props.customerTransactionPageableListSort, props.customerTransactionPageableListDirection);
  };

  const handleCustomerTransactionPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.customerGetTransactionPageableList(props.customerUuid, newPage, props.customerTransactionPageableListPageSize, props.customerTransactionPageableListSort, props.customerTransactionPageableListDirection)
  }

  const handleCustomerTransactionDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.customerTransactionPageableListDirection === Direction.ASC) {
      props.customerGetTransactionPageableList(props.customerUuid, props.customerTransactionPageableListPageNumber, props.customerTransactionPageableListPageSize, property, Direction.DESC);
    } else {
      props.customerGetTransactionPageableList(props.customerUuid, props.customerTransactionPageableListPageNumber, props.customerTransactionPageableListPageSize, property, Direction.ASC);
    }
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.customerUuid !== undefined && UUIDHelper.validateUUID(params.customerUuid)) {
        props.customerGet(params.customerUuid);
      } else {
        navigate('404', { replace: true });
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isParkingAreaLoaded && props.customerUuid !== undefined && props.customerUuid !== "") {
      props.customerGetParkingAreaPageableList(props.customerUuid, props.customerParkingAreaPageableListPageNumber, props.customerParkingAreaPageableListPageSize, props.customerParkingAreaPageableListSort, props.customerParkingAreaPageableListDirection);
      setParkingAreaLoaded(true);
    }

    if (!isUserClientLoaded && props.customerUuid !== undefined && props.customerUuid !== "") {
      props.customerGetUserClientPageableList(props.customerUuid, props.customerUserClientPageableListPageNumber, props.customerUserClientPageableListPageSize, props.customerUserClientPageableListSort, props.customerUserClientPageableListDirection);
      setUserClientLoaded(true);
    }

    if (!isTransactionLoaded && props.customerUuid !== undefined && props.customerUuid !== "") {
      props.customerGetTransactionPageableList(props.customerUuid, props.customerTransactionPageableListPageNumber, props.customerTransactionPageableListPageSize, props.customerTransactionPageableListSort, props.customerTransactionPageableListDirection);
      setTransactionLoaded(true);
    }
  }, [props.customerUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.customerStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("customerUuid", props.customerUuid);
        setUpdateFormValue("customerCompanyName", props.customerCompanyName);
        setUpdateFormValue("customerFirstName", props.customerFirstName);
        setUpdateFormValue("customerLastName", props.customerLastName);
        setUpdateFormValue("customerAddress", props.customerAddress);
        setUpdateFormValue("customerZip", props.customerZip);
        setUpdateFormValue("customerCity", props.customerCity);
        setUpdateFormValue("customerEmail", props.customerEmail);
        setUpdateFormValue("customerPhoneNumber", props.customerPhoneNumber);
        setUpdateFormValue("customerVatNumber", props.customerVatNumber);
        setUpdateFormValue("customerBankName", props.customerBankName);
        setUpdateFormValue("customerBankAccount", props.customerBankAccount);
        setUpdateFormValue("customerNotes", props.customerNotes);
        setViewLoading(false);
        setViewError(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.customerStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.customerParkingAreaStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setParkingAreaLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setParkingAreaLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setParkingAreaLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.customerParkingAreaStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.customerUserClientStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setUserClientLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUserClientLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setUserClientLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.customerUserClientStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.customerTransactionStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setTransactionLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setTransactionLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setTransactionLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.customerTransactionStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const customerParkingAreaHeadCells: readonly HeadCell[] = [
    {
      id: "areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Adresse"
    }
  ]

  const customerUserClientHeadCells: readonly HeadCell[] = [
    {
      id: "username",
      numeric: false,
      disablePadding: false,
      label: "Brugernavn"
    }
  ]

  const customerTransactionHeadCells: readonly HeadCell[] = [
    {
      id: "transactionType",
      numeric: false,
      disablePadding: false,
      label: "Type"
    },
    {
      id: "transactionCost",
      numeric: false,
      disablePadding: false,
      label: "Beløb"
    },
    {
      id: "currency",
      numeric: false,
      disablePadding: false,
      label: "Valuta"
    }
  ]

  if (hasViewError) {
    return (
      <ErrorContainer errorCode={props.customerErrorCode} errorMessage={props.customerErrorMessage} errorUuid={props.customerErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Kunde
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    <CardHeader
                      subheader={"Generelle oplysninger for kunden"}
                      title="Kunde oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerCompanyName"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Firma navn er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "Firma navn må ikke overstige 256 tegn"
                              },
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value !== undefined && value.length >= 1) {
                                      if (value.toLocaleLowerCase() === props.customerCompanyName.toLocaleLowerCase()) {
                                        resolve(true)
                                      }
                                      v1WebCustomerService.existsCompanyName(value).then((res) => {
                                        if (res.data) {
                                          resolve("Firma navn er allerede i brug")
                                        } else {
                                          resolve(true)
                                        }
                                      }).catch((error) => {
                                        resolve(error);
                                      })
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Firma navn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerFirstName"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Fornavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Fornavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerLastName"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Efternavn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Efternavn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerAddress"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Adresse må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerZip"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Postnummer må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerCity"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "By må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerEmail"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "E-mail må ikke overstige 256 tegn"
                              },
                              pattern: {
                                value: /^[\w!#$%&"*+/=?`{|}~^-]+(?:\.[\w!#$%&"*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/,
                                message: "Ugyldig e-mail"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="E-mail"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerPhoneNumber"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Telefon må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Telefon"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerVatNumber"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "CVR nummer må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="CVR nummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerBankName"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Bank navn må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Bank navn"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerBankAccount"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Bank konto må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Bank konto"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="customerNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem kunde oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Card>
                  <CardHeader
                    subheader={"Alle parkeringsområder tildelt denne kunde"}
                    title="Parkeringsområder" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {customerParkingAreaHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.customerParkingAreaPageableListSort === headCell.id}
                              direction={props.customerParkingAreaPageableListDirection}
                              onClick={handleCustomerParkingAreaDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isParkingAreaLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.customerParkingAreaPageableListContent === undefined || props.customerParkingAreaPageableListContent.length === 0 ? props.customerParkingAreaPageableListPageSize : props.customerParkingAreaPageableListContent.length} tableColSpan={customerParkingAreaHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.customerParkingAreaPageableListContent === undefined || props.customerParkingAreaPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={customerParkingAreaHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.customerParkingAreaPageableListContent.map(parkingArea => (
                                <TableRow
                                  hover
                                  key={parkingArea[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {parkingArea[1]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {parkingArea[2] + ", " + parkingArea[3] + " " + parkingArea[4]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title="Vis">
                                      <IconButton aria-label="Edit"
                                        edge='end'
                                        component={NavLink}
                                        to={"../parkingarea/" + parkingArea[0]}
                                      >
                                        <NavigateNext />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={customerParkingAreaHeadCells.length + 1}
                          count={props.customerParkingAreaPageableListCount !== undefined ? props.customerParkingAreaPageableListCount : 0}
                          rowsPerPage={props.customerParkingAreaPageableListPageSize !== undefined ? props.customerParkingAreaPageableListPageSize : 5}
                          page={props.customerParkingAreaPageableListPageNumber !== undefined ? props.customerParkingAreaPageableListPageNumber : 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleCustomerParkingAreaPageNumberChange}
                          onRowsPerPageChange={handleCustomerParkingAreaPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
                <Card
                  sx={{
                    marginTop: 3
                  }}
                >
                  <CardHeader
                    subheader={"Alle klient brugere tilknyttet transaktion oprettelse"}
                    title="Klient brugere" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {customerUserClientHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.customerUserClientPageableListSort === headCell.id}
                              direction={props.customerUserClientPageableListDirection}
                              onClick={handleCustomerUserClientDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isUserClientLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.customerUserClientPageableListContent === undefined || props.customerUserClientPageableListContent.length === 0 ? props.customerUserClientPageableListPageSize : props.customerUserClientPageableListContent.length} tableColSpan={customerUserClientHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.customerUserClientPageableListContent === undefined || props.customerUserClientPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={customerUserClientHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.customerUserClientPageableListContent.map(userClient => (
                                <TableRow
                                  hover
                                  key={userClient[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {userClient[1]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <Tooltip title="Vis">
                                      <IconButton aria-label="Edit"
                                        edge='end'
                                        component={NavLink}
                                        to={"../user/" + userClient[0]}
                                      >
                                        <NavigateNext />
                                      </IconButton>
                                    </Tooltip>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={customerUserClientHeadCells.length + 1}
                          count={props.customerUserClientPageableListCount !== undefined ? props.customerUserClientPageableListCount : 0}
                          rowsPerPage={props.customerUserClientPageableListPageSize !== undefined ? props.customerUserClientPageableListPageSize : 5}
                          page={props.customerUserClientPageableListPageNumber !== undefined ? props.customerUserClientPageableListPageNumber : 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleCustomerUserClientPageNumberChange}
                          onRowsPerPageChange={handleCustomerUserClientPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
                <Card
                  sx={{
                    marginTop: 3
                  }}
                >
                  <CardHeader
                    subheader={"Alle transaktioner tilknyttet kunden"}
                    title="Transaktioner" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {customerTransactionHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.customerTransactionPageableListSort === headCell.id}
                              direction={props.customerTransactionPageableListDirection}
                              onClick={handleCustomerTransactionDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isTransactionLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.customerTransactionPageableListContent === undefined || props.customerTransactionPageableListContent.length === 0 ? props.customerTransactionPageableListPageSize : props.customerTransactionPageableListContent.length} tableColSpan={customerTransactionHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.customerTransactionPageableListContent === undefined || props.customerTransactionPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={customerTransactionHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.customerTransactionPageableListContent.map(transaction => (
                                <TableRow
                                  hover
                                  key={transaction[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {transaction[3] === TransactionType.TEXTMESSAGE &&
                                        <>
                                          SMS transaktion
                                        </>
                                      }
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {transaction[2]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {transaction[1]}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={customerTransactionHeadCells.length + 1}
                          count={props.customerTransactionPageableListCount !== undefined ? props.customerTransactionPageableListCount : 0}
                          rowsPerPage={props.customerTransactionPageableListPageSize !== undefined ? props.customerTransactionPageableListPageSize : 5}
                          page={props.customerTransactionPageableListPageNumber !== undefined ? props.customerTransactionPageableListPageNumber : 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleCustomerTransactionPageNumberChange}
                          onRowsPerPageChange={handleCustomerTransactionPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    customerStateStatus: redux.customer.status,
    customerUuid: redux.customer.customerUuid,
    customerCompanyName: redux.customer.customerCompanyName,
    customerFirstName: redux.customer.customerFirstName,
    customerLastName: redux.customer.customerLastName,
    customerAddress: redux.customer.customerAddress,
    customerZip: redux.customer.customerZip,
    customerCity: redux.customer.customerCity,
    customerEmail: redux.customer.customerEmail,
    customerPhoneNumber: redux.customer.customerPhoneNumber,
    customerVatNumber: redux.customer.customerVatNumber,
    customerBankName: redux.customer.customerBankName,
    customerBankAccount: redux.customer.customerBankAccount,
    customerNotes: redux.customer.customerNotes,
    customerParkingAreaStatus: redux.customer.parkingAreaStatus,
    customerParkingAreaPageableListCount: redux.customer.parkingAreaPageableListCount,
    customerParkingAreaPageableListPageNumber: redux.customer.parkingAreaPageableListPageNumber,
    customerParkingAreaPageableListPageSize: redux.customer.parkingAreaPageableListPageSize,
    customerParkingAreaPageableListContent: redux.customer.parkingAreaPageableListContent,
    customerParkingAreaPageableListSort: redux.customer.parkingAreaPageableListSort,
    customerParkingAreaPageableListDirection: redux.customer.parkingAreaPageableListDirection,
    customerUserClientStatus: redux.customer.userClientStatus,
    customerUserClientPageableListCount: redux.customer.userClientPageableListCount,
    customerUserClientPageableListPageNumber: redux.customer.userClientPageableListPageNumber,
    customerUserClientPageableListPageSize: redux.customer.userClientPageableListPageSize,
    customerUserClientPageableListContent: redux.customer.userClientPageableListContent,
    customerUserClientPageableListSort: redux.customer.userClientPageableListSort,
    customerUserClientPageableListDirection: redux.customer.userClientPageableListDirection,
    customerTransactionStatus: redux.customer.transactionStatus,
    customerTransactionPageableListCount: redux.customer.transactionPageableListCount,
    customerTransactionPageableListPageNumber: redux.customer.transactionPageableListPageNumber,
    customerTransactionPageableListPageSize: redux.customer.transactionPageableListPageSize,
    customerTransactionPageableListContent: redux.customer.transactionPageableListContent,
    customerTransactionPageableListSort: redux.customer.transactionPageableListSort,
    customerTransactionPageableListDirection: redux.customer.transactionPageableListDirection,
    customerErrorCode: redux.customer.errorCode,
    customerErrorMessage: redux.customer.errorMessage,
    customerErrorUuid: redux.customer.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    customerGet: async (customerUuid) => {
      await dispatch(customerGet(customerUuid));
    },
    customerGetParkingAreaPageableList: async (customerUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(customerGetParkingAreaPageableList(customerUuid, pageNumber, rowsPerPage, sort, direction));
    },
    customerGetUserClientPageableList: async (customerUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(customerGetUserClientPageableList(customerUuid, pageNumber, rowsPerPage, sort, direction));
    },
    customerGetTransactionPageableList: async (customerUuid, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(customerGetTransactionPageableList(customerUuid, pageNumber, rowsPerPage, sort, direction));
    },
    customerPut: async (customerUuid, customerCompanyName, customerFirstName, customerLastName, customerAddress, customerZip, customerCity, customerEmail, customerPhoneNumber, customerVatNumber, customerBankName, customerBankAccount, customerNotes) => {
      await dispatch(customerPut(customerUuid, customerCompanyName, customerFirstName, customerLastName, customerAddress, customerZip, customerCity, customerEmail, customerPhoneNumber, customerVatNumber, customerBankName, customerBankAccount, customerNotes))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdministratorCustomerView);