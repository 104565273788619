import { axiosConfig } from "axios/axios.config";
import V1LoginDTO from "dto/v1/v1login.dto";
import V1ForgotPasswordDTO from "dto/v1/v1forgotpassword.dto";
import V1ResetPasswordDTO from "dto/v1/v1resetpassword.dto";
import V1ActivateUserDTO from "dto/v1/v1activateuser.dto";

class V1AuthenticationService {
  login(v1LoginDto: V1LoginDTO) {
    return axiosConfig.post("v1/authentication/login", v1LoginDto);
  }
  forgotPassword(v1ForgotPasswordDto: V1ForgotPasswordDTO) {
    return axiosConfig.post("v1/authentication/forgotpassword", v1ForgotPasswordDto);
  }
  resetPassword(v1ResetPasswordDto: V1ResetPasswordDTO) {
    return axiosConfig.put("v1/authentication/resetpassword", v1ResetPasswordDto);
  }
  activateUser(v1ActivateUserDto: V1ActivateUserDTO) {
    return axiosConfig.put("v1/authentication/activateuser", v1ActivateUserDto);
  }
}

export default new V1AuthenticationService();