import UUIDHelper from "helper/uuidhelper"
import { useEffect, useState } from "react"
import { connect } from "react-redux"
import { NavLink, useNavigate, useParams } from "react-router-dom"
import { ThunkDispatch } from "redux-thunk"
import { parkingAreaGet, parkingAreaGetParkPermitPageableList, parkingAreaGetTicketPageableList, parkingAreaPut } from "redux/parkingarea/parkingarea.action"
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus"
import { RootRedux } from "redux/store"
import { ParkingAreaType } from "type/parkingarea.type"
import ErrorContainer from "component/errorcontainer";
import { Typography, Grid, Card, CardContent, TextField, Divider, Box, Button, CardHeader, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, Autocomplete, CircularProgress, InputAdornment, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material"
import CustomContainer from "component/customcontainer"
import SpinnerContainer from "component/spinnercontainer"
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import HeadCell from "component/headcell"
import { NavigateNext } from "@mui/icons-material"
import { Direction } from "dto/direction"
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import v1WebCustomerService from "service/v1/web/v1webcustomer.service"
import Page from "dto/page"
import V1KeywordDTO from "dto/v1/v1keyword.dto"
import { AxiosResponse } from "axios"
import V1WebCustomerListElementDTO from "dto/v1/web/v1webcustomerlistelement.dto"
import Response from "dto/response";
import { LimitationType } from "type/limitation.type"
import V1DateTimeDTO from "dto/v1/v1datetime.dto"

interface OwnProps {
}

interface DispatchProps {
  parkingAreaGet: (parkingAreaUuid: string) => void;
  parkingAreaGetParkPermitPageableList: (parkingAreaUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  parkingAreaGetTicketPageableList: (parkingAreaUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  parkingAreaPut: (parkingAreaUuid: string, parkingAreaParkingAreaCode: string, parkingAreaAddress: string, parkingAreaZip: string, parkingAreaCity: string, parkingAreaWarningMinutes: number, parkingAreaTicketPrice: number, parkingAreaDefaultInitialUnlimitedParking: number, parkingAreaDefaultConcurrentUnlimitedParking: number, parkingAreaDefaultUnlimitedParkingLimitationType: LimitationType | null, parkingAreaDefaultMinuteParkingV1DateTimeDto: V1DateTimeDTO | null, parkingAreaDefaultInitialMinuteParking: number | null, parkingAreaDefaultConcurrentMinuteParking: number, parkingAreaDefaultMinuteParkingLimitationType: LimitationType | null, parkingAreaNotes: string, parkingAreaType: ParkingAreaType | null, parkingAreaCustomerUuid: string, parkingAreaDayNumberOfDays: number, parkingAreaDayHourOfDay: number, parkingAreaDayMinuteOfHour: number, parkingAreaMinuteAllowedParkingMinutes: number) => void;
}

interface StateProps {
  parkingAreaStateStatus: StateStatus
  parkingAreaUuid: string
  parkingAreaParkingAreaCode: string
  parkingAreaAddress: string
  parkingAreaZip: string
  parkingAreaCity: string
  parkingAreaWarningMinutes: number
  parkingAreaTicketPrice: number
  parkingAreaDefaultInitialUnlimitedParking: number | string
  parkingAreaDefaultConcurrentUnlimitedParking: number | string
  parkingAreaDefaultInitialMinuteParking: number | string
  parkingAreaDefaultConcurrentMinuteParking: number | string
  parkingAreaParkingAreaType: ParkingAreaType | null
  parkingAreaNotes: string
  parkingAreaCustomerUuid: string
  parkingAreaCustomerCompanyName: string
  parkingAreaDayNumberOfDays: number
  parkingAreaDayHourOfDay: number
  parkingAreaDayMinuteOfHour: number
  parkingAreaMinuteAllowedParkingMinutes: number
  parkingAreaParkPermitStateStatus: StateStatus
  parkingAreaParkPermitPageableListCount: number
  parkingAreaParkPermitPageableListPageNumber: number
  parkingAreaParkPermitPageableListPageSize: number
  parkingAreaParkPermitPageableListContent: Array<any[]>
  parkingAreaParkPermitPageableListSort: string
  parkingAreaParkPermitPageableListDirection: Direction
  parkingAreaTicketStateStatus: StateStatus
  parkingAreaTicketPageableListCount: number
  parkingAreaTicketPageableListPageNumber: number
  parkingAreaTicketPageableListPageSize: number
  parkingAreaTicketPageableListContent: Array<any[]>
  parkingAreaTicketPageableListSort: string
  parkingAreaTicketPageableListDirection: Direction
  parkingAreaErrorCode: number
  parkingAreaErrorMessage: string
  parkingAreaErrorUuid: string
}

interface FormProps {
  parkingAreaDefaultUnlimitedParkingLimitationType: LimitationType,
  parkingAreaDefaultInitialUnlimitedParking: number,
  parkingAreaDefaultConcurrentUnlimitedParking: number,
  parkingAreaDefaultMinuteParkingLimitationType: LimitationType,
  parkingAreaDefaultInitialMinuteParkingDays: number,
  parkingAreaDefaultInitialMinuteParkingHours: number,
  parkingAreaDefaultInitialMinuteParkingMinutes: number,
  parkingAreaDefaultConcurrentMinuteParking: number
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorParkingAreaView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isParkPermitLoaded, setParkPermitLoaded] = useState(false);
  const [isTicketLoaded, setTicketLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isParkPermitLoading, setParkPermitLoading] = useState(true);
  const [isTicketLoading, setTicketLoading] = useState(true);
  const [isCustomerLoading, setCustomerLoading] = useState(false);
  const [hasViewError, setViewError] = useState(false);
  const [customerOptions, setCustomerOptions] = useState(Array<any[]>());
  const [isSetUnlimitedParkingLimitationDialogOpen, setSetUnlimitedParkingLimitationDialogOpen] = useState(false);
  const [isSetMinuteParkingLimitationDialogOpen, setSetMinuteParkingLimitationDialogOpen] = useState(false);

  const params = useParams();
  const navigate = useNavigate();

  const {
    handleSubmit: handleUpdateFormSubmit,
    control: updateFormControl,
    getValues: getUpdateFormValues,
    setValue: setUpdateFormValue,
    watch: watchUpdateForm
  } = useForm<StateProps>({
    mode: 'all'
  });

  const watchParkingAreaCustomerCompanyName = watchUpdateForm("parkingAreaCustomerCompanyName");

  const onUpdateFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading && formProps.parkingAreaParkingAreaType !== null) {
      props.parkingAreaPut(formProps.parkingAreaUuid, formProps.parkingAreaParkingAreaCode, formProps.parkingAreaAddress, formProps.parkingAreaZip, formProps.parkingAreaCity, formProps.parkingAreaWarningMinutes, formProps.parkingAreaTicketPrice, props.parkingAreaDefaultInitialUnlimitedParking as number, props.parkingAreaDefaultConcurrentUnlimitedParking as number, null, null, props.parkingAreaDefaultInitialMinuteParking as number, props.parkingAreaDefaultConcurrentMinuteParking as number, null, formProps.parkingAreaNotes, formProps.parkingAreaParkingAreaType, formProps.parkingAreaCustomerUuid, formProps.parkingAreaDayNumberOfDays, formProps.parkingAreaDayHourOfDay, formProps.parkingAreaDayMinuteOfHour, formProps.parkingAreaMinuteAllowedParkingMinutes)
    }
  }

  const {
    handleSubmit: handleSetUnlimitedParkingLimitationFormSubmit,
    control: setUnlimitedParkingLimitationFormControl,
    reset: resetSetUnlimitedParkingLimitationForm,
    watch: watchSetUnlimitedParkingLimitationForm  } = useForm<FormProps>({
    mode: 'all'
  });

  const onSetUnlimitedParkingLimitationFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      props.parkingAreaPut(props.parkingAreaUuid, props.parkingAreaParkingAreaCode, props.parkingAreaAddress, props.parkingAreaZip, props.parkingAreaCity, props.parkingAreaWarningMinutes, props.parkingAreaTicketPrice, formProps.parkingAreaDefaultInitialUnlimitedParking as number, formProps.parkingAreaDefaultConcurrentUnlimitedParking as number, formProps.parkingAreaDefaultUnlimitedParkingLimitationType, null, props.parkingAreaDefaultInitialMinuteParking as number, props.parkingAreaDefaultConcurrentMinuteParking as number, null, props.parkingAreaNotes, props.parkingAreaParkingAreaType, props.parkingAreaCustomerUuid, props.parkingAreaDayNumberOfDays, props.parkingAreaDayHourOfDay, props.parkingAreaDayMinuteOfHour, props.parkingAreaMinuteAllowedParkingMinutes)
      toogleSetUnlimitedParkingLimitationDialogOpen();
    }
  }

  const watchParkingLimitationUnlimitedParkingLimitationType = watchSetUnlimitedParkingLimitationForm("parkingAreaDefaultUnlimitedParkingLimitationType");

  const {
    handleSubmit: handleSetMinuteParkingLimitationFormSubmit,
    control: setMinuteParkingLimitationFormControl,
    reset: resetSetMinuteParkingLimitationForm,
    watch: watchSetMinuteParkingLimitationForm  } = useForm<FormProps>({
    mode: 'all'
  });

  const onSetMinuteParkingLimitationFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading) {
      props.parkingAreaPut(props.parkingAreaUuid, props.parkingAreaParkingAreaCode, props.parkingAreaAddress, props.parkingAreaZip, props.parkingAreaCity, props.parkingAreaWarningMinutes, props.parkingAreaTicketPrice, props.parkingAreaDefaultInitialUnlimitedParking as number, props.parkingAreaDefaultConcurrentUnlimitedParking as number, null, { date: formProps.parkingAreaDefaultInitialMinuteParkingDays, hour: formProps.parkingAreaDefaultInitialMinuteParkingHours, minute: formProps.parkingAreaDefaultInitialMinuteParkingMinutes, year: 0, month: 0, second: 0 }, props.parkingAreaDefaultInitialMinuteParking as number, formProps.parkingAreaDefaultConcurrentMinuteParking as number, formProps.parkingAreaDefaultMinuteParkingLimitationType, props.parkingAreaNotes, props.parkingAreaParkingAreaType, props.parkingAreaCustomerUuid, props.parkingAreaDayNumberOfDays, props.parkingAreaDayHourOfDay, props.parkingAreaDayMinuteOfHour, props.parkingAreaMinuteAllowedParkingMinutes)
      toogleSetMinuteParkingLimitationDialogOpen();
    }
  }

  const watchParkingLimitationMinuteParkingLimitationType = watchSetMinuteParkingLimitationForm("parkingAreaDefaultMinuteParkingLimitationType");

  const handleParkingAreaParkPermitPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.parkingAreaGetParkPermitPageableList(props.parkingAreaUuid, 0, parseInt(event.target.value), props.parkingAreaParkPermitPageableListSort, props.parkingAreaParkPermitPageableListDirection);
  };

  const handleParkingAreaParkPermitPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.parkingAreaGetParkPermitPageableList(props.parkingAreaUuid, newPage, props.parkingAreaParkPermitPageableListPageSize, props.parkingAreaParkPermitPageableListSort, props.parkingAreaParkPermitPageableListDirection)
  }

  const handleParkingAreaParkPermitDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkingAreaParkPermitPageableListDirection === Direction.ASC) {
      props.parkingAreaGetParkPermitPageableList(props.parkingAreaUuid, props.parkingAreaParkPermitPageableListPageNumber, props.parkingAreaParkPermitPageableListPageSize, property, Direction.DESC);
    } else {
      props.parkingAreaGetParkPermitPageableList(props.parkingAreaUuid, props.parkingAreaParkPermitPageableListPageNumber, props.parkingAreaParkPermitPageableListPageSize, property, Direction.ASC);
    }
  }

  const handleParkingAreaTicketPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    props.parkingAreaGetTicketPageableList(props.parkingAreaUuid, 0, parseInt(event.target.value), props.parkingAreaTicketPageableListSort, props.parkingAreaTicketPageableListDirection);
  };

  const handleParkingAreaTicketPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    props.parkingAreaGetTicketPageableList(props.parkingAreaUuid, newPage, props.parkingAreaTicketPageableListPageSize, props.parkingAreaTicketPageableListSort, props.parkingAreaTicketPageableListDirection)
  }

  const handleParkingAreaTicketDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkingAreaTicketPageableListDirection === Direction.ASC) {
      props.parkingAreaGetTicketPageableList(props.parkingAreaUuid, props.parkingAreaTicketPageableListPageNumber, props.parkingAreaTicketPageableListPageSize, property, Direction.DESC);
    } else {
      props.parkingAreaGetTicketPageableList(props.parkingAreaUuid, props.parkingAreaTicketPageableListPageNumber, props.parkingAreaTicketPageableListPageSize, property, Direction.ASC);
    }
  }

  const toogleSetUnlimitedParkingLimitationDialogOpen = () => {
    setSetUnlimitedParkingLimitationDialogOpen(!isSetUnlimitedParkingLimitationDialogOpen);
    resetSetUnlimitedParkingLimitationForm();
  }

  const toogleSetMinuteParkingLimitationDialogOpen = () => {
    setSetMinuteParkingLimitationDialogOpen(!isSetMinuteParkingLimitationDialogOpen);
    resetSetMinuteParkingLimitationForm();
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (params.parkingAreaUuid !== undefined && UUIDHelper.validateUUID(params.parkingAreaUuid)) {
        props.parkingAreaGet(params.parkingAreaUuid);
      } else {
        navigate('404', { replace: true });
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isParkPermitLoaded && props.parkingAreaUuid !== undefined && props.parkingAreaUuid !== "") {
      props.parkingAreaGetParkPermitPageableList(props.parkingAreaUuid, props.parkingAreaParkPermitPageableListPageNumber, props.parkingAreaParkPermitPageableListPageSize, props.parkingAreaParkPermitPageableListSort, props.parkingAreaParkPermitPageableListDirection);
      setParkPermitLoaded(true);
    }

    if (!isTicketLoaded && props.parkingAreaUuid !== undefined && props.parkingAreaUuid !== "") {
      props.parkingAreaGetTicketPageableList(props.parkingAreaUuid, props.parkingAreaTicketPageableListPageNumber, props.parkingAreaTicketPageableListPageSize, props.parkingAreaTicketPageableListSort, props.parkingAreaTicketPageableListDirection);
      setTicketLoaded(true);
    }
  }, [props.parkingAreaUuid]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingAreaStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setUpdateFormValue("parkingAreaUuid", props.parkingAreaUuid);
        setUpdateFormValue("parkingAreaParkingAreaCode", props.parkingAreaParkingAreaCode);
        setUpdateFormValue("parkingAreaAddress", props.parkingAreaAddress);
        setUpdateFormValue("parkingAreaZip", props.parkingAreaZip);
        setUpdateFormValue("parkingAreaCity", props.parkingAreaCity);
        setUpdateFormValue("parkingAreaWarningMinutes", props.parkingAreaWarningMinutes);
        setUpdateFormValue("parkingAreaTicketPrice", props.parkingAreaTicketPrice);
        setUpdateFormValue("parkingAreaDefaultInitialUnlimitedParking", props.parkingAreaDefaultInitialUnlimitedParking === null ? "Ubegrænset" : props.parkingAreaDefaultInitialUnlimitedParking);
        setUpdateFormValue("parkingAreaDefaultConcurrentUnlimitedParking", props.parkingAreaDefaultConcurrentUnlimitedParking === null ? "Ubegrænset" : props.parkingAreaDefaultConcurrentUnlimitedParking);
        setUpdateFormValue("parkingAreaDefaultInitialMinuteParking", props.parkingAreaDefaultInitialMinuteParking === null ? "Ubegrænset" : props.parkingAreaDefaultInitialMinuteParking);
        setUpdateFormValue("parkingAreaDefaultConcurrentMinuteParking", props.parkingAreaDefaultConcurrentMinuteParking === null ? "Ubegrænset" : props.parkingAreaDefaultConcurrentMinuteParking);
        setUpdateFormValue("parkingAreaNotes", props.parkingAreaNotes);
        setUpdateFormValue("parkingAreaParkingAreaType", props.parkingAreaParkingAreaType);
        setUpdateFormValue("parkingAreaCustomerCompanyName", props.parkingAreaCustomerCompanyName);
        setUpdateFormValue("parkingAreaCustomerUuid", props.parkingAreaCustomerUuid);
        setUpdateFormValue("parkingAreaDayNumberOfDays", props.parkingAreaDayNumberOfDays);
        setUpdateFormValue("parkingAreaDayHourOfDay", props.parkingAreaDayHourOfDay);
        setUpdateFormValue("parkingAreaDayMinuteOfHour", props.parkingAreaDayMinuteOfHour);
        setUpdateFormValue("parkingAreaMinuteAllowedParkingMinutes", props.parkingAreaMinuteAllowedParkingMinutes);
        setViewLoading(false);
        setViewError(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.parkingAreaStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingAreaParkPermitStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setParkPermitLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setParkPermitLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setParkPermitLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.parkingAreaParkPermitStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingAreaTicketStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setTicketLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setTicketLoading(false);
        break;
      }
      case FAILURE_STATUS: {
        setTicketLoading(false);
        setViewError(true);
        break;
      }
    }
  }, [props.parkingAreaTicketStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchParkingAreaCustomerCompanyName !== undefined && watchParkingAreaCustomerCompanyName !== null && watchParkingAreaCustomerCompanyName.trim().length > 0) {
      setCustomerLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "companyName",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getUpdateFormValues("parkingAreaCustomerCompanyName")
      }

      v1WebCustomerService.searchCustomer(v1KeywordDto, page).then((response: AxiosResponse) => {
        let customerPageableList = response.data as Response<V1WebCustomerListElementDTO>

        let pageableListContent: Array<any[]> = [];
        customerPageableList.content.forEach((value) => {
          pageableListContent.push([value.customerUuid, value.customerCompanyName])
        });
        setCustomerOptions(pageableListContent);
        setCustomerLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl. Prøv igen!")
      });
    }
  }, [watchParkingAreaCustomerCompanyName]); // eslint-disable-line react-hooks/exhaustive-deps

  let formatter = new Intl.DateTimeFormat("da-DK", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit"
  });

  const parkingAreaParkPermitHeadCells: readonly HeadCell[] = [
    {
      id: "vehicle.vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    },
    {
      id: "validFrom",
      numeric: false,
      disablePadding: false,
      label: "Gyldig fra"
    },
    {
      id: "validTo",
      numeric: false,
      disablePadding: false,
      label: "Gyldig til"
    }
  ]

  const parkingAreaTicketHeadCells: readonly HeadCell[] = [
    {
      id: "ticketId",
      numeric: false,
      disablePadding: false,
      label: "Afgiftsnummer"
    },
    {
      id: "vehicle.vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    },
    {
      id: "isEnabled",
      numeric: false,
      disablePadding: false,
      label: "Status"
    }
  ]

  if (hasViewError) {
    return (
      <ErrorContainer errorCode={props.parkingAreaErrorCode} errorMessage={props.parkingAreaErrorMessage} errorUuid={props.parkingAreaErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Parkeringsområde
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0
          }}
        >
          {isViewLoading ? (
            <>
              <SpinnerContainer>
              </SpinnerContainer>
            </>
          ) : (
            <>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Card>
                  <form onSubmit={handleUpdateFormSubmit(onUpdateFormSubmit)}>
                    {props.parkingAreaParkingAreaType === ParkingAreaType.DAY &&
                      <>
                        <CardHeader
                          subheader={"Dette område har dagsparkering"}
                          title="Dagsparkering"
                        />
                        <CardContent
                          sx={{
                            marginTop: -3
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="parkingAreaDayNumberOfDays"
                                control={updateFormControl}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Antal dage er påkrævet"
                                  },
                                  pattern: {
                                    value: /^[0-9]+$/i,
                                    message: "Ikke et gyldigt tal"
                                  }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Antal dage"
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : "Antal dage en ny tilladelse skal gælde"}
                                    onKeyPress={(e) => {
                                      if (!e.key.match(/[0-9]+/i)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="parkingAreaDayHourOfDay"
                                control={updateFormControl}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Time er påkrævet"
                                  },
                                  pattern: {
                                    value: /^[0-9]+$/i,
                                    message: "Ikke et gyldigt tal"
                                  },
                                  validate: {
                                    value: ((value) => {
                                      return new Promise((resolve) => {
                                        if (value >= 0 && value <= 23) {
                                          resolve(true)
                                        } else {
                                          resolve("Angiv venligst et tal mellem 0 og 23")
                                        }
                                      });
                                    })
                                  }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Time på dagen"
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : "Angiv den time hvortil en ny tilladelse skal gælde"}
                                    onKeyPress={(e) => {
                                      if (!e.key.match(/[0-9]+/i)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="parkingAreaDayMinuteOfHour"
                                control={updateFormControl}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Minut er påkrævet"
                                  },
                                  pattern: {
                                    value: /^[0-9]+$/i,
                                    message: "Ikke et gyldigt tal"
                                  },
                                  validate: {
                                    value: ((value) => {
                                      return new Promise((resolve) => {
                                        if (value >= 0 && value <= 59) {
                                          resolve(true)
                                        } else {
                                          resolve("Angiv venligst et tal mellem 0 og 59")
                                        }
                                      });
                                    })
                                  }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Antal minut i timen"
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : "Angiv det minut hvortil en ny tilladelse skal gælde"}
                                    onKeyPress={(e) => {
                                      if (!e.key.match(/[0-9]+/i)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    }
                    {props.parkingAreaParkingAreaType === ParkingAreaType.MINUTE &&
                      <>
                        <CardHeader
                          subheader={"Dette område har minutparkering"}
                          title="Minutparkering"
                        />
                        <CardContent
                          sx={{
                            marginTop: -3
                          }}
                        >
                          <Grid
                            container
                            spacing={3}
                          >
                            <Grid
                              item
                              lg={12}
                              md={12}
                              xs={12}
                            >
                              <Controller
                                name="parkingAreaMinuteAllowedParkingMinutes"
                                control={updateFormControl}
                                rules={{
                                  required: {
                                    value: true,
                                    message: "Tilladt parkerings tid er påkrævet"
                                  },
                                  pattern: {
                                    value: /^[0-9]+$/i,
                                    message: "Ikke et gyldigt tal"
                                  }
                                }}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <TextField
                                    label="Tilladt parkerings tid"
                                    fullWidth
                                    value={value}
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : "Angiv i minutter"}
                                    onKeyPress={(e) => {
                                      if (!e.key.match(/[0-9]+/i)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                        </CardContent>
                      </>
                    }
                    <Divider />
                    <CardHeader
                      subheader={'Definerer standardværdier for registreringer/ændringer af egenparkeringer som tildeles når en begrænsning oprettes'}
                      title="Egenparkering"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaDefaultConcurrentUnlimitedParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Maksimale antal tiladte igangværende egenparkeringer"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaDefaultInitialUnlimitedParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Button
                            color="primary"
                            disabled={isViewLoading}
                            onClick={toogleSetUnlimitedParkingLimitationDialogOpen}
                            type="button"
                            fullWidth
                            variant="outlined"
                          >
                            Ændre egenparkering
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardHeader
                      subheader={'Definerer standardværdier for den månedlige mængde gæsteparkering som tildeles ved begrænsningens oprettelse'}
                      title="Gæsteparkeringer"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaDefaultConcurrentMinuteParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Maksimale antal tiladte igangværende gæsteparkeringer"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaDefaultInitialMinuteParking"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Månedlig tilladt gæsteparkering"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                                disabled
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">minutter</InputAdornment>,
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Button
                            color="primary"
                            disabled={isViewLoading}
                            onClick={toogleSetMinuteParkingLimitationDialogOpen}
                            type="button"
                            fullWidth
                            variant="outlined"
                          >
                            Ændre gæsteparkering
                          </Button>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <CardHeader
                      subheader={"Generelle oplysninger for området"}
                      title="Område oplysninger"
                    />
                    <CardContent
                      sx={{
                        marginTop: -3
                      }}
                    >
                      <Grid
                        container
                        spacing={3}
                      >
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaParkingAreaCode"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Parking area code er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "Parking area code må ikke overstige 256 tegn"
                              },
                              validate: {
                                value: ((value) => {
                                  return new Promise((resolve) => {
                                    if (value.toLocaleLowerCase() === props.parkingAreaParkingAreaCode.toLocaleLowerCase()) {
                                      resolve(true)
                                    }
                                    if (value !== undefined && value.length >= 1) {
                                      v1WebParkingAreaService.existsParkingAreaCode(value).then((res) => {
                                        if (res.data) {
                                          resolve("Parking area code er allerede i brug")
                                        } else {
                                          resolve(true)
                                        }
                                      }).catch((error) => {
                                        resolve(error);
                                      })
                                    }
                                  });
                                })
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Parking area code"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaAddress"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Adresse er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "Adresse må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Adresse"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaZip"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Postnummer er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "Postnummer må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Postnummer"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaCity"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "By er påkrævet"
                              },
                              maxLength: {
                                value: 256,
                                message: "By må ikke overstige 256 tegn"
                              },
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="By"
                                fullWidth
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaCustomerCompanyName"
                            control={updateFormControl}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                inputValue={value || ""}
                                onChange={(event: any, newValue: any[] | null) => {
                                  if (newValue !== null) {
                                    setUpdateFormValue("parkingAreaCustomerCompanyName", newValue[1]);
                                    setUpdateFormValue("parkingAreaCustomerUuid", newValue[0]);
                                  } else {
                                    setUpdateFormValue("parkingAreaCustomerCompanyName", "");
                                    setUpdateFormValue("parkingAreaCustomerUuid", "");
                                  }
                                }}
                                options={customerOptions}
                                getOptionLabel={(option) => option[1]}
                                isOptionEqualToValue={(option, value) => option[0] === value[0]}
                                noOptionsText={"Ingen resultater"}
                                filterOptions={(x) => x}
                                loading={isCustomerLoading}
                                loadingText={"Vent venligst.."}
                                openText={""}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Kunde"
                                    fullWidth
                                    onChange={onChange}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    InputProps={{
                                      ...params.InputProps,
                                      endAdornment: (
                                        <>
                                          {isCustomerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                          {params.InputProps.endAdornment}
                                        </>
                                      ),
                                    }}
                                  />
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaWarningMinutes"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Advarsels minutter er påkrævet"
                              },
                              pattern: {
                                value: /^[0-9]+$/i,
                                message: "Ikke et gyldigt tal"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Advarsels tid"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : "Angiv i minutter"}
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaTicketPrice"
                            control={updateFormControl}
                            rules={{
                              required: {
                                value: true,
                                message: "Afgifters beløb er påkrævet"
                              },
                              pattern: {
                                value: /^[0-9]+$/i,
                                message: "Ikke et gyldigt tal"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Afgifters beløb"
                                fullWidth
                                value={value}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : "Angiv i hele danske kroner inkl. moms"}
                                onKeyPress={(e) => {
                                  if (!e.key.match(/[0-9]+/i)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                        <Grid
                          item
                          lg={12}
                          md={12}
                          xs={12}
                        >
                          <Controller
                            name="parkingAreaNotes"
                            control={updateFormControl}
                            rules={{
                              maxLength: {
                                value: 256,
                                message: "Noter må ikke overstige 256 tegn"
                              }
                            }}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <TextField
                                label="Noter"
                                fullWidth
                                multiline
                                rows={4}
                                value={value || ""}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        p: 2
                      }}
                    >
                      <Button
                        color="primary"
                        disabled={isViewLoading}
                        type="submit"
                        variant="contained"
                      >
                        Gem parkeringsområde oplysinger
                      </Button>
                    </Box>
                  </form>
                </Card>
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Card>
                  <CardHeader
                    subheader={"Alle tilladelser udsted på dette område"}
                    title="Tilladelser" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {parkingAreaParkPermitHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.parkingAreaParkPermitPageableListSort === headCell.id}
                              direction={props.parkingAreaParkPermitPageableListDirection}
                              onClick={handleParkingAreaParkPermitDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isParkPermitLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.parkingAreaParkPermitPageableListContent === undefined || props.parkingAreaParkPermitPageableListContent.length === 0 ? props.parkingAreaParkPermitPageableListPageSize : props.parkingAreaParkPermitPageableListContent.length} tableColSpan={parkingAreaParkPermitHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.parkingAreaParkPermitPageableListContent === undefined || props.parkingAreaParkPermitPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={parkingAreaParkPermitHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.parkingAreaParkPermitPageableListContent.map(parkpermit => (
                                <TableRow
                                  hover
                                  key={parkpermit[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {parkpermit[8]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {(parkpermit[1] === undefined || parkpermit[1] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkpermit[1]))}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {(parkpermit[2] === undefined || parkpermit[2] === null) ? "Ubegrænset" : formatter.format(Date.parse(parkpermit[2]))}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      edge='end'
                                      component={NavLink}
                                      to={"../parkpermit/" + parkpermit[0]}
                                    >
                                      <NavigateNext />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={parkingAreaParkPermitHeadCells.length + 1}
                          count={props.parkingAreaParkPermitPageableListCount !== undefined ? props.parkingAreaParkPermitPageableListCount : 0}
                          rowsPerPage={props.parkingAreaParkPermitPageableListPageSize !== undefined ? props.parkingAreaParkPermitPageableListPageSize : 5}
                          page={props.parkingAreaParkPermitPageableListPageNumber !== undefined ? props.parkingAreaParkPermitPageableListPageNumber : 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleParkingAreaParkPermitPageNumberChange}
                          onRowsPerPageChange={handleParkingAreaParkPermitPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
                <Card
                  sx={{
                    marginTop: 3
                  }}
                >
                  <CardHeader
                    subheader={"Alle afgifter udsted til dette køretøj"}
                    title="Afgifter" />
                  <Divider />
                  <Table>
                    <TableHead>
                      <TableRow>
                        {parkingAreaTicketHeadCells.map((headCell) => (
                          <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                          >
                            {headCell.label}
                            <TableSortLabel
                              active={props.parkingAreaTicketPageableListSort === headCell.id}
                              direction={props.parkingAreaTicketPageableListDirection}
                              onClick={handleParkingAreaTicketDirectionChange(headCell.id)}
                            >
                            </TableSortLabel>
                          </TableCell>
                        ))}
                        <TableCell>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {isTicketLoading ? (
                        <>
                          <FetchingContainer tablePageSize={props.parkingAreaTicketPageableListContent === undefined || props.parkingAreaTicketPageableListContent.length === 0 ? props.parkingAreaTicketPageableListPageSize : props.parkingAreaTicketPageableListContent.length} tableColSpan={parkingAreaTicketHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.parkingAreaTicketPageableListContent === undefined || props.parkingAreaTicketPageableListCount === 0 ? (
                            <>
                              <EmptyContainer tableColSpan={parkingAreaTicketHeadCells.length} />
                            </>
                          ) : (
                            <>
                              {props.parkingAreaTicketPageableListContent.map(ticket => (
                                <TableRow
                                  hover
                                  key={ticket[0]}
                                >
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {ticket[1]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {ticket[2]}
                                    </Typography>
                                  </TableCell>
                                  <TableCell>
                                    <Typography
                                      color="textPrimary"
                                      variant="body1"
                                    >
                                      {ticket[4] === "true" ? (
                                        <>
                                          Aktiv
                                        </>
                                      ) : (
                                        <>
                                          Annulleret
                                        </>
                                      )}
                                    </Typography>
                                  </TableCell>
                                  <TableCell align="right">
                                    <IconButton
                                      edge='end'
                                      component={NavLink}
                                      to={"../ticket/" + ticket[0]}
                                    >
                                      <NavigateNext />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                              ))}
                            </>
                          )}
                        </>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, 50, 100]}
                          colSpan={parkingAreaTicketHeadCells.length + 1}
                          count={props.parkingAreaTicketPageableListCount !== undefined ? props.parkingAreaTicketPageableListCount : 0}
                          rowsPerPage={props.parkingAreaTicketPageableListPageSize !== undefined ? props.parkingAreaTicketPageableListPageSize : 5}
                          page={props.parkingAreaTicketPageableListPageNumber !== undefined ? props.parkingAreaTicketPageableListPageNumber : 0}
                          labelRowsPerPage="Antal pr. side:"
                          labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                          onPageChange={handleParkingAreaTicketPageNumberChange}
                          onRowsPerPageChange={handleParkingAreaTicketPageSizeChange}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </Card>
              </Grid>
            </>
          )}
        </Grid>
      </CustomContainer>
      <Dialog
        open={isSetUnlimitedParkingLimitationDialogOpen}
        onClose={toogleSetUnlimitedParkingLimitationDialogOpen}
      >
        <form onSubmit={handleSetUnlimitedParkingLimitationFormSubmit(onSetUnlimitedParkingLimitationFormSubmit)}>
          <DialogTitle>Ændre egenparkering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld formularen for at ændre begrænsningen til egenparkering. Ændringerne overskriver den eksisterende begrænsning for egenparkering
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Egenparkerings begrænsnings type er påkrævet"
                        }
                      }}
                      control={setUnlimitedParkingLimitationFormControl}
                      name="parkingAreaDefaultUnlimitedParkingLimitationType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          component="fieldset"
                          error={!!error}
                        >
                          <RadioGroup
                            {...field}
                            row
                            value={field.value || ""}
                          >
                            <FormControlLabel
                              value={LimitationType.PROHIBITED}
                              control={<Radio />}
                              label="Forbudt"
                            />
                            <FormControlLabel
                              value={LimitationType.LIMITED}
                              control={<Radio />}
                              label="Begrænset"
                            />
                            <FormControlLabel
                              value={LimitationType.UNLIMITED}
                              control={<Radio />}
                              label="Ubegrænset"
                            />
                          </RadioGroup>
                          <FormHelperText sx={{
                            marginLeft: 0
                          }}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      {(watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.LIMITED || watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.UNLIMITED) &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingAreaDefaultConcurrentUnlimitedParking"
                              control={setUnlimitedParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Maksimale antal tiladte igangværende egenparkeringer"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                      {watchParkingLimitationUnlimitedParkingLimitationType === LimitationType.LIMITED &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <DialogContentText marginBottom={-4}>
                              Udfyld feltet nedenfor for at definere det årlige antal tilladte registreringer/ændringer af egenparkeringer som tildeles ved årets start
                            </DialogContentText>
                          </Grid>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingAreaDefaultInitialUnlimitedParking"
                              control={setUnlimitedParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleSetUnlimitedParkingLimitationDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Gem
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog
        open={isSetMinuteParkingLimitationDialogOpen}
        onClose={toogleSetMinuteParkingLimitationDialogOpen}
      >
        <form onSubmit={handleSetMinuteParkingLimitationFormSubmit(onSetMinuteParkingLimitationFormSubmit)}>
          <DialogTitle>Ændre gæsteparkering</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld formularen for at ændre begrænsningen til gæsteparkering. Ændringerne overskriver den eksisterende begrænsning for gæsteparkering
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: "Gæsteparkerings begrænsnings type er påkrævet"
                        }
                      }}
                      control={setMinuteParkingLimitationFormControl}
                      name="parkingAreaDefaultMinuteParkingLimitationType"
                      render={({ field, fieldState: { error } }) => (
                        <FormControl
                          component="fieldset"
                          error={!!error}
                        >
                          <RadioGroup
                            {...field}
                            row
                            value={field.value || ""}
                          >
                            <FormControlLabel
                              value={LimitationType.PROHIBITED}
                              control={<Radio />}
                              label="Forbudt"
                            />
                            <FormControlLabel
                              value={LimitationType.LIMITED}
                              control={<Radio />}
                              label="Begrænset"
                            />
                            <FormControlLabel
                              value={LimitationType.UNLIMITED}
                              control={<Radio />}
                              label="Ubegrænset"
                            />
                          </RadioGroup>
                          <FormHelperText sx={{
                            marginLeft: 0
                          }}>{error ? error.message : null}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Grid
                      container
                      spacing={2}
                    >
                      {(watchParkingLimitationMinuteParkingLimitationType === LimitationType.LIMITED || watchParkingLimitationMinuteParkingLimitationType === LimitationType.UNLIMITED) &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <Controller
                              name="parkingAreaDefaultConcurrentMinuteParking"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 99)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 99")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label="Maksimale antal tiladte igangværende gæsteparkeringer"
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                      {watchParkingLimitationMinuteParkingLimitationType === LimitationType.LIMITED &&
                        <>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                          >
                            <DialogContentText marginBottom={-4}>
                              Udfyld felterne nedenfor for at definere den månedlige mængde gæsteparkering som tildeles ved månedens start. Angiv enten i dage, timer, minutter eller en kombination heraf
                            </DialogContentText>
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingAreaDefaultInitialMinuteParkingDays"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 31)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 31")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Dage</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingAreaDefaultInitialMinuteParkingHours"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 999)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 999")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Timer</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid
                            item
                            lg={4}
                            md={4}
                            xs={4}
                          >
                            <Controller
                              name="parkingAreaDefaultInitialMinuteParkingMinutes"
                              control={setMinuteParkingLimitationFormControl}
                              rules={{
                                pattern: {
                                  value: /^[0-9]+$/i,
                                  message: "Ikke et gyldigt tal"
                                },
                                validate: {
                                  value: ((value) => {
                                    return new Promise((resolve) => {
                                      if (value === undefined || (value >= 0 && value <= 999)) {
                                        resolve(true)
                                      } else {
                                        resolve("Angiv venligst et tal mellem 0 og 999")
                                      }
                                    });
                                  })
                                }
                              }}
                              render={({ field: { onChange, value }, fieldState: { error } }) => (
                                <TextField
                                  label=""
                                  fullWidth
                                  value={value || ""}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  onKeyPress={(e) => {
                                    if (!e.key.match(/[0-9]+/i)) {
                                      e.preventDefault();
                                    }
                                  }}
                                  InputProps={{
                                    startAdornment: <InputAdornment position="start">Minutter</InputAdornment>,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleSetMinuteParkingLimitationDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Gem
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkingAreaStateStatus: redux.parkingArea.status,
    parkingAreaUuid: redux.parkingArea.abstractParkingAreaUuid,
    parkingAreaParkingAreaCode: redux.parkingArea.abstractParkingAreaParkingAreaCode,
    parkingAreaAddress: redux.parkingArea.abstractParkingAreaAddress,
    parkingAreaZip: redux.parkingArea.abstractParkingAreaZip,
    parkingAreaCity: redux.parkingArea.abstractParkingAreaCity,
    parkingAreaWarningMinutes: redux.parkingArea.abstractParkingAreaWarningMinutes,
    parkingAreaTicketPrice: redux.parkingArea.abstractParkingAreaTicketPrice,
    parkingAreaDefaultInitialUnlimitedParking: redux.parkingArea.abstractParkingAreaDefaultInitialUnlimitedParking,
    parkingAreaDefaultConcurrentUnlimitedParking: redux.parkingArea.abstractParkingAreaDefaultConcurrentUnlimitedParking,
    parkingAreaDefaultInitialMinuteParking: redux.parkingArea.abstractParkingAreaDefaultInitialMinuteParking,
    parkingAreaDefaultConcurrentMinuteParking: redux.parkingArea.abstractParkingAreaDefaultConcurrentMinuteParking,
    parkingAreaParkingAreaType: redux.parkingArea.abstractParkingAreaParkingAreaType,
    parkingAreaNotes: redux.parkingArea.abstractParkingAreaNotes,
    parkingAreaCustomerUuid: redux.parkingArea.customerUuid,
    parkingAreaCustomerCompanyName: redux.parkingArea.customerCompanyName,
    parkingAreaDayNumberOfDays: redux.parkingArea.parkingAreaDayNumberOfDays,
    parkingAreaDayHourOfDay: redux.parkingArea.parkingAreaDayHourOfDay,
    parkingAreaDayMinuteOfHour: redux.parkingArea.parkingAreaDayMinuteOfHour,
    parkingAreaMinuteAllowedParkingMinutes: redux.parkingArea.parkingAreaMinuteAllowedParkingMinutes,
    parkingAreaParkPermitStateStatus: redux.parkingArea.parkPermitStatus,
    parkingAreaParkPermitPageableListCount: redux.parkingArea.parkPermitPageableListCount,
    parkingAreaParkPermitPageableListPageNumber: redux.parkingArea.parkPermitPageableListPageNumber,
    parkingAreaParkPermitPageableListPageSize: redux.parkingArea.parkPermitPageableListPageSize,
    parkingAreaParkPermitPageableListContent: redux.parkingArea.parkPermitPageableListContent,
    parkingAreaParkPermitPageableListSort: redux.parkingArea.parkPermitPageableListSort,
    parkingAreaParkPermitPageableListDirection: redux.parkingArea.parkPermitPageableListDirection,
    parkingAreaTicketStateStatus: redux.parkingArea.ticketStatus,
    parkingAreaTicketPageableListCount: redux.parkingArea.ticketPageableListCount,
    parkingAreaTicketPageableListPageNumber: redux.parkingArea.ticketPageableListPageNumber,
    parkingAreaTicketPageableListPageSize: redux.parkingArea.ticketPageableListPageSize,
    parkingAreaTicketPageableListContent: redux.parkingArea.ticketPageableListContent,
    parkingAreaTicketPageableListSort: redux.parkingArea.ticketPageableListSort,
    parkingAreaTicketPageableListDirection: redux.parkingArea.ticketPageableListDirection,
    parkingAreaErrorCode: redux.parkingArea.errorCode,
    parkingAreaErrorMessage: redux.parkingArea.errorMessage,
    parkingAreaErrorUuid: redux.parkingArea.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    parkingAreaGet: async (parkingAreaUuid) => {
      dispatch(parkingAreaGet(parkingAreaUuid));
    },
    parkingAreaGetParkPermitPageableList: async (parkingAreaUuid, pageNumber, rowsPerPage, sort, direction) => {
      dispatch(parkingAreaGetParkPermitPageableList(parkingAreaUuid, pageNumber, rowsPerPage, sort, direction));
    },
    parkingAreaGetTicketPageableList: async (parkingAreaUuid, pageNumber, rowsPerPage, sort, direction) => {
      dispatch(parkingAreaGetTicketPageableList(parkingAreaUuid, pageNumber, rowsPerPage, sort, direction));
    },
    parkingAreaPut: async (parkingAreaUuid, parkingAreaParkingAreaCode, parkingAreaAddress, parkingAreaZip, parkingAreaCity, parkingAreaWarningMinutes, parkingAreaTicketPrice, parkingAreaDefaultInitialUnlimitedParking, parkingAreaDefaultConcurrentUnlimitedParking, parkingAreaDefaultUnlimitedParkingLimitationType, parkingAreaDefaultMinuteParkingV1DateTimeDto, parkingAreaDefaultInitialMinuteParking, parkingAreaDefaultConcurrentMinuteParking, parkingAreaDefaultMinuteParkingLimitationType, parkingAreaNotes, parkingAreaType, parkingAreaCustomerUuid, parkingAreaDayNumberOfDays, parkingAreaDayHourOfDay, parkingAreaDayMinuteOfHour, parkingAreaMinuteAllowedParkingMinutes) => {
      dispatch(parkingAreaPut(parkingAreaUuid, parkingAreaParkingAreaCode, parkingAreaAddress, parkingAreaZip, parkingAreaCity, parkingAreaWarningMinutes, parkingAreaTicketPrice, parkingAreaDefaultInitialUnlimitedParking, parkingAreaDefaultConcurrentUnlimitedParking, parkingAreaDefaultUnlimitedParkingLimitationType, parkingAreaDefaultMinuteParkingV1DateTimeDto, parkingAreaDefaultInitialMinuteParking, parkingAreaDefaultConcurrentMinuteParking, parkingAreaDefaultMinuteParkingLimitationType, parkingAreaNotes, parkingAreaType, parkingAreaCustomerUuid, parkingAreaDayNumberOfDays, parkingAreaDayHourOfDay, parkingAreaDayMinuteOfHour, parkingAreaMinuteAllowedParkingMinutes))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdministratorParkingAreaView);