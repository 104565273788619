import { AxiosResponse } from "axios";
import { Direction } from "dto/direction";
import Page from "dto/page";
import Response from "dto/response";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebAbstractUserDTO from "dto/v1/web/v1webabstractuser.dto";
import V1WebAbstractUserClientDTO from "dto/v1/web/v1webabstractuserclient.dto";
import V1WebAuthorityListElementDTO from "dto/v1/web/v1webauthoritylistelement.dto";
import V1WebParkingLimitationListElementDTO from "dto/v1/web/v1webparkinglimitationlistelement.dto";
import V1WebTokenListElementDTO from "dto/v1/web/v1webtokenlistelement.dto";
import V1WebUserAdministratorDTO from "dto/v1/web/v1webuseradministrator.dto";
import V1WebUserClientKioskDTO from "dto/v1/web/v1webuserclientkiosk.dto";
import V1WebUserClientAreaOperatorDTO from "dto/v1/web/v1webuserclientoperator.dto";
import V1WebUserClientPublicDTO from "dto/v1/web/v1webuserclientpublic.dto";
import V1WebUserGuardDTO from "dto/v1/web/v1webuserguard.dto";
import V1WebUserListElementDTO from "dto/v1/web/v1webuserlistelement.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { ROOT_INIT_USER } from "redux/root/root.action";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebUserService from "service/v1/web/v1webuser.service";
import { UserType } from "type/user.type";
import { UserState } from "./user.reducer";
import V1WebParkPermitListElementDTO from "dto/v1/web/v1webparkpermitlistelement.dto";
import V1WebParkingAreaListElementDTO from "dto/v1/web/v1webparkingarealistelement.dto";
import V1WebAddUserClientDTO from "dto/v1/web/v1webadduserclient.dto";

export const USER_GET = "user/get";
export const USER_GET_PAGEABLELIST = "user/get/pageablelist";
export const USER_GET_USERCLIENT_PAGEABLELIST = "user/get/userclient/pageablelist";
export const USERCLIENT_GET_OWN_PARKPERMIT_PAGEABLELIST = "userclient/get/own/parkpermit/pageablelist";
export const USER_SEARCH_PAGEABLELIST = "user/search/pageablelist";
export const USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST = "useroperator/search/parkinglimitation/own/pageablelist";
export const USER_GET_AUTHORITY_PAGEABLELIST = "user/get/authority/pageablelist";
export const USER_GET_TOKEN_PAGEABLELIST = "user/get/token/pageablelist";
export const USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST = "useroperator/get/parkinglimitation/own/pageablelist";
export const USER_PUT = "user/put";
export const USER_PUT_AUTHORITIES = "user/put/authorities"
export const USER_PUT_USER_CLIENT = "user/put/userclient"

export interface UserAction {
  type:
    typeof USER_GET |
    typeof USER_GET_PAGEABLELIST |
    typeof USER_GET_USERCLIENT_PAGEABLELIST |
    typeof USERCLIENT_GET_OWN_PARKPERMIT_PAGEABLELIST |
    typeof USER_SEARCH_PAGEABLELIST |
    typeof USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST |
    typeof USER_GET_AUTHORITY_PAGEABLELIST |
    typeof USER_GET_TOKEN_PAGEABLELIST |
    typeof USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST |
    typeof USER_PUT |
    typeof USER_PUT_AUTHORITIES | 
    typeof USER_PUT_USER_CLIENT
  status: StateStatus
  pageableListCount: number
  pageableListPageNumber: number
  pageableListPageSize: number
  pageableListContent: Array<any[]>
  pageableListSort: string
  pageableListDirection: Direction
  pageableListKeyword: string
  abstractUserUuid: string;
  abstractUserUsername: string;
  abstractUserFirstName: string;
  abstractUserLastName: string;
  abstractUserAddress: string;
  abstractUserZip: string;
  abstractUserCity: string;
  abstractUserEmail: string;
  abstractUserPhone: string;
  abstractUserUserType: UserType | null;
  abstractUserNotes: string;
  abstractUserIsEnabled: boolean;
  abstractUserIsAccountNonExpired: boolean;
  abstractUserIsAccountNonLocked: boolean;
  abstractUserIsCredentialsNonExpired: boolean;
  abstractUserCredentialsDate: Date | null;
  abstractUserClientHasTextMessageNotificationService: boolean;
  abstractUserClientHasEmailNotificationService: boolean;
  customerUuid: string;
  customerCompanyName: string;
  abstractParkingAreaUuid: string;
  abstractParkingAreaParkingAreaCode: string;
  userGuardTicketCount: number;
  userGuardGuardId: number;
  authorityStateStatus: StateStatus
  authorityPageableListCount: number
  authorityPageableListPageNumber: number
  authorityPageableListPageSize: number
  authorityPageableListContent: Array<any[]>
  authorityPageableListSort: string
  authorityPageableListDirection: Direction
  tokenStateStatus: StateStatus
  tokenPageableListCount: number
  tokenPageableListPageNumber: number
  tokenPageableListPageSize: number
  tokenPageableListContent: Array<any[]>
  tokenPageableListSort: string
  tokenPageableListDirection: Direction
  userOperatorParkingLimitationStatus: StateStatus
  userOperatorParkingLimitationPageableListCount: number
  userOperatorParkingLimitationPageableListPageNumber: number
  userOperatorParkingLimitationPageableListPageSize: number
  userOperatorParkingLimitationPageableListContent: Array<any[]>
  userOperatorParkingLimitationPageableListSort: string
  userOperatorParkingLimitationPageableListDirection: Direction
  userOperatorParkingLimitationPageableListKeyword: string
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type UserThunkAction = ThunkAction<Promise<void>, UserState, any, UserAction>;

export function getUserClientParkPermitPageableList(abstractUserClientUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: "parkpermit/get/pageablelist",
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUserClientParkPermitPageableList(abstractUserClientUuid, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkPermitListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.parkPermitUuid, value.parkPermitValidFrom, value.parkPermitValidTo, value.parkPermitNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.vehicleVehicleId, value.parkPermitIsEnabled])
        });

        dispatch({
          type: "parkpermit/get/pageablelist",
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: "parkpermit/get/pageablelist",
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function getUserClientAreaParkingAreaPageableList(abstractUserClientUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: "parkingarea/get/pageablelist",
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUserClientAreaParkingAreaPageableList(abstractUserClientUuid, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity])
        });

        dispatch({
          type: "parkingarea/get/pageablelist",
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: "parkingarea/get/pageablelist",
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function getUserClientAreaOperatorUserClientPageableList(abstractUserClientUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: "user/get/userclient/pageablelist",
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUserClientAreaOperatorUserClientPageableList(abstractUserClientUuid, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserUsername, value.abstractUserUserType, value.abstractUserIsEnabled])
        });

        dispatch({
          type: "user/get/userclient/pageablelist",
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: "user/get/userclient/pageablelist",
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}





export function userGet(abstractParkingAreaUuid: string): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_GET,
        status: LOADING_STATUS
      });

      v1WebUserService.getUser(abstractParkingAreaUuid).then((response: AxiosResponse) => {
        let v1WebAbstractUserDto = response.data as V1WebAbstractUserDTO;

        dispatch({
          type: USER_GET,
          status: SUCCESS_STATUS,
          abstractUserUuid: v1WebAbstractUserDto.abstractUserUuid,
          abstractUserUsername: v1WebAbstractUserDto.abstractUserUsername,
          abstractUserFirstName: v1WebAbstractUserDto.abstractUserFirstName,
          abstractUserLastName: v1WebAbstractUserDto.abstractUserLastName,
          abstractUserAddress: v1WebAbstractUserDto.abstractUserAddress,
          abstractUserZip: v1WebAbstractUserDto.abstractUserZip,
          abstractUserCity: v1WebAbstractUserDto.abstractUserCity,
          abstractUserEmail: v1WebAbstractUserDto.abstractUserEmail,
          abstractUserPhone: v1WebAbstractUserDto.abstractUserPhone,
          abstractUserUserType: v1WebAbstractUserDto.abstractUserUserType,
          abstractUserNotes: v1WebAbstractUserDto.abstractUserNotes,
          abstractUserIsEnabled: v1WebAbstractUserDto.abstractUserIsEnabled,
          abstractUserIsAccountNonExpired: v1WebAbstractUserDto.abstractUserIsAccountNonExpired,
          abstractUserIsAccountNonLocked: v1WebAbstractUserDto.abstractUserIsAccountNonLocked,
          abstractUserIsCredentialsNonExpired: v1WebAbstractUserDto.abstractUserIsCredentialsNonExpired,
          abstractUserCredentialsDate: v1WebAbstractUserDto.abstractUserCredentialsDate,
          abstractUserClientHasTextMessageNotificationService: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasTextMessageNotificationService,
          abstractUserClientHasEmailNotificationService: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasEmailNotificationService,
          customerUuid: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).customerUuid,
          customerCompanyName: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).customerCompanyName,
          abstractParkingAreaUuid: (v1WebAbstractUserDto as V1WebUserClientKioskDTO).abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: (v1WebAbstractUserDto as V1WebUserClientKioskDTO).abstractParkingAreaParkingAreaCode,
          userGuardTicketCount: (v1WebAbstractUserDto as V1WebUserGuardDTO).userGuardTicketCount,
          userGuardGuardId: (v1WebAbstractUserDto as V1WebUserGuardDTO).userGuardGuardId
        });
      }).catch((error) => {
        dispatch({
          type: USER_GET,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function userGetPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_GET_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUsers(page).then((response: AxiosResponse) => {
        let userPageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: any[] = [];
        userPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserUsername, value.abstractUserUserType, value.abstractUserIsEnabled])
        });

        dispatch({
          type: USER_GET_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: userPageableList.totalElements,
          pageableListPageNumber: userPageableList.pageable.pageNumber,
          pageableListPageSize: userPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: USER_GET_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function userSearchPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_SEARCH_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebUserService.searchUser(v1keywordDto, page).then((response: AxiosResponse) => {
        let userPageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: any[] = [];
        userPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserUsername, value.abstractUserUserType, value.abstractUserIsEnabled])
        });

        dispatch({
          type: USER_SEARCH_PAGEABLELIST,
          status: SUCCESS_STATUS,
          pageableListCount: userPageableList.totalElements,
          pageableListPageNumber: userPageableList.pageable.pageNumber,
          pageableListPageSize: userPageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction,
          pageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: USER_SEARCH_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    })
  }
}

export function userOperatorSearchParkingLimitationOwnPageableList(keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let v1keywordDto: V1KeywordDTO = {
        keyword: keyword
      }

      v1WebUserService.searchUserOperatorOwnParkingLimitation(v1keywordDto, page).then((response: AxiosResponse) => {
        let parkingLimitationPageableList = response.data as Response<V1WebParkingLimitationListElementDTO>

        let pageableListContent: any[] = [];
        parkingLimitationPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkingLimitationUuid, value.parkingLimitationNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.userClientPublicUsername])
        });

        dispatch({
          type: USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST,
          status: SUCCESS_STATUS,
          parkingLimitationPageableListCount: parkingLimitationPageableList.totalElements,
          parkingLimitationPageableListPageNumber: parkingLimitationPageableList.pageable.pageNumber,
          parkingLimitationPageableListPageSize: parkingLimitationPageableList.pageable.pageSize,
          parkingLimitationPageableListContent: pageableListContent,
          parkingLimitationPageableListSort: sort,
          parkingLimitationPageableListDirection: direction,
          parkingLimitationPageableListKeyword: keyword
        });
      }).catch((error) => {
        dispatch({
          type: USEROPERATOR_SEARCH_PARKINGLIMITATION_OWN_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function userGetAuthorityPageableList(abstractUserUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_GET_AUTHORITY_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUserAuthority(abstractUserUuid, page).then((response: AxiosResponse) => {
        let authorityPageableList = response.data as Response<V1WebAuthorityListElementDTO>

        let pageableListContent: any[] = [];
        authorityPageableList.content.forEach((value) => {
          pageableListContent.push([value.authorityUuid, value.authorityName, value.authorityDisplayName])
        });

        dispatch({
          type: USER_GET_AUTHORITY_PAGEABLELIST,
          status: SUCCESS_STATUS,
          authorityPageableListCount: authorityPageableList.totalElements,
          authorityPageableListPageNumber: authorityPageableList.pageable.pageNumber,
          authorityPageableListPageSize: authorityPageableList.pageable.pageSize,
          authorityPageableListContent: pageableListContent,
          authorityPageableListSort: sort,
          authorityPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: USER_GET_AUTHORITY_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function userGetTokenPageableList(abstractUserUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_GET_TOKEN_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUserToken(abstractUserUuid, page).then((response: AxiosResponse) => {
        let tokenPageableList = response.data as Response<V1WebTokenListElementDTO>

        let pageableListContent: any[] = [];
        tokenPageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractTokenUuid, value.abstractTokenTokenType, value.abstractTokenNotes])
        });

        dispatch({
          type: USER_GET_TOKEN_PAGEABLELIST,
          status: SUCCESS_STATUS,
          tokenPageableListCount: tokenPageableList.totalElements,
          tokenPageableListPageNumber: tokenPageableList.pageable.pageNumber,
          tokenPageableListPageSize: tokenPageableList.pageable.pageSize,
          tokenPageableListContent: pageableListContent,
          tokenPageableListSort: sort,
          tokenPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: USER_GET_TOKEN_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function userOperatorGetParkingLimitationOwnPageableList(pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.getUserOperatorOwnParkingLimitation(page).then((response: AxiosResponse) => {
        let parkingLimitationPageableList = response.data as Response<V1WebParkingLimitationListElementDTO>

        let pageableListContent: any[] = [];
        parkingLimitationPageableList.content.forEach((value) => {
          pageableListContent.push([value.parkingLimitationUuid, value.parkingLimitationNotes, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity, value.userClientPublicUsername])
        });

        dispatch({
          type: USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST,
          status: SUCCESS_STATUS,
          userOperatorParkingLimitationPageableListCount: parkingLimitationPageableList.totalElements,
          userOperatorParkingLimitationPageableListPageNumber: parkingLimitationPageableList.pageable.pageNumber,
          userOperatorParkingLimitationPageableListPageSize: parkingLimitationPageableList.pageable.pageSize,
          userOperatorParkingLimitationPageableListContent: pageableListContent,
          userOperatorParkingLimitationPageableListSort: sort,
          userOperatorParkingLimitationPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: USEROPERATOR_GET_PARKINGLIMITATION_OWN_PAGEABLELIST,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function userPut(abstractUserUuid: string, abstractUserUsername: string, abstractUserFirstName: string, abstractUserLastName: string, abstractUserAddress: string, abstractUserZip: string, abstractUserCity: string, abstractUserEmail: string, abstractUserPhone: string, abstractUserUserType: UserType, abstractUserNotes: string, abstractUserIsEnabled: boolean, abstractUserClientHasTextMessageNotificationService: boolean, abstractUserClientHasEmailNotificationService: boolean, customerUuid: string, abstractParkingAreaUuid: string): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_PUT,
        status: LOADING_STATUS
      });

      let v1WebAbstractUserDto: V1WebAbstractUserDTO;

      switch (abstractUserUserType) {
        case UserType.ADMINISTRATOR: {
          v1WebAbstractUserDto = new V1WebUserAdministratorDTO();
          break;
        }
        case UserType.GUARD: {
          v1WebAbstractUserDto = new V1WebUserGuardDTO();
          break;
        }
        case UserType.KIOSK: {
          v1WebAbstractUserDto = new V1WebUserClientKioskDTO();
          (v1WebAbstractUserDto as V1WebUserClientKioskDTO).abstractParkingAreaUuid = abstractParkingAreaUuid;
          break;
        }
        case UserType.OPERATOR: {
          v1WebAbstractUserDto = new V1WebUserClientAreaOperatorDTO();
          break;
        }
        case UserType.PUBLIC: {
          v1WebAbstractUserDto = new V1WebUserClientPublicDTO();
          break;
        }
        default: {
          throw new Error("Unable to determine user type");
        }
      }

      if (v1WebAbstractUserDto instanceof V1WebAbstractUserClientDTO) {
        (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasTextMessageNotificationService = abstractUserClientHasTextMessageNotificationService;
        (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasEmailNotificationService = abstractUserClientHasEmailNotificationService;
        (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).customerUuid = customerUuid;
      }

      v1WebAbstractUserDto.abstractUserUuid = abstractUserUuid;
      v1WebAbstractUserDto.abstractUserUsername = abstractUserUsername;
      v1WebAbstractUserDto.abstractUserFirstName = abstractUserFirstName;
      v1WebAbstractUserDto.abstractUserLastName = abstractUserLastName;
      v1WebAbstractUserDto.abstractUserAddress = abstractUserAddress;
      v1WebAbstractUserDto.abstractUserZip = abstractUserZip;
      v1WebAbstractUserDto.abstractUserCity = abstractUserCity;
      v1WebAbstractUserDto.abstractUserEmail = abstractUserEmail;
      v1WebAbstractUserDto.abstractUserPhone = abstractUserPhone;
      v1WebAbstractUserDto.abstractUserNotes = abstractUserNotes;
      v1WebAbstractUserDto.abstractUserIsEnabled = abstractUserIsEnabled;

      v1WebUserService.putUser(v1WebAbstractUserDto).then((response: AxiosResponse) => {
        let v1WebAbstractUserDto = response.data as V1WebAbstractUserDTO;

        dispatch({
          type: USER_PUT,
          status: SUCCESS_STATUS,
          abstractUserUuid: v1WebAbstractUserDto.abstractUserUuid,
          abstractUserUsername: v1WebAbstractUserDto.abstractUserUsername,
          abstractUserFirstName: v1WebAbstractUserDto.abstractUserFirstName,
          abstractUserLastName: v1WebAbstractUserDto.abstractUserLastName,
          abstractUserAddress: v1WebAbstractUserDto.abstractUserAddress,
          abstractUserZip: v1WebAbstractUserDto.abstractUserZip,
          abstractUserCity: v1WebAbstractUserDto.abstractUserCity,
          abstractUserEmail: v1WebAbstractUserDto.abstractUserEmail,
          abstractUserPhone: v1WebAbstractUserDto.abstractUserPhone,
          abstractUserUserType: v1WebAbstractUserDto.abstractUserUserType,
          abstractUserNotes: v1WebAbstractUserDto.abstractUserNotes,
          abstractUserIsEnabled: v1WebAbstractUserDto.abstractUserIsEnabled,
          abstractUserIsAccountNonExpired: v1WebAbstractUserDto.abstractUserIsAccountNonExpired,
          abstractUserIsAccountNonLocked: v1WebAbstractUserDto.abstractUserIsAccountNonLocked,
          abstractUserIsCredentialsNonExpired: v1WebAbstractUserDto.abstractUserIsCredentialsNonExpired,
          abstractUserCredentialsDate: v1WebAbstractUserDto.abstractUserCredentialsDate,
          abstractUserClientHasTextMessageNotificationService: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasTextMessageNotificationService,
          abstractUserClientHasEmailNotificationService: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).abstractUserClientHasEmailNotificationService,
          customerUuid: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).customerUuid,
          customerCompanyName: (v1WebAbstractUserDto as V1WebAbstractUserClientDTO).customerCompanyName,
          abstractParkingAreaUuid: (v1WebAbstractUserDto as V1WebUserClientKioskDTO).abstractParkingAreaUuid,
          abstractParkingAreaParkingAreaCode: (v1WebAbstractUserDto as V1WebUserClientKioskDTO).abstractParkingAreaParkingAreaCode,
          userGuardTicketCount: (v1WebAbstractUserDto as V1WebUserGuardDTO).userGuardTicketCount,
          userGuardGuardId: (v1WebAbstractUserDto as V1WebUserGuardDTO).userGuardGuardId
        });

        dispatch({
          type: ROOT_INIT_USER
        });
      }).catch((error) => {
        dispatch({
          type: USER_PUT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function userPutAuthorities(abstractUserUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction, authorityUuids: string[]): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_PUT_AUTHORITIES,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.putUserAuthorities(abstractUserUuid, page, authorityUuids).then((response: AxiosResponse) => {
        let authorityPageableList = response.data as Response<V1WebAuthorityListElementDTO>

        let pageableListContent: any[] = [];
        authorityPageableList.content.forEach((value) => {
          pageableListContent.push([value.authorityUuid, value.authorityName, value.authorityDisplayName])
        });
        
        dispatch({
          type: USER_PUT_AUTHORITIES,
          status: SUCCESS_STATUS,
          authorityPageableListCount: authorityPageableList.totalElements,
          authorityPageableListPageNumber: authorityPageableList.pageable.pageNumber,
          authorityPageableListPageSize: authorityPageableList.pageable.pageSize,
          authorityPageableListContent: pageableListContent,
          authorityPageableListSort: sort,
          authorityPageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: USER_PUT_AUTHORITIES,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}

export function areaAdd(userUuid: string, areaUuid: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: "parkingarea/put/userclient",
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      v1WebUserService.putAreas(userUuid, areaUuid, page).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebParkingAreaListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractParkingAreaUuid, value.abstractParkingAreaParkingAreaCode, value.abstractParkingAreaAddress, value.abstractParkingAreaZip, value.abstractParkingAreaCity])
        });

        dispatch({
          type: "parkingarea/put/userclient",
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: "parkingarea/put/userclient",
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });


      resolve();
    });
  }
}

export function userAdd(userUuid: string, userClientUuid: string, multiSetup: boolean, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction): UserThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: USER_PUT_USER_CLIENT,
        status: LOADING_STATUS
      });

      let page: Page = {
        pageNumber: pageNumber,
        rowsPerPage: rowsPerPage,
        sort: sort,
        direction: direction
      }

      let addUserClientDto: V1WebAddUserClientDTO = {
        userClientUuid: userClientUuid,
        multiSetup: multiSetup
      }

      v1WebUserService.putUserClients(userUuid, page, addUserClientDto).then((response: AxiosResponse) => {
        let pageableList = response.data as Response<V1WebUserListElementDTO>

        let pageableListContent: any[] = [];
        pageableList.content.forEach((value) => {
          pageableListContent.push([value.abstractUserUuid, value.abstractUserUsername, value.abstractUserUserType, value.abstractUserIsEnabled])
        });

        dispatch({
          type: USER_PUT_USER_CLIENT,
          status: SUCCESS_STATUS,
          pageableListCount: pageableList.totalElements,
          pageableListPageNumber: pageableList.pageable.pageNumber,
          pageableListPageSize: pageableList.pageable.pageSize,
          pageableListContent: pageableListContent,
          pageableListSort: sort,
          pageableListDirection: direction
        });
      }).catch((error) => {
        dispatch({
          type: USER_PUT_USER_CLIENT,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        })
      });

      resolve();
    });
  }
}