import { AxiosResponse } from "axios";
import V1ForgotPasswordDTO from "dto/v1/v1forgotpassword.dto";
import V1JWTDTO from "dto/v1/v1jwt.dto";
import V1LoginDTO from "dto/v1/v1login.dto";
import V1ResetPasswordDTO from "dto/v1/v1resetpassword.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1AuthenticationService from "service/v1/v1authentication.service";
import v2WebAuthenticationService from "service/v2/web/v2webauthentication.service";
import { AuthenticationState } from "./authentication.reducer";
import V2WebLoginDTO from "dto/v2/web/v2weblogin.dto";
import V2WebAuthenticationDTO from "dto/v2/web/v2webauthentication.dto";

export const AUTHENTICATION_INIT = "authentication/init";
export const AUTHENTICATION_LOGIN = "authentication/login";
export const AUTHENTICATION_LOGOUT = "authentication/logout";

export interface AuthenticationAction {
  type:
    typeof AUTHENTICATION_INIT |
    typeof AUTHENTICATION_LOGIN |
    typeof AUTHENTICATION_LOGOUT
  status: StateStatus
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type AuthenticationThunkAction = ThunkAction<Promise<void>, AuthenticationState, any, AuthenticationAction>;

export function authenticationInit(): AuthenticationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: AUTHENTICATION_INIT,
        status: LOADING_STATUS
      });

      if (localStorage.getItem("authentication") !== null) {
        dispatch({
          type: AUTHENTICATION_INIT,
          status: SUCCESS_STATUS
        });
      } else {
        dispatch({
          type: AUTHENTICATION_INIT,
          status: FAILURE_STATUS
        });
      }

      resolve();
    });
  }
}

export function login(usernameOrEmail: string, password: string): AuthenticationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: AUTHENTICATION_LOGIN,
        status: LOADING_STATUS
      })

      let v2WebLoginDTO: V2WebLoginDTO = {
        usernameOrEmail: usernameOrEmail,
        password: password
      }

      v2WebAuthenticationService.login(v2WebLoginDTO).then((response: AxiosResponse) => {
        let v2WebAuthenticationDTO = response.data as V2WebAuthenticationDTO;

        localStorage.setItem("authentication", v2WebAuthenticationDTO.jwtString);

        dispatch({
          type: AUTHENTICATION_LOGIN,
          status: SUCCESS_STATUS,
        });
      }).catch((error) => {
        dispatch({
          type: AUTHENTICATION_LOGIN,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        });
      });

      resolve();
    });
  }
}

export function logout(): AuthenticationThunkAction {
  return async (dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: AUTHENTICATION_LOGOUT
      });

      resolve();
    });
  }
}