import { NavigateNext } from "@mui/icons-material";
import { Autocomplete, Box, Button, Card, CardContent, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputAdornment, Radio, RadioGroup, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import { parkingAreaGetPageableList, parkingAreaSearchPageableList } from "redux/parkingarea/parkingarea.action";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { NavLink } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import AddIcon from '@mui/icons-material/Add';
import v1WebParkingAreaService from "service/v1/web/v1webparkingarea.service"
import { ParkingAreaType } from "type/parkingarea.type";
import { AxiosResponse } from "axios";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebCustomerListElementDTO from "dto/v1/web/v1webcustomerlistelement.dto";
import v1WebCustomerService from "service/v1/web/v1webcustomer.service";
import Response from "dto/response";
import V1WebAbstractParkingAreaDTO from "dto/v1/web/v1webabstractparkingarea.dto";
import V1WebParkingAreaDayDTO from "dto/v1/web/v1webparkingareaday.dto";
import V1WebParkingAreaMinuteDTO from "dto/v1/web/v1webparkingareaminute.dto";
import { LimitationType } from "type/limitation.type";

interface OwnProps {
}

interface DispatchProps {
  parkingAreaGetPageableList: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  parkingAreaSearchPageableList: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  parkingAreaStateStatus: StateStatus
  parkingAreaPageableListCount: number
  parkingAreaPageableListPageNumber: number
  parkingAreaPageableListPageSize: number
  parkingAreaPageableListContent: Array<any[]>
  parkingAreaPageableListSort: string
  parkingAreaPageableListDirection: Direction
  parkingAreaPageableListKeyword: string
  parkingAreaErrorCode: number
  parkingAreaErrorMessage: string
  parkingAreaErrorUuid: string
}

interface FormProps {
  parkingAreaParkingAreaCode: string
  parkingAreaAddress: string
  parkingAreaZip: string
  parkingAreaCity: string
  parkingAreaWarningMinutes: number
  parkingAreaTicketPrice: number
  parkingAreaDefaultUnlimitedParkingLimitationType: LimitationType,
  parkingAreaDefaultInitialUnlimitedParking: number
  parkingAreaDefaultConcurrentUnlimitedParking: number
  parkingAreaDefaultMinuteParkingLimitationType: LimitationType,
  parkingAreaDefaultInitialMinuteParkingDays: number,
  parkingAreaDefaultInitialMinuteParkingHours: number,
  parkingAreaDefaultInitialMinuteParkingMinutes: number,
  parkingAreaDefaultConcurrentMinuteParking: number
  parkingAreaParkingAreaType: ParkingAreaType
  parkingAreaNotes: string
  parkingAreaCustomerUuid: string
  parkingAreaCustomerCompanyName: string
  parkingAreaDayNumberOfDays: number
  parkingAreaDayHourOfDay: number
  parkingAreaDayMinuteOfHour: number
  parkingAreaMinuteAllowedParkingMinutes: number
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorParkingAreaListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [isCustomerLoading, setCustomerLoading] = useState(false);
  const [showViewAlert, setShowViewAlert] = useState(false);
  const [isCreateDialogOpen, setCreateDialogOpen] = useState(false);
  const [customerOptions, setCustomerOptions] = useState(Array<any[]>());

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({ mode: 'all' });

  const onSearchFormSubmit: SubmitHandler<StateProps> = (stateProps: StateProps) => {
    if (!isViewLoading && stateProps.parkingAreaPageableListKeyword.length > 0) {
      props.parkingAreaSearchPageableList(stateProps.parkingAreaPageableListKeyword, 0, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
    }
  }

  const {
    handleSubmit: handleCreateFormSubmit,
    control: createFormControl,
    getValues: getCreateFormValues,
    setValue: setCreateFormValue,
    reset: resetCreateForm,
    watch: watchCreateForm
  } = useForm<FormProps>({
    mode: 'all',
    defaultValues: {
    }
  });

  const onCreateFormSubmit: SubmitHandler<FormProps> = (formProps: FormProps) => {
    if (!isViewLoading && formProps.parkingAreaParkingAreaType !== null) {
      let v1WebAbstractParkingAreaDto: V1WebAbstractParkingAreaDTO;

      switch (formProps.parkingAreaParkingAreaType) {
        case ParkingAreaType.DAY: {
          v1WebAbstractParkingAreaDto = new V1WebParkingAreaDayDTO();
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayNumberOfDays = formProps.parkingAreaDayNumberOfDays;
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayHourOfDay = formProps.parkingAreaDayHourOfDay;
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaDayDTO).parkingAreaDayMinuteOfHour = formProps.parkingAreaDayMinuteOfHour;
          break;
        }
        case ParkingAreaType.MINUTE: {
          v1WebAbstractParkingAreaDto = new V1WebParkingAreaMinuteDTO();
          (v1WebAbstractParkingAreaDto as V1WebParkingAreaMinuteDTO).parkingAreaMinuteAllowedParkingMinutes = formProps.parkingAreaMinuteAllowedParkingMinutes;
          break;
        }
        default: {
          throw new Error("Unable to determine parking area type derp");
        }
      }

      v1WebAbstractParkingAreaDto.abstractParkingAreaParkingAreaCode = formProps.parkingAreaParkingAreaCode;
      v1WebAbstractParkingAreaDto.abstractParkingAreaAddress = formProps.parkingAreaAddress;
      v1WebAbstractParkingAreaDto.abstractParkingAreaZip = formProps.parkingAreaZip;
      v1WebAbstractParkingAreaDto.abstractParkingAreaCity = formProps.parkingAreaCity;
      v1WebAbstractParkingAreaDto.abstractParkingAreaWarningMinutes = formProps.parkingAreaWarningMinutes;
      v1WebAbstractParkingAreaDto.abstractParkingAreaTicketPrice = formProps.parkingAreaTicketPrice;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultInitialUnlimitedParking = formProps.parkingAreaDefaultInitialUnlimitedParking;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentUnlimitedParking = formProps.parkingAreaDefaultConcurrentUnlimitedParking;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultUnlimitedParkingLimitationType = formProps.parkingAreaDefaultUnlimitedParkingLimitationType;

      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultMinuteParkingV1DateTimeDto = {
        date: formProps.parkingAreaDefaultInitialMinuteParkingDays,
        hour: formProps.parkingAreaDefaultInitialMinuteParkingHours,
        minute: formProps.parkingAreaDefaultInitialMinuteParkingMinutes,
        year: 0,
        month: 0,
        second: 0
      }

      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultConcurrentMinuteParking = formProps.parkingAreaDefaultConcurrentMinuteParking;
      v1WebAbstractParkingAreaDto.abstractParkingAreaDefaultMinuteParkingLimitationType = formProps.parkingAreaDefaultMinuteParkingLimitationType;
      v1WebAbstractParkingAreaDto.abstractParkingAreaNotes = formProps.parkingAreaNotes;
      v1WebAbstractParkingAreaDto.customerUuid = formProps.parkingAreaCustomerUuid;

      setViewLoading(true);

      v1WebParkingAreaService.createParkingArea(v1WebAbstractParkingAreaDto).then((response: AxiosResponse) => {
        setViewLoading(false);
        alert("Parkeringsområde oprettet");

        if (props.parkingAreaPageableListKeyword.length > 0) {
          props.parkingAreaSearchPageableList(props.parkingAreaPageableListKeyword, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
        } else {
          props.parkingAreaGetPageableList(props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
        }

        setCreateDialogOpen(false);
      }).catch((error) => {
        setViewLoading(false);
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }

  const watchParkingAreaUnlimitedParkingLimitationType = watchCreateForm("parkingAreaDefaultUnlimitedParkingLimitationType");
  const watchParkingAreaMinuteParkingLimitationType = watchCreateForm("parkingAreaDefaultMinuteParkingLimitationType");
  const watchParkingAreaCustomerCompanyName = watchCreateForm("parkingAreaCustomerCompanyName");
  const watchParkingAreaParkingAreaType = watchCreateForm("parkingAreaParkingAreaType");

  const handleParkingAreaPageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.parkingAreaPageableListKeyword.length > 0) {
      props.parkingAreaSearchPageableList(props.parkingAreaPageableListKeyword, 0, parseInt(event.target.value), props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
    } else {
      props.parkingAreaGetPageableList(0, parseInt(event.target.value), props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
    }
  }

  const handleParkingAreaPageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.parkingAreaPageableListKeyword.length > 0) {
      props.parkingAreaSearchPageableList(props.parkingAreaPageableListKeyword, newPage, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection)
    } else {
      props.parkingAreaGetPageableList(newPage, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection)
    }
  }

  const handleParkingAreaDirectionChange = (property: string) => (event: React.MouseEvent<unknown>) => {
    if (props.parkingAreaPageableListDirection === Direction.ASC) {
      if (props.parkingAreaPageableListKeyword.length > 0) {
        props.parkingAreaSearchPageableList(props.parkingAreaPageableListKeyword, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, property, Direction.DESC);
      } else {
        props.parkingAreaGetPageableList(props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.parkingAreaPageableListKeyword.length > 0) {
        props.parkingAreaSearchPageableList(props.parkingAreaPageableListKeyword, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, property, Direction.ASC);
      } else {
        props.parkingAreaGetPageableList(props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleParkingAreaKeywordClear = () => {
    props.parkingAreaGetPageableList(props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
  }

  const toogleCreateDialogOpen = () => {
    resetCreateForm();
    setCreateDialogOpen(!isCreateDialogOpen);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.parkingAreaPageableListKeyword.length > 0) {
        props.parkingAreaSearchPageableList(props.parkingAreaPageableListKeyword, props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
      } else {
        props.parkingAreaGetPageableList(props.parkingAreaPageableListPageNumber, props.parkingAreaPageableListPageSize, props.parkingAreaPageableListSort, props.parkingAreaPageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.parkingAreaStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("parkingAreaPageableListKeyword", props.parkingAreaPageableListKeyword)
        setViewLoading(false);
        setShowViewAlert(false)
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.parkingAreaStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (watchParkingAreaCustomerCompanyName !== undefined && watchParkingAreaCustomerCompanyName !== null && watchParkingAreaCustomerCompanyName.trim().length > 0) {
      setCustomerLoading(true);

      let page: Page = {
        pageNumber: 0,
        rowsPerPage: 5,
        sort: "companyName",
        direction: Direction.ASC
      }

      let v1KeywordDto: V1KeywordDTO = {
        keyword: getCreateFormValues("parkingAreaCustomerCompanyName")
      }

      v1WebCustomerService.searchCustomer(v1KeywordDto, page).then((response: AxiosResponse) => {
        let customerPageableList = response.data as Response<V1WebCustomerListElementDTO>

        let pageableListContent: Array<any[]> = [];
        customerPageableList.content.forEach((value) => {
          pageableListContent.push([value.customerUuid, value.customerCompanyName])
        });
        setCustomerOptions(pageableListContent);
        setCustomerLoading(false);
      }).catch((error) => {
        alert("Der skete en fejl: " + error.errorMessage);
      });
    }
  }, [watchParkingAreaCustomerCompanyName]); // eslint-disable-line react-hooks/exhaustive-deps

  const headCells: readonly HeadCell[] = [
    {
      id: "areaCode",
      numeric: false,
      disablePadding: false,
      label: "PAC"
    },
    {
      id: "address",
      numeric: false,
      disablePadding: false,
      label: "Adresse"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.parkingAreaErrorCode} errorMessage={props.parkingAreaErrorMessage} errorUuid={props.parkingAreaErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Parkeringsområder
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="parkingAreaPageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => { handleParkingAreaKeywordClear() }}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {headCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.parkingAreaPageableListSort === headCell.id}
                          direction={props.parkingAreaPageableListDirection}
                          onClick={handleParkingAreaDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.parkingAreaPageableListContent === undefined || props.parkingAreaPageableListContent.length === 0 ? props.parkingAreaPageableListPageSize : props.parkingAreaPageableListContent.length} tableColSpan={headCells.length} />
                    </>
                  ) : (
                    <>
                      {props.parkingAreaPageableListCount === undefined || props.parkingAreaPageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={headCells.length} />
                        </>
                      ) : (
                        <>
                          {props.parkingAreaPageableListContent.map(parkingArea => (
                            <TableRow
                              hover
                              key={parkingArea[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkingArea[1]}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {parkingArea[2] + ", " + parkingArea[3] + " " + parkingArea[4]}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={parkingArea[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={headCells.length + 1}
                      count={props.parkingAreaPageableListCount || 0}
                      rowsPerPage={props.parkingAreaPageableListPageSize || 5}
                      page={props.parkingAreaPageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                      onPageChange={handleParkingAreaPageNumberChange}
                      onRowsPerPageChange={handleParkingAreaPageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
      <Dialog
        open={isCreateDialogOpen}
        onClose={toogleCreateDialogOpen}
        fullScreen
      >
        <form onSubmit={handleCreateFormSubmit(onCreateFormSubmit)}>
          <DialogTitle>Opret parkeringsområde</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Udfyld felterne nedenfor for at oprette et parkeringsområde
            </DialogContentText>
            <Divider />
          </DialogContent>
          <DialogContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Områdetype er påkrævet"
                    }
                  }}
                  control={createFormControl}
                  name="parkingAreaParkingAreaType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      component="fieldset"
                      error={!!error}
                    >
                      <FormLabel component="legend">Parkeringsområde begrænsninger</FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        value={field.value || ""}
                      >
                        <FormControlLabel
                          value={ParkingAreaType.DAY}
                          control={<Radio />}
                          label="Dags parkering"
                        />
                        <FormControlLabel
                          value={ParkingAreaType.MINUTE}
                          control={<Radio />}
                          label="Minut parkering"
                        />
                      </RadioGroup>
                      <FormHelperText sx={{
                        marginLeft: 0
                      }}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              {watchParkingAreaParkingAreaType === ParkingAreaType.DAY &&
                <>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <Controller
                      name="parkingAreaDayNumberOfDays"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Antal dage er påkrævet"
                        },
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Antal dage"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : "Antal dage en ny tilladelse skal gælde"}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <Controller
                      name="parkingAreaDayHourOfDay"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Time er påkrævet"
                        },
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value >= 0 && value <= 23) {
                                resolve(true)
                              } else {
                                resolve("Angiv venligst et tal mellem 0 og 23")
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Time på dagen"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : "Angiv den time hvortil en ny tilladelse skal gælde"}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid
                    item
                    lg={4}
                    md={4}
                    xs={4}
                  >
                    <Controller
                      name="parkingAreaDayMinuteOfHour"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Minut er påkrævet"
                        },
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        },
                        validate: {
                          value: ((value) => {
                            return new Promise((resolve) => {
                              if (value >= 0 && value <= 59) {
                                resolve(true)
                              } else {
                                resolve("Angiv venligst et tal mellem 0 og 59")
                              }
                            });
                          })
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Antal minut i timen"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : "Angiv det minut hvortil en ny tilladelse skal gælde"}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              }
              {watchParkingAreaParkingAreaType === ParkingAreaType.MINUTE &&
                <>
                  <Grid
                    item
                    lg={12}
                    md={12}
                    xs={12}
                  >
                    <Controller
                      name="parkingAreaMinuteAllowedParkingMinutes"
                      control={createFormControl}
                      rules={{
                        required: {
                          value: true,
                          message: "Tilladt parkerings tid er påkrævet"
                        },
                        pattern: {
                          value: /^[0-9]+$/i,
                          message: "Ikke et gyldigt tal"
                        }
                      }}
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <TextField
                          label="Tilladt parkerings tid"
                          fullWidth
                          value={value || ""}
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : "Angiv i minutter"}
                          onKeyPress={(e) => {
                            if (!e.key.match(/[0-9]+/i)) {
                              e.preventDefault();
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                </>
              }
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Egenparkerings begrænsnings type er påkrævet"
                    }
                  }}
                  control={createFormControl}
                  name="parkingAreaDefaultUnlimitedParkingLimitationType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      component="fieldset"
                      error={!!error}
                    >
                      <FormLabel component="legend">Egenparkering</FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        value={field.value || ""}
                      >
                        <FormControlLabel
                          value={LimitationType.PROHIBITED}
                          control={<Radio />}
                          label="Forbudt"
                        />
                        <FormControlLabel
                          value={LimitationType.LIMITED}
                          control={<Radio />}
                          label="Begrænset"
                        />
                        <FormControlLabel
                          value={LimitationType.UNLIMITED}
                          control={<Radio />}
                          label="Ubegrænset"
                        />
                      </RadioGroup>
                      <FormHelperText sx={{
                        marginLeft: 0
                      }}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  rules={{
                    required: {
                      value: true,
                      message: "Gæsteparkerings begrænsnings type er påkrævet"
                    }
                  }}
                  control={createFormControl}
                  name="parkingAreaDefaultMinuteParkingLimitationType"
                  render={({ field, fieldState: { error } }) => (
                    <FormControl
                      component="fieldset"
                      error={!!error}
                    >
                      <FormLabel component="legend">Gæsteparkering</FormLabel>
                      <RadioGroup
                        {...field}
                        row
                        value={field.value || ""}
                      >
                        <FormControlLabel
                          value={LimitationType.PROHIBITED}
                          control={<Radio />}
                          label="Forbudt"
                        />
                        <FormControlLabel
                          value={LimitationType.LIMITED}
                          control={<Radio />}
                          label="Begrænset"
                        />
                        <FormControlLabel
                          value={LimitationType.UNLIMITED}
                          control={<Radio />}
                          label="Ubegrænset"
                        />
                      </RadioGroup>
                      <FormHelperText sx={{
                        marginLeft: 0
                      }}>{error ? error.message : null}</FormHelperText>
                    </FormControl>
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  {(watchParkingAreaUnlimitedParkingLimitationType === LimitationType.LIMITED || watchParkingAreaUnlimitedParkingLimitationType === LimitationType.UNLIMITED) &&
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <Controller
                          name="parkingAreaDefaultConcurrentUnlimitedParking"
                          control={createFormControl}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Ikke et gyldigt tal"
                            },
                            validate: {
                              value: ((value) => {
                                return new Promise((resolve) => {
                                  if (value === undefined || (value >= 0 && value <= 99)) {
                                    resolve(true)
                                  } else {
                                    resolve("Angiv venligst et tal mellem 0 og 99")
                                  }
                                });
                              })
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Maksimale antal tiladte igangværende egenparkeringer"
                              fullWidth
                              value={value || ""}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              onKeyPress={(e) => {
                                if (!e.key.match(/[0-9]+/i)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  }
                  {watchParkingAreaUnlimitedParkingLimitationType === LimitationType.LIMITED &&
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <DialogContentText marginBottom={-4}>
                          Udfyld feltet nedenfor for at definere det årlige antal tilladte registreringer/ændringer af egenparkeringer som tildeles ved årets start
                        </DialogContentText>
                      </Grid>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <Controller
                          name="parkingAreaDefaultInitialUnlimitedParking"
                          control={createFormControl}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Ikke et gyldigt tal"
                            },
                            validate: {
                              value: ((value) => {
                                return new Promise((resolve) => {
                                  if (value === undefined || (value >= 0 && value <= 99)) {
                                    resolve(true)
                                  } else {
                                    resolve("Angiv venligst et tal mellem 0 og 99")
                                  }
                                });
                              })
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Årlige antal tilladte registreringer/ændringer af egenparkering"
                              fullWidth
                              value={value || ""}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              onKeyPress={(e) => {
                                if (!e.key.match(/[0-9]+/i)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Grid
                  container
                  spacing={2}
                >
                  {(watchParkingAreaMinuteParkingLimitationType === LimitationType.LIMITED || watchParkingAreaMinuteParkingLimitationType === LimitationType.UNLIMITED) &&
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <Controller
                          name="parkingAreaDefaultConcurrentMinuteParking"
                          control={createFormControl}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Ikke et gyldigt tal"
                            },
                            validate: {
                              value: ((value) => {
                                return new Promise((resolve) => {
                                  if (value === undefined || (value >= 0 && value <= 99)) {
                                    resolve(true)
                                  } else {
                                    resolve("Angiv venligst et tal mellem 0 og 99")
                                  }
                                });
                              })
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label="Maksimale antal tiladte igangværende gæsteparkeringer"
                              fullWidth
                              value={value || ""}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              onKeyPress={(e) => {
                                if (!e.key.match(/[0-9]+/i)) {
                                  e.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  }
                  {watchParkingAreaMinuteParkingLimitationType === LimitationType.LIMITED &&
                    <>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                      >
                        <DialogContentText marginBottom={-4}>
                          Udfyld felterne nedenfor for at definere den månedlige mængde gæsteparkering som tildeles ved månedens start. Angiv enten i dage, timer, minutter eller en kombination heraf
                        </DialogContentText>
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xs={4}
                      >
                        <Controller
                          name="parkingAreaDefaultInitialMinuteParkingDays"
                          control={createFormControl}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Ikke et gyldigt tal"
                            },
                            validate: {
                              value: ((value) => {
                                return new Promise((resolve) => {
                                  if (value === undefined || (value >= 0 && value <= 31)) {
                                    resolve(true)
                                  } else {
                                    resolve("Angiv venligst et tal mellem 0 og 31")
                                  }
                                });
                              })
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label=""
                              fullWidth
                              value={value || ""}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              onKeyPress={(e) => {
                                if (!e.key.match(/[0-9]+/i)) {
                                  e.preventDefault();
                                }
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Dage</InputAdornment>,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xs={4}
                      >
                        <Controller
                          name="parkingAreaDefaultInitialMinuteParkingHours"
                          control={createFormControl}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Ikke et gyldigt tal"
                            },
                            validate: {
                              value: ((value) => {
                                return new Promise((resolve) => {
                                  if (value === undefined || (value >= 0 && value <= 999)) {
                                    resolve(true)
                                  } else {
                                    resolve("Angiv venligst et tal mellem 0 og 999")
                                  }
                                });
                              })
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label=""
                              fullWidth
                              value={value || ""}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              onKeyPress={(e) => {
                                if (!e.key.match(/[0-9]+/i)) {
                                  e.preventDefault();
                                }
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Timer</InputAdornment>,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid
                        item
                        lg={4}
                        md={4}
                        xs={4}
                      >
                        <Controller
                          name="parkingAreaDefaultInitialMinuteParkingMinutes"
                          control={createFormControl}
                          rules={{
                            pattern: {
                              value: /^[0-9]+$/i,
                              message: "Ikke et gyldigt tal"
                            },
                            validate: {
                              value: ((value) => {
                                return new Promise((resolve) => {
                                  if (value === undefined || (value >= 0 && value <= 999)) {
                                    resolve(true)
                                  } else {
                                    resolve("Angiv venligst et tal mellem 0 og 999")
                                  }
                                });
                              })
                            }
                          }}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <TextField
                              label=""
                              fullWidth
                              value={value || ""}
                              onChange={onChange}
                              error={!!error}
                              helperText={error ? error.message : null}
                              onKeyPress={(e) => {
                                if (!e.key.match(/[0-9]+/i)) {
                                  e.preventDefault();
                                }
                              }}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">Minutter</InputAdornment>,
                              }}
                            />
                          )}
                        />
                      </Grid>
                    </>
                  }
                </Grid>
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  name="parkingAreaParkingAreaCode"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Parking area code er påkrævet"
                    },
                    maxLength: {
                      value: 256,
                      message: "Parking area code må ikke overstige 256 tegn"
                    },
                    validate: {
                      value: ((value) => {
                        return new Promise((resolve) => {
                          if (value !== undefined && value.length >= 1) {
                            v1WebParkingAreaService.existsParkingAreaCode(value).then((res) => {
                              if (res.data) {
                                resolve("Parking area code er allerede i brug")
                              } else {
                                resolve(true)
                              }
                            }).catch((error) => {
                              resolve(error);
                            })
                          }
                        });
                      })
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Parking area code"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  name="parkingAreaAddress"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Adresse er påkrævet"
                    },
                    maxLength: {
                      value: 256,
                      message: "Adresse må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Adresse"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={4}
                xs={12}
              >
                <Controller
                  name="parkingAreaZip"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Postnummer er påkrævet"
                    },
                    maxLength: {
                      value: 256,
                      message: "Postnummer må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Postnummer"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={8}
                md={8}
                xs={12}
              >
                <Controller
                  name="parkingAreaCity"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "By er påkrævet"
                    },
                    maxLength: {
                      value: 256,
                      message: "By må ikke overstige 256 tegn"
                    },
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="By"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="parkingAreaCustomerCompanyName"
                  control={createFormControl}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <Autocomplete
                      inputValue={value || ""}
                      onChange={(event: any, newValue: any[] | null) => {
                        if (newValue !== null) {
                          setCreateFormValue("parkingAreaCustomerCompanyName", newValue[1]);
                          setCreateFormValue("parkingAreaCustomerUuid", newValue[0]);
                        } else {
                          setCreateFormValue("parkingAreaCustomerCompanyName", "");
                          setCreateFormValue("parkingAreaCustomerUuid", "");
                        }
                      }}
                      options={customerOptions}
                      getOptionLabel={(option) => option[1]}
                      isOptionEqualToValue={(option, value) => option[0] === value[0]}
                      noOptionsText={"Ingen resultater"}
                      filterOptions={(x) => x}
                      loading={isCustomerLoading}
                      loadingText={"Vent venligst.."}
                      openText={""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Kunde"
                          fullWidth
                          onChange={onChange}
                          error={!!error}
                          helperText={error ? error.message : null}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {isCustomerLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  name="parkingAreaWarningMinutes"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Advarsels minutter er påkrævet"
                    },
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "Ikke et gyldigt tal"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Advarsels tid"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : "Angiv i minutter"}
                      onKeyPress={(e) => {
                        if (!e.key.match(/[0-9]+/i)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
              >
                <Controller
                  name="parkingAreaTicketPrice"
                  control={createFormControl}
                  rules={{
                    required: {
                      value: true,
                      message: "Afgifters beløb er påkrævet"
                    },
                    pattern: {
                      value: /^[0-9]+$/i,
                      message: "Ikke et gyldigt tal"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Afgifters beløb"
                      fullWidth
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : "Angiv i hele danske kroner inkl. moms"}
                      onKeyPress={(e) => {
                        if (!e.key.match(/[0-9]+/i)) {
                          e.preventDefault();
                        }
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                lg={12}
                md={12}
                xs={12}
              >
                <Controller
                  name="parkingAreaNotes"
                  control={createFormControl}
                  rules={{
                    maxLength: {
                      value: 256,
                      message: "Noter må ikke overstige 256 tegn"
                    }
                  }}
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      label="Noter"
                      fullWidth
                      multiline
                      rows={4}
                      value={value || ""}
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                p: 2
              }}
            >
              <Button
                disabled={isViewLoading}
                onClick={toogleCreateDialogOpen}
              >
                Annuller
              </Button>
              <Button
                color="primary"
                disabled={isViewLoading}
                type="submit"
                variant="contained"
              >
                Opret
              </Button>
            </Box>
          </DialogActions>
        </form>
      </Dialog>
      <Fab
        onClick={toogleCreateDialogOpen}
        color="primary"
        aria-label="add"
        sx={{
          margin: 0,
          top: "auto",
          right: 20,
          bottom: 20,
          left: "auto",
          position: "fixed"
        }}
      >
        <AddIcon />
      </Fab>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    parkingAreaStateStatus: redux.parkingArea.status,
    parkingAreaPageableListCount: redux.parkingArea.pageableListCount,
    parkingAreaPageableListPageNumber: redux.parkingArea.pageableListPageNumber,
    parkingAreaPageableListPageSize: redux.parkingArea.pageableListPageSize,
    parkingAreaPageableListContent: redux.parkingArea.pageableListContent,
    parkingAreaPageableListSort: redux.parkingArea.pageableListSort,
    parkingAreaPageableListDirection: redux.parkingArea.pageableListDirection,
    parkingAreaPageableListKeyword: redux.parkingArea.pageableListKeyword,
    parkingAreaErrorCode: redux.parkingArea.errorCode,
    parkingAreaErrorMessage: redux.parkingArea.errorMessage,
    parkingAreaErrorUuid: redux.parkingArea.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    parkingAreaGetPageableList: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(parkingAreaGetPageableList(pageNumber, rowsPerPage, sort, direction))
    },
    parkingAreaSearchPageableList: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(parkingAreaSearchPageableList(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministratorParkingAreaListView);