import { Grid, Card, CardContent, Typography, Box } from "@mui/material";
import CustomContainer from "component/customcontainer";
import React from "react";

const NotFoundView: React.FC = () => {
  return (
    <CustomContainer>
      <Grid
        container
        spacing={3}
        direction="row"
        alignItems="flex-start"
        justifyContent="center"
        sx={{
          marginTop: 0
        }}
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
        >
          <Card>
            <CardContent>
              <Typography
                variant='h1'
                color="textPrimary"
                align='center'
              >
                404
              </Typography>
              <Typography
                variant='h5'
                color="textSecondary"
                align='center'
              >
                Ikke fundet
              </Typography>
              <Box
                display="flex"
                justifyContent="center"
              >
                <Grid
                  item
                  lg={8}
                  md={10}
                  xs={12}
                >
                  <img
                    width="100%"
                    alt="Error"
                    src="/asset/error.gif"
                  />
                </Grid>
              </Box>
              <Typography
                variant='h5'
                color="textSecondary"
                align='center'
              >
                Det du forsøgte at få vist kunne ikke findes.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CustomContainer>
  )
}

export default NotFoundView;