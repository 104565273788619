import DTO from "dto/dto";
import { LimitationType } from "type/limitation.type";
import { ParkingAreaType } from "type/parkingarea.type";
import V1DateTimeDTO from "../v1datetime.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default abstract class V1WebAbstractParkingAreaDTO implements DTO {

  abstractParkingAreaUuid!: string;
  abstractParkingAreaParkingAreaCode!: string;
  abstractParkingAreaAddress!: string;
  abstractParkingAreaZip!: string;
  abstractParkingAreaCity!: string;
  abstractParkingAreaWarningMinutes!: number;
  abstractParkingAreaTicketPrice!: number;
  abstractParkingAreaDefaultUnlimitedParkingLimitationType!: LimitationType | null;
  abstractParkingAreaDefaultInitialUnlimitedParking!: number;
  abstractParkingAreaDefaultConcurrentUnlimitedParking!: number;
  abstractParkingAreaDefaultMinuteParkingLimitationType!: LimitationType | null;
  abstractParkingAreaDefaultMinuteParkingV1DateTimeDto!: V1DateTimeDTO | null;
  abstractParkingAreaDefaultInitialMinuteParking!: number;
  abstractParkingAreaDefaultConcurrentMinuteParking!: number;
  abstractParkingAreaAreaType!: ParkingAreaType;
  abstractParkingAreaNotes!: string;
  customerUuid!: string;
  customerCompanyName!: string;
}