import { TableCell, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { RootRedux } from "redux/store";
import SpinnerContainer from "./spinnercontainer";

interface OwnProps {
  tablePageSize: number | null
  tableColSpan: number | null
}

interface DispatchProps {
}

interface StateProps {
}

type Props = StateProps & OwnProps & DispatchProps

const FetchingContainer = (props: Props) => {
  const [tablePageSize, setTablePageSize] = useState(0);
  const [tableColSpan, setTableColSpan] = useState(100);

  useEffect(() => {
    setTablePageSize(props.tablePageSize === null || props.tablePageSize === undefined ? 0 : props.tablePageSize);
    setTableColSpan(props.tableColSpan === null || props.tableColSpan === undefined ? 100 : props.tableColSpan);
  }, [props.tablePageSize, props.tableColSpan]); // eslint-disable-line react-hooks/exhaustive-deps

  return(
    <>
      <TableRow
        hover
        key={1}
      >
        <TableCell
          colSpan={tableColSpan + 1}
          height={tablePageSize * 73}
          sx={{
            '&:hover': {
              background: "#fff",
            }
          }}
        >
          <SpinnerContainer children={[]} />
        </TableCell>
      </TableRow>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
  }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FetchingContainer)