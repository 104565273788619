import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus"
import { UserType } from "type/user.type"
import { AccountAction, ACCOUNT_GET_USERDETAILS, ACCOUNT_PUT_USERDETAILS } from "./account.action"

export type AccountState = {
  status: StateStatus,
  username: string,
  firstName: string,
  lastName: string,
  address: string,
  zip: string,
  city: string,
  email: string,
  phone: string,
  userType: UserType | null,
  errorCode: number,
  errorMessage: string,
  errorUuid: string
}

const initialState: AccountState = {
  status: IDLE_STATUS,
  username: "",
  firstName: "",
  lastName: "",
  address: "",
  zip: "",
  city: "",
  email: "",
  phone: "",
  userType: null,
  errorCode: 0,
  errorMessage: "",
  errorUuid: ""
}

function accountReducer(state: AccountState = initialState, action: AccountAction): AccountState {
  switch (state.status) {
    case IDLE_STATUS: {
      return accountIdleReducer(state, action);
    }
    case LOADING_STATUS: {
      return accountLoadingReducer(state, action);
    }
    case SUCCESS_STATUS: {
      return accountSuccessReducer(state, action);
    }
    case FAILURE_STATUS: {
      return accountFailureReducer(state, action);
    }
    default: {
      return state;
    }
  } 
}

export default accountReducer;

function accountIdleReducer(state: AccountState, action: AccountAction): AccountState {
  switch (action.type) {
    case ACCOUNT_GET_USERDETAILS: {
      return {
        ...state,
        status: action.status,
      }
    }
    case ACCOUNT_PUT_USERDETAILS: {
      return {
        ...state,
        status: action.status,
      }
    }
    default: {
      return state;
    }
  }
}

function accountLoadingReducer(state: AccountState, action: AccountAction): AccountState {
    switch (action.type) {
      case ACCOUNT_GET_USERDETAILS: {
        return {
          status: action.status,
          username: action.username,
          firstName: action.firstName,
          lastName: action.lastName,
          address: action.address,
          zip: action.zip,
          city: action.city,
          email: action.email,
          phone: action.phone,
          userType: action.userType,
          errorCode: action.errorCode,
          errorMessage: action.errorMessage,
          errorUuid: action.errorUuid
        }
      }
      case ACCOUNT_PUT_USERDETAILS:
        return {
          status: action.status,
          username: action.username,
          firstName: action.firstName,
          lastName: action.lastName,
          address: action.address,
          zip: action.zip,
          city: action.city,
          email: action.email,
          phone: action.phone,
          userType: action.userType,
          errorCode: action.errorCode,
          errorMessage: action.errorMessage,
          errorUuid: action.errorUuid
        }
      default: {
        return state;
      }
    }
  } 

  function accountSuccessReducer(state: AccountState, action: AccountAction): AccountState {
    switch (action.type) {
      case ACCOUNT_GET_USERDETAILS: {
        return {
          ...state,
          status: action.status,
        }
      }
      case ACCOUNT_PUT_USERDETAILS: {
        return {
          ...state,
          status: action.status,
        }
      }
      default: {
        return state;
      }
    }
  } 

  function accountFailureReducer(state: AccountState, action: AccountAction): AccountState {
    switch (action.type) {
      case ACCOUNT_GET_USERDETAILS: {
        return {
          ...state,
          status: action.status,
        }
      }
      case ACCOUNT_PUT_USERDETAILS: {
        return {
          ...state,
          status: action.status,
        }
      }
      default:
        return state;
    }
  } 