import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebAbstractParkingAreaDTO from "dto/v1/web/v1webabstractparkingarea.dto";

const baseUrl = "v1/web/parkingarea";

class V1WebParkingAreaService {
  searchParkingAreaPageableListForUserClientAreaAsUserClientArea(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post(baseUrl + "/userclientoperator/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }


  createParkingArea(v1WebAbstractParkingAreaDto: V1WebAbstractParkingAreaDTO) {
    return axiosConfig.post("v1/web/parkingarea/", v1WebAbstractParkingAreaDto);
  }
  getParkingArea(parkingAreaUuid: string) {
    return axiosConfig.get("v1/web/parkingarea/" + parkingAreaUuid);
  }
  getParkingAreas(page: Page) {
    return axiosConfig.get("v1/web/parkingarea?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchParkingArea(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/parkingarea/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  existsParkingAreaCode(parkingAreaCode: string) {
    return axiosConfig.get("v1/web/parkingarea/parkingareacode/" + parkingAreaCode);
  }
  getParkingAreaParkPermit(parkingAreaUuid: string, page: Page) {
    return axiosConfig.get("v1/web/parkingarea/" + parkingAreaUuid + "/parkpermit?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getParkingAreaTicket(parkingAreaUuid: string, page: Page) {
    return axiosConfig.get("v1/web/parkingarea/" + parkingAreaUuid + "/ticket?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  putParkingArea(v1WebAbstractParkingAreaDto: V1WebAbstractParkingAreaDTO) {
    return axiosConfig.put("v1/web/parkingarea/", v1WebAbstractParkingAreaDto);
  }
}

export default new V1WebParkingAreaService();