import DTO from "dto/dto";

export default class V1DateTimeDTO implements DTO {

  month!: number
  date!: number
  year!: number
  hour!: number
  minute!: number
  second!: number
}