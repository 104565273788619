import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";

class V1WebErrorService {
  getErrors(page: Page) {
    return axiosConfig.get("v1/web/error?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchError(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/error/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
}

export default new V1WebErrorService();