import DTO from "dto/dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebCustomerDTO implements DTO {

  customerUuid!: string;
  customerCompanyName!: string;
  customerFirstName!: string;
  customerLastName!: string;
  customerAddress!: string;
  customerZip!: string;
  customerCity!: string;
  customerEmail!: string;
  customerPhoneNumber!: string;
  customerVatNumber!: string;
  customerBankName!: string;
  customerBankAccount!: string;
  customerNotes!: string;
}