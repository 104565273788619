import DTO from "dto/dto";
import { UserType } from "type/user.type";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default abstract class V1WebAbstractUserDTO implements DTO {

  abstractUserUuid!: string;
  abstractUserUsername!: string;
  abstractUserFirstName!: string;
  abstractUserLastName!: string;
  abstractUserAddress!: string;
  abstractUserZip!: string;
  abstractUserCity!: string;
  abstractUserEmail!: string;
  abstractUserPhone!: string;
  abstractUserUserType!: UserType;
  abstractUserNotes!: string;
  abstractUserIsEnabled!: boolean;
  abstractUserIsAccountNonExpired!: boolean;
  abstractUserIsAccountNonLocked!: boolean;
  abstractUserIsCredentialsNonExpired!: boolean;
  abstractUserCredentialsDate!: Date;
}