import { NavigateNext } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Tooltip, Typography } from "@mui/material";
import CustomContainer from "component/customcontainer";
import HeadCell from "component/headcell";
import { Direction } from "dto/direction";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, IDLE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import { RootRedux } from "redux/store";
import { vehicleGetPageableList, vehicleSearchPageableList } from "redux/vehicle/vehicle.action";
import ErrorContainer from "component/errorcontainer";
import FetchingContainer from "component/fetchingcontainer";
import EmptyContainer from "component/emptycontainer";
import { NavLink } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";

interface OwnProps {
}

interface DispatchProps {
  vehicleGetPageableList: (pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
  vehicleSearchPageableList: (keyword: string, pageNumber: number, rowsPerPage: number, sort: string, direction: Direction) => void;
}

interface StateProps {
  vehicleStateStatus: StateStatus
  vehiclePageableListCount: number
  vehiclePageableListPageNumber: number
  vehiclePageableListPageSize: number
  vehiclePageableListContent: Array<any[]>
  vehiclePageableListSort: string
  vehiclePageableListDirection: Direction
  vehiclePageableListKeyword: string
  vehicleErrorCode: number
  vehicleErrorMessage: string
  vehicleErrorUuid: string
}

type Props = StateProps & OwnProps & DispatchProps

const AdministratorVehicleListView = (props: Props) => {
  const [isViewLoaded, setViewLoaded] = useState(false);
  const [isViewLoading, setViewLoading] = useState(true);
  const [showViewAlert, setShowViewAlert] = useState(false);

  const {
    handleSubmit: handleSearchFormSubmit,
    control: searchFormControl,
    setValue: setSearchFormValue
  } = useForm<StateProps>({ mode: 'all' });

  const onSearchFormSubmit: SubmitHandler<StateProps> = (formProps: StateProps) => {
    if (!isViewLoading && formProps.vehiclePageableListKeyword.length > 0) {
      props.vehicleSearchPageableList(formProps.vehiclePageableListKeyword, 0, props.vehiclePageableListPageSize, props.vehiclePageableListSort, props.vehiclePageableListDirection);
    }
  }

  const handleVehiclePageSizeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (props.vehiclePageableListKeyword.length > 0) {
      props.vehicleSearchPageableList(props.vehiclePageableListKeyword, 0, parseInt(event.target.value), props.vehiclePageableListSort, props.vehiclePageableListDirection);
    } else {
      props.vehicleGetPageableList(0, parseInt(event.target.value), props.vehiclePageableListSort, props.vehiclePageableListDirection);
    }
  };

  const handleVehiclePageNumberChange = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    if (props.vehiclePageableListKeyword.length > 0) {
      props.vehicleSearchPageableList(props.vehiclePageableListKeyword, newPage, props.vehiclePageableListPageSize, props.vehiclePageableListSort, props.vehiclePageableListDirection)
    } else {
      props.vehicleGetPageableList(newPage, props.vehiclePageableListPageSize, props.vehiclePageableListSort, props.vehiclePageableListDirection)
    }
  }

  const handleVehicleDirectionChange = (property: string) => () => {
    if (props.vehiclePageableListDirection === Direction.ASC) {
      if (props.vehiclePageableListKeyword.length > 0) {
        props.vehicleSearchPageableList(props.vehiclePageableListKeyword, props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, property, Direction.DESC);
      } else {
        props.vehicleGetPageableList(props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, property, Direction.DESC);
      }
    } else {
      if (props.vehiclePageableListKeyword.length > 0) {
        props.vehicleSearchPageableList(props.vehiclePageableListKeyword, props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, property, Direction.ASC);
      } else {
        props.vehicleGetPageableList(props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, property, Direction.ASC);
      }
    }
  }

  const handleVehicleKeywordClear = () => {
    props.vehicleGetPageableList(props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, props.vehiclePageableListSort, props.vehiclePageableListDirection);
  }

  useEffect(() => {
    if (!isViewLoaded) {
      if (props.vehiclePageableListKeyword.length > 0) {
        props.vehicleSearchPageableList(props.vehiclePageableListKeyword, props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, props.vehiclePageableListSort, props.vehiclePageableListDirection);
      } else {
        props.vehicleGetPageableList(props.vehiclePageableListPageNumber, props.vehiclePageableListPageSize, props.vehiclePageableListSort, props.vehiclePageableListDirection);
      }
      setViewLoaded(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    switch (props.vehicleStateStatus) {
      case IDLE_STATUS: {
        break;
      }
      case LOADING_STATUS: {
        setViewLoading(true);
        break;
      }
      case SUCCESS_STATUS: {
        setSearchFormValue("vehiclePageableListKeyword", props.vehiclePageableListKeyword);
        setViewLoading(false);
        setShowViewAlert(false);
        break;
      }
      case FAILURE_STATUS: {
        setViewLoading(false);
        setShowViewAlert(true);
        break;
      }
    }
  }, [props.vehicleStateStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  const vehicleHeadCells: readonly HeadCell[] = [
    {
      id: "vehicleId",
      numeric: false,
      disablePadding: false,
      label: "Køretøj"
    }
  ]

  if (showViewAlert) {
    return (
      <ErrorContainer errorCode={props.vehicleErrorCode} errorMessage={props.vehicleErrorMessage} errorUuid={props.vehicleErrorUuid} />
    )
  }

  return (
    <>
      <CustomContainer>
        <Typography
          color="textPrimary"
          variant="h2"
        >
          Køretøjer
        </Typography>
        <Grid
          container
          spacing={3}
          direction="row"
          alignItems="flex-start"
          justifyContent="center"
          sx={{
            marginTop: 0,
            marginBottom: 9
          }}
        >
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <form onSubmit={handleSearchFormSubmit(onSearchFormSubmit)}>
                <CardContent>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Grid
                      item
                      lg={9}
                      md={9}
                      xs={8}
                    >
                      <Controller
                        name="vehiclePageableListKeyword"
                        control={searchFormControl}
                        rules={{
                          maxLength: {
                            value: 256,
                            message: "Indtastning må ikke overstige 256 tegn"
                          },
                        }}
                        render={({ field: { onChange, value }, fieldState: { error } }) => (
                          <TextField
                            label="Søgeord"
                            fullWidth
                            value={value || ""}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                          />
                        )}
                      />
                    </Grid>
                    <Grid
                      item
                      lg={3}
                      md={3}
                      xs={4}
                    >
                      <Grid
                        container
                        spacing={1}
                      >
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="primary"
                              fullWidth
                              disabled={isViewLoading}
                              type="submit"
                              variant="contained"
                            >
                              Søg
                            </Button>
                          </Box>
                        </Grid>
                        <Grid
                          item
                          lg={6}
                          md={6}
                          xs={12}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button
                              color="secondary"
                              fullWidth
                              disabled={isViewLoading}
                              onClick={() => { handleVehicleKeywordClear() }}
                              type="button"
                              variant="contained"
                            >
                              Ryd
                            </Button>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </CardContent>
              </form>
            </Card>
          </Grid>
          <Grid
            item
            lg={12}
            md={12}
            xs={12}
          >
            <Card>
              <Table>
                <TableHead>
                  <TableRow>
                    {vehicleHeadCells.map((headCell) => (
                      <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'right' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                      >
                        {headCell.label}
                        <TableSortLabel
                          active={props.vehiclePageableListSort === headCell.id}
                          direction={props.vehiclePageableListDirection}
                          onClick={handleVehicleDirectionChange(headCell.id)}
                        >
                        </TableSortLabel>
                      </TableCell>
                    ))}
                    <TableCell>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isViewLoading ? (
                    <>
                      <FetchingContainer tablePageSize={props.vehiclePageableListContent === undefined || props.vehiclePageableListContent.length === 0 ? props.vehiclePageableListPageSize : props.vehiclePageableListContent.length} tableColSpan={vehicleHeadCells.length} />
                    </>
                  ) : (
                    <>
                      {props.vehiclePageableListCount === undefined || props.vehiclePageableListCount === 0 ? (
                        <>
                          <EmptyContainer tableColSpan={vehicleHeadCells.length} />
                        </>
                      ) : (
                        <>
                          {props.vehiclePageableListContent.map(vehicle => (
                            <TableRow
                              hover
                              key={vehicle[0]}
                            >
                              <TableCell>
                                <Typography
                                  color="textPrimary"
                                  variant="body1"
                                >
                                  {vehicle[1]}
                                </Typography>
                              </TableCell>
                              <TableCell align="right">
                                <Tooltip title="Vis">
                                  <IconButton aria-label="Edit"
                                    edge='end'
                                    component={NavLink}
                                    to={vehicle[0]}
                                  >
                                    <NavigateNext />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, 50, 100]}
                      colSpan={vehicleHeadCells.length + 1}
                      count={props.vehiclePageableListCount || 0}
                      rowsPerPage={props.vehiclePageableListPageSize || 5}
                      page={props.vehiclePageableListPageNumber || 0}
                      labelRowsPerPage="Antal pr. side:"
                      labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count}`}
                      onPageChange={handleVehiclePageNumberChange}
                      onRowsPerPageChange={handleVehiclePageSizeChange}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </Card>
          </Grid>
        </Grid>
      </CustomContainer>
    </>
  );
}

const mapStateToProps = (redux: RootRedux): StateProps => {
  return {
    vehicleStateStatus: redux.vehicle.status,
    vehiclePageableListCount: redux.vehicle.pageableListCount,
    vehiclePageableListPageNumber: redux.vehicle.pageableListPageNumber,
    vehiclePageableListPageSize: redux.vehicle.pageableListPageSize,
    vehiclePageableListContent: redux.vehicle.pageableListContent,
    vehiclePageableListSort: redux.vehicle.pageableListSort,
    vehiclePageableListDirection: redux.vehicle.pageableListDirection,
    vehiclePageableListKeyword: redux.vehicle.pageableListKeyword,
    vehicleErrorCode: redux.vehicle.errorCode,
    vehicleErrorMessage: redux.vehicle.errorMessage,
    vehicleErrorUuid: redux.vehicle.errorUuid
  }
}

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchProps => {
  return {
    vehicleGetPageableList: async (pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(vehicleGetPageableList(pageNumber, rowsPerPage, sort, direction))
    },
    vehicleSearchPageableList: async (keyword, pageNumber, rowsPerPage, sort, direction) => {
      await dispatch(vehicleSearchPageableList(keyword, pageNumber, rowsPerPage, sort, direction))
    }
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(AdministratorVehicleListView);