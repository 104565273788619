import DTO from "dto/dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebTicketDTO implements DTO {

  ticketUuid!: string;
  ticketTicketId!: string;
  ticketGuardId!: number;
  ticketParkingAreaCode!: string;
  ticketAddress!: string;
  ticketZip!: string;
  ticketCity!: string;
  ticketVehicleId!: string;
  ticketNationality!: string;
  ticketObservationStart!: Date;
  ticketObservationStop!: Date;
  ticketPaymentAmount!: number;
  ticketParkingTime!: string;
  ticketParkingMeter!: string;
  ticketBrand!: string;
  ticketViolation!: string;
  ticketNotes!: string;
  ticketIsPayed!: boolean;
  ticketIsEnabled!: boolean;
  ticketIsPrinted!: boolean;
  userGuardUuid!: string;
  userGuardUsername!: string;
  abstractParkingAreaUuid!: string;
  abstractParkingAreaParkingAreaCode!: string;
  vehicleUuid!: string;
  vehicleVehicleId!: string;
}