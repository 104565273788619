import { AxiosResponse } from "axios";
import V1WebRootInitUserDTO from "dto/v1/web/v1webrootinituser.dto";
import { AnyAction } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { FAILURE_STATUS, LOADING_STATUS, StateStatus, SUCCESS_STATUS } from "redux/statestatus";
import v1WebRootService from "service/v1/web/v1webroot.service";
import { UserType } from "type/user.type";
import { RootState } from "./root.reducer";

export const ROOT_INIT_USER = "root/init/user";

export interface RootAction {
  type:
    typeof ROOT_INIT_USER
  status: StateStatus
  username: string
  emailAddress: string
  firstName: string
  lastName: string
  userType: UserType
  addons: string[]
  errorCode: number
  errorMessage: string
  errorUuid: string
}

export type RootThunkAction = ThunkAction<Promise<void>, RootState, any, RootAction>;

export function rootInitUser(): RootThunkAction {
  return async(dispatch: ThunkDispatch<{}, {}, AnyAction>) => {
    return new Promise<void>((resolve) => {
      dispatch({
        type: ROOT_INIT_USER,
        status: LOADING_STATUS
      });

      v1WebRootService.initUser().then((response: AxiosResponse) => {
        let v1WebRootInitUserDTO: V1WebRootInitUserDTO = response.data as V1WebRootInitUserDTO;

        dispatch({
          type: ROOT_INIT_USER,
          status: SUCCESS_STATUS,
          username: v1WebRootInitUserDTO.username,
          emailAddress: v1WebRootInitUserDTO.emailAddress,
          firstName: v1WebRootInitUserDTO.firstName,
          lastName: v1WebRootInitUserDTO.lastName,
          userType: v1WebRootInitUserDTO.userType,
          addons: v1WebRootInitUserDTO.addons
        });
      }).catch((error) => {
        dispatch({
          type: ROOT_INIT_USER,
          status: FAILURE_STATUS,
          errorCode: error.errorCode,
          errorMessage: error.errorMessage,
          errorUuid: error.errorUuid
        });
      });

      resolve();
    });
  }
}