import { ParkingAreaType } from "type/parkingarea.type";
import V1WebAbstractParkingAreaDTO from "./v1webabstractparkingarea.dto";

/**
 * @author Von Frank - https://vonfrank.dk
 */

export default class V1WebParkingAreaDayDTO extends V1WebAbstractParkingAreaDTO {

  parkingAreaDayNumberOfDays!: number;
  parkingAreaDayHourOfDay!: number;
  parkingAreaDayMinuteOfHour!: number;

  constructor() {
    super();
    this.abstractParkingAreaAreaType = ParkingAreaType.DAY;
  }
}