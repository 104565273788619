import { axiosConfig } from "axios/axios.config";
import Page from "dto/page";
import V1KeywordDTO from "dto/v1/v1keyword.dto";
import V1WebAbstractUserDTO from "dto/v1/web/v1webabstractuser.dto";
import V1WebAddUserClientDTO from "dto/v1/web/v1webadduserclient.dto";
import V1WebWelcomeUserDTO from "dto/v1/web/v1webwelcomeuser.dto";

const baseUrl = "v1/web/user";

class V1WebUserService {
  existsUserClientPublicAsUserClientOperator(usernameOrEmail: string) {
    return axiosConfig.get(baseUrl + "/userclientpublic/exists/" + usernameOrEmail);
  }
  getUserClientParkPermitPageableList(userClientUuid: string, page: Page) {
    return axiosConfig.get(baseUrl + "/" + userClientUuid + "/parkpermit?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getUserClientAreaParkingAreaPageableList(userClientAreaUuid: string, page: Page) {
    return axiosConfig.get(baseUrl + "/" + userClientAreaUuid + "/parkingarea?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getUserClientAreaOperatorUserClientPageableList(userClientAreaOperatorUuid: string, page: Page) {
    return axiosConfig.get(baseUrl + "/" + userClientAreaOperatorUuid + "/userclient?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchUserClientPageableListForUserClientAreaOperatorAsUserClientAreaOperator(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post(baseUrl + "/userclientoperator/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  createUser(v1WebAbstractUserDto: V1WebAbstractUserDTO) {
    return axiosConfig.post("v1/web/user/", v1WebAbstractUserDto);
  }
  getUser(userUuid: string) {
    return axiosConfig.get("v1/web/user/" + userUuid);
  }
  getUsers(page: Page) {
    return axiosConfig.get("v1/web/user?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  searchUser(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/user/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  searchUserClient(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/user/search/userclient?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), keyword);
  }
  searchUserOperatorOwnParkingLimitation(keyword: V1KeywordDTO, page: Page) {
    return axiosConfig.post("v1/web/user/parkinglimitation/search?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase())
  }
  existsUsernameOrEmail(usernameOrEmail: string) {
    return axiosConfig.get("v1/web/user/usernameoremail/" + usernameOrEmail);
  }
  getUserAuthority(userUuid: string, page: Page) {
    return axiosConfig.get("v1/web/user/" + userUuid + "/addon?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getUserToken(userUuid: string, page: Page) {
    return axiosConfig.get("v1/web/user/" + userUuid + "/token?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase());
  }
  getUserOperatorOwnParkingLimitation(page: Page) {
    return axiosConfig.get("v1/web/user/parkinglimitation?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase())
  }
  putUser(v1WebAbstractUserDto: V1WebAbstractUserDTO) {
    return axiosConfig.put("v1/web/user/", v1WebAbstractUserDto);
  }
  putUserAuthorities(userUuid: string, page: Page, authorities: string[]) {
    return axiosConfig.put("v1/web/user/" + userUuid + "/addon?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), authorities);
  }
  putUserClients(userUuid: string, page: Page, v1WebAddUserClientDto: V1WebAddUserClientDTO) {
    return axiosConfig.put("v1/web/user/" + userUuid + "/userclient?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase(), v1WebAddUserClientDto)
  }
  putAreas(userUuid: string, areaUuid: string, page: Page) {
    return axiosConfig.put("v1/web/user/" + userUuid + "/area/" + areaUuid + "?page=" + page.pageNumber + "&size=" + page.rowsPerPage + "&sort=" + page.sort + "&direction=" + page.direction.toUpperCase())
  }
  welcomeUser(v1WebWelcomeUserDto: V1WebWelcomeUserDTO) {
    return axiosConfig.put("v1/web/user/welcome", v1WebWelcomeUserDto);
  }
}

export default new V1WebUserService();