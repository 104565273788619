import { ThemeProvider } from "@mui/material";
import React from "react";
import { useRoutes } from "react-router-dom";
import theme from "theme/theme";
import mainRoute from "route/main.route";

const App: React.FC = () => {
  const routing = useRoutes(mainRoute());
  
  return (
    <ThemeProvider theme={theme}>
      {routing}
    </ThemeProvider>
  );
}

export default App;