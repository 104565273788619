import { Box, Container, styled } from "@mui/material";
import Logo from "component/logo";
import React from "react";
import { Outlet } from "react-router-dom";

const Root = styled("div")(({theme}) =>({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%"
}));

const Wrapper = styled("div")(({theme}) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: 64
}));

const ContentContainer = styled("div")(({theme}) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden"
}));

const Content = styled("div")(({theme}) => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto"
}));

const MainLayout: React.FC = () => {
  return (
    <Root>
      <Wrapper>
        <ContentContainer>
          <Content>
            <Box
              display="flex"
              flexDirection="column"
              height="100%"
              justifyContent="center"
            >
              <Container
                maxWidth="sm"
              >
                <Box mb={4}>
                  <Logo />
                </Box>
                <Outlet />
              </Container>
            </Box>
          </Content>
        </ContentContainer>
      </Wrapper>
    </Root>
  );
};

export default MainLayout;