import { Navigate } from "react-router-dom";
import MainLayout from "layout/mainlayout/main.layout";
import AdministratorLayout from "layout/administratorlayout/administrator.layout";
import GuardLayout from "layout/guardlayout/guard.layout";
import KioskLayout from "layout/kiosklayout/kiosk.layout";
import OperatorLayout from "layout/operatorlayout/operator.layout";
import NotFoundView from "view/common/notfound.view";
import LoginView from "view/common/login.view";
import AccountView from "view/common/account.view";
import ForgotPasswordView from "view/common/forgotpassword.view";
import ResetPasswordView from "view/common/resetpassword.view";
import ActivateUserView from "view/common/activateuser.view";
import AdministratorVehicleListView from "view/administrator/administratorvehiclelist.view";
import AdministratorVehicleView from "view/administrator/administratorvehicle.view";
import AdministratorParkingAreaListView from "view/administrator/administratorparkingarealist.view";
import AdministratorParkingAreaView from "view/administrator/administratorparkingarea.view";
import AdministratorParkPermitListView from "view/administrator/administratorparkpermitlist.view";
import AdministratorParkPermitView from "view/administrator/administratorparkpermit.view";
import AdministratorCustomerListView from "view/administrator/administratorcustomerlist.view";
import AdministratorCustomerView from "view/administrator/administratorcustomer.view";
import AdministratorTicketListView from "view/administrator/administratorticketlist.view";
import AdministratorTicketView from "view/administrator/administratorticket.view";
import AdministratorUserListView from "view/administrator/administratoruserlist.view";
import AdministratorUserView from "view/administrator/administratoruser.view";
import AuthenticatedView from "view/common/authenticated.view";
import KioskParkPermitListView from "view/kiosk/kioskparkpermitlist.view";
import KioskParkPermitView from "view/kiosk/kioskparkpermit.view";
import OperatorParkPermitListView from "view/operator/operatorparkpermitlist.view";
import OperatorParkPermitView from "view/operator/operatorparkpermit.view";
import OperatorResidentListView from "view/operator/operatorresidentlist.view";
import OperatorResidentView from "view/operator/operatorresident.view";

const MainRoute = () => [
  {
    path: "/administrator",
    element: <AuthenticatedView><AdministratorLayout /></AuthenticatedView>,
    children: [
      { path: "vehicle", element: <AdministratorVehicleListView /> },
      { path: "vehicle/:vehicleUuid", element: <AdministratorVehicleView /> },
      { path: "parkingarea", element: <AdministratorParkingAreaListView /> },
      { path: "parkingarea/:parkingAreaUuid", element: <AdministratorParkingAreaView /> },
      { path: "parkpermit", element: <AdministratorParkPermitListView /> },
      { path: "parkpermit/:parkPermitUuid", element: <AdministratorParkPermitView /> },
      { path: "customer", element: <AdministratorCustomerListView /> },
      { path: "customer/:customerUuid", element: <AdministratorCustomerView /> },
      { path: "ticket", element: <AdministratorTicketListView /> },
      { path: "ticket/:ticketUuid", element: <AdministratorTicketView /> },
      { path: "user", element: <AdministratorUserListView /> },
      { path: "user/:userUuid", element: <AdministratorUserView /> },
      { path: "account", element: <AccountView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "", element: <Navigate to="/administrator/account" /> },
      { path: "*", element: <Navigate to="/administrator/404" /> }
    ]
  },
  {
    path: "/guard",
    element: <AuthenticatedView><GuardLayout /></AuthenticatedView>,
    children: [
      { path: "account", element: <AccountView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "", element: <Navigate to="/guard/account" /> },
      { path: "*", element: <Navigate to="/guard/404" /> }
    ]
  },
  {
    path: "/kiosk",
    element: <AuthenticatedView><KioskLayout /></AuthenticatedView>,
    children: [
      { path: "parkpermit", element: <KioskParkPermitListView /> },
      { path: "parkpermit/:parkPermitUuid", element: <KioskParkPermitView /> },
      { path: "account", element: <AccountView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "", element: <Navigate to="/kiosk/parkpermit" /> },
      { path: "*", element: <Navigate to="/kiosk/404" /> }
    ]
  },
  {
    path: "/operator",
    element: <AuthenticatedView><OperatorLayout /></AuthenticatedView>,
    children: [
      { path: "parkpermit", element: <OperatorParkPermitListView /> },
      { path: "parkpermit/:parkPermitUuid", element: <OperatorParkPermitView /> },
      { path: "resident", element: <OperatorResidentListView /> },
      { path: "resident/:residentUuid", element: <OperatorResidentView />},
      { path: "account", element: <AccountView /> },
      { path: "404", element: <NotFoundView /> },
      { path: "", element: <Navigate to="/operator/account" /> },
      { path: "*", element: <Navigate to="/operator/404" /> }
    ]
  },
  {
    path: "/",
    element: <MainLayout />,
    children: [
      { path: "login", element: <LoginView /> },
      { path: "forgotpassword", element: <ForgotPasswordView /> },
      { path: "resetpassword/:base64ResetToken", element: <ResetPasswordView /> },
      { path: "activateuser/:base64ActivateToken", element: <ActivateUserView />},
      { path: "404", element: <NotFoundView /> },
      { path: "/", element: <Navigate to="/login" /> },
      { path: "*", element: <Navigate to="/404" /> }
    ]
  }
];

export default MainRoute;